import React, { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { SalesChart, SalesChartType } from '../SalesChart'
import styles from './WidgetSalesSchedule.module.scss'

type Props = {}

const WidgetSalesSchedule: FC<Props> = ({}) => {
    const { t } = useTranslation()

    const [type, setType] = useState<SalesChartType>(SalesChartType.Today)

    return (
        <div className={styles.root}>
            <div className={styles.head}>
                {t('1_WidgetSalesSchedule_salesSchedule')}
            </div>

            <div className={styles.body}>
                <SalesChart type={type} onChangeType={setType} />
            </div>
        </div>
    )
}

export default WidgetSalesSchedule

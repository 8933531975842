import React, { FC } from 'react'
import classNames from 'classnames'
import styles from './Table.module.scss'

type Props = {
    align?: 'left' | 'center' | 'right' | 'justify'
    addClassName?: string
}

const Td: FC<Props> = ({ align = 'left', addClassName, children }) => {
    return (
        <td className={classNames(styles.td, styles[align], addClassName)}>
            {children}
        </td>
    )
}

export default Td

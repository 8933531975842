import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { SubHeader } from '../SubHeader'
import styles from './IncomeInfo.module.scss'

type Props = {
    incomeMonth: number
    incomeWeek: number
    incomeToday: number
}
const IncomeInfo: FC<Props> = ({ incomeMonth, incomeWeek, incomeToday }) => {
    const { t } = useTranslation()

    return (
        <div className={styles.root}>
            <SubHeader>{t('61_IncomInfo_revenueInformation')}</SubHeader>

            <div className={styles.content}>
                <div className={styles.block}>
                    <div className={styles.blockTitle}>
                        {t('62_IncomInfo_perDay')}
                    </div>
                    <div className={styles.blockValue}>{incomeToday}</div>
                </div>

                <div className={styles.block}>
                    <div className={styles.blockTitle}>
                        {t('63_IncomInfo_forLastWeek')}
                    </div>
                    <div className={styles.blockValue}>{incomeWeek}</div>
                </div>

                <div className={styles.block}>
                    <div className={styles.blockTitle}>
                        {t('64_IncomInfo_forLastMonth')}
                    </div>
                    <div className={styles.blockValue}>{incomeMonth}</div>
                </div>
            </div>
        </div>
    )
}

export default IncomeInfo

import { endpoints } from '../constants'
import api from '../utils/api'
import { route } from '../utils/route'
import { GoodsItem as GoodsItemType } from '../types'

type GetListParams = {
    search?: string
    offset?: number
    limit?: number
}

type GetListResponse = {
    data: Array<GoodsItemType>
    baseUrl: string
}

type CreateParams = {
    name: string
    barcode: string
    brandId: number
    photo?: File
}

type CreateResponse = {
    data: GoodsItemType
    baseUrl: string
}

type UpdateParams = {
    name?: string
    barcode?: string
    brandId?: number
    photo?: File
}

type UpdateResponse = {
    data: GoodsItemType
    baseUrl: string
}

export class Goods {
    static async getList(
        params: GetListParams = {}
    ): Promise<Array<GoodsItemType>> {
        const { search = '', offset = 0, limit = 30 } = params

        const queries = []
        if (search) {
            queries.push(`search=${search}`)
        }
        if (offset) {
            queries.push(`offset=${offset}`)
        }
        if (limit) {
            queries.push(`limit=${limit}`)
        }

        let endpoint = endpoints.getGoodsList
        if (queries.length > 0) {
            endpoint += `?` + queries.join('&')
        }

        const response = await api.get<GetListResponse>(endpoint)

        return response.data.data.map((goodsItem) =>
            Goods._prepareGoodsItem(goodsItem, response.data.baseUrl)
        )
    }

    static async create(params: CreateParams): Promise<GoodsItemType> {
        const { name, barcode, brandId, photo } = params

        const data = new FormData()
        data.set('name', name)
        data.set('barcode', barcode)
        data.set('brandId', brandId + '')

        if (photo) {
            data.set('photo', photo)
        }

        const response = await api.post<CreateResponse>(
            endpoints.createGoodsItem,
            data
        )

        return Goods._prepareGoodsItem(
            response.data.data,
            response.data.baseUrl
        )
    }

    static async update(
        id: number,
        params: UpdateParams
    ): Promise<GoodsItemType> {
        const { name, barcode, brandId, photo } = params

        const data = new FormData()

        if (name) {
            data.set('name', name)
        }
        if (barcode) {
            data.set('barcode', barcode)
        }
        if (brandId) {
            data.set('brandId', brandId + '')
        }
        if (photo) {
            data.set('photo', photo)
        }

        const response = await api.post<UpdateResponse>(
            route(endpoints.updateGoodsItem, { id }),
            data
        )

        return Goods._prepareGoodsItem(
            response.data.data,
            response.data.baseUrl
        )
    }

    static async deleteGoodsItem(id: number): Promise<void> {
        await api.delete(route(endpoints.deleteGoodsItem, { id }))
    }

    static async import(file: File): Promise<void> {
        const data = new FormData()
        data.set('file', file)

        await api.post(endpoints.importGoods, data)
    }

    static async export(): Promise<Blob> {
        const response = await api.get(endpoints.exportGoods, {
            responseType: 'blob',
        })

        return response.data
    }

    static _prepareGoodsItem(
        goodsItem: GoodsItemType,
        baseUrl: string
    ): GoodsItemType {
        return {
            ...goodsItem,
            photoPath: goodsItem.photoPath
                ? baseUrl + goodsItem.photoPath
                : null,
        }
    }
}

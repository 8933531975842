import React, {
    FC,
    useEffect,
    useState,
    useMemo,
    useRef,
    useCallback,
} from 'react'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import i18n from '../../../locale/i18n'
import styles from './LanguageSelect.module.scss'

enum Languages {
    RU = 'ru',
    EN = 'en',
    IT = 'it',
}

const defaultOptions = [
    { value: Languages.RU, label: 'Русский' },
    { value: Languages.EN, label: 'English' },
    { value: Languages.IT, label: 'italiano' },
]

const LanguageSelect: FC = () => {
    const { t } = useTranslation()

    // Текущее значение
    const [current, setCurrent] = useState<Languages>(Languages.EN)
    useEffect(() => {
        const setLanguage = (lang?: string) => {
            if (lang) {
                if (lang === 'ru') {
                    setCurrent(Languages.RU)
                } else if (lang === 'it') {
                    setCurrent(Languages.IT)
                }
            } else if (i18n.language === 'ru') {
                setCurrent(Languages.RU)
            } else if (i18n.language === 'it') {
                setCurrent(Languages.IT)
            }
        }

        setLanguage()
        i18n.on('onLanguageChanged', setLanguage)

        return () => {
            i18n.off('onLanguageChanged', setLanguage)
        }
    }, [])
    const handleOptionClick = async (value: Languages) => {
        close()
        await i18n.changeLanguage(value)
        setCurrent(value)
    }

    // Опции
    const options = useMemo(() => {
        const currentOptions = defaultOptions.find((o) => o.value === current)

        if (!currentOptions) {
            return defaultOptions
        }

        return [
            currentOptions,
            ...defaultOptions.filter((o) => o.value !== current),
        ]
    }, [current])

    // Состояние открытия
    const selectRef = useRef<HTMLDivElement>(null)
    const [isOpen, setIsOpen] = useState(false)
    const toggleOpen = () => {
        if (isOpen) {
            close()
        } else {
            open()
        }
    }

    // Обработчик по клику по любой части документа
    // Если клик происходит внутри селектора, то ничего не делается
    const onHandleClickDocument = useCallback((event: MouseEvent) => {
        if (
            event &&
            event.target &&
            selectRef &&
            selectRef.current &&
            selectRef.current.contains(event.target as HTMLElement)
        ) {
            return
        }

        if (close) {
            close()
        }
    }, [])

    // Функция открытия
    const open = useCallback(() => {
        setIsOpen(true)
        // @ts-ignore
        document.addEventListener('click', onHandleClickDocument)
    }, [setIsOpen])

    // Функция закрытия
    const close = useCallback(() => {
        setIsOpen(false)
        // @ts-ignore
        document.removeEventListener('click', onHandleClickDocument)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setIsOpen])

    return (
        <div
            className={classNames(styles.root, { [styles.open]: isOpen })}
            ref={selectRef}
        >
            <div className={styles.controller} onClick={toggleOpen}>
                {current}
            </div>

            <div className={styles.menu}>
                <div className={styles.menuLabel}>
                    {t('476_LanguageSelect_selectLanguage')}
                </div>
                <div className={styles.menuOptionsList}>
                    {options.map((option) => (
                        <div
                            className={classNames(styles.menuOptionsItem, {
                                [styles.active]: option.value === current,
                            })}
                            onClick={() => handleOptionClick(option.value)}
                            key={option.value}
                        >
                            {option.label}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default LanguageSelect

import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AutomatFilterType } from '../../components/complexes/AutomatsFilter/AutomatsFilter'
import { RootState } from '../index'

const initFilterAuto: AutomatFilterType = {
    minDay: '',
    minWeek: '',
    minMonth: '',
    maxDay: '',
    maxWeek: '',
    maxMonth: '',
    status: [],
    address: '',
}

const initialState = {
    automatFilter: initFilterAuto,
}

export const filtersSlice = createSlice({
    name: 'filters',
    initialState,
    reducers: {
        setFilter: (state, action: PayloadAction<AutomatFilterType>) => {
            state.automatFilter = action.payload
        },
        resetFilter: (state) => {
            state.automatFilter = initFilterAuto
        },
    },
})

export const { setFilter, resetFilter } = filtersSlice.actions

export const selectAutoFilter = (state: RootState) =>
    state.filters.automatFilter

export default filtersSlice.reducer

import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AutomatKioskGoodsItem } from '../../../../types'
import { Row } from './Row'
import { AddButton } from '../../../simples/AddButton'

import styles from './Goods.module.scss'

type Props = {
    goods: Array<AutomatKioskGoodsItem>
    onChange: (goods: Array<AutomatKioskGoodsItem>) => void
}

const Goods: FC<Props> = ({ goods, onChange }) => {
    const { t } = useTranslation()
    const [newCount, setNewCount] = useState(() => (goods.length === 0 ? 1 : 0))

    useEffect(() => {
        if (goods.length === 0 && newCount < 1) {
            setNewCount(1)
        }
    }, [goods])

    const handleGoodsItemChange = (goodsItem: AutomatKioskGoodsItem) => {
        onChange &&
            onChange(
                goods.map((gi) => {
                    if (gi.goodsItemId === goodsItem.goodsItemId) {
                        return goodsItem
                    }

                    return gi
                })
            )
    }

    const handleGoodsItemDelete = (goodsItem?: AutomatKioskGoodsItem) => {
        if (!goodsItem) {
            throw new Error('Goods item is empty')
        }

        const updatedGoods = goods.filter(
            (gi) => gi.goodsItemId !== goodsItem.goodsItemId
        )
        onChange && onChange(updatedGoods)

        if (updatedGoods.length === 0 && newCount === 0) {
            setNewCount(1)
        }
    }

    const handleNewGoodsItemChange = (goodsItem: AutomatKioskGoodsItem) => {
        if (newCount < 1) {
            throw new Error('No new goods items')
        }

        onChange && onChange([...goods, goodsItem])
        setNewCount(newCount - 1)
    }

    const handleNewGoodsItemDelete = () => {
        if (
            (goods.length > 0 && newCount > 0) ||
            (goods.length === 0 && newCount > 1)
        ) {
            setNewCount(newCount - 1)
        }
    }

    const handleGoodsItemAdd = () => {
        setNewCount(newCount + 1)
    }

    return (
        <div className={styles.root}>
            <table>
                <thead>
                    <tr>
                        <th>{t('135_FillingEditor_brand')}</th>
                        <th>{t('641_Goods_Goods_item')}</th>
                        <th className={styles.rowPrice}>
                            {t('642_Goods_Price')}
                        </th>
                        <th className={styles.actions} />
                    </tr>
                </thead>
                <tbody>
                    {goods.map((goodsItemData, index) => (
                        <Row
                            key={index}
                            data={goodsItemData}
                            automatGoods={goods}
                            onChange={handleGoodsItemChange}
                            onDelete={handleGoodsItemDelete}
                        />
                    ))}

                    {Array(newCount)
                        .fill(1)
                        .map((_, index) => (
                            <Row
                                key={`new_${index}`}
                                automatGoods={goods}
                                onChange={handleNewGoodsItemChange}
                                onDelete={handleNewGoodsItemDelete}
                            />
                        ))}

                    <tr>
                        <td>
                            <AddButton
                                label={t('644_Goods_Add_goods_item')}
                                onClick={handleGoodsItemAdd}
                            />
                        </td>
                        <td className={styles.rowPrice} />
                        <td className={styles.actions} />
                    </tr>
                </tbody>
            </table>
        </div>
    )
}

export default Goods

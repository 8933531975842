import React, { FC } from 'react'
import classNames from 'classnames'
import { Preview } from '../../simples/Preview'
import styles from './AdMaterialsFinder.module.scss'

type Props = {
    id: number
    photo: string | null
    name: string
    checked?: boolean
    onChecked?: (checked: boolean, adMaterialId: number) => void
}

export const AdMaterial: FC<Props> = ({
    id,
    photo,
    name,
    checked = false,
    onChecked,
}) => {
    const handleClick = () => {
        onChecked && onChecked(!checked, id)
    }

    return (
        <div
            className={classNames(styles.adMaterialItem, {
                [styles.checked]: checked,
            })}
            onClick={handleClick}
        >
            <div className={styles.adMaterialItemPhoto}>
                <Preview
                    src={photo}
                    className={styles.adMaterialItemPhotoPreview}
                />
                <div className={styles.adMaterialsCheckbox} />
            </div>
            <div className={styles.adMaterialItemName}>{name}</div>
        </div>
    )
}

import { TFunction } from 'i18next'

export const validateDate = (date: string) => {
    const darr = date.split('-')
    const month = darr[1].split('')
    const day = darr[2].split('')

    if (
        (+month[0] === 1 && +month[1] > 2) ||
        +month[0] > 1 ||
        +day[0] > 3 ||
        (+day[0] === 3 && +day[1] > 1) ||
        darr[1] === '00' ||
        darr[2] === '00'
    ) {
        return false
    }
    return true
}

const passwordRegExp =
    /^[a-zA-Z0-9\`\-\=\[\]\;\’\,\.\/\~\!\@\#\$\%\^\&\*\(\)\_\+\{\}\:\”\<\>\?]*$/

export const validatePassword = (value: string, t: TFunction) => {
    if (!passwordRegExp.test(value)) {
        return t('468_validate_incorrectPassword')
    }

    return ''
}

export const validateLogin = (value: string, t: TFunction) => {
    if (!passwordRegExp.test(value)) {
        return t('474_validate_incorrectLogin')
    }

    return ''
}

import React, { FC, useEffect, useMemo, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { AutomatType, Order as OrderType } from '../../../types'
import { Automats as AutomatsService } from '../../../services/Automats'
import {
    Orders as OrdersService,
    SortOrderBy as OrdersSortOrderBy,
    SortOrder as OrdersSortOrder,
} from '../../../services/Orders'
import { Layout } from '../../complexes/Layout'
import { AutomatTabs } from '../../complexes/automat/AutomatTabs'
import { SubHeader } from '../../complexes/automat/SubHeader'
import { OrdersChart } from '../../complexes/automat/OrdersChart'
import { OrderTable } from '../../complexes/automat/OrdersTable'
import { SalesChart, SalesChartType } from '../../complexes/SalesChart'
import styles from './AutomatOrders.module.scss'
import { Search } from '../../simples/Search'
import { Button } from '../../simples/Button'
import moment, { Moment } from 'moment'
import { OrdersExport } from '../../complexes/OrdersExport'
import { getTimezone } from '../../../utils/date'

const getStartByType = (type: SalesChartType): Date => {
    let startOfPeriod: Moment = moment()

    if (type === SalesChartType.PerYear) {
        startOfPeriod = moment().startOf('year')
    } else if (type === SalesChartType.PerMonth) {
        startOfPeriod = moment().startOf('month')
    } else if (type === SalesChartType.PerWeek) {
        startOfPeriod = moment().startOf('isoWeek')
    } else {
        startOfPeriod = moment().startOf('day')
    }

    const timezone = getTimezone()

    if (timezone > 0) {
        startOfPeriod.subtract(timezone, 'hours')
    } else if (timezone < 0) {
        startOfPeriod.add(Math.abs(timezone), 'hours')
    }

    return startOfPeriod.toDate()
}

const downloadButtonClassNames = {
    button: styles.downloadButton,
}

type RouterParams = {
    id: string
}

const AutomatOrders: FC = () => {
    const { t } = useTranslation()
    const { id } = useParams<RouterParams>()

    const [automat, setAutomat] = useState<AutomatType>()
    useEffect(() => {
        AutomatsService.getItem(+id).then((automat) => {
            setAutomat(automat)
        })
    }, [])

    const [type, setType] = useState<SalesChartType>(SalesChartType.Today)
    // console.log(type)

    const [orders, setOrders] = useState<Array<OrderType>>([])
    const ordersCurrent = useRef<Array<OrderType>>(orders)
    ordersCurrent.current = orders
    const [ordersLoading, setOrdersLoading] = useState(false)
    const ordersOffset = useRef(0)
    const ordersHas = useRef(true)
    const ordersOrderBy = useRef<OrdersSortOrderBy>()
    const ordersOrder = useRef<OrdersSortOrder>()
    const start = useMemo(() => {
        return getStartByType(type)
    }, [type])
    const startRef = useRef(start)
    startRef.current = start
    const search = useRef('')

    const loadOrders = async () => {
        if (!ordersHas.current || ordersLoading) {
            return
        }

        setOrdersLoading(true)

        const result = await OrdersService.getList({
            automatId: +id,
            offset: ordersOffset.current,
            start: startRef.current,
            orderBy: ordersOrderBy.current,
            order: ordersOrder.current,
            search: search.current,
        })

        setOrdersLoading(false)

        if (!result || result.length === 0) {
            ordersHas.current = false
        }

        const newOrders = [...ordersCurrent.current, ...result]
        ordersOffset.current = newOrders.length
        setOrders(newOrders)
    }

    const clear = async () => {
        ordersOffset.current = 0
        ordersHas.current = true
        ordersCurrent.current = []
        setOrders([])
        await loadOrders()
    }

    const handleEndReached = async () => {
        if (orders.length > 0) {
            await loadOrders()
        }
    }

    const handleSortChange = async (
        orderBy: OrdersSortOrderBy,
        order: OrdersSortOrder
    ) => {
        clear()
        ordersOrderBy.current = orderBy
        ordersOrder.current = order
    }

    const handleSearch = async (text: string) => {
        clear()
        search.current = text
    }

    const [showOrdersExport, setShowOrdersExport] = useState(false)
    // console.log('order', odrders)

    useEffect(() => {
        clear().then()
    }, [type])

    return (
        <>
            <Layout onEndReached={handleEndReached}>
                <h1 className={styles.header}>
                    {t('324_AutomatOrders_automateControl')} "
                    {automat ? automat.name : ''}"
                </h1>

                <AutomatTabs automatId={+id} />

                <SubHeader>{t('325_AutomatOrders_automatesSales')}</SubHeader>

                <div className={styles.chart}>
                    <SalesChart
                        type={type}
                        onChangeType={setType}
                        automatId={+id}
                    />
                </div>

                <div className={styles.panel}>
                    <div className={styles.searchContainer}>
                        <Search onSearch={handleSearch} />
                    </div>
                    <div className={styles.downloadButtonContainer}>
                        <Button
                            classNames={downloadButtonClassNames}
                            onClick={() => setShowOrdersExport(true)}
                        >
                            {t('510_OrderSchedule_export')}
                        </Button>
                    </div>
                </div>

                <div className={styles.ordersTableContainer}>
                    <div className={styles.ordersTableTitle}>
                        {t('492_OrderSchedule_salesTable')}
                    </div>
                    <OrderTable
                        orders={orders}
                        loading={ordersLoading}
                        onSortChange={handleSortChange}
                    />
                </div>
            </Layout>

            {showOrdersExport && (
                <OrdersExport
                    onClose={() => setShowOrdersExport(false)}
                    start={start}
                    automatId={+id}
                />
            )}
        </>
    )
}

export default AutomatOrders

import React, { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useAppSelector } from '../../hooks/store'
import { selectTastes } from '../../../store/slices/storage'
import { MultiSelector } from '../../simples/MultiSelector'

type Props = {
    value: Array<number>
    onChange: (value: Array<number>) => void
    isDisabled?: boolean
}

const TastesSelector: FC<Props> = ({ value, onChange, isDisabled }) => {
    const { t } = useTranslation()

    const tastes = useAppSelector(selectTastes)

    const options = useMemo(() => {
        return tastes.map((t) => {
            const labelParts: Array<string> = []
            if (t.name) {
                labelParts.push(t.name)
            }

            if (t.product) {
                if (t.product.name) {
                    labelParts.push(t.product.name)
                }

                if (t.product.brand && t.product.brand.name) {
                    labelParts.push(t.product.brand.name)
                }
            }

            return {
                value: t.id,
                label: labelParts.join(' | '),
            }
        })
    }, tastes)

    return (
        <MultiSelector
            options={options}
            value={value}
            onChange={onChange}
            isDisabled={isDisabled}
            placeholder={t('479_TasteSelector_placeholder')}
        />
    )
}

export default TastesSelector

import React, { FC, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'
import { User as UserService } from '../../../services/User'
import { routes } from '../../../config'
import { useValidator } from '../../hooks/validator'
import { validateLogin } from '../../../utils/validate'
import { AuthLayout } from '../../complexes/AuthLayout'
import { TextField, TextFieldStatus } from '../../simples/TextField'
import { Button } from '../../simples/Button'
import styles from './RecoveryPassword.module.scss'

const loginValidateConfig = {
    required: true,
    max: 100,
    validateFunc: validateLogin,
}

const RecoveryPassword: FC = () => {
    const { t } = useTranslation()

    const history = useHistory()

    const {
        value: account,
        setValue: setAccount,
        error: accountError,
        setError: setAccountError,
        touched: accountTouched,
        setTouched: setAccountTouched,
    } = useValidator('', loginValidateConfig, t)
    const isValid = !accountError

    const [loading, setLoading] = useState(false)

    const handleClick = async () => {
        if (!isValid) {
            throw new Error('Invalid form')
        }

        setAccountTouched(true)

        setLoading(true)
        try {
            await UserService.restorePassword(account)
            history.push(routes.recoveryPasswordSuccess)
        } catch (error) {
            if (
                error.response &&
                error.response.data &&
                _.isArray(error.response.data.errors)
            ) {
                setAccountError(error.response.data.errors.join(', '))
            }
        }
        setLoading(false)
    }

    return (
        <AuthLayout>
            <div className={styles.root}>
                <h3 className={styles.header}>
                    {t('435_RecoveryPassword_recoveryPassword')}
                </h3>

                <p className={styles.text}>
                    {t('436_RecoveryPassword_enterYourUsername')}
                </p>

                <div className={styles.input}>
                    <TextField
                        value={account}
                        onChange={(event) => setAccount(event.target.value)}
                        onBlur={() => setAccountTouched(true)}
                        status={
                            accountError && accountTouched
                                ? TextFieldStatus.Error
                                : undefined
                        }
                        note={
                            accountError && accountTouched ? accountError : ''
                        }
                    />
                </div>

                <Button
                    disabled={!isValid}
                    loading={loading}
                    onClick={handleClick}
                    classNames={{ button: styles.button }}
                >
                    {t('437_RecoveryPassword_recover')}
                </Button>
            </div>
        </AuthLayout>
    )
}

export default RecoveryPassword

import React, {
    FC,
    useEffect,
    useRef,
    useState,
    useMemo,
    useCallback,
} from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useAppSelector, useAppDispatch } from '../../hooks/store'
import {
    selectBrands,
    loadBrands,
    fetchBaseDosages,
} from '../../../store/slices/storage'
import { routes } from '../../../config'
import { productType } from '../../../constants'
import { ProductsService } from '../../../services/Products'
import { setProduct } from '../../../store/slices/edit'
import { ProductType } from '../../../types'
import { route } from '../../../utils/route'

import { Layout } from '../../complexes/Layout'
import { Header } from '../../simples/tablePages/Header'
import { ItemsList } from '../../simples/tablePages/ItemsList'
import { TopPanel } from '../../simples/tablePages/TopPanel'
import { Confirm } from '../../simples/Confirm'
import { SearchSelector } from '../../simples/SearchSelector'

const Product: FC = () => {
    const { t } = useTranslation()
    const header = [
        t('406_Product_productName'),
        t('407_Product_brand'),
        t('408_Product_type'),
    ]
    const dispatch = useAppDispatch()

    const brands = useAppSelector(selectBrands)
    useEffect(() => {
        dispatch(loadBrands())
        dispatch(fetchBaseDosages())
    }, [])

    const history = useHistory()
    const [products, setProducts] = useState<Array<ProductType>>([])
    const productsCurrent = useRef<Array<ProductType>>(products)
    productsCurrent.current = products
    const [loading, setLoading] = useState(false)
    const offset = useRef(0)
    const search = useRef('')
    const has = useRef(true)
    const [deletedProductId, setDeletedProductId] = useState(0)
    const [brandId, setBrandId] = useState(0)
    const brandIdRef = useRef(brandId)
    brandIdRef.current = brandId

    const load = async () => {
        if (!has.current || loading) {
            return
        }

        setLoading(true)
        try {
            const result = await ProductsService.getProductList({
                offset: offset.current,
                search: search.current,
                brandId: brandIdRef.current,
            })

            setLoading(false)
            if (!result.length) {
                has.current = false
                return
            }

            offset.current = offset.current + result.length
            setProducts([...productsCurrent.current, ...result])
        } catch (error) {
            console.log(error)
            setLoading(false)
        } finally {
            setLoading(false)
        }
    }

    const clear = () => {
        has.current = true
        offset.current = 0
        search.current = ''
        setProducts([])
    }

    useEffect(() => {
        load().then()
    }, [])

    const handleEndReached = async () => {
        await load()
    }

    const handleSearch = async (text: string) => {
        clear()
        search.current = text
        await load()
    }

    const handleFiltered = async (id: number) => {
        clear()
        setBrandId(id)
        brandIdRef.current = id
        await load()
    }

    const startCreateProduct = () => {
        dispatch(setProduct(undefined))
        history.replace(routes.createProducts)
    }

    const editProduct = useCallback(
        (id: number) => {
            const editbleProduct = products.find((i) => i.id === id)

            if (!editbleProduct) {
                throw Error('Product not found')
            }

            dispatch(setProduct(editbleProduct))
            history.push(route(routes.editProducts, { id: id }))
        },
        [history, products]
    )

    const startDeleteProduct = useCallback((id: number) => {
        setDeletedProductId(id)
    }, [])

    const cancelDeleteProduct = () => {
        setDeletedProductId(0)
    }

    const confirmDeleteProduct = async () => {
        setDeletedProductId(0)
        await ProductsService.delete(deletedProductId)
        setProducts(products.filter((p) => p.id !== deletedProductId))
    }

    const rows: any = useMemo(() => {
        const getBrandName = (id: number) => {
            const brand = brands.find((i) => i.id === id)
            return brand ? brand.name : ''
        }

        return products.map((product) => ({
            id: product.id,
            values: [
                product.name,
                getBrandName(product.brandId),
                productType[`${product.condition}`],
            ],
        }))
    }, [products, brands])

    const formatOptions = brands.map((brand) => ({
        id: brand.id,
        value: brand.name,
    }))

    return (
        <Layout onEndReached={handleEndReached}>
            <Header text={t('409_Product_baseCreation')} />
            <TopPanel
                createButtonName={t('410_Product_createProduct')}
                onCreateButtonClick={startCreateProduct}
                onSearch={handleSearch}
                rightAddition={
                    <SearchSelector
                        options={formatOptions}
                        value={brandId}
                        onClick={(e) => handleFiltered(e.id)}
                        placeholder="Все бренды"
                    />
                }
            />
            <ItemsList
                headers={header}
                rows={rows}
                loading={loading}
                onEdit={editProduct}
                onDelete={startDeleteProduct}
            />

            {!!deletedProductId && (
                <Confirm
                    text={t('411_Product_confirmDelete')}
                    onConfirm={confirmDeleteProduct}
                    onCancel={cancelDeleteProduct}
                />
            )}
        </Layout>
    )
}

export default Product

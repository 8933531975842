import moment from 'moment'
import _ from 'lodash'
import api, { download } from '../utils/api'
import { Order as OrderType, OrderPriceMethods } from '../types'
import { endpoints } from '../constants'
import { getTimezone } from '../utils/date'

export type SortOrderBy =
    | 'companyName'
    | 'productName'
    | 'outletName'
    | 'tasteName'
    | 'automatName'
    | 'totalPrice'
    | 'priceMethod'
    | 'buyAt'
    | 'promo'
    | 'address'
export type SortOrder = 'ASC' | 'DESC'
export type ExportFields =
    | 'productType'
    | 'productName'
    | 'tasteName'
    | 'drinkVolume'
    | 'positionPrice'
    | 'purchaseCost'
    | 'purchaseDate'
    | 'payType'
    | 'automatName'
    | 'automatSerialNumber'
    | 'automatAddr'
    | 'automatStatus'
    | 'automatWaterRemaining'
    | 'automatConsumption'
    | 'promo'
export type ExportType = 'CSV' | 'XLSX'

/**
 * Параметры запроса получения списка заказов.
 */
type ResponseOrderType = OrderType & {
    buyAt: string
}

/**
 * Ответ при получении списка заказов.
 */
type GetListParams = {
    search?: string
    automatId?: number
    productName?: string
    productId?: number
    address?: number
    outletName?: string
    outletId?: number
    tasteId?: number
    priceMethod?: OrderPriceMethods
    start?: Date
    end?: Date
    withPromocode?: boolean
    orderBy?: SortOrderBy
    order?: SortOrder
    offset?: number
    limit?: number
}

/**
 * Параметры запроса получения списка заказов.
 */
type GetListResponse = {
    data: Array<ResponseOrderType>
}

const timezone = getTimezone()

/**
 * Сервис для работы с заказами.
 */
export class Orders {
    /**
     * Получение списка заказов.
     *
     * @param {GetListParams} params    Параметры запроса.
     * @return                          Массив заказов.
     */
    static async getList(params: GetListParams = {}) {
        const {
            search,
            automatId,
            productName,
            productId,
            address,
            outletName,
            outletId,
            tasteId,
            priceMethod,
            start,
            end,
            withPromocode,
            orderBy,
            order,
            offset = 0,
            limit = 30,
        } = params

        const queries = [`offset=${offset}`, `limit=${limit}`]

        if (search) {
            queries.push(`search=${search}`)
        }
        if (automatId) {
            queries.push(`automatId=${automatId}`)
        }
        if (productName) {
            queries.push(`productName=${productName}`)
        }
        if (productId) {
            queries.push(`productId=${productId}`)
        }
        if (address) {
            queries.push(`address=${address}`)
        }
        if (outletName) {
            queries.push(`outletName=${outletName}`)
        }
        if (outletId) {
            queries.push(`outletId=${outletId}`)
        }
        if (tasteId) {
            queries.push(`tasteId=${tasteId}`)
        }
        if (priceMethod) {
            queries.push(`priceMethod=${priceMethod}`)
        }
        if (start) {
            queries.push(`start=${moment(start).format('YYYY-MM-DD HH:mm:ss')}`)
        }
        if (end) {
            queries.push(
                `end=${moment(end).add(1, 'day').format('YYYY-MM-DD HH:mm:ss')}`
            )
        }
        if (withPromocode !== undefined) {
            queries.push(`withPromocode=${withPromocode ? '1' : '0'}`)
        }
        if (orderBy) {
            queries.push(`orderBy=${orderBy}`)
        }
        if (order) {
            queries.push(`order=${order}`)
        }

        const endpoint = endpoints.getOrdersList + `?${queries.join('&')}`

        const result = await api.get<GetListResponse>(endpoint)

        if (!result || !result.data || !_.isArray(result.data.data)) {
            return []
        }

        return result.data.data.map(Orders._prepareOrder)
    }

    static async download(
        exportType: ExportType,
        exportFields: Array<ExportFields> = [],
        params: GetListParams = {}
    ) {
        const {
            search,
            automatId,
            productName,
            productId,
            address,
            outletName,
            outletId,
            tasteId,
            priceMethod,
            start,
            end,
            orderBy,
            order,
        } = params

        const queries = [
            `export=${exportType}`,
            `exportFields=${JSON.stringify(exportFields)}`,
        ]

        if (search) {
            queries.push(`search=${search}`)
        }
        if (automatId) {
            queries.push(`automatId=${automatId}`)
        }
        if (productName) {
            queries.push(`productName=${productName}`)
        }
        if (productId) {
            queries.push(`productId=${productId}`)
        }
        if (address) {
            queries.push(`address=${address}`)
        }
        if (outletName) {
            queries.push(`outletName=${outletName}`)
        }
        if (outletId) {
            queries.push(`outletId=${outletId}`)
        }
        if (tasteId) {
            queries.push(`tasteId=${tasteId}`)
        }
        if (priceMethod) {
            queries.push(`priceMethod=${priceMethod}`)
        }
        if (start) {
            queries.push(`start=${moment(start).format('YYYY-MM-DD')}`)
        }
        if (end) {
            queries.push(`end=${moment(end).format('YYYY-MM-DD')}`)
        }
        if (orderBy) {
            queries.push(`orderBy=${orderBy}`)
        }
        if (order) {
            queries.push(`order=${order}`)
        }

        const endpoint = endpoints.getOrdersList + `?${queries.join('&')}`

        const fileName = `orders_${moment().format('YYYYMMDDHHmmss')}.${
            exportType === 'CSV' ? 'csv' : 'xlsx'
        }`

        await download(endpoint, fileName)
    }

    /**
     * Удаление заказа.
     *
     * @param {ResponseOrderType} order   Заказ.
     */
    static _prepareOrder(order: ResponseOrderType): OrderType {
        const buyAt = moment(order.buyAt)
        if (timezone > 0) {
            buyAt.add(timezone, 'hours')
        } else if (timezone < 0) {
            buyAt.subtract(Math.abs(timezone), 'hours')
        }

        return {
            ...order,
            buyAt: buyAt.toDate(),
        }
    }
}

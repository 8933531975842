import React, { FC, useEffect, useRef, useState, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Brands as BrandsService } from '../../../services/Brands'
import { Brand as BrandType } from '../../../types'
import { Layout } from '../../complexes/Layout'
import { Header } from '../../simples/tablePages/Header'
import { TopPanel } from '../../simples/tablePages/TopPanel'
import { ItemsList } from '../../simples/tablePages/ItemsList'
import { BrandEditor } from '../../complexes/BrandEditor'
import { Confirm } from '../../simples/Confirm'

const Brand: FC = () => {
    const { t } = useTranslation()

    const header = [t('339_Brand_brandName'), t('340_Brand_mediaKey')]

    const [brands, setBrands] = useState<Array<BrandType>>([])
    const brandCurrent = useRef<Array<BrandType>>(brands)
    brandCurrent.current = brands
    const [loading, setLoading] = useState(false)
    const offset = useRef(0)
    const search = useRef('')
    const has = useRef(true)

    const [showBrandEditor, setShowBrandEditor] = useState(false)
    const [editedBrand, setEditedBrand] = useState<BrandType>()

    const [deletedBrandId, setDeletedBrandId] = useState(0)

    const load = async () => {
        if (!has.current || loading) {
            return
        }

        setLoading(true)
        const result = await BrandsService.getList({
            offset: offset.current,
            search: search.current,
        })
        setLoading(false)

        if (!result.length) {
            has.current = false
            return
        }

        offset.current = offset.current + result.length
        setBrands([...brandCurrent.current, ...result])
    }

    const clear = () => {
        has.current = true
        offset.current = 0
        search.current = ''
        setBrands([])
    }

    useEffect(() => {
        load().then()
    }, [])

    const handleEndReached = async () => {
        await load()
    }

    const handleSearch = async (text: string) => {
        clear()
        search.current = text
        await load()
    }

    const startCreateBrand = () => {
        setShowBrandEditor(true)
    }

    const startUpdateBrand = (brandId: number) => {
        const brand = brands.find((b) => b.id === brandId)

        if (!brand) {
            return
        }

        setShowBrandEditor(true)
        setEditedBrand(brand)
    }

    const handleSubmit = async (savedBrand: BrandType) => {
        let updated = false
        let newBrands = brands.map((b) => {
            if (b.id === savedBrand.id) {
                updated = true
                return savedBrand
            }

            return b
        })

        if (!updated) {
            newBrands = [savedBrand, ...newBrands]
        }

        setBrands(newBrands)

        closeBrandEditor()
    }

    const closeBrandEditor = () => {
        setShowBrandEditor(false)
        setEditedBrand(undefined)
    }

    const startDeleteBrand = (brandId: number) => {
        setDeletedBrandId(brandId)
    }

    const cancelBrandDelete = () => {
        setDeletedBrandId(0)
    }

    const confirmBrandDelete = async () => {
        setDeletedBrandId(0)
        await BrandsService.delete(deletedBrandId)
        setBrands(brands.filter((brand) => brand.id !== deletedBrandId))
    }

    const rows = useMemo(() => {
        return brands.map((brand) => ({
            id: brand.id,
            values: [brand.name, brand.mediaKey],
        }))
    }, [brands])

    return (
        <Layout onEndReached={handleEndReached}>
            <Header text={t('341_Brand_baseCreation')} />
            <TopPanel
                createButtonName={t('342_Brand_createBrand')}
                onCreateButtonClick={startCreateBrand}
                onSearch={handleSearch}
            />
            <ItemsList
                headers={header}
                rows={rows}
                loading={loading}
                onEdit={startUpdateBrand}
                onDelete={startDeleteBrand}
            />

            {showBrandEditor && (
                <BrandEditor
                    brand={editedBrand}
                    onSubmit={handleSubmit}
                    onClose={closeBrandEditor}
                />
            )}

            {!!deletedBrandId && (
                <Confirm
                    text={t('343_Brand_confirmDelete')}
                    onConfirm={confirmBrandDelete}
                    onCancel={cancelBrandDelete}
                />
            )}
        </Layout>
    )
}

export default Brand

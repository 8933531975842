import React, { ChangeEvent, FC, useEffect, useState } from 'react'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { TFunction } from 'i18next'
import { PromoCode as PromoCodeService } from '../../../services/PromoCode'
import { NOT_LIMIT_PERIOD } from '../../../constants'
import { useDate } from '../../hooks/date'

import { ReactComponent as UpdateIcon } from '../../../assets/icons/update.svg'

import { TextField } from '../../simples/TextField'
import { Editor } from '../../simples/modalEditor/Editor'
import { Row } from '../../simples/modalEditor/Row'
import { Col } from '../../simples/modalEditor/Col'
import { MaskField } from '../../simples/MaskField'
import { H } from '../../simples/modalEditor/H'
import { CheckBox } from '../../simples/CheckBox'
import { AutomatsSelector } from '../AutomatsSelector'
import { TastesSelector } from '../TastesSelector'
import styles from './PromoCodeEditor.module.scss'

const codeRegExp = /^[A-Za-z0-9]+$/
const validateCode = (code: string, t: TFunction) => {
    if (!code) {
        return t('211_PromoCodeEditor_codeError_1')
    }

    if (!codeRegExp.test(code)) {
        return t('212_PromoCodeEditor_codeError_2')
    }

    if (code.length > 8) {
        return t('213_PromoCodeEditor_codeError_3')
    }

    return ''
}

const validateCount = (count: string, t: TFunction) => {
    if (!count) {
        return t('592_please_enter_a_value')
    }

    if (+count <= 0) {
        return t('590_Greater_then_0')
    }

    if (!/^[0-9]+$/.test(count)) {
        return t('591_Must_be_an_integer')
    }

    return ''
}

const validateDiscount = (discount: number, t: TFunction) => {
    if (discount > 100) {
        return t('214_PromoCodeEditor_discountError_1')
    }

    if (discount < 0) {
        return t('215_PromoCodeEditor_discountError_2')
    }

    return ''
}

export type SourceFormData = {
    id: number
    promoCode: string
    startAt: Date
    endAt: Date
    usageAmount: number
    discount: number
    automatsIds: Array<number> | 'all'
    tastes: string
}

const initPromoCode: SourceFormData = {
    id: 0,
    promoCode: '',
    startAt: new Date(),
    endAt: new Date(),
    usageAmount: 0,
    discount: 0,
    automatsIds: [],
    tastes: '',
}

const editorClasses = {
    modalModal: styles.modal,
    buttons: styles.buttons,
}

type Props = {
    promoCode?: SourceFormData
    onSubmit: (PromoCode: SourceFormData | Array<SourceFormData>) => void
    onClose: () => void
}

const PromoCodeEditor: FC<Props> = ({
    promoCode = initPromoCode,
    onSubmit,
    onClose,
}) => {
    const { t } = useTranslation()

    const [code, setCode] = useState(promoCode.promoCode)
    const [codeError, setCodeError] = useState(() =>
        validateCode(promoCode.promoCode, t)
    )
    const [codeTouched, setCodeTouched] = useState(false)
    const [isRandom, setIsRandom] = useState(false)
    const [count, setCount] = useState(0)
    const [countError, setCountError] = useState(() => validateCount('', t))
    const [countTouched, setCountTouched] = useState(false)
    const {
        value: startAt,
        error: startAtError,
        touched: startAtTouched,
        setValue: setStartAt,
        setTouched: setStartAtTouched,
    } = useDate(`${moment(promoCode.startAt).format('DD.MM.YYYY')}`)
    const {
        value: endAt,
        error: endAtError,
        touched: endAtTouched,
        setValue: setEndAt,
        setTouched: setEndAtTouched,
    } = useDate(`${moment(promoCode.endAt).format('DD.MM.YYYY')}`)
    const [usageAmount, setUsageAmount] = useState(promoCode.usageAmount)
    const [discount, setDiscount] = useState(promoCode.discount)
    const [discountError, setDiscountError] = useState('')
    const [discountTouched, setDiscountTouched] = useState(false)
    const [automatsIds, setAutomatsIds] = useState<Array<number> | 'all'>(
        promoCode?.automatsIds || []
    )
    const [tastesIds, setTastesIds] = useState<Array<any> | 'all'>(
        promoCode.tastes === 'all'
            ? 'all'
            : promoCode.tastes
            ? promoCode.tastes.split(',').map((tid) => +tid)
            : []
    )
    // const [tastesArray, setTastesArray] = useState(promoCode.tastes)
    const [errorMess, setErrorMess] = useState(false)

    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if (!code) {
            PromoCodeService.generate().then((data) => {
                setCode(data.code)
                setCodeError(validateCode(data.code, t))
            })
        }
    }, [])

    useEffect(() => {
        if (!startAt && !endAt) return
        const start = new Date(startAt)
        const end = new Date(endAt)

        if (start > end) {
            setErrorMess(true)
        } else {
            setErrorMess(false)
        }
    }, [startAt, endAt])

    const submit = async () => {
        if (
            (!isRandom && !!codeError) ||
            (isRandom && !!countError) ||
            (endAt !== NOT_LIMIT_PERIOD &&
                (!startAt ||
                    !!startAtError ||
                    !endAt ||
                    !!endAtError ||
                    moment(startAt, 'DD.MM.YYYY').isAfter(
                        moment(endAt, 'DD.MM.YYYY')
                    ))) ||
            !usageAmount ||
            !discount ||
            discountError ||
            (automatsIds !== 'all' &&
                (!automatsIds || automatsIds.length === 0)) ||
            (tastesIds !== 'all' && (!tastesIds || tastesIds.length === 0))
        ) {
            throw new Error('Form is invalid')
        }

        setLoading(true)

        let savedPromoCode = promoCode.id
            ? await PromoCodeService.update(promoCode.id, {
                  promoCode: code,
                  startAt: startAt
                      ? moment(startAt, 'DD.MM.YYYY').toDate()
                      : undefined,
                  endAt: moment(endAt, 'DD.MM.YYYY').toDate(),
                  usageAmount,
                  discount,
                  automatsIds,
                  tastes: tastesIds === 'all' ? 'all' : tastesIds.join(','),
              })
            : await PromoCodeService.create({
                  promoCode: !isRandom ? code : undefined,
                  count: isRandom ? count : undefined,
                  startAt: startAt
                      ? moment(startAt, 'DD.MM.YYYY').toDate()
                      : undefined,
                  endAt: moment(endAt, 'DD.MM.YYYY').toDate(),
                  usageAmount,
                  discount,
                  automatsIds,
                  tastes: tastesIds === 'all' ? 'all' : tastesIds.join(','),
              })

        onSubmit(savedPromoCode)
    }

    const handleCodeChange = (event: ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value

        setCode(value)
        setCodeError(validateCode(value, t))
    }

    const handleCodeBlur = () => {
        setCodeTouched(true)
    }

    const updatePromoCode = async () => {
        const data = await PromoCodeService.generate()
        setCode(data.code)
    }

    const handleCountChange = (event: ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value
        setCount(+value)
        setCountError(validateCount(value, t))
    }

    const handleCountBlur = () => {
        setCountTouched(true)
    }

    const handleUsageAmountNotLimitChange = (checked: boolean) => {
        setUsageAmount(checked ? -1 : 0)
    }

    const handleUsagePeriodNotLimitChange = (checked: boolean) => {
        if (checked) {
            setStartAt(NOT_LIMIT_PERIOD)
            setEndAt(NOT_LIMIT_PERIOD)
        } else {
            setStartAt('')
            setEndAt('')
        }
    }

    const handleAutomatsNotLimitChange = (checked: boolean) => {
        if (checked) {
            setAutomatsIds('all')
        } else {
            setAutomatsIds([])
        }
    }

    const handleTastesNotLimitChange = (checked: boolean) => {
        if (checked) {
            setTastesIds('all')
        } else {
            setTastesIds([])
        }
    }

    const handleDiscountChange = (event: ChangeEvent<HTMLInputElement>) => {
        const value = +event.target.value

        setDiscount(value)
        setDiscountError(validateDiscount(value, t))
    }

    const handleDiscountBlur = () => {
        setDiscountTouched(true)
    }

    return (
        <Editor
            header={
                promoCode.id > 0
                    ? t('216_PromoCodeEditor_editPromoCode')
                    : t('217_PromoCodeEditor_createPromoCode')
            }
            submitButtonName={
                promoCode.id > 0
                    ? t('218_PromoCodeEditor_save')
                    : t('219_PromoCodeEditor_create')
            }
            submitDisabled={
                (!isRandom && !!codeError) ||
                (isRandom && !!countError) ||
                (endAt !== NOT_LIMIT_PERIOD &&
                    (!startAt ||
                        !!startAtError ||
                        !endAt ||
                        !!endAtError ||
                        moment(startAt, 'DD.MM.YYYY').isAfter(
                            moment(endAt, 'DD.MM.YYYY')
                        ))) ||
                !usageAmount ||
                !discount ||
                !!discountError ||
                (automatsIds !== 'all' &&
                    (!automatsIds || automatsIds.length === 0)) ||
                (tastesIds !== 'all' && (!tastesIds || tastesIds.length === 0))
            }
            submitLoading={loading}
            onSubmit={submit}
            onCancel={onClose}
            classes={editorClasses}
        >
            <Row>
                <Col>
                    <div className={styles.codeContainer}>
                        <TextField
                            placeholder={t('220_PromoCodeEditor_promoCode')}
                            value={isRandom ? '' : code}
                            onChange={handleCodeChange}
                            onBlur={handleCodeBlur}
                            status={codeError && codeTouched ? 2 : undefined}
                            note={codeError && codeTouched ? codeError : ''}
                            disabled={isRandom}
                        />

                        {!isRandom && (
                            <button
                                title={t('221_PromoCodeEditor_update')}
                                className={styles.codeUpdateButton}
                                onClick={updatePromoCode}
                            >
                                <UpdateIcon />
                            </button>
                        )}
                    </div>
                    {promoCode.id === 0 && (
                        <div className={styles.random}>
                            <CheckBox
                                label={t('588_Generate_random')}
                                checked={isRandom}
                                onChange={setIsRandom}
                            />

                            {isRandom && (
                                <div className={styles.countWrapper}>
                                    <TextField
                                        placeholder={t(
                                            '589_Number_of_promo_codes'
                                        )}
                                        type="number"
                                        value={count === 0 ? '' : count}
                                        onChange={handleCountChange}
                                        onBlur={handleCountBlur}
                                        status={
                                            countError && countTouched
                                                ? 2
                                                : undefined
                                        }
                                        note={
                                            countError && countTouched
                                                ? countError
                                                : ''
                                        }
                                    />
                                </div>
                            )}
                        </div>
                    )}
                </Col>
            </Row>
            <Row>
                <Col>
                    <H level={3}>{t('222_PromoCodeEditor_periodOfUse')}</H>

                    <div className={styles.notLimitCheckbox}>
                        <CheckBox
                            label={t('223_PromoCodeEditor_unlimited')}
                            checked={endAt === NOT_LIMIT_PERIOD}
                            onChange={handleUsagePeriodNotLimitChange}
                        />
                    </div>

                    <Row>
                        <Col css={styles.dates}>
                            <div className={styles.dateStart}>
                                <MaskField
                                    mask={'99.99.9999'}
                                    placeholder={t('224_PromoCodeEditor_start')}
                                    value={
                                        endAt === NOT_LIMIT_PERIOD
                                            ? ''
                                            : `${startAt}`
                                    }
                                    onChange={(event) =>
                                        setStartAt(event.target.value)
                                    }
                                    onBlur={() => setStartAtTouched(true)}
                                    status={
                                        startAtError && startAtTouched
                                            ? 2
                                            : undefined
                                    }
                                    note={
                                        startAtError && startAtTouched
                                            ? t(startAtError)
                                            : ''
                                    }
                                    disabled={endAt === NOT_LIMIT_PERIOD}
                                />
                            </div>
                            <div className={styles.dateEnd}>
                                <MaskField
                                    mask={'99.99.9999'}
                                    placeholder={t('225_PromoCodeEditor_end')}
                                    value={
                                        endAt === NOT_LIMIT_PERIOD
                                            ? ''
                                            : `${endAt}`
                                    }
                                    onChange={(event) =>
                                        setEndAt(event.target.value)
                                    }
                                    onBlur={() => setEndAtTouched(true)}
                                    status={
                                        endAtError && endAtTouched
                                            ? 2
                                            : undefined
                                    }
                                    note={
                                        endAtError && endAtTouched
                                            ? t(endAtError)
                                            : ''
                                    }
                                    disabled={endAt === NOT_LIMIT_PERIOD}
                                />
                            </div>
                        </Col>
                    </Row>
                    {errorMess && (
                        <Row>
                            <Col>
                                <div className={styles.errorMess}>
                                    {t(
                                        '226_PromoCodeEditor_periodOfUseError_1'
                                    )}
                                </div>
                            </Col>
                        </Row>
                    )}
                </Col>
            </Row>
            <Row>
                <Col>
                    <H level={3}>{t('227_PromoCodeEditor_usageAmount')}</H>

                    <div className={styles.notLimitCheckbox}>
                        <CheckBox
                            label={t('223_PromoCodeEditor_unlimited')}
                            checked={usageAmount === -1}
                            onChange={handleUsageAmountNotLimitChange}
                        />
                    </div>

                    <TextField
                        type={'number'}
                        placeholder={t(
                            '228_PromoCodeEditor_usageAmountPlaceholder'
                        )}
                        value={
                            usageAmount === 0 || usageAmount === -1
                                ? ''
                                : usageAmount
                        }
                        onChange={(event) =>
                            setUsageAmount(+event.target.value)
                        }
                        disabled={usageAmount === -1}
                    />
                </Col>
            </Row>
            <Row>
                <Col>
                    <H level={3}>{t('229_PromoCodeEditor_discount')}</H>
                    <TextField
                        type={'number'}
                        placeholder={t(
                            '230_PromoCodeEditor_discountPlaceholder'
                        )}
                        value={discount === 0 ? '' : discount}
                        onChange={handleDiscountChange}
                        onBlur={handleDiscountBlur}
                        status={
                            discountError && discountTouched ? 2 : undefined
                        }
                        note={
                            discountError && discountTouched
                                ? t(discountError)
                                : ''
                        }
                    />
                </Col>
            </Row>

            <Row>
                <Col>
                    <div className={styles.fieldsGroups}>
                        <H level={3}>
                            {t('231_PromoCodeEditor_availableAutomates')}
                        </H>

                        <div className={styles.notLimitCheckbox}>
                            <CheckBox
                                label={t('232_PromoCodeEditor_allAutomates')}
                                checked={automatsIds === 'all'}
                                onChange={handleAutomatsNotLimitChange}
                            />
                        </div>

                        <AutomatsSelector
                            value={automatsIds !== 'all' ? automatsIds : []}
                            onChange={setAutomatsIds}
                            isDisabled={automatsIds === 'all'}
                        />
                    </div>
                </Col>
            </Row>

            <Row>
                <Col>
                    <div className={styles.fieldsGroups}>
                        <H level={3}>
                            {t('235_PromoCodeEditor_availableTastes')}
                        </H>

                        <div className={styles.notLimitCheckbox}>
                            <CheckBox
                                label={t('236_PromoCodeEditor_allTastes')}
                                checked={tastesIds === 'all'}
                                onChange={handleTastesNotLimitChange}
                            />
                        </div>

                        <TastesSelector
                            value={tastesIds !== 'all' ? tastesIds : []}
                            onChange={setTastesIds}
                            isDisabled={tastesIds === 'all'}
                        />
                    </div>
                </Col>
            </Row>
        </Editor>
    )
}

export default PromoCodeEditor

import React, { FC, useEffect, useState, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { AutomatModelTypes, AutomatType } from '../../../types'
import { Automats as AutomatsService } from '../../../services/Automats'
import {
    loadModels,
    loadProducts,
    loadBrands,
    loadTastes,
    selectModels,
    loadGoods,
} from '../../../store/slices/storage'
import { useAppDispatch, useAppSelector } from '../../hooks/store'
import { Layout } from '../../complexes/Layout'
import { AutomatTabs } from '../../complexes/automat/AutomatTabs'
import { Spinner } from '../../simples/Spinner'
import { BeveragesRefill } from '../../complexes/automat/BeveragesRefill'
import { SnackRefill } from '../../complexes/automat/SnackRefill'
import { KioskRefill } from '../../complexes/automat/KioskRefill'
import styles from './AutomatRefill.module.scss'

type RouterParams = {
    id: string
}

const AutomatRefill: FC = () => {
    const { t } = useTranslation()
    const { id } = useParams<RouterParams>()
    const [automat, setAutomat] = useState<AutomatType>()
    const [loading, setLoading] = useState(false)
    useEffect(() => {
        setLoading(true)
        AutomatsService.getItem(+id).then((automat) => {
            setAutomat(automat)
            setLoading(false)
        })
    }, [])

    const models = useAppSelector(selectModels)

    const dispatch = useAppDispatch()
    useEffect(() => {
        dispatch(loadModels())
        dispatch(loadTastes())
        dispatch(loadBrands())
        dispatch(loadProducts())
        dispatch(loadGoods())
    }, [])

    const currentModel = useMemo(() => {
        if (!automat) {
            return undefined
        }

        return models.find((m) => m.id === automat.automatModelId)
    }, [automat, models])

    return (
        <Layout>
            <h1 className={styles.header}>
                {t('328_AutomatRefill_automateControl')} "
                {automat ? automat.name : ''}"
            </h1>

            <AutomatTabs automatId={+id} />

            {loading ? (
                <div className={styles.loading}>
                    <Spinner />
                </div>
            ) : automat && currentModel ? (
                <>
                    {currentModel.type === AutomatModelTypes.Beverages ? (
                        <BeveragesRefill
                            automat={automat}
                            model={currentModel}
                        />
                    ) : currentModel.type === AutomatModelTypes.Snack ? (
                        <SnackRefill automat={automat} model={currentModel} />
                    ) : currentModel.type === AutomatModelTypes.Kiosk ? (
                        <KioskRefill automat={automat} model={currentModel} />
                    ) : null}
                </>
            ) : null}
        </Layout>
    )
}

export default AutomatRefill

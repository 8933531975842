import React, { FC } from 'react'
import { NewsType } from '../../../types'
import { NewsItem } from './NewsItem'
import styles from './WidgetNews.module.scss'

type Props = {
    news: Array<NewsType>
}

const WidgetNews: FC<Props> = ({ news }) => {
    return (
        <div className={styles.root}>
            {news.map((newsItem) => (
                <div className={styles.newsItemWrap} key={newsItem.id}>
                    <NewsItem newsItem={newsItem} />
                </div>
            ))}
        </div>
    )
}

export default WidgetNews

import React, {
    FC,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'
import { MAX_INT_32 } from '../../../../constants'
import {
    AddressNoteType,
    AutomatModel,
    AutomatModelTypes,
    AutomatType,
} from '../../../../types'
import { useAppDispatch, useAppSelector } from '../../../hooks/store'
import { selectModels } from '../../../../store/slices/storage'
import { showToast } from '../../../../store/slices/site'
import { Automats as AutomatsService } from '../../../../services/Automats'
import { Address as AddressService } from '../../../../services/Address'

import { Row } from '../../../simples/modalEditor/Row'
import { Col } from '../../../simples/modalEditor/Col'
import { TextField, TextFieldStatus } from '../../../simples/TextField'
import { OptionType, Selector } from '../../../simples/Selector'
import { TextFieldNote } from '../../../simples/TextFieldNotes'
import { H } from '../../../simples/modalEditor/H'
import { CheckBox } from '../../../simples/CheckBox'
import { Button } from '../../../simples/Button'
import { FormType } from './Types/Types'
import styles from './Settings.module.scss'

const initErrors: FormError = {
    serialNumber: '',
}

type FormError = {
    serialNumber: string
}

type Props = {
    automat: AutomatType
    onSubmit: (automat: AutomatType) => void
    model: AutomatModel
}

export const General: FC<Props> = ({
    automat: automatDefault,
    onSubmit,
    model,
}) => {
    const { t, i18n } = useTranslation()
    const models = useAppSelector(selectModels)
    const dispatch = useAppDispatch()

    const [automat, setAutomat] = useState<FormType>(() => ({
        ...automatDefault,
        longitude: automatDefault.longitude + '',
        latitude: automatDefault.latitude + '',
    }))

    useEffect(() => {
        setAutomat({
            ...automatDefault,
            longitude: automatDefault.longitude + '',
            latitude: automatDefault.latitude + '',
        })
    }, [automatDefault])

    const modelsOptions: OptionType[] = useMemo(() => {
        return models.map((t) => {
            return {
                label: t.name,
                value: t.id,
            }
        })
    }, [models])

    const [errors, setErrors] = useState<FormError>(initErrors)

    const disabled = useMemo(() => {
        return !automat.name || !automat.automatModelId || !automat.serialNumber
    }, [automat])

    /**
     * notes
     */
    const [notes, setNotes] = useState<Array<AddressNoteType>>([])
    const addressWasChanged = useRef(false)

    const getAddressNotes = async (search: string) => {
        const response = await AddressService.search({
            query: search,
            language: i18n.language as 'ru' | 'en',
        })
        setNotes(response)
    }

    useEffect(() => {
        if (!addressWasChanged.current) {
            return
        }

        const fetchAddressNotes = async () => {
            await getAddressNotes(automat.address)
        }

        const timer = setTimeout(fetchAddressNotes, 500)

        return () => {
            clearTimeout(timer)
        }
    }, [automat.address])

    const onSetSearch = (s: string) => {
        addressWasChanged.current = true
        setAutomat({ ...automat, address: s })
    }

    const onClickOnNote = useCallback(
        (e: string) => {
            const find = notes.find((i) => i.address === e)
            if (find) {
                setAutomat({
                    ...automat,
                    address: find.address,
                    latitude: `${find.latitude}`,
                    longitude: `${find.longitude}`,
                })
            }
            setNotes([])
            addressWasChanged.current = false
        },
        [automat, notes]
    )

    const [loading, setLoading] = useState(false)

    const submit = async () => {
        setLoading(true)

        try {
            const updatedAutomat = await AutomatsService.update(
                automatDefault.id,
                {
                    name: automat.name,
                    serialNumber: automat.serialNumber,
                    automatModelId: automat.automatModelId,
                    address: automat.address,
                    longitude: automat.longitude
                        ? +automat.longitude
                        : undefined,
                    latitude: automat.latitude ? +automat.latitude : undefined,
                    waterVolume: automat.waterVolume,
                }
            )

            setLoading(false)

            dispatch(showToast(t('50_GeneralSettings_saveSuccessMessage')))

            onSubmit(updatedAutomat)
        } catch (error) {
            setLoading(false)

            if (
                error &&
                // @ts-ignore
                error.response &&
                // @ts-ignore
                error.response.data &&
                // @ts-ignore
                error.response.data.errors
            ) {
                // @ts-ignore
                const errors = error.response.data.errors
                if (errors.serialNumber && _.isArray(errors.serialNumber)) {
                    setErrors({
                        ...errors,
                        serialNumber: errors.serialNumber.join(', '),
                    })
                }
            }
        }
    }

    return (
        <div className={styles.container}>
            <Row>
                <Col>
                    <TextField
                        placeholder={t('52_GeneralSettings_namePlaceholder')}
                        value={automat.name}
                        onChange={(event) => {
                            setAutomat({
                                ...automat,
                                name: event.target.value,
                            })
                        }}
                    />
                </Col>
            </Row>
            <Row>
                <Col>
                    <Selector
                        options={modelsOptions}
                        label={t('53_GeneralSettings_automateModelPlaceholder')}
                        value={automat.automatModelId}
                        onChange={(event) => {
                            setAutomat({
                                ...automat,
                                automatModelId: event,
                            })
                        }}
                    />
                </Col>
            </Row>
            <Row>
                <Col>
                    <TextField
                        placeholder={t(
                            '55_GeneralSettings_serialNumberPlaceholder'
                        )}
                        value={automat.serialNumber}
                        onChange={(event) => {
                            setAutomat({
                                ...automat,
                                serialNumber: event.target.value,
                            })
                            setErrors({
                                ...errors,
                                serialNumber: '',
                            })
                        }}
                        note={errors.serialNumber}
                        status={
                            !!errors.serialNumber
                                ? TextFieldStatus.Error
                                : TextFieldStatus.Normal
                        }
                    />
                </Col>
            </Row>
            <Row>
                <Col>
                    <TextFieldNote
                        value={automat.address || ''}
                        onChange={onSetSearch}
                        notes={Array.from(notes, (i) => i.address)}
                        onClick={onClickOnNote}
                        placeholder={t('56_GeneralSettings_addressPlaceholder')}
                    />
                </Col>
                <Col>
                    <Row>
                        <Col>
                            <TextField
                                placeholder={t(
                                    '57_GeneralSettings_longitudePlaceholder'
                                )}
                                value={automat.longitude}
                                onChange={(event) => {
                                    setAutomat({
                                        ...automat,
                                        longitude: event.target.value,
                                    })
                                }}
                            />
                        </Col>
                        <Col>
                            <TextField
                                placeholder={t(
                                    '58_GeneralSettings_latitudePlaceholder'
                                )}
                                value={automat.latitude}
                                onChange={(event) => {
                                    setAutomat({
                                        ...automat,
                                        latitude: event.target.value,
                                    })
                                }}
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>

            {model.type === AutomatModelTypes.Beverages && (
                <Row>
                    <Col>
                        <H level={3}>{t('564_AutomatEditor_waterVolume')}</H>

                        <TextField
                            type={'number'}
                            placeholder={t('564_AutomatEditor_waterVolume')}
                            value={
                                automat.waterVolume === MAX_INT_32
                                    ? 0
                                    : Math.round(automat.waterVolume / 100) /
                                          10 +
                                      ''
                            }
                            onChange={(e) =>
                                setAutomat({
                                    ...automat,
                                    waterVolume: +e.target.value * 1000,
                                })
                            }
                            disabled={automat.waterVolume === MAX_INT_32}
                        />

                        <div className={styles.waterPipesCheckbox}>
                            <CheckBox
                                checked={automat.waterVolume === MAX_INT_32}
                                onChange={(checked) =>
                                    setAutomat({
                                        ...automat,
                                        waterVolume: checked ? MAX_INT_32 : 0,
                                    })
                                }
                                label={t('565_AutomatEditor_waterPipes')}
                            />
                        </div>
                    </Col>
                </Row>
            )}

            <div className={styles.buttons}>
                <Button onClick={submit} loading={loading} disabled={disabled}>
                    {t('60_GeneralSettings_update')}
                </Button>
            </div>
        </div>
    )
}

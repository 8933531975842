import React, { FC, useEffect } from 'react'
import { AutomatModel, AutomatModelTypes, AutomatType } from '../../../../types'
import styles from './KioskRemains.module.scss'
import { useTranslation } from 'react-i18next'
import { useAppDispatch, useAppSelector } from '../../../hooks/store'
import { loadGoods, selectGoods } from '../../../../store/slices/storage'
import { SubHeader } from '../SubHeader'

type Props = {
    automat: AutomatType
    model: AutomatModel
}

const KioskRemains: FC<Props> = ({ automat, model }) => {
    const { t } = useTranslation()
    const dispatch = useAppDispatch()
    const goods = useAppSelector(selectGoods)

    useEffect(() => {
        dispatch(loadGoods())
    }, [])

    if (model.type !== AutomatModelTypes.Kiosk) {
        throw new Error('Automat model must be Kiosk')
    }

    return (
        <div className={styles.root}>
            <SubHeader>{t('85_Remains_stockStatus')}</SubHeader>

            <table>
                <thead>
                    <tr>
                        <th>{t('651_KioskRemains_Goods_item')}</th>
                        <th className={styles.remainValues}>
                            {t('652_KioskRemains_Remains')}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {automat.goods &&
                        automat.goods.map((goodsItem) => {
                            const goodsItemData = goodsItem
                                ? goods.find(
                                      (gi) => gi.id === goodsItem.goodsItemId
                                  )
                                : undefined

                            return (
                                <tr>
                                    <td>
                                        {goodsItemData
                                            ? goodsItemData.name
                                            : '--'}
                                    </td>
                                    <td className={styles.remainValues}>
                                        {goodsItem
                                            ? `${goodsItem.remains} ${t(
                                                  '653_KioskRemains_Pieces'
                                              )}`
                                            : '--'}
                                    </td>
                                </tr>
                            )
                        })}
                </tbody>
            </table>
        </div>
    )
}

export default KioskRemains

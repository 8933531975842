import React, { FC } from 'react'
import classNames from 'classnames'

import styles from './H.module.scss'

type Props = {
    level: number
    addClassName?: string
}

const H: FC<Props> = ({ level, addClassName, children }) => {
    return (
        <div
            className={classNames(
                styles.h,
                styles[`level${level}`],
                addClassName
            )}
        >
            {children}
        </div>
    )
}

export default H

import React, { FC, useEffect, useRef, useState } from 'react'
import {
    AutomatLog,
    AutomatLogLevels,
    AutomatStatusNumberType,
    AutomatType,
    DashboardData,
} from '../../../types'
import { Dashboard as DashboardService } from '../../../services/Dashboard'
import { Automats as AutomatService } from '../../../services/Automats'
import { loadModels } from '../../../store/slices/storage'
import { useAppDispatch } from '../../hooks/store'
import { Layout } from '../../complexes/Layout'
import { WidgetNews } from '../../complexes/WidgetNews'
import { WidgetStatisticsNumbers } from '../../complexes/WidgetStatisticsNumbers'
import { WidgetStatisticsTops } from '../../complexes/WidgetStatisticsTops'
import { WidgetSalesSchedule } from '../../complexes/WidgetSalesSchedule'
import { WidgetAutomatLogs } from '../../complexes/WidgetAutomatLogs'
import { Spinner } from '../../simples/Spinner'
import styles from './Dashboard.module.scss'

const Dashboard: FC = () => {
    const [data, setData] = useState<DashboardData>()
    const [loading, setLoading] = useState(false)

    const [attentionAutomats, setAttentionAutomats] = useState<
        Array<AutomatType>
    >([])
    const attentionAutomatsCurrent =
        useRef<Array<AutomatType>>(attentionAutomats)
    attentionAutomatsCurrent.current = attentionAutomats
    const attentionAutomatsOffset = useRef(0)
    const hasAttentionAutomats = useRef(true)
    const [attentionAutomatsLoading, setAttentionAutomatsLoading] =
        useState(false)

    const dispatch = useAppDispatch()

    useEffect(() => {
        setLoading(true)

        DashboardService.getData().then((data) => {
            setLoading(false)

            setData(data)
        })

        loadAttentionAutomats().then()
    }, [])

    const loadAttentionAutomats = async () => {
        if (!hasAttentionAutomats.current || attentionAutomatsLoading) {
            return
        }

        setAttentionAutomatsLoading(true)
        const result = await AutomatService.getLists({
            offset: attentionAutomatsOffset.current,
            status: ['red', 'yellow'],
        })
        setAttentionAutomatsLoading(false)

        if (!result || result.length === 0) {
            hasAttentionAutomats.current = false
            return
        }
        const newAttentionAutomats = [
            ...attentionAutomatsCurrent.current,
            ...result,
        ]
        attentionAutomatsOffset.current = newAttentionAutomats.length
        setAttentionAutomats(newAttentionAutomats)
    }

    useEffect(() => {
        dispatch(loadModels())
    }, [])

    return (
        <Layout onEndReached={loadAttentionAutomats}>
            {loading ? (
                <div className={styles.loader}>
                    <Spinner />
                </div>
            ) : data ? (
                <>
                    {data.news && data.news.length > 0 && (
                        <div className={styles.news}>
                            <WidgetNews news={data.news} />
                        </div>
                    )}
                    <div className={styles.statistics}>
                        <div className={styles.statisticsNumbers}>
                            <WidgetStatisticsNumbers
                                incomeMonth={data.incomeMonth || 0}
                                incomeRelativelyLastMonth={
                                    data.incomeRelativelyLastMonth || 0
                                }
                                incomeWeek={data.incomeWeek || 0}
                                incomeRelativelyLastWeek={
                                    data.incomeRelativelyLastWeek || 0
                                }
                                incomeToday={data.incomeToday || 0}
                                incomeRelativelyYesterday={
                                    data.incomeRelativelyYesterday || 0
                                }
                                automatsCount={data.automatsCount || 0}
                            />
                        </div>

                        <div className={styles.statisticsTops}>
                            <WidgetStatisticsTops
                                top3Products={data.top3Products}
                                top3Automats={data.top3Automats}
                                top3PromoCodes={data.top3PromoCodes}
                            />
                        </div>
                    </div>

                    <div className={styles.salesSchedule}>
                        <WidgetSalesSchedule />
                    </div>

                    <div className={styles.automatLogs}>
                        <WidgetAutomatLogs
                            automats={attentionAutomats}
                            loading={attentionAutomatsLoading}
                        />
                    </div>
                </>
            ) : null}
        </Layout>
    )
}

export default Dashboard

import React, { FC, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
    BaseDosagesType,
    ComponentType,
    ConditionType,
    TasteType,
} from '../../../types'
import { Col } from '../../simples/modalEditor/Col'
import { Editor } from '../../simples/modalEditor/Editor'
import { Row } from '../../simples/modalEditor/Row'
import { ParamBlockWrapper } from '../../simples/ParamBlockWrapper'
import { TextField } from '../../simples/TextField'
import { AddButton } from '../../simples/AddButton'
import { ComponentItem } from './ComponentItem'
import { DosageItem } from './DosageItem'
import { useAppSelector } from '../../hooks/store'
import { selectBaseDosages } from '../../../store/slices/storage'

export const initTaste: TasteType = {
    id: 0,
    name: '',
    productId: 0,
    product: {
        id: 0,
        brandId: 0,
        brand: {
            id: 0,
            mediaKey: '',
            name: '',
        },
        name: '',
        cupId: 0,
        cup: {
            id: 0,
            mediaPath: '',
            name: '',
        },
        condition: ConditionType.POWDER,
    },
    color: '',
    componentOnAmount: 0,
    mediaKey: '',
    components: [],
    baseDosages: [],
}

const initComponent: ComponentType = {
    id: 0,
    name: '',
    amount: 0,
    measurementUnit: '',
}

const initDose: BaseDosagesType = {
    id: 0,
    drinkVolume: 0,
    water: 0,
    product: 0,
    conversionFactor: 0,
    price: 0,
}

type Props = {
    taste?: TasteType
    onSubmit: (taste: TasteType) => void
    onClose: () => void
    condition: ConditionType
}

/**
 *
 * @param taste
 * @param onSubmit
 * @param onClose
 * @returns
 */
const TasteEditor: FC<Props> = ({
    taste = initTaste,
    onSubmit,
    onClose,
    condition,
}) => {
    const { t } = useTranslation()

    const prepareDosages: Array<BaseDosagesType> = useMemo(() => {
        return taste.baseDosages.map((i) => ({
            drinkVolume: i.drinkVolume,
            water: i.water,
            product: i.product,
            conversionFactor: i.conversionFactor,
            price: i.price,
        }))
    }, [taste.baseDosages])
    const [tasteCurrent, setTasteCurrent] = useState({
        ...taste,
        baseDosages: prepareDosages,
    })

    const submit = async () => {
        onSubmit(tasteCurrent)
    }

    const handleComponentChange = (component: ComponentType, index: number) => {
        setTasteCurrent({
            ...tasteCurrent,
            components: tasteCurrent.components.map((c, i) => {
                if (i === index) {
                    return component
                }

                return c
            }),
        })
    }

    const handleComponentDelete = (index: number) => {
        setTasteCurrent({
            ...tasteCurrent,
            components: tasteCurrent.components.filter((c, i) => i !== index),
        })
    }

    const addComponent = () => {
        setTasteCurrent({
            ...tasteCurrent,
            components: [...tasteCurrent.components, { ...initComponent }],
        })
    }

    const handleDosageChange = (dosage: BaseDosagesType, index: number) => {
        setTasteCurrent({
            ...tasteCurrent,
            baseDosages: tasteCurrent.baseDosages.map((d, i) => {
                if (i === index) {
                    return dosage
                }

                return d
            }),
        })
    }

    const handleDosageDelete = (index: number) => {
        setTasteCurrent({
            ...tasteCurrent,
            baseDosages: tasteCurrent.baseDosages.filter((d, i) => i !== index),
        })
    }

    const addDosage = () => {
        setTasteCurrent({
            ...tasteCurrent,
            baseDosages: [
                ...(tasteCurrent?.baseDosages || []),
                { ...initDose },
            ],
        })
    }

    return (
        <Editor
            header={
                taste.id > 0
                    ? t('258_TasteEditor_editTaste')
                    : t('259_TasteEditor_createTaste')
            }
            submitButtonName={
                taste.id > 0
                    ? t('260_TasteEditor_save')
                    : t('261_TasteEditor_create')
            }
            onSubmit={submit}
            onCancel={onClose}
        >
            <Row>
                <Col>
                    <TextField
                        placeholder={t('262_TasteEditor_name')}
                        value={tasteCurrent.name}
                        onChange={(event) =>
                            setTasteCurrent({
                                ...tasteCurrent,
                                name: event.target.value,
                            })
                        }
                    />
                </Col>
                <Col>
                    <TextField
                        placeholder={t('263_TasteEditor_color')}
                        value={tasteCurrent.color}
                        onChange={(event) =>
                            setTasteCurrent({
                                ...tasteCurrent,
                                color: event.target.value,
                            })
                        }
                    />
                </Col>
            </Row>
            <Row>
                <Col>
                    <TextField
                        placeholder={t('264_TasteEditor_componentOnAmount')}
                        type={'number'}
                        value={tasteCurrent.componentOnAmount || ''}
                        onChange={(event) =>
                            setTasteCurrent({
                                ...tasteCurrent,
                                componentOnAmount: +event.target.value,
                            })
                        }
                    />
                </Col>
                <Col>
                    <TextField
                        placeholder={t('265_TasteEditor_mediaKey')}
                        value={tasteCurrent.mediaKey}
                        onChange={(event) =>
                            setTasteCurrent({
                                ...tasteCurrent,
                                mediaKey: event.target.value,
                            })
                        }
                    />
                </Col>
            </Row>
            <ParamBlockWrapper title={t('266_TasteEditor_composition')}>
                {tasteCurrent.components &&
                    tasteCurrent.components.map((component, index) => {
                        return (
                            <ComponentItem
                                key={index}
                                component={component}
                                onChange={(component) =>
                                    handleComponentChange(component, index)
                                }
                                onDelete={() => handleComponentDelete(index)}
                            />
                        )
                    })}
                <Row>
                    <Col>
                        <AddButton
                            onClick={addComponent}
                            label={t('267_TasteEditor_addItem')}
                        />
                    </Col>
                    <Col />
                </Row>
            </ParamBlockWrapper>
            <ParamBlockWrapper title={t('268_TasteEditor_basicDosages')}>
                {tasteCurrent.baseDosages &&
                    tasteCurrent.baseDosages.map((dosage, index) => {
                        return (
                            <DosageItem
                                key={index}
                                number={index + 1}
                                dosage={dosage}
                                onChange={(dosage) =>
                                    handleDosageChange(dosage, index)
                                }
                                showDeleteButton={
                                    tasteCurrent.baseDosages.length > 2 &&
                                    index > 1
                                }
                                onDelete={() => handleDosageDelete(index)}
                                condition={condition}
                            />
                        )
                    })}
                <Row>
                    <Col>
                        <AddButton
                            onClick={addDosage}
                            label={t('269_TasteEditor_addDosage')}
                        />
                    </Col>
                    <Col />
                </Row>
            </ParamBlockWrapper>
        </Editor>
    )
}

export default TasteEditor

import api from '../utils/api'
import { endpoints } from '../constants'

type knowledgeBase = {
    title: string
    content: string
}

export type knowledgeBaseSendData = {
    data: knowledgeBase
    success?: boolean
}

export class KnowledgeBaseService {
    static async getKnowledgeBase() {
        const { data } = await api.get<knowledgeBaseSendData>(
            endpoints.knowledgeBase
        )
        return data.data
    }

    static async createKnowledgeBase(payload: knowledgeBaseSendData) {
        const formData = new FormData()

        formData.set('title', payload.data.title)
        formData.set('content', payload.data.content)

        const { data } = await api.post<knowledgeBaseSendData>(
            endpoints.knowledgeBase,
            formData
        )
        return data
    }
}

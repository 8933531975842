import React, { FC, useState } from 'react'
import { QRCodeCanvas } from 'qrcode.react'
import { useTranslation } from 'react-i18next'
import printJS from 'print-js'
import { v4 as uuidv4 } from 'uuid'
import { User as UserType, UserGroups } from '../../../types'
import { User as UserService } from '../../../services/User'

import { ReactComponent as PrintIcon } from '../../../assets/icons/print.svg'
import { ReactComponent as DownloadIcon } from '../../../assets/icons/download.svg'

import { Modal } from '../../simples/Modal'
import { Button, ButtonKind } from '../../simples/Button'
import styles from './UserAccessKeyEditor.module.scss'
import { useAppSelector } from '../../hooks/store'
import { selectAuthUser } from '../../../store/slices/auth'
import { useUserGroup } from '../../hooks/userGroup'

const modalClasses = {
    modal: styles.modal,
}

const minButtonClasses = {
    button: styles.minButton,
}

function blobToBase64(blob: Blob) {
    return new Promise((resolve, _) => {
        const reader = new FileReader()
        reader.onloadend = () => resolve(reader.result)
        reader.readAsDataURL(blob)
    })
}

type Props = {
    user: UserType
    onUserChange: (user: UserType) => void
    onClose: () => void
}

const UserAccessKeyEditor: FC<Props> = ({ user, onUserChange, onClose }) => {
    const { t } = useTranslation()

    const authUser = useAppSelector(selectAuthUser)
    const authUserGroup = useUserGroup(authUser)

    const [generating, setGenerating] = useState(false)
    const generateNewAccessKey = async () => {
        setGenerating(true)
        const accessKey = await UserService.generateAccessKey(user.id)
        setGenerating(false)

        const updatedUser = {
            ...user,
            accessKey,
        }

        onUserChange(updatedUser)
    }

    const printPdf = async () => {
        const blob = await UserService.downloadAccessKeyQR(user.id)
        const base64 = (await blobToBase64(blob)) as string
        const clearedBase64 = base64.substr(
            'data:application/pdf;base64,'.length
        )

        printJS({ printable: clearedBase64, type: 'pdf', base64: true })
    }

    const downloadPdf = async () => {
        const blob = await UserService.downloadAccessKeyQR(user.id)

        const pdfUrl = URL.createObjectURL(blob)

        const anchor = document.createElement('a')
        anchor.href = pdfUrl
        anchor.download = `${uuidv4()}.pdf`

        document.body.appendChild(anchor)
        anchor.click()
        document.body.removeChild(anchor)

        URL.revokeObjectURL(pdfUrl)
    }

    const deleteAccessKey = async () => {
        await UserService.deleteAccessKey(user.id)

        const updatedUser = {
            ...user,
            accessKey: null,
        }

        onUserChange(updatedUser)
        onClose()
    }

    return (
        <Modal onClose={onClose} showClose classes={modalClasses}>
            <div className={styles.row}>
                <div className={styles.qr}>
                    {user.accessKey && (
                        <QRCodeCanvas value={user.accessKey} size={256} />
                    )}
                </div>
                {user.accessKey && (
                    <div className={styles.accessKey}>{user.accessKey}</div>
                )}
            </div>

            {authUserGroup === UserGroups.Owner && (
                <div className={styles.row}>
                    <div className={styles.buttonsContainer}>
                        <Button
                            loading={generating}
                            onClick={generateNewAccessKey}
                        >
                            {t('632_Generate_new')}
                        </Button>
                    </div>
                </div>
            )}

            <div className={styles.row}>
                <div className={styles.minButtonContainer}>
                    <button className={styles.minButton} onClick={printPdf}>
                        <PrintIcon />
                    </button>

                    <button className={styles.minButton} onClick={downloadPdf}>
                        <DownloadIcon />
                    </button>
                </div>
            </div>

            {user.accessKey && authUserGroup === UserGroups.Owner && (
                <div className={styles.row}>
                    <div className={styles.buttonsContainer}>
                        <Button
                            kind={ButtonKind.Link}
                            onClick={deleteAccessKey}
                        >
                            {t('633_Delete_access_key')}
                        </Button>
                    </div>
                </div>
            )}
        </Modal>
    )
}

export default UserAccessKeyEditor

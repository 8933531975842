import React, { FC, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import styles from './BeveragesRefill.module.scss'
import {
    AutomatContainerTypes,
    AutomatModel,
    AutomatModelTypes,
    AutomatType,
} from '../../../../types'
import { Automats as AutomatsService } from '../../../../services/Automats'
import { MAX_INT_32 } from '../../../../constants'
import { SubHeader } from '../SubHeader'
import { HorizontalSlider } from '../../../simples/HorizontalSlider'
import classNames from 'classnames'
import { VerticalSlider } from '../../../simples/VerticalSlider'
import { ContainersTable } from '../ContainersTable'

const waterSliderClasses = {
    sliderIndicator: styles.waterSliderIndicator,
}

type Remains = {
    cupsRemaining: number
    waterRemaining: number
    containers: Array<{
        id: number
        remainsVolume: number
        automatModelContainerId: number
    }>
}

const prepareRemainsFromAutomat = (automat: AutomatType): Remains => {
    return {
        cupsRemaining: automat.cupsRemaining,
        waterRemaining: automat.waterRemaining,
        containers: automat.containers.map((container) => ({
            id: container.id,
            remainsVolume: container.remainsVolume,
            automatModelContainerId: container.automatModelContainerId,
        })),
    }
}

type Props = {
    automat: AutomatType
    model: AutomatModel
}

const BeveragesRefill: FC<Props> = ({ automat, model }) => {
    const { t } = useTranslation()

    const [remains, setRemains] = useState<Remains>(
        prepareRemainsFromAutomat(automat)
    )
    const saveRemains = async (remains: Remains) => {
        await AutomatsService.setRemains(automat.id, remains)
        setRemains(remains)
    }

    const handleCupsRemainingChange = async (value: number) => {
        await saveRemains({
            ...remains,
            cupsRemaining: value,
        })
    }

    const handleWaterChange = async (value: number) => {
        await saveRemains({
            ...remains,
            waterRemaining: value * 1000,
        })
    }

    const handleContainerChange = async (
        value: number,
        containerId: number
    ) => {
        await saveRemains({
            ...remains,
            containers: remains.containers.map((c) => {
                if (c.id === containerId) {
                    return {
                        ...c,
                        remainsVolume: value,
                    }
                }

                return c
            }),
        })
    }

    const { maxCups, powderContainers, syrupContainers, waterVolume } =
        useMemo(() => {
            let maxCups = 0
            let powderContainers: Array<{
                id: number
                volume: number
                number: number
            }> = []
            let syrupContainers: Array<{
                id: number
                volume: number
                number: number
            }> = []
            let waterVolume = 0

            if (model.type !== AutomatModelTypes.Beverages) {
                throw new Error('Automat model must be Beverages')
            }

            maxCups = model.cups

            if (model.containers) {
                for (let i = 0; i < model.containers.length; i++) {
                    const container = model.containers[i]

                    if (container.type === AutomatContainerTypes.Powder) {
                        powderContainers.push({
                            id: container.id,
                            volume: container.volume,
                            number: container.number,
                        })
                    } else if (container.type === AutomatContainerTypes.Syrup) {
                        syrupContainers.push({
                            id: container.id,
                            volume: container.volume,
                            number: container.number,
                        })
                    }
                }
            }

            waterVolume = Math.round(automat.waterVolume / 100) / 10

            return { maxCups, powderContainers, syrupContainers, waterVolume }
        }, [automat])

    const waterRemaining = useMemo(() => {
        if (waterVolume === 0) {
            return 0
        } else if (automat && automat.waterVolume === MAX_INT_32) {
            return Math.round(waterVolume / 100) / 10
        } else if (remains) {
            return Math.round(remains.waterRemaining / 100) / 10
        } else {
            return 0
        }
    }, [remains, waterVolume, automat])

    return (
        <div className={styles.root}>
            <div className={styles.left}>
                <div className={styles.row}>
                    <div className={styles.col}>
                        <SubHeader>{t('512_AutomatRefill_cups')}</SubHeader>
                        <div className={styles.verticalSliderContainer}>
                            <HorizontalSlider
                                defaultValue={remains.cupsRemaining}
                                maxValue={maxCups}
                                onChange={handleCupsRemainingChange}
                                unit={t('515_AutomatRefill_items')}
                                round
                            />
                        </div>
                    </div>
                    <div className={styles.col}>
                        <SubHeader>{t('511_AutomatRefill_water')}</SubHeader>
                        <div
                            className={styles.verticalSliderContainer}
                            title={
                                !waterVolume
                                    ? t(
                                          '568_AutomatRefill_waterVolumeNotSpecified'
                                      )
                                    : automat.waterVolume === MAX_INT_32
                                    ? t('567_AutomatRefill_waterPipes')
                                    : undefined
                            }
                        >
                            <HorizontalSlider
                                defaultValue={
                                    automat.waterVolume === MAX_INT_32
                                        ? waterVolume
                                        : waterRemaining
                                }
                                maxValue={waterVolume}
                                onChange={handleWaterChange}
                                unit={t('566_AutomatRefill_l')}
                                emptyInput={automat.waterVolume === MAX_INT_32}
                                numberOfDecimals={1}
                                classes={waterSliderClasses}
                                disabled={
                                    waterVolume === 0 ||
                                    automat.waterVolume === MAX_INT_32
                                }
                            />
                        </div>
                    </div>
                </div>

                {powderContainers.length > 0 && (
                    <div
                        className={classNames(
                            styles.row,
                            styles.containersContainer
                        )}
                    >
                        <div className={styles.col}>
                            <SubHeader>
                                {t('513_AutomatRefill_powders')}
                            </SubHeader>

                            <div className={styles.containersList}>
                                {powderContainers.map((modelContainer) => {
                                    const { id, volume, number } =
                                        modelContainer
                                    const remaining = remains.containers.find(
                                        (c) => c.automatModelContainerId === id
                                    )

                                    return (
                                        <div className={styles.containersItem}>
                                            <VerticalSlider
                                                defaultValue={
                                                    remaining
                                                        ? remaining.remainsVolume
                                                        : 0
                                                }
                                                maxValue={volume}
                                                onChange={(value) =>
                                                    handleContainerChange(
                                                        value,
                                                        remaining
                                                            ? remaining.id
                                                            : 0
                                                    )
                                                }
                                                number={number}
                                                unit={t('517_AutomatRefill_g')}
                                                round
                                                disabled={!remaining}
                                            />
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                )}

                {syrupContainers.length > 0 && (
                    <div
                        className={classNames(
                            styles.row,
                            styles.containersContainer
                        )}
                    >
                        <div className={styles.col}>
                            <SubHeader>
                                {t('514_AutomatRefill_syrups')}
                            </SubHeader>

                            <div className={styles.containersList}>
                                {syrupContainers.map((modelContainer) => {
                                    const { id, volume, number } =
                                        modelContainer
                                    const remaining = remains.containers.find(
                                        (c) => c.automatModelContainerId === id
                                    )

                                    return (
                                        <div className={styles.containersItem}>
                                            <VerticalSlider
                                                defaultValue={
                                                    remaining
                                                        ? remaining.remainsVolume
                                                        : 0
                                                }
                                                maxValue={volume}
                                                onChange={(value) =>
                                                    handleContainerChange(
                                                        value,
                                                        remaining
                                                            ? remaining.id
                                                            : 0
                                                    )
                                                }
                                                number={number}
                                                unit={t('518_AutomatRefill_ml')}
                                                round
                                                disabled={!remaining}
                                            />
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                )}
            </div>
            <div className={styles.right}>
                <ContainersTable automat={automat} />
            </div>
        </div>
    )
}

export default BeveragesRefill

import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
    AutomatModel,
    AutomatModelTypes,
    AutomatSnackCell,
    AutomatType,
} from '../../../../types'
import { Automats as AutomatsService } from '../../../../services/Automats'
import { useAppDispatch } from '../../../hooks/store'
import { SubHeader } from '../SubHeader'
import { Button } from '../../../simples/Button'
import { SnackCells } from '../SnackCells'

import styles from './Settings.module.scss'
import { showToast } from '../../../../store/slices/site'

type Props = {
    automat: AutomatType
    onSubmit: (automat: AutomatType) => void
    model: AutomatModel
}

export const Cells: FC<Props> = ({
    automat: automatDefault,
    onSubmit,
    model,
}) => {
    const { t } = useTranslation()
    const dispatch = useAppDispatch()

    const [automat, setAutomat] = useState(automatDefault)
    const [submiting, setSubmiting] = useState(false)
    const [changed, setChanged] = useState(false)

    useEffect(() => {
        if (automatDefault !== automat) {
            setAutomat(automatDefault)
        }
    }, [automatDefault])

    if (model.type !== AutomatModelTypes.Snack) {
        throw new Error('Automat model must be Snack')
    }

    const handleCellsChange = (cells: Array<AutomatSnackCell>) => {
        setAutomat({
            ...automat,
            cells,
        })
        setChanged(true)
    }

    const save = async () => {
        setSubmiting(true)
        const updatedAutomat = await AutomatsService.update(automat.id, {
            cells: automat.cells,
        })
        setSubmiting(false)
        setChanged(false)

        dispatch(showToast(t('50_GeneralSettings_saveSuccessMessage')))

        onSubmit(updatedAutomat)
    }

    return (
        <div className={styles.cellsSection}>
            <SubHeader>{t('645_Settings_cells')}</SubHeader>

            <div className={styles.cellsBlock}>
                <SnackCells
                    model={model}
                    cells={automat.cells || []}
                    onChange={handleCellsChange}
                />

                <div className={styles.buttons}>
                    <Button
                        onClick={save}
                        loading={submiting}
                        disabled={!changed}
                    >
                        {t('60_GeneralSettings_update')}
                    </Button>
                </div>
            </div>
        </div>
    )
}

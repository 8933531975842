import React, { FC, useEffect, useMemo, useState } from 'react'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'
import {
    selectFillings,
    selectModels,
    selectOutlets,
    selectTastes,
} from '../../../store/slices/storage'
import { useAppDispatch, useAppSelector } from '../../hooks/store'
import {
    AutomatContainerData,
    AutomatContainerTypes,
    AutomatKioskGoodsItem,
    AutomatModelTypes,
    AutomatSnackCell,
    AutomatType,
} from '../../../types'
import { Automats as AutomatsService } from '../../../services/Automats'
import { Button } from '../../simples/Button'
import { Col } from '../../simples/modalEditor/Col'
import { Editor } from '../../simples/modalEditor/Editor'
import { Row } from '../../simples/modalEditor/Row'
import { OptionType, Selector } from '../../simples/Selector'
import { TextField, TextFieldStatus } from '../../simples/TextField'
import { H } from '../../simples/modalEditor/H'
import BunkerItem, { BunkerItemData } from './BunkerItem'
import { AutomatContainerEditor } from '../AutomatContainerEditor'
import { CheckBox } from '../../simples/CheckBox'
import { MAX_INT_32 } from '../../../constants'
import { showToast } from '../../../store/slices/site'
import { SnackCells } from '../automat/SnackCells'
import { Goods } from '../automat/Goods'

import styles from './AutomatEditor.module.scss'

type ContainerFormType = {
    automatModelContainerId: number
    tasteId: number
    isActive: boolean
    hasSmallDrink: boolean
}

type DosagesFormType = {
    drinkVolume: number
    water: number
    product: number
    conversionFactor: number
    price: number
    tasteId: number
}

export type FormType = {
    name: string
    serialNumber: string
    automatModelId: number
    containers?: Array<ContainerFormType>
    dosages?: Array<DosagesFormType>
    outletId?: number
    waterVolume?: number
    cells?: Array<AutomatSnackCell>
    goods?: Array<AutomatKioskGoodsItem>
}

type FormError = {
    serialNumber: string
}

export const initData: FormType = {
    name: '',
    serialNumber: '',
    automatModelId: 0,
    containers: [],
    dosages: [],
    // outletId: 0,
    waterVolume: 0,
    cells: [],
    goods: [],
}

export const initErrors: FormError = {
    serialNumber: '',
}

type Props = {
    outletId: number
    onSubmit: (automat: AutomatType) => void
    onClose: () => void
}
/**
 *
 * @param mashin
 * @param onSubmit
 * @param onClose
 * @returns модальное окно редактирования автомата
 */

const AutomatEditor: FC<Props> = ({ outletId, onSubmit, onClose }) => {
    const { t } = useTranslation()
    const dispatch = useAppDispatch()
    const outlets = useAppSelector(selectOutlets)

    /**
     * Наполнения
     */
    const fillings = useAppSelector(selectFillings)
    /**
     * модели
     */
    const models = useAppSelector(selectModels)

    const tastes = useAppSelector(selectTastes)

    /**
     * редактируемый аппарат
     */
    const [data, setData] = useState<FormType>(initData)
    const [errors, setErrors] = useState<FormError>(initErrors)

    /**
     * набор id
     */
    const [copedFillingId, setCopedFillingId] = useState<number>()

    const [editedContainerNumber, setEditedContainerNumber] = useState(0)
    const [editedContainerType, setEditedContainerType] =
        useState<AutomatContainerTypes>()
    const [editedContainerData, setEditedContainerData] =
        useState<AutomatContainerData>()

    useEffect(() => {
        if (!!outletId) {
            setData((prev) => ({ ...prev, outletId }))
        }
    }, [outletId])

    //error
    const [error, setError] = useState<undefined | string>()
    /**
     * формирование опций наполнения
     */
    const fillingsOptions: OptionType[] = useMemo(() => {
        return fillings
            .filter((f) => f.automatModelId === data.automatModelId)
            .map((t) => {
                return {
                    label: t.name,
                    value: t.id,
                }
            })
    }, [fillings, data.automatModelId])

    /**
     * Формируем опций для точки размещения
     */
    const outletOptions: OptionType[] = useMemo(() => {
        if (outlets) {
            return outlets.map((i) => {
                return {
                    label: i.address,
                    value: i.id,
                }
            })
        } else {
            return []
        }
    }, [outlets])

    /**
     * формирование опций моделей автоматов
     */
    const modelsOptions: OptionType[] = useMemo(() => {
        return models.map((t) => {
            return {
                label: t.name,
                value: t.id,
            }
        })
    }, [models])

    const currentAutomatModel = useMemo(() => {
        return models.find((model) => model.id === data.automatModelId)
    }, [models, data.automatModelId])

    /**
     * Копирование набора
     */
    const onCopyFromSet = () => {
        if (!currentAutomatModel) {
            throw new Error('Automat model is empty')
        }

        if (currentAutomatModel.type !== AutomatModelTypes.Beverages) {
            throw new Error('Automat model must be Beverages')
        }

        if (!copedFillingId) {
            return
        }

        const filling = fillings.find((f) => f.id === copedFillingId)

        if (!filling) {
            throw new Error('Filling not found')
        }

        if (filling.automatModelId !== data.automatModelId) {
            return
        }

        const newDosages: Array<DosagesFormType> = []
        const hasSmallDrinkOfContainers: Array<boolean> = []

        for (let i = 0; i < filling.containers.length; i++) {
            const container = filling.containers[i]
            let taste = tastes.find((t) => t.id === container.tasteId)

            if (!taste) {
                continue
            }

            hasSmallDrinkOfContainers[container.automatContainerId] =
                taste.baseDosages.length > 1

            for (let j = 0; j < taste.baseDosages.length; j++) {
                let dosage = taste.baseDosages[j]

                newDosages.push({
                    ...dosage,
                    tasteId: taste.id,
                    price: 0,
                })
            }
        }

        const newContainers = filling.containers.map((c) => ({
            automatModelContainerId: c.automatContainerId,
            tasteId: c.tasteId,
            isActive: true,
            hasSmallDrink: hasSmallDrinkOfContainers[c.automatContainerId],
        }))

        setData({
            ...data,
            containers: newContainers,
            dosages: newDosages,
        })
    }

    const startUpdateContainer = (
        number: number,
        container?: BunkerItemData
    ) => {
        if (!currentAutomatModel) {
            throw new Error('Current automat model is empty')
        }

        if (currentAutomatModel.type !== AutomatModelTypes.Beverages) {
            throw new Error('Automat must be Beverages')
        }

        const modelContainer = currentAutomatModel.containers.find(
            (c) => c.number === number
        )

        if (!modelContainer) {
            throw new Error('Model container not found')
        }

        if (!data.dosages) {
            throw new Error('Automat has not dosages')
        }

        setEditedContainerNumber(number)
        setEditedContainerType(modelContainer.type)

        if (container) {
            setEditedContainerData({
                tasteId: container.tasteId,
                dosages: data.dosages.filter(
                    (d) => d.tasteId === container.tasteId
                ),
            })
        }
    }

    const closeContainerEditor = () => {
        if (!currentAutomatModel) {
            throw new Error('Current automat model is empty')
        }

        if (currentAutomatModel.type !== AutomatModelTypes.Beverages) {
            throw new Error('Automat must be Beverages')
        }

        setEditedContainerNumber(0)
        setEditedContainerData(undefined)
    }

    const containerEditorSubmit = (containerData: AutomatContainerData) => {
        if (!currentAutomatModel) {
            throw new Error('Current automat model is absent')
        }

        if (currentAutomatModel.type !== AutomatModelTypes.Beverages) {
            throw new Error('Automat must be Beverages')
        }

        const automatModelContainer = currentAutomatModel.containers.find(
            (c) => c.number === editedContainerNumber
        )

        if (!automatModelContainer) {
            throw new Error('Automat model container not found')
        }

        if (!data.containers) {
            throw new Error('Automat has not containers')
        }

        if (!data.dosages) {
            throw new Error('Automat has not dosages')
        }

        let updated = false
        const newContainers = data.containers.map((container) => {
            if (
                container.automatModelContainerId === automatModelContainer.id
            ) {
                updated = true
                return {
                    ...container,
                    tasteId: containerData.tasteId,
                    isActive: true,
                    hasSmallDrink: containerData.dosages.length > 1,
                }
            }

            return container
        })
        if (!updated) {
            newContainers.push({
                automatModelContainerId: automatModelContainer.id,
                tasteId: containerData.tasteId,
                isActive: true,
                hasSmallDrink: containerData.dosages.length > 1,
            })
        }

        const newDosages = [
            ...data.dosages.filter((d) => d.tasteId !== containerData.tasteId),
            ...containerData.dosages,
        ]

        setData({
            ...data,
            containers: newContainers,
            dosages: newDosages,
        })

        closeContainerEditor()
    }

    useEffect(() => {
        setError(undefined)
    }, [data.outletId])

    const handleSubmit = async () => {
        if (!!!data.outletId) {
            setError(t('587_Select_Outlet'))
            dispatch(showToast(t('587_Select_Outlet')))
            return false
        }
        const sendData = {
            ...data,
        }
        try {
            const createdAutomat = await AutomatsService.create(
                !!outletId && outletId !== 0
                    ? { ...sendData, outletId }
                    : sendData
            )

            onSubmit(createdAutomat)
        } catch (error) {
            if (
                error &&
                // @ts-ignore
                error.response &&
                // @ts-ignore
                error.response.data &&
                // @ts-ignore
                error.response.data.errors
            ) {
                // @ts-ignore
                const errors = error.response.data.errors
                if (errors.serialNumber && _.isArray(errors.serialNumber)) {
                    setErrors({
                        ...errors,
                        serialNumber: errors.serialNumber.join(', '),
                    })
                }
            }
        }
    }

    const handleCellsChange = (cells: Array<AutomatSnackCell>) => {
        setData({
            ...data,
            cells,
        })
    }

    const handleGoodsChange = (goods: Array<AutomatKioskGoodsItem>) => {
        setData({
            ...data,
            goods,
        })
    }

    const disabled = useMemo(() => {
        return !data.name || !data.automatModelId || !data.serialNumber
    }, [data])

    return (
        <Editor
            onSubmit={handleSubmit}
            submitDisabled={disabled}
            onCancel={onClose}
            header={t('103_AutomatEditor_creationOfAutomate')}
            submitButtonName={t('103_AutomatEditor_save')}
        >
            <Row>
                <Col>
                    <TextField
                        placeholder={t('104_AutomatEditor_automateName')}
                        value={data.name}
                        onChange={(e) =>
                            setData({
                                ...data,
                                name: e.target.value,
                            })
                        }
                    />
                </Col>
            </Row>
            <Row>
                <Col>
                    <Selector
                        label={t('105_AutomatEditor_automateModel')}
                        options={modelsOptions}
                        value={+data.automatModelId}
                        onChange={(e) => {
                            setData({ ...data, automatModelId: e })
                        }}
                    />
                </Col>
            </Row>
            <Row>
                <Col>
                    <Selector
                        error={error}
                        label={t('198_PermissionsForm_outlet')}
                        options={outletOptions}
                        value={data.outletId}
                        onChange={(e) => {
                            setData({ ...data, outletId: e })
                        }}
                    />
                </Col>
            </Row>
            <Row>
                <Col>
                    <TextField
                        placeholder={t('106_AutomatEditor_serialNumber')}
                        value={data.serialNumber}
                        onChange={(e) => {
                            setData({
                                ...data,
                                serialNumber: e.target.value,
                            })
                            setErrors({
                                ...errors,
                                serialNumber: '',
                            })
                        }}
                        note={errors.serialNumber}
                        status={
                            !!errors.serialNumber
                                ? TextFieldStatus.Error
                                : TextFieldStatus.Normal
                        }
                    />
                </Col>
            </Row>

            {currentAutomatModel && (
                <>
                    {currentAutomatModel.type ===
                        AutomatModelTypes.Beverages && (
                        <>
                            <Row>
                                <Col>
                                    <H level={3}>
                                        {t('564_AutomatEditor_waterVolume')}
                                    </H>

                                    <TextField
                                        type={'number'}
                                        placeholder={t(
                                            '564_AutomatEditor_waterVolume'
                                        )}
                                        value={
                                            data.waterVolume === MAX_INT_32
                                                ? 0
                                                : Math.round(
                                                      (data.waterVolume || 0) /
                                                          100
                                                  ) /
                                                      10 +
                                                  ''
                                        }
                                        onChange={(e) =>
                                            setData({
                                                ...data,
                                                waterVolume:
                                                    +e.target.value * 1000,
                                            })
                                        }
                                        disabled={
                                            data.waterVolume === MAX_INT_32
                                        }
                                    />

                                    <div className={styles.waterPipesCheckbox}>
                                        <CheckBox
                                            checked={
                                                data.waterVolume === MAX_INT_32
                                            }
                                            onChange={(checked) =>
                                                setData({
                                                    ...data,
                                                    waterVolume: checked
                                                        ? MAX_INT_32
                                                        : 0,
                                                })
                                            }
                                            label={t(
                                                '565_AutomatEditor_waterPipes'
                                            )}
                                        />
                                    </div>
                                </Col>
                            </Row>

                            <H level={3}>
                                {t('110_AutomatEditor_copyFromSet')}
                            </H>
                            <Row>
                                <Col width={'forth-fith'}>
                                    <Selector
                                        label={t('111_AutomatEditor_selectSet')}
                                        options={fillingsOptions}
                                        value={copedFillingId}
                                        onChange={(i) => {
                                            setCopedFillingId(i)
                                        }}
                                    />
                                </Col>
                                <Col width={'one-fith'}>
                                    <Button
                                        onClick={onCopyFromSet}
                                        classNames={{
                                            button: styles.btn,
                                        }}
                                    >
                                        {t('112_AutomatEditor_copy')}
                                    </Button>
                                </Col>
                            </Row>

                            <H level={2}>{t('113_AutomatEditor_products')}</H>

                            {[...currentAutomatModel.containers]
                                .sort((c1, c2) => c1.number - c2.number)
                                .map((modelContainer) => {
                                    if (!data.containers) {
                                        throw new Error(
                                            'Automat has not containers'
                                        )
                                    }

                                    const container = data.containers.find(
                                        (c) =>
                                            c.automatModelContainerId ===
                                            modelContainer.id
                                    )

                                    return (
                                        <Row>
                                            <Col>
                                                <BunkerItem
                                                    number={
                                                        modelContainer.number
                                                    }
                                                    bunker={container}
                                                    onEdit={
                                                        startUpdateContainer
                                                    }
                                                />
                                            </Col>
                                        </Row>
                                    )
                                })}

                            {!!editedContainerNumber &&
                                !!editedContainerType && (
                                    <AutomatContainerEditor
                                        data={editedContainerData}
                                        containerType={editedContainerType}
                                        onClose={closeContainerEditor}
                                        onSubmit={containerEditorSubmit}
                                    />
                                )}
                        </>
                    )}
                </>
            )}
        </Editor>
    )
}

export default AutomatEditor

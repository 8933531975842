import React, { FC } from 'react'
import classNames from 'classnames'
import { decimalAdjust } from '../../../utils/math'
import { ReactComponent as ArrowIcon } from '../../../assets/icons/relativaly-value-arrow.svg'
import styles from './WidgetStatisticsNumbers.module.scss'

type Props = {
    value: number
    note?: string
}

export const RelativelyValue: FC<Props> = ({ value, note }) => {
    return (
        <div className={styles.relativelyValue}>
            <div
                className={classNames(styles.relativelyValuePercent, {
                    [styles.growth]: value > 0,
                    [styles.drop]: value < 0,
                })}
            >
                {Math.abs(decimalAdjust('round', value, -1))}%
                <ArrowIcon className={styles.relativelyValueArrow} />
            </div>

            {note && <div className={styles.relativelyValueNote}>{note}</div>}
        </div>
    )
}

import React, { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AdMaterial as AdMaterialType } from '../../../types'
import { Button } from '../../simples/Button'
import { AdMaterialsFinder } from '../AdMaterialsFinder'
import { Preview } from '../../simples/Preview'
import styles from './AdModuleEditor.module.scss'

type Props = {
    adMaterial?: AdMaterialType
    onChange?: (adMaterial: AdMaterialType) => void
}

export const AdMaterialInput: FC<Props> = ({ adMaterial, onChange }) => {
    const { t } = useTranslation()
    const [showFinder, setShowFinder] = useState(false)

    const handleAdMaterialAdd = (adMaterial: AdMaterialType) => {
        setShowFinder(false)
        onChange && onChange(adMaterial)
    }

    return (
        <div className={styles.adMaterialInput}>
            {adMaterial ? (
                <>
                    <div className={styles.preview}>
                        <Preview
                            src={adMaterial.photoPath}
                            className={styles.previewPhoto}
                        />
                        <div className={styles.previewName}>
                            {adMaterial.name}
                        </div>
                    </div>
                    <Button onClick={() => setShowFinder(true)}>
                        {t('24_AdModuleEditor_changeMaterial')}
                    </Button>
                </>
            ) : (
                <Button onClick={() => setShowFinder(true)}>
                    {t('25_AdModuleEditor_selectMaterial')}
                </Button>
            )}

            {showFinder && (
                <AdMaterialsFinder
                    onAdd={handleAdMaterialAdd}
                    onCancel={() => setShowFinder(false)}
                />
            )}
        </div>
    )
}

import React, { FC, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AutomatContainerTypes } from '../../../types'
import { useAppSelector } from '../../hooks/store'
import {
    selectBrands,
    selectProducts,
    selectTastes,
} from '../../../store/slices/storage'
import { SearchSelector } from '../../simples/SearchSelector'
import styles from './FillingEditor.module.scss'

type Props = {
    number: number
    containerType: AutomatContainerTypes
    tasteId: number
    onTasteChange: (tasteId: number) => void
}

export const ContainerItem: FC<Props> = ({
    number,
    containerType,
    tasteId,
    onTasteChange,
}) => {
    const { t } = useTranslation()
    const brands = useAppSelector(selectBrands)
    const products = useAppSelector(selectProducts)
    const tastes = useAppSelector(selectTastes)

    const [brandId, setBrandId] = useState(0)
    const [productId, setProductId] = useState(0)

    useEffect(() => {
        if (!tasteId) {
            return
        }

        const taste = tastes.find((t) => t.id === tasteId)

        if (!taste) {
            return
        }

        const product = products.find((p) => p.id === taste.productId)

        if (!product) {
            return
        }

        const brand = brands.find((b) => b.id === product.brandId)

        if (brand) {
            setBrandId(brand.id)
        }
        setProductId(product.id)
    }, [])

    const brandsOptions = useMemo(() => {
        return brands.map((brand) => ({ value: brand.name, id: brand.id }))
    }, [brands])

    const productsOptions = useMemo(() => {
        if (!brandId) {
            return []
        }

        return products
            .filter((product) => product.brandId === brandId)
            .map((product) => ({ value: product.name, id: product.id }))
    }, [products, brandId])

    const tastesOptions = useMemo(() => {
        if (!productId) {
            return []
        }

        return tastes
            .filter(
                (taste) =>
                    taste.product &&
                    taste.product.id === productId &&
                    +taste.product.condition === +containerType
            )
            .map((taste) => ({ value: taste.name, id: taste.id }))
    }, [tastes, containerType, productId])

    return (
        <div className={styles.containerItem}>
            <div className={styles.containerItemNumber}>{number}.</div>

            <div className={styles.containerItemSelect}>
                <SearchSelector
                    placeholder={t('134_FillingEditor_selectBrand')}
                    options={brandsOptions}
                    label={t('135_FillingEditor_brand')}
                    value={brandId}
                    onClick={(option) => setBrandId(option.id)}
                />
            </div>

            <div className={styles.containerItemSelect}>
                <SearchSelector
                    placeholder={t('136_FillingEditor_selectProduct')}
                    options={productsOptions}
                    label={t('137_FillingEditor_product')}
                    value={productId}
                    onClick={(option) => setProductId(option.id)}
                    disabled={!brandId}
                />
            </div>

            <div className={styles.containerItemSelect}>
                <SearchSelector
                    placeholder={t('138_FillingEditor_selectTaste')}
                    options={tastesOptions}
                    label={t('139_FillingEditor_taste')}
                    value={tasteId}
                    onClick={(option) => onTasteChange(option.id)}
                    disabled={!productId}
                />
            </div>
        </div>
    )
}

import React, { FC, useEffect, useMemo, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import {
    AutomatModelTypes,
    AutomatType,
    Order as OrderType,
} from '../../../types'
import { getPriceMethodName } from '../../../utils/helpers'
import { Layout } from '../../complexes/Layout'
import { AutomatTabs } from '../../complexes/automat/AutomatTabs'
import { SubHeader } from '../../complexes/automat/SubHeader'
// import { Selector } from '../../simples/Selector'
import { Button } from '../../simples/Button'
import { TableCheckbox } from '../../simples/TableCheckbox'
import styles from './AutomatFiscalization.module.scss'

import moment from 'moment'
import { Spinner } from '../../simples/Spinner'
import { Orders as OrdersService } from '../../../services/Orders'
import { Automats as AutomatsService } from '../../../services/Automats'
import { useAppDispatch, useAppSelector } from '../../hooks/store'
import { loadModels, selectModels } from '../../../store/slices/storage'

const sendButtonClassNames = {
    button: styles.sendButton,
}

type RouterParams = {
    id: string
}

const AutomatFiscalization: FC = () => {
    const { t } = useTranslation()
    const { id } = useParams<RouterParams>()

    const [automat, setAutomat] = useState<AutomatType>()
    const models = useAppSelector(selectModels)
    useEffect(() => {
        AutomatsService.getItem(+id).then((automat) => {
            setAutomat(automat)
        })
    }, [])

    const dispatch = useAppDispatch()
    useEffect(() => {
        dispatch(loadModels())
    }, [])

    const [orders, setOrders] = useState<Array<OrderType>>([])
    const ordersCurrent = useRef<Array<OrderType>>(orders)
    ordersCurrent.current = orders
    const [loading, setLoading] = useState(false)
    const offset = useRef(0)
    const has = useRef(true)
    const [checkedOrders, setCheckedOrders] = useState<Array<number>>([])

    const handleCommonCheckboxChange = (checked: boolean) => {
        if (checked) {
            setCheckedOrders(orders.map((order) => order.id))
        } else {
            setCheckedOrders([])
        }
    }

    const changeOrderChecked = (orderId: number, checked: boolean) => {
        if (checked) {
            setCheckedOrders([...checkedOrders, orderId])
        } else {
            setCheckedOrders(checkedOrders.filter((oid) => oid !== orderId))
        }
    }

    const commonCheckboxChecked = useMemo(() => {
        if (checkedOrders.length === 0) {
            return false
        }

        for (let i = 0; i < orders.length; i++) {
            const currentOrder = orders[i]

            if (checkedOrders.indexOf(currentOrder.id) === -1) {
                return 'partly'
            }
        }

        return true
    }, [checkedOrders, orders])

    const loadOrders = async () => {
        if (!has.current || loading) {
            return
        }

        setLoading(true)

        const result = await OrdersService.getList({
            automatId: +id,
            offset: offset.current,
        })

        setLoading(false)

        if (!result || result.length === 0) {
            has.current = false
        }

        const newOrders = [...ordersCurrent.current, ...result]
        offset.current = newOrders.length
        setOrders(newOrders)
    }

    useEffect(() => {
        loadOrders().then()
    }, [])

    const handleEndReached = async () => {
        await loadOrders()
    }

    const currentModel = useMemo(() => {
        if (!automat) {
            return undefined
        }

        return models.find((m) => m.id === automat.automatModelId)
    }, [automat, models])

    return (
        <Layout onEndReached={handleEndReached}>
            <h1 className={styles.header}>
                {t('315_AutomatFiscalization_automateControl')} "
                {automat ? automat.name : ''}"
            </h1>

            <AutomatTabs automatId={+id} />

            <SubHeader>
                {t('316_AutomatFiscalization_fiscalizationModuleCashbox')}
            </SubHeader>

            {/*<form className={styles.outletForm}>*/}
            {/*    <div className={styles.outletFormContainer}>*/}
            {/*        <div className={styles.outletSelectorWrap}>*/}
            {/*            <Selector*/}
            {/*                label={'Выберите торговую точку'}*/}
            {/*                options={outletOptions}*/}
            {/*                value={outletId}*/}
            {/*                onChange={setOutletId}*/}
            {/*            />*/}
            {/*        </div>*/}
            {/*        <div className={styles.outletSubmitWrap}>*/}
            {/*            <Button type="submit">Применить</Button>*/}
            {/*        </div>*/}
            {/*    </div>*/}

            {/*    <div className={styles.outletNote}>*/}
            {/*        Привяжите точку продаж из доступных в списке*/}
            {/*    </div>*/}
            {/*</form>*/}

            <div className={styles.sendPanel}>
                <Button classNames={sendButtonClassNames}>
                    {t('317_AutomatFiscalization_sendForFiscalization')}
                </Button>
            </div>

            <table className={styles.table}>
                <thead>
                    <tr>
                        <th>
                            <TableCheckbox
                                checked={commonCheckboxChecked}
                                onChange={handleCommonCheckboxChange}
                            />
                        </th>
                        <th>
                            {currentModel &&
                            (currentModel.type === AutomatModelTypes.Snack ||
                                currentModel.type === AutomatModelTypes.Kiosk)
                                ? t('664_AutomatFiscalization_GoodsItem')
                                : t('318_AutomatFiscalization_drink')}
                        </th>
                        <th>{t('319_AutomatFiscalization_automate')}</th>
                        <th>{t('320_AutomatFiscalization_sum')}</th>
                        <th>{t('321_AutomatFiscalization_paymentType')}</th>
                        <th>{t('322_AutomatFiscalization_date')}</th>
                    </tr>
                </thead>

                <tbody>
                    {orders.map((order) => {
                        let name = ''
                        if (currentModel) {
                            if (
                                currentModel.type ===
                                    AutomatModelTypes.Beverages &&
                                order.items &&
                                order.items.length > 0 &&
                                order.items[0].taste
                            ) {
                                name = order.items[0].taste.name
                            } else if (
                                (currentModel.type ===
                                    AutomatModelTypes.Snack ||
                                    currentModel.type ===
                                        AutomatModelTypes.Kiosk) &&
                                order.goodName
                            ) {
                                name = order.goodName
                            }
                        }

                        return (
                            <tr>
                                <td>
                                    <TableCheckbox
                                        checked={
                                            checkedOrders.indexOf(order.id) !==
                                            -1
                                        }
                                        onChange={(checked) =>
                                            changeOrderChecked(
                                                order.id,
                                                checked
                                            )
                                        }
                                    />
                                </td>
                                <td>{name}</td>
                                <td>{order.automat?.name}</td>
                                <td>{order.totalPrice}</td>
                                <td>
                                    {order.prices && order.prices.length > 0
                                        ? getPriceMethodName(
                                              order.prices[0].method,
                                              t
                                          )
                                        : ''}
                                </td>
                                <td>
                                    {moment(order.buyAt).format('YYYY-MM-DD')}
                                </td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>

            {loading && (
                <div className={styles.loader}>
                    <Spinner />
                </div>
            )}
        </Layout>
    )
}

export default AutomatFiscalization

import React, { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useAppSelector } from '../../hooks/store'
import { selectAutomats } from '../../../store/slices/storage'
import {
    MultiSelector,
    Option as MultiSelectorOptions,
    Props as MultiSelectorProps,
} from '../../simples/MultiSelector'

type Props = {
    value: Array<number>
    onChange: (value: Array<number>) => void
    isDisabled?: boolean
}

const AutomatsSelectorModule: FC<Props> = ({ value, onChange, isDisabled }) => {
    const { t } = useTranslation()

    const automats = useAppSelector(selectAutomats)

    const options = useMemo(() => {
        return automats.map((automat) => ({
            value: automat.id,
            label: automat.name,
        }))
    }, [automats])

    return (
        <MultiSelector
            options={options}
            value={value}
            onChange={onChange}
            isDisabled={isDisabled}
            placeholder={t('480_AutomatsSelector_placeholder')}
        />
    )
}

export default AutomatsSelectorModule

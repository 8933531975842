import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { AuthLayout } from '../../complexes/AuthLayout'
import styles from './RegistrationSuccess.module.scss'

const RegistrationSuccess: FC = () => {
    const { t } = useTranslation()

    return (
        <AuthLayout>
            <div className={styles.root}>
                <h3 className={styles.header}>
                    {t('448_RegistrationSuccess_confirmEmail')}
                </h3>
                <p className={styles.text}>
                    {t('449_RegistrationSuccess_message')}
                </p>
            </div>
        </AuthLayout>
    )
}

export default RegistrationSuccess

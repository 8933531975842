import React, {
    FC,
    useRef,
    useState,
    useEffect,
    useMemo,
    ChangeEvent,
} from 'react'
import classNames from 'classnames'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { GoodsItem as GoodsItemType } from '../../../types'
import { Goods as GoodsService } from '../../../services/Goods'
import { useAppDispatch } from '../../hooks/store'
import { loadBrands } from '../../../store/slices/storage'
import { showToast } from '../../../store/slices/site'

import { Layout } from '../../complexes/Layout'
import { Header } from '../../simples/tablePages/Header'
import { TopPanel } from '../../simples/tablePages/TopPanel'
import { Button } from '../../simples/Button'
import { ItemsList } from '../../simples/tablePages/ItemsList'
import { Confirm } from '../../simples/Confirm'
import { GoodsItemEditor } from '../../complexes/GoodsItemEditor'
import styles from './Goods.module.scss'

const topPanelButtonClasses = {
    button: styles.topPanelRightButton,
}

const Goods: FC = () => {
    const { t } = useTranslation()
    const dispatch = useAppDispatch()

    const headers = [
        t('597_Photo'),
        t('598_Barcode'),
        t('599_Brand'),
        t('600_Name'),
    ]

    const [goods, setGoods] = useState<Array<GoodsItemType>>([])
    const goodsCurrent = useRef<Array<GoodsItemType>>(goods)
    goodsCurrent.current = goods
    const [loading, setLoading] = useState(false)
    const offset = useRef(0)
    const search = useRef('')
    const has = useRef(true)

    const [importing, setImporting] = useState(false)
    const [exporting, setExporting] = useState(false)

    const [showGoodsItemEditor, setShowGoodsItemEditor] = useState(false)
    const [editedGoodsItem, setEditedGoodsItem] = useState<GoodsItemType>()

    const [deletedGoodsItemId, setDeletedGoodsItemId] = useState(0)

    const load = async () => {
        if (!has.current || loading) {
            return
        }

        setLoading(true)
        const result = await GoodsService.getList({
            offset: offset.current,
            search: search.current,
        })

        setLoading(false)

        if (!result.length) {
            has.current = false
            return
        }

        offset.current = offset.current + result.length
        setGoods([...goodsCurrent.current, ...result])
    }

    const clear = () => {
        has.current = true
        offset.current = 0
        search.current = ''
        setGoods([])
    }

    useEffect(() => {
        load().then()
        dispatch(loadBrands())
    }, [dispatch])

    const handleEndReached = async () => {
        await load()
    }

    const handleSearch = async (text: string) => {
        clear()
        search.current = text
        await load()
    }

    const startCreateGoodsItem = () => {
        setShowGoodsItemEditor(true)
    }

    const startUpdateGoodsItem = (goodsItemId: number) => {
        const goodsItem = goods.find((gi) => gi.id === goodsItemId)

        if (!goodsItem) {
            return
        }

        setShowGoodsItemEditor(true)
        setEditedGoodsItem(goodsItem)
    }

    const handleSubmit = async (goodsItem: GoodsItemType) => {
        // Закрываем модальное окно
        setShowGoodsItemEditor(false)
        setEditedGoodsItem(undefined)

        // Обновляем массив товаров
        let updated = false

        let newGoods = goods.map((gi) => {
            if (gi.id === goodsItem.id) {
                updated = true
                return goodsItem
            }

            return gi
        })

        if (!updated) {
            newGoods = [goodsItem, ...goods]
        }

        setGoods(newGoods)
    }

    const closeGoodsItemEditor = () => {
        setShowGoodsItemEditor(false)
        setEditedGoodsItem(undefined)
    }

    const startDeleteGoodsItem = (goodsItemId: number) => {
        setDeletedGoodsItemId(goodsItemId)
    }

    const cancelGoodsItemDelete = () => {
        setDeletedGoodsItemId(0)
    }

    const confirmGoodsItemDelete = async () => {
        setDeletedGoodsItemId(0)
        await GoodsService.deleteGoodsItem(deletedGoodsItemId)
        setGoods(
            goods.filter((goodsItem) => goodsItem.id !== deletedGoodsItemId)
        )
    }

    const [importButtonKey, setImportButtonKey] = useState(() =>
        Math.random().toString(36)
    )
    const handleImportButtonChange = async (
        event: ChangeEvent<HTMLInputElement>
    ) => {
        const { files } = event.target

        if (!files || files.length === 0) {
            return
        }

        setImporting(true)

        try {
            await GoodsService.import(files[0])
            setImporting(false)

            clear()
            await load()

            dispatch(showToast(t('601_Goods_imported_successfully')))
        } catch (error) {
            if (
                error.response &&
                error.response.data &&
                error.response.data.errors &&
                Array.isArray(error.response.data.errors)
            ) {
                dispatch(
                    showToast({
                        message: `${t(
                            '602_An_error_occurred_during_import'
                        )}: ${error.response.data.errors.join(', ')}`,
                        status: 'error',
                    })
                )
            } else {
                dispatch(
                    showToast({
                        message: t('603_Failed_to_upload_file'),
                        status: 'error',
                    })
                )
            }

            setImporting(false)
        }

        setImportButtonKey(Math.random().toString(36))
    }

    const exportGoods = async () => {
        setExporting(true)

        const blob = await GoodsService.export()

        const xlsxUrl = URL.createObjectURL(blob)

        const anchor = document.createElement('a')
        anchor.href = xlsxUrl
        anchor.download =
            'Goods_' + moment().format('YYYY_MM_DD HH_mm_ss') + '.xlsx'

        document.body.appendChild(anchor)
        anchor.click()
        document.body.removeChild(anchor)

        URL.revokeObjectURL(xlsxUrl)

        setExporting(false)
    }

    const handleMassDelete = async (ids: Array<number>) => {
        for (let i = 0; i < ids.length; i++) {
            const id = ids[i]

            try {
                await GoodsService.deleteGoodsItem(id)
            } catch (error) {}
        }

        const newGoods = goods.filter((gi) => ids.indexOf(gi.id) === -1)
        setGoods(newGoods)
    }

    const rows = useMemo(() => {
        if (goods.length === 0) {
            return []
        }

        return goods.map((goodsItem) => {
            return {
                id: goodsItem.id,
                values: [
                    goodsItem.photoPath ? (
                        <span
                            className={styles.goodsItemPhoto}
                            style={{
                                backgroundImage: goodsItem.photoPath
                                    ? `url("${goodsItem.photoPath}")`
                                    : undefined,
                            }}
                        />
                    ) : (
                        ''
                    ),
                    goodsItem.barcode,
                    goodsItem.brand.name,
                    goodsItem.name,
                ],
            }
        })
    }, [goods])

    return (
        <Layout onEndReached={handleEndReached}>
            <Header text={t('604_Goods')} />
            <TopPanel
                createButtonName={t('605_Create_goods_item')}
                onCreateButtonClick={startCreateGoodsItem}
                onSearch={handleSearch}
                rightAddition={
                    <div className={styles.topPanelRightAddition}>
                        <label
                            className={classNames(styles.importButton, {
                                [styles.importButtonLoading]: importing,
                            })}
                        >
                            <input
                                type="file"
                                onChange={handleImportButtonChange}
                                key={importButtonKey}
                                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                            />
                            <span className={styles.importButtonContain}>
                                {t('606_Import_xlsx')}
                            </span>
                            <span className={styles.importButtonLoader}>
                                <span />
                                <span />
                                <span />
                            </span>
                        </label>
                        <Button
                            classNames={topPanelButtonClasses}
                            onClick={exportGoods}
                            loading={exporting}
                        >
                            {t('607_Export_xlsx')}
                        </Button>
                    </div>
                }
            />

            <ItemsList
                headers={headers}
                rows={rows}
                loading={loading}
                onEdit={startUpdateGoodsItem}
                onDelete={startDeleteGoodsItem}
                enableMassDeleting
                onMassDelete={handleMassDelete}
            />

            {showGoodsItemEditor && (
                <GoodsItemEditor
                    goodsItem={editedGoodsItem}
                    onSubmit={handleSubmit}
                    onClose={closeGoodsItemEditor}
                />
            )}

            {!!deletedGoodsItemId && (
                <Confirm
                    text={t('608_Goods_item_delete_confirm')}
                    onConfirm={confirmGoodsItemDelete}
                    onCancel={cancelGoodsItemDelete}
                />
            )}
        </Layout>
    )
}

export default Goods

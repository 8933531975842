import React, { ChangeEvent, FC, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AutomatContainerData, AutomatContainerTypes } from '../../../../types'
import { useAppSelector } from '../../../hooks/store'
import {
    selectBrands,
    selectProducts,
    selectTastes,
} from '../../../../store/slices/storage'

import { ReactComponent as EditIcon } from '../../../../assets/icons/edit.svg'
import {
    OptionSelectorType,
    SearchSelector,
} from '../../../simples/SearchSelector'
import { TextField } from '../../../simples/TextField'
import { CheckBox } from '../../../simples/CheckBox'
import styles from './Settings.module.scss'
import { FormType } from './Types/Types'

const priceFieldClasses = {
    root: styles.containerItemPriceFieldRoot,
    input: styles.containerItemPriceFieldInput,
}

type Props = {
    number: number
    container?: AutomatContainerData
    containerType: AutomatContainerTypes
    onChange?: (number: number, container?: AutomatContainerData) => void
    onEdit: (number: number, container?: AutomatContainerData) => void
    automat: FormType
}

export const ContainerItem: FC<Props> = ({
    number,
    container,
    containerType,
    onChange,
    onEdit,
    automat,
}) => {
    const { t } = useTranslation()

    const brands = useAppSelector(selectBrands)
    const products = useAppSelector(selectProducts)
    const tastes = useAppSelector(selectTastes)
    // const baseDosages = useAppSelector(selectBaseDosages)

    const [brandId, setBrandId] = useState(0)
    const [productId, setProductId] = useState(0)

    const tasteId = container ? container.tasteId : 0

    useEffect(() => {
        if (!tasteId) {
            setBrandId(0)
            setProductId(0)
            return
        }

        const taste = tastes.find((t) => t.id === tasteId)

        if (!taste) {
            return
        }

        const product = products.find((p) => p.id === taste.productId)

        if (!product) {
            return
        }

        const brand = brands.find((b) => b.id === product.brandId)

        if (brand) {
            setBrandId(brand.id)
        }
        setProductId(product.id)
    }, [brands, products, tastes, tasteId])

    const brandsOptions = useMemo(() => {
        return brands.map((brand) => ({ value: brand.name, id: brand.id }))
    }, [brands])

    const productsOptions = useMemo(() => {
        if (!brandId) {
            return []
        }

        return products
            .filter((product) => product.brandId === brandId)
            .map((product) => ({ value: product.name, id: product.id }))
    }, [products, brandId])

    const tasteOptions = useMemo(() => {
        if (!productId) {
            return []
        }
        return tastes
            .filter(
                (taste) =>
                    taste.product &&
                    taste.product.id === productId &&
                    +taste.product.condition === +containerType
            )
            .map((t) => {
                return {
                    id: t.id,
                    value: t.name,
                }
            })
    }, [tastes, containerType, productId])

    const handleBrandChange = (option: OptionSelectorType) => {
        if (option.id === brandId) {
            return
        }

        onChange && onChange(number, undefined)
        setBrandId(option.id)
    }

    const handleProductChange = (option: OptionSelectorType) => {
        if (option.id === productId) {
            return
        }

        onChange && onChange(number, undefined)
        setProductId(option.id)
    }

    const handleTasteChange = (option: OptionSelectorType) => {
        if (option.id === tasteId) {
            return
        }

        const currentTaste = tastes.find((item) => item.id === option.id)

        if (!currentTaste) {
            // throw new Error('currentTaste not found')
            return
        }

        // Установка дозировок

        let minId =
            automat.dosages.length > 0
                ? Math.min(...automat.dosages.map((d) => d?.id || 0))
                : 0

        if (minId > 0) {
            minId = 0
        }

        const newContainer: AutomatContainerData = {
            tasteId: option.id,
            dosages: [],
        }

        if (currentTaste.baseDosages.length > 0) {
            const largeDosage = [...currentTaste.baseDosages].sort(
                (d1, d2) => d2.drinkVolume - d1.drinkVolume
            )[0]
            newContainer.dosages.push({
                ...largeDosage,
                tasteId: option.id,
                id: --minId,
            })
        } else {
            newContainer.dosages.push({
                drinkVolume: 0,
                water: 0,
                product: 0,
                conversionFactor: 0,
                price: 0,
                tasteId: option.id,
                id: --minId,
            })
        }

        onChange && onChange(number, newContainer)
    }

    const handleDosage2CheckboxChange = (checked: boolean) => {
        if (!container) {
            throw new Error('Container is empty')
        }

        if (!tasteId) {
            throw new Error('Taste not selected')
        }

        const currentTaste = tastes.find((item) => item.id === tasteId)

        if (!currentTaste) {
            throw new Error('currentTaste not found')
        }

        if (checked) {
            // Добавляем самую маленькую дозировку из базовых
            const newDosages = [...container.dosages]

            if (currentTaste.baseDosages.length > 0) {
                const smallDosage = [...currentTaste.baseDosages].sort(
                    (d1, d2) => d1.drinkVolume - d2.drinkVolume
                )[0]
                newDosages.push({
                    ...smallDosage,
                    tasteId: tasteId,
                    id: undefined,
                })
            } else {
                newDosages.push({
                    drinkVolume: 0,
                    water: 0,
                    product: 0,
                    conversionFactor: 0,
                    price: 0,
                    tasteId: tasteId,
                    id: undefined,
                })
            }

            onChange &&
                onChange(number, {
                    ...container,
                    dosages: newDosages,
                })
        } else if (container.dosages.length > 0) {
            // Оставляем только самую большую дозировку

            const largestDosage = [...container.dosages].sort(
                (d1, d2) => d2.drinkVolume - d1.drinkVolume
            )[0]

            onChange &&
                onChange(number, {
                    ...container,
                    dosages: [largestDosage],
                })
        }
    }

    const priceLarge = useMemo(() => {
        if (!container || container.dosages.length === 0) {
            return undefined
        }

        const sortedDosages = [...container.dosages].sort(
            (dosageA, dosageB) => {
                return dosageB.drinkVolume - dosageA.drinkVolume
            }
        )

        const largeDosage = sortedDosages[0]

        return largeDosage.price || 0
    }, [container])

    const priceSmall = useMemo(() => {
        if (!container || container.dosages.length < 2) {
            return undefined
        }

        const sortedDosages = [...container.dosages].sort(
            (dosageA, dosageB) => {
                return dosageB.drinkVolume - dosageA.drinkVolume
            }
        )

        const smallDosage = sortedDosages[1]

        return smallDosage.price || 0
    }, [container])

    const handlePriceLargeChange = (event: ChangeEvent<HTMLInputElement>) => {
        if (!container || container.dosages.length === 0) {
            throw new Error('Dosages is empty')
        }

        const value = Math.abs(+event.target.value)

        let largeDosageIndex = -1
        let largeDosageDrinkVolume = -1

        for (let i = 0; i < container.dosages.length; i++) {
            if (container.dosages[i].drinkVolume > largeDosageDrinkVolume) {
                largeDosageDrinkVolume = container.dosages[i].drinkVolume
                largeDosageIndex = i
            }
        }

        if (largeDosageIndex === -1) {
            throw new Error('Large dosage not found')
        }

        onChange &&
            onChange(number, {
                ...container,
                dosages: container.dosages.map((dosage, index) => {
                    if (largeDosageIndex === index) {
                        return {
                            ...dosage,
                            price: value,
                        }
                    }

                    return dosage
                }),
            })
    }

    const handlePriceSmallChange = (event: ChangeEvent<HTMLInputElement>) => {
        if (!container || container.dosages.length < 2) {
            throw new Error('There is not small dosage')
        }

        const value = Math.abs(+event.target.value)

        let largeDosageIndex = -1
        let largeDosageDrinkVolume = -1
        let smallDosageIndex = -1
        let smallDosageDrinkVolume = -1

        for (let i = 0; i < container.dosages.length; i++) {
            const drinkVolume = container.dosages[i].drinkVolume

            if (drinkVolume > largeDosageDrinkVolume) {
                smallDosageDrinkVolume = largeDosageDrinkVolume
                smallDosageIndex = largeDosageIndex
                largeDosageDrinkVolume = drinkVolume
                largeDosageIndex = i
            } else if (
                drinkVolume <= largeDosageDrinkVolume &&
                drinkVolume > smallDosageDrinkVolume
            ) {
                smallDosageDrinkVolume = drinkVolume
                smallDosageIndex = i
            }
        }

        if (smallDosageIndex === -1) {
            throw new Error('Small dosage not found')
        }
        if (onChange) {
            let a = {
                ...container,
                dosages: container.dosages.map((dosage, index) => {
                    if (smallDosageIndex === index) {
                        return {
                            ...dosage,
                            price: value,
                        }
                    }

                    return dosage
                }),
            }
        }
        onChange &&
            onChange(number, {
                ...container,
                dosages: container.dosages.map((dosage, index) => {
                    if (smallDosageIndex === index) {
                        return {
                            ...dosage,
                            price: value,
                        }
                    }

                    return dosage
                }),
            })
        if (onChange) {
            let a = {
                ...container,
                dosages: container.dosages.map((dosage, index) => {
                    if (smallDosageIndex === index) {
                        return {
                            ...dosage,
                            price: value,
                        }
                    }

                    return dosage
                }),
            }
        }
    }

    return (
        <tr>
            <td>{number}.</td>
            <td>
                <SearchSelector
                    upperCase
                    value={brandId}
                    options={brandsOptions}
                    onClick={handleBrandChange}
                    placeholder={t('92_AutomatContainerEditor_selectBrand')}
                />
            </td>
            <td>
                <SearchSelector
                    upperCase
                    value={productId}
                    options={productsOptions}
                    onClick={handleProductChange}
                    placeholder={t('93_AutomatContainerEditor_selectProduct')}
                    disabled={!brandId}
                />
            </td>
            <td>
                <SearchSelector
                    upperCase
                    value={tasteId}
                    options={tasteOptions}
                    onClick={handleTasteChange}
                    placeholder={t('94_AutomatContainerEditor_selectTaste')}
                    disabled={!productId}
                />
            </td>
            <td className={styles.price}>
                <TextField
                    type={'number'}
                    value={priceLarge || ''}
                    onChange={handlePriceLargeChange}
                    disabled={priceLarge === undefined}
                    classes={priceFieldClasses}
                />
            </td>
            <td>
                <CheckBox
                    disabled={!tasteId}
                    checked={priceSmall !== undefined}
                    onChange={handleDosage2CheckboxChange}
                />
            </td>
            <td className={styles.price}>
                <TextField
                    type={'number'}
                    value={priceSmall || ''}
                    onChange={handlePriceSmallChange}
                    disabled={priceSmall === undefined}
                    classes={priceFieldClasses}
                />
            </td>
            <td>
                <div
                    onClick={() => onEdit(number, container)}
                    className={styles.icon}
                >
                    <EditIcon />
                </div>
            </td>
        </tr>
    )
}

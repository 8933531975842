import React, { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AdMaterial as AdMaterialType } from '../../../types'
import { AdMaterials as AdMaterialsService } from '../../../services/AdMaterials'
import { Editor } from '../../simples/modalEditor/Editor'
import { Row } from '../../simples/modalEditor/Row'
import { Col } from '../../simples/modalEditor/Col'
import { UploadPhoto } from '../../simples/UploadPhoto'
import { TextField } from '../../simples/TextField'

import styles from './AdMaterialEditor.module.scss'

const editorClasses = {
    modalModal: styles.modal,
    buttons: styles.buttons,
}

export type SourceFormData = {
    id: number
    name: string
    photo: string | null
}

const initData: SourceFormData = {
    id: 0,
    name: '',
    photo: null,
}

type Props = {
    data?: SourceFormData
    onSubmit: (data: AdMaterialType) => void
    onCancel: () => void
}

const AdMaterialEditor: FC<Props> = ({
    data = initData,
    onSubmit,
    onCancel,
}) => {
    const { t } = useTranslation()
    const [name, setName] = useState(data.name)
    const [photoFile, setPhotoFile] = useState<File>()
    const [loading, setLoading] = useState(false)

    const submit = async () => {
        if (!name || (!data.id && !photoFile)) {
            throw new Error('Name or photo is empty')
        }

        setLoading(true)

        let savedAdMaterial = data.id
            ? await AdMaterialsService.update(data.id, { name })
            : await AdMaterialsService.create({ name })

        if (photoFile) {
            savedAdMaterial = await AdMaterialsService.uploadPhoto(
                savedAdMaterial.id,
                photoFile
            )
        }

        onSubmit(savedAdMaterial)
    }

    return (
        <Editor
            classes={editorClasses}
            header={
                data.id
                    ? t('6_AdMaterialEditor_editingAdvertisingMaterial')
                    : t('7_AdMaterialEditor_creationOfAdvertisingMaterial')
            }
            submitButtonName={
                data.id
                    ? t('8_AdMaterialEditor_save')
                    : t('9_AdMaterialEditor_create')
            }
            submitDisabled={!name || (!data.id && !photoFile)}
            submitLoading={loading}
            onSubmit={submit}
            onCancel={onCancel}
        >
            <Row>
                <Col>
                    <UploadPhoto
                        defaultPhotoUrl={data.photo || ''}
                        onChange={setPhotoFile}
                    />
                </Col>
            </Row>

            <Row>
                <Col>
                    <TextField
                        placeholder={t('10_AdMaterialEditor_name')}
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                    />
                </Col>
            </Row>
        </Editor>
    )
}

export default AdMaterialEditor

import React, { useState, useMemo, useCallback, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { Layout } from '../../complexes/Layout'
import { OptionType, Selector } from '../../simples/Selector'
import { Header } from '../../simples/tablePages/Header'
import { ParamBlockWrapper } from '../../simples/ParamBlockWrapper'
import { TwoColumnWrapper } from '../../simples/TwoColumnWrapper'
import { TextField } from '../../simples/TextField'
import styles from './EditProductPage.module.scss'
import { SimpleInput } from '../../simples/SimpleInput'
import { ProductType, TasteType } from '../../../types'
import { EditbleItem } from '../../simples/EditbleItem'
import { AddButton } from '../../simples/AddButton'
import { Button, ButtonKind } from '../../simples/Button'
import { TasteEditor, initTaste } from '../../complexes/TasteEditor'
import { useHistory, useParams } from 'react-router'
import { routes } from '../../../config'
import {
    loadBrands,
    selectBrands,
    selectCups,
} from '../../../store/slices/storage'
import { productTypeOptions } from '../../../constants'
import { useAppSelector, useAppDispatch } from '../../hooks/store'
import { TasteService } from '../../../services/Tastes'
import { ProductsService } from '../../../services/Products'

const initProduct = {
    id: 0,
    name: '',
    brandId: 0,
    cupId: 0,
    condition: 0,
} as ProductType

const EditProductPage = () => {
    const { t } = useTranslation()

    const navigation = useHistory()
    const { id = 0 } = useParams<{ id?: string }>()

    const dispatch = useAppDispatch()
    const brands = useAppSelector(selectBrands)
    const cups = useAppSelector(selectCups)

    useEffect(() => {
        dispatch(loadBrands())
    }, [])

    const [product, setProduct] = useState<ProductType>(initProduct)
    const [tastes, setTastes] = useState<Array<TasteType>>([])

    useEffect(() => {
        if (!id) {
            return
        }

        ProductsService.getProductItem(+id).then((product) => {
            setProduct(product)
        })
        TasteService.getTastes({
            productId: +id,
            disableTranslation: true,
            disableUppercase: true,
        }).then((tastes) => {
            setTastes(tastes)
        })
    }, [id])

    const [modal, setModal] = useState<boolean>(false)
    const [editTaste, setEditTaste] = useState<TasteType>()
    const newLastTasteId = useRef(-1)
    const tastesForDelete = useRef<Array<number>>([])

    const cupsOptions = useMemo((): Array<OptionType> => {
        const options: Array<OptionType> = cups.map((i) => {
            return {
                value: +i.id,
                label: i.name,
            }
        })

        return options
    }, [cups])

    const brandsOptions = useMemo((): Array<OptionType> => {
        const arrOPtions: Array<OptionType> = brands.map((i) => {
            return {
                value: i.id,
                label: i.name,
            }
        })
        return arrOPtions
    }, [brands])

    const prepareArr = useMemo(() => {
        const newArr: Array<any> = []
        tastes.map((i, index) => {
            if ((index + 1) % 2 === 0) {
                newArr.push([tastes[index - 1], tastes[index]])
            }
            if (index + 1 === tastes.length && (index + 1) % 2 !== 0) {
                newArr.push([tastes[index]])
            }
        })
        return newArr
    }, [tastes, setTastes, id])

    const startCreateTaste = () => {
        if (tastes.length > 0) {
            const firstTaste = tastes[0]

            const baseDosages = firstTaste.baseDosages.map((bd) => ({ ...bd }))
            const components = firstTaste.components.map((c) => ({ ...c }))

            setEditTaste({
                ...initTaste,
                componentOnAmount: firstTaste.componentOnAmount,
                baseDosages,
                components,
            })
        } else {
            setEditTaste(undefined)
        }

        setModal(true)
    }

    const startUpdateTaste = (id: number) => {
        const taste = tastes.find((t) => t.id === id)

        if (!taste) {
            throw new Error('Taste not found')
        }

        setEditTaste(taste)
        setModal(true)
    }
    const deleteTaste = useCallback(
        (id: number) => {
            tastesForDelete.current = [...tastesForDelete.current, id]
            const newTastes = tastes.filter((t) => t.id !== id)
            setTastes(newTastes)
        },
        [tastes, setTastes]
    )

    const handleTasteSubmit = (taste: TasteType) => {
        const newTastes =
            taste.id !== 0
                ? tastes.map((t) => {
                      if (t.id === taste.id) {
                          return taste
                      }

                      return t
                  })
                : [...tastes, { ...taste, id: newLastTasteId.current }]

        newLastTasteId.current = newLastTasteId.current - 1

        setTastes(newTastes)
        closeModal()
    }

    const closeModal = () => {
        setEditTaste(undefined)
        setModal(false)
    }

    const [loading, setLoading] = useState(false)

    const onHandleSave = async () => {
        setLoading(true)
        const savedProduct = id
            ? await ProductsService.updateProduct(product)
            : await ProductsService.createProduct(product)

        for (let i = 0; i < tastes.length; i++) {
            const taste = tastes[i]

            let preparedTaste = {
                ...taste,
                components: taste.components.map((component) => {
                    if (component.id === 0) {
                        const preparedComponent = { ...component }
                        delete preparedComponent['id']
                        return preparedComponent
                    }

                    return component
                }),
                baseDosages: taste.baseDosages.map((dosage) => {
                    if (dosage.id === 0) {
                        const preparedDosage = { ...dosage }
                        delete preparedDosage['id']

                        return preparedDosage
                    }

                    return dosage
                }),
                productId: savedProduct.id,
            }

            if (preparedTaste.id > 0) {
                await TasteService.updateTaste(preparedTaste.id, preparedTaste)
            } else {
                delete preparedTaste['id']
                await TasteService.creteTaste(preparedTaste)
            }
        }

        for (let i = 0; i < tastesForDelete.current.length; i++) {
            const deletedTasteId = tastesForDelete.current[i]
            await TasteService.deleteTaste(deletedTasteId)
        }

        setLoading(false)
        navigation.push(routes.products)
    }

    return (
        <Layout>
            <Header text={t('361_EditProductPage_createProduct')} />
            <div className={styles.root}>
                <ParamBlockWrapper
                    classes={{
                        root: styles.block,
                    }}
                    title={t('362_EditProductPage_params')}
                >
                    <TwoColumnWrapper>
                        <Selector
                            value={product.brandId || 0}
                            onChange={(e) =>
                                setProduct({ ...product, brandId: e })
                            }
                            label={t('363_EditProductPage_selectBrand')}
                            options={brandsOptions}
                        />
                        <TextField
                            value={product.name}
                            onChange={(e) =>
                                setProduct({ ...product, name: e.target.value })
                            }
                            title={t('364_EditProductPage_productName')}
                            placeholder={t('364_EditProductPage_productName')}
                        />
                    </TwoColumnWrapper>
                    <TwoColumnWrapper>
                        <Selector
                            onChange={(i) =>
                                setProduct({ ...product, cupId: i })
                            }
                            value={product.cupId || 0}
                            label={t('365_EditProductPage_selectCup')}
                            options={cupsOptions}
                        />
                        <Selector
                            onChange={(i) =>
                                setProduct({ ...product, condition: i })
                            }
                            value={product.condition || 0}
                            label={t('366_EditProductPage_powderOrLiquid')}
                            options={productTypeOptions}
                        />
                    </TwoColumnWrapper>
                </ParamBlockWrapper>
                <SimpleInput
                    placeholder={t('367_EditProductPage_productName')}
                    onChange={(e) => setProduct({ ...product, name: e })}
                    value={product.name}
                />
                <div className={styles.tastes}>
                    {prepareArr &&
                        prepareArr.map((arr, index) => {
                            return (
                                <div className={styles.tastesRow} key={index}>
                                    {arr.map((taste: TasteType) => {
                                        return (
                                            <div
                                                className={styles.tasteItem}
                                                key={taste.id}
                                            >
                                                <EditbleItem
                                                    name={taste.name}
                                                    id={1}
                                                    onEdit={() =>
                                                        startUpdateTaste(
                                                            taste.id
                                                        )
                                                    }
                                                    onDelete={() =>
                                                        deleteTaste(taste.id)
                                                    }
                                                />
                                            </div>
                                        )
                                    })}
                                </div>
                            )
                        })}
                </div>
                <TwoColumnWrapper>
                    <AddButton
                        positionLabel={'center'}
                        label={t('368_EditProductPage_addTaste')}
                        onClick={startCreateTaste}
                    />
                </TwoColumnWrapper>
            </div>
            <div className={styles.buttons}>
                <Button
                    classNames={{
                        contain: styles.link,
                    }}
                    kind={ButtonKind.Link}
                    onClick={() => navigation.push(routes.products)}
                >
                    {t('369_EditProductPage_cancel')}
                </Button>
                <Button onClick={onHandleSave} loading={loading}>
                    {t('370_EditProductPage_save')}
                </Button>
            </div>

            {modal && (
                <TasteEditor
                    taste={editTaste}
                    onSubmit={handleTasteSubmit}
                    onClose={closeModal}
                    condition={product.condition}
                />
            )}
        </Layout>
    )
}

export default EditProductPage

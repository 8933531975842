import React, { FC, useMemo, useState } from 'react'
import { AutomatStatus } from '../../../types'
import { ReactComponent as RedIcon } from '../../../assets/icons/red.svg'
import { ReactComponent as GreenIcon } from '../../../assets/icons/green.svg'
import { ReactComponent as YellowIcon } from '../../../assets/icons/yellow.svg'
import { useTranslation } from 'react-i18next'
import styles from './StatusTooltip.module.scss'

type Props = {
    status: AutomatStatus
    text: string
}

/**
 *
 * @param status
 * @param text
 * @returns
 */

const StatusTooltip: FC<Props> = ({ status, text }) => {
    const { t } = useTranslation()
    const [showTooltip, setShowTooltip] = useState<boolean>(false)

    const showData: {
        label: string
        icon: JSX.Element
    } = useMemo(() => {
        switch (status) {
            case AutomatStatus.RED:
                return {
                    label: `${t(`534_Status_description_${status}`)}`,
                    icon: <RedIcon />,
                }

            case AutomatStatus.YELLOW:
                return {
                    label: `${t(`534_Status_description_${status}`)}`,
                    icon: <YellowIcon />,
                }

            case AutomatStatus.GREEN:
                return {
                    label: `${t(`534_Status_description_${status}`)}`,
                    icon: <GreenIcon />,
                }

            default:
                return {
                    label: `${t(`534_Status_description_green`)}`,
                    icon: <GreenIcon />,
                }
        }
    }, [status])
    return (
        <div
            onMouseLeave={() => setShowTooltip(false)}
            onMouseEnter={() => setShowTooltip(true)}
            className={styles.root}
        >
            <div className={styles.icon}>{showData.icon}</div>
            {showTooltip && (
                <div className={styles.tooltip}>
                    <div className={styles.label}>{showData.label}</div>
                    <div className={styles.text}>{text}</div>
                </div>
            )}
        </div>
    )
}

export default React.memo(StatusTooltip)

import React, { FC } from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { routes } from '../../../config'
import { NumbersItem } from './NumbersItem'
import { RelativelyValue } from './RelativelyValue'
import styles from './WidgetStatisticsNumbers.module.scss'

type Props = {
    incomeMonth: number
    incomeRelativelyLastMonth: number
    incomeWeek: number
    incomeRelativelyLastWeek: number
    incomeToday: number
    incomeRelativelyYesterday: number
    automatsCount: number
}

const WidgetStatisticsNumbers: FC<Props> = ({
    incomeMonth,
    incomeRelativelyLastMonth,
    incomeWeek,
    incomeRelativelyLastWeek,
    incomeToday,
    incomeRelativelyYesterday,
    automatsCount,
}) => {
    const { t } = useTranslation()

    return (
        <div className={styles.root}>
            <div className={styles.numbersItemWrap}>
                <NumbersItem
                    label={t('292_WidgetStatisticsNumber_monthlyRevenue')}
                    value={incomeMonth}
                    additionally={
                        <RelativelyValue
                            value={incomeRelativelyLastMonth}
                            note={t('293_WidgetStatisticsNumber_toPastPeriod')}
                        />
                    }
                />
            </div>

            <div className={styles.numbersItemWrap}>
                <NumbersItem
                    label={t('294_WidgetStatisticsNumber_revenueForWeek')}
                    value={incomeWeek}
                    additionally={
                        <RelativelyValue
                            value={incomeRelativelyLastWeek}
                            note={t('295_WidgetStatisticsNumber_byLastWeek')}
                        />
                    }
                />
            </div>

            <div className={styles.numbersItemWrap}>
                <NumbersItem
                    label={t('296_WidgetStatisticsNumber_revenueForToday')}
                    value={incomeToday}
                    additionally={
                        <RelativelyValue
                            value={incomeRelativelyYesterday}
                            note={t('297_WidgetStatisticsNumber_toLastDay')}
                        />
                    }
                />
            </div>

            <div className={styles.numbersItemWrap}>
                <NumbersItem
                    label={t(
                        '298_WidgetStatisticsNumber_automatesInOrganizations'
                    )}
                    value={automatsCount}
                    additionally={
                        <Link to={routes.outlets} className={styles.link}>
                            {t('299_WidgetStatisticsNumber_toManagement')}
                        </Link>
                    }
                />
            </div>
        </div>
    )
}

export default WidgetStatisticsNumbers

import React, { FC } from 'react'
import { Button } from '../../Button'
import { Search } from '../../Search'
import styles from './TopPanel.module.scss'

const buttonClasses = {
    button: styles.createButton,
}

type Props = {
    createButtonName: string
    onCreateButtonClick: () => void
    hideCreateButton?: boolean
    onSearch: (search: string) => void
    onFilter?: () => void
    rightAddition?: JSX.Element
}

const TopPanel: FC<Props> = ({
    createButtonName,
    onCreateButtonClick,
    hideCreateButton = false,
    onSearch,
    onFilter,
    rightAddition,
}) => {
    return (
        <div className={styles.root}>
            {!hideCreateButton && (
                <div className={styles.createButtonContainer}>
                    <Button
                        classNames={buttonClasses}
                        onClick={onCreateButtonClick}
                    >
                        {createButtonName}
                    </Button>
                </div>
            )}
            <div className={styles.searchContainer}>
                <Search onFilter={onFilter} onSearch={onSearch} />
            </div>
            {rightAddition && (
                <div className={styles.rightAddition}>{rightAddition}</div>
            )}
        </div>
    )
}

export default TopPanel

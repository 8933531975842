import React, { useState, useEffect, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Layout } from '../../complexes/Layout'
import { Header } from '../../simples/tablePages/Header'
import styles from './Profile.module.scss'
import { Profile as ProfileType, User as UserType } from '../../../types'
import { User as UserService } from '../../../services/User'
import { UserEditForm, ResultDataForm } from '../../complexes/UserEditForm'
import { useAppSelector, useAppDispatch } from '../../hooks/store'
import {
    selectAuthUser,
    setProfile as setAuthUserProfile,
} from '../../../store/slices/auth'
import { showToast } from '../../../store/slices/site'
import { routes } from '../../../config'
import { UserAccessKeyEditor } from '../../complexes/UserAccessKeyEditor'

const Profile = () => {
    const { t } = useTranslation()
    const history = useHistory()
    const [loading, setLoading] = useState(false)
    const [saveLoading, setSaveLoading] = useState(false)
    const [profile, setProfile] = useState<ProfileType | null>()
    const user = useAppSelector(selectAuthUser)

    const [showAccessKeyEditor, setShowAccessKeyEditor] = useState(false)

    const dispatch = useAppDispatch()

    useEffect(() => {
        setLoading(true)

        setProfile(user)

        setLoading(false)
    }, [user])

    const onHandleSave = async (
        data: ResultDataForm,
        deletePhoto: boolean = false
    ) => {
        setSaveLoading(true)

        const savedProfile = await UserService.update({
            fullName: data.name,
            contacts: data.contacts.map((contact) => ({
                ...contact,
                confirmed: contact.confirmed ? true : false,
            })),
            password: data.password,
        })

        if (data.photo) {
            await UserService.uploadPhoto(data.photo)
        } else if (deletePhoto) {
            await UserService.deletePhoto()
        }

        setSaveLoading(false)
        dispatch(setAuthUserProfile(savedProfile))
        dispatch(showToast(t('412_Profile_profileSavedSuccessfully')))
    }

    const onHandleCancel = () => {
        history.push(routes.home)
    }

    const handleAccessKeyUserChange = (user: UserType) => {
        dispatch(setAuthUserProfile(user))
    }

    const handleCreateAccessKeyClick = async () => {
        if (!profile) {
            throw new Error('User is empty')
        }

        if (profile.accessKey) {
            throw new Error('The user already has an access key')
        }

        const accessKey = await UserService.generateAccessKey(profile.id)

        const updatedUser = {
            ...profile,
            accessKey,
        }

        dispatch(setAuthUserProfile(updatedUser))
        setShowAccessKeyEditor(true)
    }

    const userFormData = useMemo(() => {
        if (!profile) {
            return {
                name: '',
                photo: '',
                contacts: [],
            }
        }

        return {
            name: profile.fullName,
            photo: profile.photoPath || '',
            contacts: profile.contacts,
            permissions: profile.permissions,
            accessKey: profile.accessKey,
        }
    }, [profile])

    return (
        <Layout>
            <Header text={t('413_Profile_profileSettings')} />

            {loading ? (
                <></>
            ) : profile ? (
                <div className={styles.root}>
                    <UserEditForm
                        data={userFormData}
                        onSave={onHandleSave}
                        onCancel={onHandleCancel}
                        loading={saveLoading}
                        showPasswordFields
                        onOpenAccessKey={() => setShowAccessKeyEditor(true)}
                        onCreateAccessKey={handleCreateAccessKeyClick}
                    />

                    {showAccessKeyEditor && (
                        <UserAccessKeyEditor
                            user={profile}
                            onUserChange={handleAccessKeyUserChange}
                            onClose={() => setShowAccessKeyEditor(false)}
                        />
                    )}
                </div>
            ) : null}
        </Layout>
    )
}

export default Profile

import React, { ChangeEvent, FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
    AdMaterial as AdMaterialType,
    AdModule as AdModuleType,
} from '../../../types'
import { useAppSelector } from '../../hooks/store'
import { selectAutomats } from '../../../store/slices/storage'
import { AdModules as AdModulesService } from '../../../services/AdModules'
import { Editor } from '../../simples/modalEditor/Editor'
import { H } from '../../simples/modalEditor/H'
import { Row } from '../../simples/modalEditor/Row'
import { Col } from '../../simples/modalEditor/Col'
import { AdMaterialInput } from './AdMaterialInput'
import { TextField } from '../../simples/TextField'
import { SearchSelector } from '../../simples/SearchSelector'
import { TimeSelector } from './TimeSelectro'
import { CheckBox } from '../../simples/CheckBox'
import styles from './AdModuleEditor.module.scss'
import { AutomatsSelector } from '../AutomatsSelector'

export type SourceFormData = {
    id: number
    adMaterial?: AdMaterialType
    name: string
    automatId: number
    automatsIds: 'all' | number[]
    numberOfShowing: number
    timeOfShowing: string
}

const initFormData: SourceFormData = {
    id: 0,
    name: '',
    automatId: 0,
    automatsIds: [],
    numberOfShowing: 0,
    timeOfShowing: '',
}

type Props = {
    data?: SourceFormData
    onSubmit: (adModule: AdModuleType) => void
    onCancel: () => void
}

const AdModuleEditor: FC<Props> = ({
    data = initFormData,
    onSubmit,
    onCancel,
}) => {
    const { t } = useTranslation()
    const automats = useAppSelector(selectAutomats)

    const [adMaterial, setAdMaterial] = useState(data.adMaterial)
    const [name, setName] = useState(data.name)
    const [automatId, setAutomatId] = useState(data.automatId)
    const [automatsIds, setAutomatsIds] = useState<'all' | number[]>(
        data.automatsIds
    )
    const [numberOfShowing, setNumberOfShowing] = useState(data.numberOfShowing)
    const [timeOfShowing, setTimeOfShowing] = useState(data.timeOfShowing)

    const [saving, setSaving] = useState(false)

    const handleNumberOfShowingChange = (
        event: ChangeEvent<HTMLInputElement>
    ) => {
        const value = event.target.value.replace(/\D/, '')
        setNumberOfShowing(+value)
    }

    // console.log(automatsIds)

    const submit = async () => {
        if (!adMaterial) {
            throw new Error('There is not enough data in the form')
        }

        setSaving(true)

        const savedAdModule =
            data.id > 0
                ? await AdModulesService.update(data.id, {
                      adMaterialId: adMaterial.id,
                      name,
                      automatId,
                      automatsIds:
                          automatsIds === 'all'
                              ? automatsIds
                              : JSON.stringify(automatsIds),
                      numberOfShowing,
                      timeOfShowing,
                  })
                : await AdModulesService.create({
                      adMaterialId: adMaterial.id,
                      name,
                      automatId,
                      automatsIds:
                          automatsIds === 'all'
                              ? automatsIds
                              : JSON.stringify(automatsIds),
                      numberOfShowing,
                      timeOfShowing,
                  })

        setSaving(false)

        onSubmit(savedAdModule)
    }

    /**
     * choose all automats
     * @param checked
     */
    const handleAutomatsNotLimitChange = (checked: boolean) => {
        if (checked) {
            setAutomatsIds('all')
        } else {
            setAutomatsIds([])
        }
    }

    return (
        <Editor
            header={
                data.id > 0
                    ? t('15_AdModuleEditor_editingAnAdModule')
                    : t('16_AdModuleEditor_creationOfAnAdvertisingModule')
            }
            submitButtonName={
                data.id > 0
                    ? t('17_AdModuleEditor_save')
                    : t('18_AdModuleEditor_create')
            }
            submitDisabled={
                !adMaterial ||
                !name ||
                automatsIds.length === 0 ||
                // !numberOfShowing ||
                !timeOfShowing
            }
            submitLoading={saving}
            onSubmit={submit}
            onCancel={onCancel}
        >
            <H level={3}>{t('19_AdModuleEditor_advertisingMaterial')}</H>

            <Row>
                <Col>
                    <AdMaterialInput
                        adMaterial={adMaterial}
                        onChange={setAdMaterial}
                    />
                </Col>
            </Row>

            <Row>
                <Col>
                    <TextField
                        placeholder={t('20_AdModuleEditor_name')}
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                    />
                </Col>
            </Row>

            <Row>
                <Col>
                    <div className={styles.notLimitCheckbox}>
                        <CheckBox
                            label={t('232_PromoCodeEditor_allAutomates')}
                            checked={automatsIds === 'all'}
                            onChange={handleAutomatsNotLimitChange}
                        />
                    </div>

                    <AutomatsSelector
                        value={automatsIds !== 'all' ? automatsIds : []}
                        onChange={setAutomatsIds}
                        isDisabled={automatsIds === 'all'}
                    />
                    {/* <SearchSelector
                        value={automatId}
                        options={automats.map((a) => ({
                            id: a.id,
                            value: a.name,
                        }))}
                        onClick={(option) => setAutomatId(option.id)}
                        placeholder={t('21_AdModuleEditor_automate')}
                    /> */}
                </Col>
            </Row>

            <Row>
                <Col>
                    <TextField
                        value={numberOfShowing}
                        onChange={handleNumberOfShowingChange}
                        placeholder={t(
                            '22_AdModuleEditor_usageAmountPlaceholder'
                        )}
                        note={t('23_AdModuleEditor_usageAmountNote')}
                    />
                </Col>
            </Row>

            <Row>
                <Col>
                    <TimeSelector
                        value={timeOfShowing}
                        onChange={setTimeOfShowing}
                    />
                </Col>
            </Row>
        </Editor>
    )
}

export default AdModuleEditor

import React, { FC, useState, useRef, useEffect, useMemo } from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Outlet as OutletType, UserGroups } from '../../../types'
import { Outlets as OutletsService } from '../../../services/Outlets'
import { routes } from '../../../config'
import { useQuery } from '../../hooks/query'
import { Layout } from '../../complexes/Layout'
import { Header } from '../../simples/tablePages/Header'
import { TopPanel } from '../../simples/tablePages/TopPanel'
import { ItemsList } from '../../simples/tablePages/ItemsList'
import { Confirm } from '../../simples/Confirm'
import { OutletEditor } from '../../complexes/OutletEditor'
import { useAppDispatch, useAppSelector } from '../../hooks/store'
import { loadAutomats } from '../../../store/slices/storage'
import { selectAuthUser } from '../../../store/slices/auth'
import { useUserGroup } from '../../hooks/userGroup'

const Outlets: FC = () => {
    const { t } = useTranslation()
    const header = [t('401_Outlets_outletName'), t('402_Outlets_outletAddress')]
    const user = useAppSelector(selectAuthUser)
    const userGroup = useUserGroup(user)
    const dispatch = useAppDispatch()

    const query = useQuery()
    const companyId = query.get('companyId')

    const [outlets, setOutlets] = useState<Array<OutletType>>([])
    const outletsCurrent = useRef<Array<OutletType>>(outlets)
    outletsCurrent.current = outlets
    const [loading, setLoading] = useState(false)
    const offset = useRef(0)
    const search = useRef('')
    const has = useRef(true)

    const [showOutletEditor, setShowOutletEditor] = useState(false)
    const [editedOutlet, setEditedOutlet] = useState<OutletType>()

    const [deletedOutletId, setDeletedOutletId] = useState(0)

    const load = async () => {
        if (!has.current || loading) {
            return
        }

        setLoading(true)
        const result = await OutletsService.getList({
            offset: offset.current,
            search: search.current,
            companyId: companyId ? companyId : undefined,
        })
        setLoading(false)

        if (!result.length) {
            has.current = false
            return ``
        }

        const newOutlets = [...outletsCurrent.current, ...result]
        offset.current = newOutlets.length
        setOutlets(newOutlets)
    }

    const clear = () => {
        has.current = true
        offset.current = 0
        search.current = ''
        setOutlets([])
    }

    useEffect(() => {
        dispatch(loadAutomats())
        load().then()
    }, [])

    const handleEndReached = async () => {
        await load()
    }

    const handleSearch = async (text: string) => {
        clear()
        search.current = text
        await load()
    }

    const startCreateOutlet = () => {
        setEditedOutlet(undefined)
        setShowOutletEditor(true)
    }

    const startUpdateOutlet = (outletId: number) => {
        const outlet = outlets.find((o) => o.id === outletId)

        if (!outlet) {
            throw new Error('Outlet not found')
        }

        setEditedOutlet(outlet)
        setShowOutletEditor(true)
    }

    const handleSubmit = (savedOutlet: OutletType) => {
        let updated = false
        let newOutlets = outlets.map((o) => {
            if (o.id === savedOutlet.id) {
                updated = true
                return savedOutlet
            }

            return o
        })

        if (!updated) {
            newOutlets = [savedOutlet, ...newOutlets]
        }

        setOutlets(newOutlets)
        closeOutletEditor()
    }

    const closeOutletEditor = () => {
        setShowOutletEditor(false)
        setEditedOutlet(undefined)
    }

    const startDeleteOutlet = (outletId: number) => {
        setDeletedOutletId(outletId)
    }

    const cancelOutletDelete = () => {
        setDeletedOutletId(0)
    }

    const confirmOutletDelete = async () => {
        setDeletedOutletId(0)
        await OutletsService.delete(deletedOutletId)
        setOutlets(outlets.filter((outlet) => outlet.id !== deletedOutletId))
    }

    const rows = useMemo(() => {
        return outlets.map((outlet) => ({
            id: outlet.id,
            values: [
                <Link to={`${routes.automats}?outletId=${outlet.id}`}>
                    {outlet.name}
                </Link>,
                outlet.address,
            ],
        }))
    }, [outlets])

    return (
        <Layout onEndReached={handleEndReached}>
            <Header text={t('403_Outlets_outletsCreated')} />
            <TopPanel
                createButtonName={t('404_Outlets_addOutlet')}
                onCreateButtonClick={startCreateOutlet}
                hideCreateButton={
                    userGroup !== UserGroups.Root &&
                    userGroup !== UserGroups.Owner
                }
                onSearch={handleSearch}
            />
            <ItemsList
                headers={header}
                rows={rows}
                loading={loading}
                onEdit={startUpdateOutlet}
                onDelete={startDeleteOutlet}
            />

            {showOutletEditor && (
                <OutletEditor
                    companyId={companyId ? +companyId : undefined}
                    data={editedOutlet}
                    onSubmit={handleSubmit}
                    onClose={closeOutletEditor}
                />
            )}

            {!!deletedOutletId && (
                <Confirm
                    text={t('405_Outlets_confirmDelete')}
                    onConfirm={confirmOutletDelete}
                    onCancel={cancelOutletDelete}
                />
            )}
        </Layout>
    )
}

export default Outlets

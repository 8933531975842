import React, { FC } from 'react'
import classNames from 'classnames'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { useAppSelector } from '../../hooks/store'
import { selectModels } from '../../../store/slices/storage'
import { route } from '../../../utils/route'
import { routes } from '../../../config'
import { AutomatModelTypes, AutomatType } from '../../../types'
import { Spinner } from '../../simples/Spinner'
import { RemainsContainerBlock } from '../RemainsContainerBlock'
import { StatusTooltip } from '../../simples/StatusTooltip'
import styles from './WidgetAutomatLogs.module.scss'
import { timeLocalParser } from '../../../utils/helpers'
import { SnackRemainsForTable } from '../automat/SnackRemainsForTable'
import { KioskRemainsForTable } from '../automat/KioskRemainsForTable'

type Props = {
    automats?: Array<AutomatType>
    loading?: boolean
}

const WidgetAutomatLogs: FC<Props> = ({ automats = [], loading = false }) => {
    const { t } = useTranslation()
    const models = useAppSelector(selectModels)

    // console.log('logs', logs)

    const history = useHistory()

    const goToAutomat = (automatId: number) => {
        history.push(route(routes.automatMain, { id: automatId }))
    }

    return (
        <div className={styles.root}>
            <div className={styles.header}>
                {t('285_WidgetAutomatLogs_attentionAutomates')}
            </div>

            {automats.length > 0 && (
                <div className={styles.table}>
                    <div className={styles.tableHeader}>
                        <div
                            className={classNames(
                                styles.tableHeaderCol,
                                styles.firstWidth
                            )}
                        />
                        <div
                            className={classNames(
                                styles.tableHeaderCol,
                                styles.nameWidth
                            )}
                        >
                            {t('286_WidgetAutomatLogs_name')}
                        </div>
                        <div
                            className={classNames(
                                styles.tableHeaderCol,
                                styles.addressWidth
                            )}
                        >
                            {t('287_WidgetAutomatLogs_placeOfInstallation')}
                        </div>
                        <div
                            className={classNames(
                                styles.tableHeaderCol,
                                styles.remainsWidth,
                                styles.remains
                            )}
                        >
                            {t('547_WidgetAutomatLogs_remains')}
                        </div>
                        <div
                            className={classNames(
                                styles.tableHeaderCol,
                                styles.statisticsWidth,
                                styles.statistics
                            )}
                        >
                            {t('548_WidgetAutomatLogs_sales')}
                        </div>
                        <div
                            className={classNames(
                                styles.tableHeaderCol,
                                styles.timeWidth
                            )}
                        >
                            {t('549_WidgetAutomatLogs_datetime')}
                        </div>
                    </div>

                    {automats.map((automat) => {
                        const model = models.find(
                            (m) => m.id === automat.automatModelId
                        )

                        return (
                            <div className={styles.tableRow}>
                                <div
                                    className={styles.tableRowContent}
                                    onClick={() => goToAutomat(automat.id)}
                                >
                                    <div
                                        className={classNames(
                                            styles.tableCol,
                                            styles.firstWidth,
                                            styles.first
                                        )}
                                    >
                                        <StatusTooltip
                                            status={automat.status}
                                            text={automat.lastLog || ''}
                                        />
                                    </div>
                                    <div
                                        className={classNames(
                                            styles.tableCol,
                                            styles.nameWidth,
                                            styles.name
                                        )}
                                    >
                                        {automat.name}
                                    </div>
                                    <div
                                        className={classNames(
                                            styles.tableCol,
                                            styles.addressWidth,
                                            styles.address
                                        )}
                                    >
                                        {automat.outlet
                                            ? automat.outlet.name
                                            : ''}
                                        {automat.address
                                            ? ` (${automat.address})`
                                            : ''}
                                    </div>
                                    <div
                                        className={classNames(
                                            styles.tableCol,
                                            styles.remainsWidth,
                                            styles.remains
                                        )}
                                    >
                                        <div
                                            className={styles.remainsContainer}
                                        >
                                            {model && (
                                                <>
                                                    {model.type ===
                                                    AutomatModelTypes.Beverages ? (
                                                        <RemainsContainerBlock
                                                            mashin={automat}
                                                        />
                                                    ) : model.type ===
                                                      AutomatModelTypes.Snack ? (
                                                        <SnackRemainsForTable
                                                            mashin={automat}
                                                        />
                                                    ) : model.type ===
                                                      AutomatModelTypes.Kiosk ? (
                                                        <KioskRemainsForTable
                                                            mashin={automat}
                                                        />
                                                    ) : null}
                                                </>
                                            )}
                                        </div>
                                    </div>
                                    <div
                                        className={classNames(
                                            styles.tableCol,
                                            styles.statisticsWidth,
                                            styles.statistics
                                        )}
                                    >
                                        <div className={styles.statisticsBlock}>
                                            <p>
                                                <span
                                                    className={
                                                        styles.statisticsBlockLabel
                                                    }
                                                >
                                                    {t(
                                                        '534_WidgetAutomatLogs_forToday'
                                                    )}
                                                </span>
                                                :{' '}
                                                <span
                                                    className={
                                                        styles.statisticsBlockValue
                                                    }
                                                >
                                                    {
                                                        automat.statistics
                                                            .incomeToday
                                                    }
                                                </span>
                                            </p>
                                            <p>
                                                <span
                                                    className={
                                                        styles.statisticsBlockLabel
                                                    }
                                                >
                                                    {t(
                                                        '535_WidgetAutomatLogs_perWeek'
                                                    )}
                                                </span>
                                                :{' '}
                                                <span
                                                    className={
                                                        styles.statisticsBlockValue
                                                    }
                                                >
                                                    {
                                                        automat.statistics
                                                            .incomeWeek
                                                    }
                                                </span>
                                            </p>
                                            <p>
                                                <span
                                                    className={
                                                        styles.statisticsBlockLabel
                                                    }
                                                >
                                                    {t(
                                                        '536_WidgetAutomatLogs_perMonth'
                                                    )}
                                                </span>
                                                :{' '}
                                                <span
                                                    className={
                                                        styles.statisticsBlockValue
                                                    }
                                                >
                                                    {
                                                        automat.statistics
                                                            .incomeMonth
                                                    }
                                                </span>
                                            </p>
                                        </div>
                                    </div>
                                    <div
                                        className={classNames(
                                            styles.tableCol,
                                            styles.timeWidth,
                                            styles.time
                                        )}
                                    >
                                        {automat.lastLogTimeAt &&
                                            timeLocalParser(
                                                moment(
                                                    automat.lastLogTimeAt
                                                ).format('YYYY-MM-DD HH:mm:ss')
                                            )}
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
            )}

            {automats.length === 0 && !loading && (
                <div className={styles.empty}>
                    {t('291_WidgetAutomatLogs_emptyNote')}
                </div>
            )}

            {loading && (
                <div className={styles.loader}>
                    <Spinner />
                </div>
            )}
        </div>
    )
}

export default WidgetAutomatLogs

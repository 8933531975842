import React, {
    FC,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import {
    fetchBaseDosages,
    loadFillings,
    loadModels,
    loadOutlets,
    loadProducts,
    loadTastes,
    selectModels,
    selectOutlets,
    loadGoods,
} from '../../../store/slices/storage'
import { selectAuthUser } from '../../../store/slices/auth'
import { useUserGroup } from '../../hooks/userGroup'
import { AutomatType, UserGroups, AutomatModelTypes } from '../../../types'
import { Layout } from '../../complexes/Layout'
import { useAppDispatch, useAppSelector } from '../../hooks/store'
import { Header } from '../../simples/tablePages/Header'
import { ItemsList } from '../../simples/tablePages/ItemsList'
import { TopPanel } from '../../simples/tablePages/TopPanel'
import { Automats as AutomatsService } from '../../../services/Automats'

import styles from './AutomatsPage.module.scss'
import { RemainsContainerBlock } from '../../complexes/RemainsContainerBlock'
import { AutomatsFilter } from '../../complexes/AutomatsFilter'
import { selectAutoFilter } from '../../../store/slices/filters'
import { routes } from '../../../config'
import { route } from '../../../utils/route'
import { AutomatEditor } from '../../complexes/AutomatEditor'
import { StatusTooltip } from '../../simples/StatusTooltip'
import { timeLocalParser } from '../../../utils/helpers'
import { SnackRemainsForTable } from '../../complexes/automat/SnackRemainsForTable'
import { KioskRemainsForTable } from '../../complexes/automat/KioskRemainsForTable'

const AutomatsPage: FC = () => {
    const { t } = useTranslation()

    const filter = useAppSelector(selectAutoFilter)

    const history = useHistory()

    const models = useAppSelector(selectModels)
    const outlets = useAppSelector(selectOutlets)

    const user = useAppSelector(selectAuthUser)
    const userGroup = useUserGroup(user)
    const dispatch = useAppDispatch()

    const offset = useRef(0)
    const search = useRef('')
    const has = useRef(true)

    const [showFilter, setShowFilter] = useState<boolean>(false)

    const [loading, setLoading] = useState<boolean>(false)

    const [showAutomatEditor, setShowAutomatEditor] = useState<boolean>(false)

    const [mashins, setMashins] = useState<Array<AutomatType>>([])
    const mashinsCurrent = useRef<Array<AutomatType>>(mashins)
    mashinsCurrent.current = mashins

    const header = [
        t('332_Automats_automateName'),
        // t('334_Automats_serialNumber'),
        t('333_Automats_automateModel'),
        t('512_Automats_address'),
        t('513_Automats_sales'),
        t('515_Automats_remains'),
        t('516_Automats_messages'),
    ]

    const rows: any = useMemo(() => {
        return mashins.map((mashin: AutomatType) => {
            const model = models.find((m) => m.id === mashin.automatModelId)
            const outlet = outlets.find((o) => o.id === mashin.outletId)

            return {
                id: mashin.id,
                values: [
                    <div className={styles.nameMashin}>
                        <StatusTooltip
                            status={mashin.status}
                            text={mashin.lastLog || ''}
                        />
                        <div className={styles.machineName}>{mashin.name}</div>
                    </div>,
                    model ? model.name : '',
                    outlet ? outlet.address : '',
                    <div>
                        <div className="">
                            <span className={styles.statisticValue}>
                                {mashin.statistics.incomeToday || 0}
                            </span>{' '}
                            - {t('517_Automats_sales_today')}
                        </div>
                        <div className="">
                            <span className={styles.statisticValue}>
                                {mashin.statistics.incomeWeek || 0}
                            </span>{' '}
                            - {t('517_Automats_sales_week')}
                        </div>
                        <div className="">
                            <span className={styles.statisticValue}>
                                {mashin.statistics.incomeMonth || 0}
                            </span>{' '}
                            - {t('517_Automats_sales_month')}
                        </div>
                    </div>,
                    <>
                        {model && (
                            <>
                                {model.type === AutomatModelTypes.Beverages ? (
                                    <RemainsContainerBlock mashin={mashin} />
                                ) : model.type === AutomatModelTypes.Snack ? (
                                    <SnackRemainsForTable mashin={mashin} />
                                ) : model.type === AutomatModelTypes.Kiosk ? (
                                    <KioskRemainsForTable mashin={mashin} />
                                ) : null}
                            </>
                        )}
                    </>,
                    <div>
                        <div className="">{mashin.lastLog}</div>
                        {mashin.lastError && mashin.lastError.timeAt ? (
                            <div className={styles.errorLogTime}>
                                <span>
                                    {timeLocalParser(mashin.lastError.timeAt)} -{' '}
                                </span>
                                <span>
                                    {mashin.errorHours && mashin.errorHours}{' '}
                                    часов
                                </span>
                            </div>
                        ) : mashin.lastLogTimeAt ? (
                            <div className={styles.lastLogTimeAt}>
                                {timeLocalParser(mashin.lastLogTimeAt)}
                            </div>
                        ) : null}
                    </div>,
                ],
            }
        })
    }, [mashins, models, t])

    const load = async () => {
        if (!has.current || loading) {
            return
        }

        setLoading(true)
        try {
            const result = await AutomatsService.getLists({
                offset: offset.current,
                search: search.current,
                filter,
            })
            setLoading(false)
            if (!result.length) {
                has.current = false
                return
            }
            offset.current = offset.current + result.length
            setMashins([...mashinsCurrent.current, ...result])
        } catch (error) {
            setLoading(false)
        }
    }

    const clear = () => {
        has.current = true
        offset.current = 0
        search.current = ''
        setMashins([])
    }

    useEffect(() => {
        dispatch(loadTastes())
        dispatch(loadFillings())
        dispatch(loadModels())
        dispatch(loadOutlets())
        dispatch(fetchBaseDosages())
        dispatch(loadProducts())
        dispatch(loadGoods())
    }, [])

    useEffect(() => {
        clear()
        load().then()
    }, [filter])

    const handleEndReached = async () => {
        await load()
    }

    const onHandleFilter = () => {
        setShowFilter(true)
    }

    const handleSearch = async (text: string) => {
        clear()
        search.current = text
        await load()
    }

    const onMachineClick = useCallback(
        (id: number) => {
            history.push(route(routes.automatMain, { id }))
        },
        [history]
    )

    const startCreateAutomat = () => {
        setShowAutomatEditor(true)
    }
    const closeAutomatEditor = () => {
        setShowAutomatEditor(false)
    }
    const handleAutomatSubmit = async (automat: AutomatType) => {
        setMashins([automat, ...mashins])
        closeAutomatEditor()
    }
    return (
        <Layout onEndReached={handleEndReached}>
            <Header text={t('511_Automats_page')} />
            <TopPanel
                createButtonName={t('336_Automats_addAutomate')}
                onCreateButtonClick={startCreateAutomat}
                hideCreateButton={
                    userGroup !== UserGroups.Root &&
                    userGroup !== UserGroups.Owner
                }
                onSearch={handleSearch}
                onFilter={onHandleFilter}
            />

            <ItemsList
                onRowClick={onMachineClick}
                headers={header}
                rows={rows}
                loading={loading}
            />

            {showFilter && (
                <AutomatsFilter onClose={() => setShowFilter(false)} />
            )}
            {showAutomatEditor && (
                <AutomatEditor
                    outletId={0}
                    onSubmit={handleAutomatSubmit}
                    onClose={closeAutomatEditor}
                />
            )}
        </Layout>
    )
}

export default AutomatsPage

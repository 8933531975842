import React, { FC, useMemo } from 'react'
import { AutomatModelTypes, AutomatType } from '../../../../types'
import { useAppSelector } from '../../../hooks/store'
import { selectModels } from '../../../../store/slices/storage'
import styles from './SnackRemainsForTable.module.scss'

type Row = {
    cell: number
    goodsItemName: string
    remains: number
}

type Props = {
    mashin: AutomatType
}

const SnackRemainsForTable: FC<Props> = ({ mashin }) => {
    const models = useAppSelector(selectModels)

    const rows = useMemo((): Array<Row> => {
        const model = models.find((m) => m.id === mashin.automatModelId)

        if (!model) {
            return []
        }

        if (model.type !== AutomatModelTypes.Snack) {
            throw new Error('Automat model must be Snack')
        }

        if (!mashin.cells) {
            return []
        }

        const cells = [...mashin.cells]
            .sort((c1, c2) => c1.remains - c2.remains)
            .slice(0, 3)

        return cells.map((c) => {
            return {
                cell: c.number,
                goodsItemName: c.good ? c.good.name : '',
                remains: c.remains,
            }
        })
    }, [mashin, models])

    return (
        <span className={styles.root}>
            {rows.map((row) => (
                <span className={styles.row}>
                    <span className={styles.cell}>{row.cell}.</span>
                    <span className={styles.goodsItemName}>
                        {row.goodsItemName}
                    </span>
                    <span className={styles.remains}>{row.remains}</span>
                </span>
            ))}
        </span>
    )
}

export default SnackRemainsForTable

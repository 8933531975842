import React, { FC, useCallback, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'
import { routes } from '../../../config'
import { Auth as AuthService } from '../../../services/Auth'
import { useValidator } from '../../hooks/validator'
import { validateLogin, validatePassword } from '../../../utils/validate'
import { TextField, TextFieldStatus } from '../../simples/TextField'
import { Button } from '../../simples/Button'
import { EmailField } from '../../simples/EmailField'
import { PassField } from '../../simples/PassField'
import styles from './RegistrationForm.module.scss'

const buttonClasses = {
    button: styles.button,
}

const loginValidateOptions = {
    required: true,
    validateFunc: validateLogin,
    max: 100,
}
const organizationValidateOptions = {
    required: true,
    max: 100,
}
const passwordValidateOptions = {
    required: true,
    max: 100,
    validateFunc: validatePassword,
}
const emailValidateOptions = {
    required: true,
    max: 100,
    email: true,
}

const RegistrationForm: FC = () => {
    const { t } = useTranslation()

    const {
        value: login,
        setValue: setLogin,
        error: loginError,
        setError: setLoginError,
        touched: loginTouched,
        setTouched: setLoginTouched,
    } = useValidator('', loginValidateOptions, t)
    const {
        value: organisation,
        setValue: setOrganisation,
        error: organisationError,
        setError: setOrganisationError,
        touched: organisationTouched,
        setTouched: setOrganisationTouched,
    } = useValidator('', organizationValidateOptions, t)
    const {
        value: password,
        setValue: setPassword,
        error: passwordError,
        setError: setPasswordError,
        touched: passwordTouched,
        setTouched: setPasswordTouched,
    } = useValidator('', passwordValidateOptions, t)
    const {
        value: email,
        setValue: setEmail,
        error: emailError,
        setError: setEmailError,
        touched: emailTouched,
        setTouched: setEmailTouched,
    } = useValidator('', emailValidateOptions, t)

    const [errors, setErrors] = useState<Array<string>>([])

    const isValid =
        !organisationError && !loginError && !passwordError && !emailError

    const history = useHistory()
    const [loading, setLoading] = useState(false)

    const handleSubmit = useCallback(
        async (e: React.FormEvent<HTMLFormElement>) => {
            e.preventDefault()

            if (!isValid) {
                throw new Error('Invalid form')
            }

            setOrganisationTouched(true)
            setLoginTouched(true)
            setPasswordTouched(true)
            setEmailTouched(true)
            setErrors([])

            setLoading(true)

            try {
                await AuthService.registaration({
                    companyName: organisation,
                    login,
                    password,
                    email,
                })

                history.push(routes.registrationSuccess)
            } catch (error) {
                if (
                    error.response &&
                    error.response.data &&
                    _.isArray(error.response.data.errors)
                ) {
                    setErrors(error.response.data.errors)
                } else {
                    if (error.response.data.errors.email)
                        setEmailError(error.response.data.errors.email)
                    if (error.response.data.errors.login)
                        setLoginError(error.response.data.errors.login)
                    if (error.response.data.errors.companyName)
                        setOrganisationError(
                            error.response.data.errors.companyName
                        )
                    if (error.response.data.errors.password)
                        setPasswordError(error.response.data.errors.password)
                }
            }

            setLoading(false)
        },
        [login, password, organisation, email]
    )

    return (
        <div className={styles.content}>
            <h2 className={styles.auth_h2}>
                {t('250_RegistrationForm_registration')}
            </h2>

            {errors.length > 0 && (
                <div className={styles.errors}>
                    {errors.map((error) => (
                        <div className={styles.errorsItem}>{error}</div>
                    ))}
                </div>
            )}

            <form action="/" onSubmit={(e) => handleSubmit(e)}>
                <div className={styles.formGroup}>
                    <TextField
                        value={organisation}
                        onChange={(e) => setOrganisation(e.target.value)}
                        onBlur={() => setOrganisationTouched(true)}
                        placeholder={t('249_RegistrationForm_organizationName')}
                        status={
                            organisationError && organisationTouched
                                ? TextFieldStatus.Error
                                : undefined
                        }
                        required
                        note={
                            organisationError && organisationTouched
                                ? organisationError
                                : ''
                        }
                    />
                </div>

                <div className={styles.formGroup}>
                    <TextField
                        value={login}
                        onChange={(e) => setLogin(e.target.value)}
                        onBlur={() => setLoginTouched(true)}
                        placeholder={t('251_RegistrationForm_login')}
                        status={
                            loginError && loginTouched
                                ? TextFieldStatus.Error
                                : undefined
                        }
                        required
                        note={loginError && loginTouched ? loginError : ''}
                    />
                </div>

                <div className={styles.formGroup}>
                    <EmailField
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        onBlur={() => setEmailTouched(true)}
                        placeholder={t('252_RegistrationForm_email')}
                        required
                        type={'text'}
                        status={
                            emailError && emailTouched
                                ? TextFieldStatus.Error
                                : undefined
                        }
                        note={emailError && emailTouched ? emailError : ''}
                    />
                </div>
                <div className={styles.keep_pass_box}>
                    <Link
                        to={routes.recoveryPassword}
                        className={styles.keep_pass}
                        tabIndex={-1}
                    >
                        {t('253_RegistrationForm_recoveryPassword')}
                    </Link>
                </div>

                <div className={styles.formGroup}>
                    <PassField
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        onBlur={() => setPasswordTouched(true)}
                        placeholder={t('254_RegistrationForm_password')}
                        required
                        status={
                            passwordError && passwordTouched
                                ? TextFieldStatus.Error
                                : undefined
                        }
                        note={
                            passwordError && passwordTouched
                                ? passwordError
                                : ''
                        }
                    />
                </div>

                <div className={styles.marg_btn}>
                    <Link to={routes.login}>
                        {t('254_RegistrationForm_signIn')}
                    </Link>

                    <label>{t('256_RegistrationForm_or')}</label>

                    <Button
                        type={'submit'}
                        classNames={buttonClasses}
                        disabled={!isValid}
                        loading={loading}
                    >
                        {t('257_RegistrationForm_registerNow')}
                    </Button>
                </div>
            </form>
        </div>
    )
}

export default RegistrationForm

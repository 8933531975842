import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import {
    AutomatModel,
    AutomatModelTypes,
    AutomatSnackCell,
} from '../../../../types'
import { Cell } from './Cell'

import styles from './SnackCells.module.scss'

type Props = {
    model: AutomatModel
    cells: Array<AutomatSnackCell>

    onChange: (cells: Array<AutomatSnackCell>) => void
}

const SnackCells: FC<Props> = ({ cells, model, onChange }) => {
    const { t } = useTranslation()

    if (model.type !== AutomatModelTypes.Snack) {
        throw new Error('Automat model must be Snack')
    }

    const handleCellChange = (number: number, cell?: AutomatSnackCell) => {
        if (!cell) {
            onChange(cells.filter((cell) => cell.number !== number))
            return
        }
        if (cell.number !== number) {
            throw new Error('Numbers not match')
        }

        let updated = false

        const updatedCells = cells.map((c) => {
            if (c.number === number) {
                updated = true
                return cell
            }

            return c
        })

        if (!updated) {
            updatedCells.push(cell)
            updatedCells.sort((c1, c2) => c1.number - c2.number)
        }

        onChange(updatedCells)
    }

    const handleCellDelete = (number: number) => {
        onChange(cells.filter((c) => c.number !== number))
    }

    return (
        <div className={styles.root}>
            <table>
                <thead>
                    <tr>
                        <th />
                        <th>{t('135_FillingEditor_brand')}</th>
                        <th>{t('637_SnackCells_Goods_item')}</th>
                        <th className={styles.cellPrice}>
                            {t('638_SnackCells_Price')}
                        </th>
                        <th className={styles.actions} />
                    </tr>
                </thead>
                <tbody>
                    {Array(model.cellsCount)
                        .fill(1)
                        .map((_, index) => {
                            const number = index + 1

                            const cellData = cells.find(
                                (cell) => cell.number === number
                            )

                            return (
                                <Cell
                                    key={number}
                                    number={number}
                                    data={cellData}
                                    onChange={handleCellChange}
                                    onDelete={handleCellDelete}
                                />
                            )
                        })}
                </tbody>
            </table>
        </div>
    )
}

export default SnackCells

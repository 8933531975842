import React, { FC, useState } from 'react'
import printJS from 'print-js'
import { v4 as uuidv4 } from 'uuid'
import { useTranslation } from 'react-i18next'

import { PromoCode as PromoCodeService } from '../../../services/PromoCode'

import { Modal } from '../../simples/Modal'
import { SourceFormData } from '../PromoCodeEditor/PromoCodeEditor'
import { QRCodeCanvas } from 'qrcode.react'
import { Button } from '../../simples/Button'
import styles from './QrCodesModal.module.scss'

function blobToBase64(blob: Blob) {
    return new Promise((resolve, _) => {
        const reader = new FileReader()
        reader.onloadend = () => resolve(reader.result)
        reader.readAsDataURL(blob)
    })
}

const modalClasses = {
    modal: styles.modal,
}

type Props = {
    onClose?: () => void
    promoCodes: Array<SourceFormData>
}

const QrCodesModal: FC<Props> = ({ onClose, promoCodes }) => {
    const { t } = useTranslation()

    const [printPreparing, setPrintPreparing] = useState(false)
    const [downloading, setDownloading] = useState(false)

    const printPdf = async () => {
        setPrintPreparing(true)

        const blob = await PromoCodeService.downloadQrsPdf(
            promoCodes.map((promoCode) => promoCode.id)
        )
        const base64 = (await blobToBase64(blob)) as string
        const clearedBase64 = base64.substr(
            'data:application/pdf;base64,'.length
        )

        printJS({ printable: clearedBase64, type: 'pdf', base64: true })

        setPrintPreparing(false)
    }

    const downloadPdf = async () => {
        setDownloading(true)

        const blob = await PromoCodeService.downloadQrsPdf(
            promoCodes.map((promoCode) => promoCode.id)
        )

        const pdfUrl = URL.createObjectURL(blob)

        const anchor = document.createElement('a')
        anchor.href = pdfUrl
        anchor.download = `${uuidv4()}.pdf`

        document.body.appendChild(anchor)
        anchor.click()
        document.body.removeChild(anchor)

        URL.revokeObjectURL(pdfUrl)

        setDownloading(false)
    }

    return (
        <Modal classes={modalClasses} onClose={onClose} showClose>
            <h3 className={styles.header}>{t('593_qr_promo_codes')}</h3>

            <div className={styles.buttons}>
                <Button onClick={printPdf} loading={printPreparing}>
                    {t('594_print')}
                </Button>
                <Button onClick={downloadPdf} loading={downloading}>
                    {t('595_download')}
                </Button>
            </div>

            {promoCodes.length > 0 ? (
                <div className={styles.list}>
                    {promoCodes.map((promoCode, index) => (
                        <div className={styles.item} key={index}>
                            <QRCodeCanvas
                                value={promoCode.promoCode}
                                size={256}
                            />
                        </div>
                    ))}
                </div>
            ) : (
                <div className={styles.empty}>{t('596_Nothing_to_show')}</div>
            )}
        </Modal>
    )
}

export default QrCodesModal

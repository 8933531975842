import React, { FC, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { AutomatModel, AutomatModelTypes, AutomatType } from '../../../../types'
import { loadGoods, selectGoods } from '../../../../store/slices/storage'
import { useAppDispatch, useAppSelector } from '../../../hooks/store'
import { SubHeader } from '../SubHeader'
import styles from './SnackRemains.module.scss'

type Props = {
    automat: AutomatType
    model: AutomatModel
}

const SnackRemains: FC<Props> = ({ automat, model }) => {
    const { t } = useTranslation()
    const dispatch = useAppDispatch()
    const goods = useAppSelector(selectGoods)

    useEffect(() => {
        dispatch(loadGoods())
    }, [])

    if (model.type !== AutomatModelTypes.Snack) {
        throw new Error('Automat model must be Snack')
    }

    return (
        <div className={styles.root}>
            <SubHeader>{t('85_Remains_stockStatus')}</SubHeader>

            <table>
                <thead>
                    <tr>
                        <th className={styles.cells}>
                            {t('647_SnackRemains_Cells')}
                        </th>
                        <th>{t('648_SnackRemains_Goods_item')}</th>
                        <th className={styles.remainValues}>
                            {t('649_SnackRemains_Remains')}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {Array(model.cellsCount)
                        .fill(1)
                        .map((_, index) => {
                            const number = index + 1
                            const goodsItem = automat.cells
                                ? automat.cells.find(
                                      (gi) => gi.number === number
                                  )
                                : undefined
                            const goodsItemData = goodsItem
                                ? goods.find(
                                      (gi) => gi.id === goodsItem.goodsItemId
                                  )
                                : undefined

                            return (
                                <tr>
                                    <td className={styles.cells}>{number}.</td>
                                    <td>
                                        {goodsItemData
                                            ? goodsItemData.name
                                            : '--'}
                                    </td>
                                    <td className={styles.remainValues}>
                                        {goodsItem
                                            ? `${goodsItem.remains} ${t(
                                                  '650_SnackRemains_Pieces'
                                              )}`
                                            : '--'}
                                    </td>
                                </tr>
                            )
                        })}
                </tbody>
            </table>
        </div>
    )
}

export default SnackRemains

import React, { FC, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useAppDispatch, useAppSelector } from '../../hooks/store'
import {
    loadBrands,
    loadProducts,
    loadTastes,
    loadModels,
    loadFillings,
    loadGoods,
    fetchBaseDosages,
    selectModels,
} from '../../../store/slices/storage'
import { AutomatType, AutomatModelTypes } from '../../../types'
import { Automats as AutomatsService } from '../../../services/Automats'
import { Layout } from '../../complexes/Layout'
import { AutomatTabs } from '../../complexes/automat/AutomatTabs'
import { Commands } from '../../complexes/automat/Commands'
import { Settings } from '../../complexes/automat/Settings'
import styles from './AutomatSettings.module.scss'
import { Spinner } from '../../simples/Spinner'

type RouterParams = {
    id: string
}

const AutomatSettings: FC = () => {
    const { t } = useTranslation()
    const { id } = useParams<RouterParams>()
    const dispatch = useAppDispatch()

    const models = useAppSelector(selectModels)

    useEffect(() => {
        dispatch(loadBrands())
        dispatch(loadProducts())
        dispatch(loadTastes())
        dispatch(loadModels())
        dispatch(loadFillings())
        dispatch(fetchBaseDosages())
        dispatch(loadGoods())
    }, [])

    const [automat, setAutomat] = useState<AutomatType>()
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        setLoading(true)
        AutomatsService.getItem(+id).then((automat) => {
            setAutomat(automat)
            setLoading(false)
        })
    }, [])

    const currentModel = useMemo(() => {
        if (!automat) {
            return undefined
        }

        return models.find((model) => model.id === automat.automatModelId)
    }, [models, automat])

    return (
        <Layout>
            <h1 className={styles.header}>
                {t('338_AutomatSettings_automateControl')} "
                {automat ? automat.name : ''}"
            </h1>

            <AutomatTabs automatId={+id} />

            {loading ? (
                <div className={styles.loading}>
                    <Spinner />
                </div>
            ) : automat && currentModel ? (
                <>
                    {currentModel.type === AutomatModelTypes.Beverages && (
                        <div className={styles.commands}>
                            <Commands automatId={+id} />
                        </div>
                    )}

                    <div className={styles.generalSettings}>
                        <Settings
                            automat={automat}
                            onSubmit={setAutomat}
                            model={currentModel}
                        />
                    </div>
                </>
            ) : null}
        </Layout>
    )
}

export default AutomatSettings

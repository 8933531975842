import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import _ from 'lodash'
import { RootState } from '../index'

type Toast = {
    time: number
    message: string
    status: 'success' | 'error'
}

type ToastActionPayload = {
    message: string
    status?: 'success' | 'error'
}

const initialState = {
    toasts: [] as Array<Toast>,
}

export const siteSlice = createSlice({
    name: 'site',
    initialState,
    reducers: {
        showToast: (
            state,
            action: PayloadAction<string | ToastActionPayload>
        ) => {
            _.isString()

            state.toasts = [
                {
                    time: new Date().getTime(),
                    message: _.isString(action.payload)
                        ? action.payload
                        : action.payload.message,
                    status:
                        _.isObject(action.payload) && action.payload.status
                            ? action.payload.status
                            : 'success',
                },
                ...state.toasts,
            ]
        },
        hideToast: (state, action: PayloadAction<number>) => {
            state.toasts = state.toasts.filter(
                (toast) => toast.time !== action.payload
            )
        },
    },
})

export const { showToast, hideToast } = siteSlice.actions

export const selectToasts = (state: RootState) => state.site.toasts

export default siteSlice.reducer

import React, { FC, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useAppDispatch } from '../../../hooks/store'
import { showToast } from '../../../../store/slices/site'

import { Table, THead, Th, TBody, Tr, Td } from '../../Table'
import { ReactComponent as EditIcon } from '../../../../assets/icons/edit.svg'
import { ReactComponent as DeleteIcon } from '../../../../assets/icons/delete.svg'

import { Spinner } from '../../Spinner'
import { TableCheckbox } from '../../TableCheckbox'
import { Button } from '../../Button'
import { Confirm } from '../../Confirm'
import styles from './ItemList.module.scss'

const checkedDeleteButtonClasses = {
    button: styles.checkedDeleteButton,
}

type Props = {
    headers: Array<string>
    rows: Array<{
        id: number
        values: Array<string | JSX.Element | number>
    }>
    loading?: boolean
    onEdit?: (id: number) => void
    onDelete?: (id: number) => void
    enableMassDeleting?: boolean
    onMassDelete?: (ids: Array<number>) => Promise<void> | void
    onRowClick?: (id: number) => void
    additionMass?: JSX.Element
    onCheckedItemsChange?: (ids: Array<number>) => void
}

const ItemsList: FC<Props> = ({
    headers,
    rows,
    loading = false,
    onEdit,
    onDelete,
    enableMassDeleting = false,
    onMassDelete,
    onRowClick,
    additionMass,
    onCheckedItemsChange,
}) => {
    const { t } = useTranslation()

    const dispatch = useAppDispatch()

    const [checkedItems, setCheckedItems] = useState<Array<number>>([])
    const [deleting, setDeleting] = useState(false)
    const [deletingRequest, setDeletingRequest] = useState(false)

    const handleCommonCheckboxChange = (checked: boolean) => {
        let newCheckedItems: Array<number> = []

        if (checked) {
            newCheckedItems = rows.map((row) => row.id)
        }

        setCheckedItems(newCheckedItems)
        onCheckedItemsChange && onCheckedItemsChange(newCheckedItems)
    }

    const changeChecked = (id: number, checked: boolean) => {
        let newCheckedItems: Array<number> = []

        if (checked) {
            newCheckedItems = [...checkedItems, id]
        } else {
            newCheckedItems = checkedItems.filter((iid) => iid !== id)
        }

        setCheckedItems(newCheckedItems)
        onCheckedItemsChange && onCheckedItemsChange(newCheckedItems)
    }

    const commonCheckboxChecked = useMemo(() => {
        if (checkedItems.length === 0) {
            return false
        }

        for (let i = 0; i < rows.length; i++) {
            const row = rows[i]

            if (checkedItems.indexOf(row.id) === -1) {
                return 'partly'
            }
        }

        return true
    }, [checkedItems, rows])

    const startMassDelete = () => {
        setDeleting(true)
    }

    const cancelMassDelete = () => {
        setDeleting(false)
    }

    const confirmMassDelete = async () => {
        setDeleting(false)

        if (!onMassDelete) {
            return
        }

        const r = onMassDelete(checkedItems)

        if (r instanceof Promise) {
            setDeletingRequest(true)
            await r
            setDeletingRequest(false)
        }

        setCheckedItems([])
        dispatch(showToast(t('482_ItemsList_massDeletingSuccess')))
    }

    return (
        <div className={styles.root}>
            <Table>
                <THead>
                    {enableMassDeleting && (
                        <Th addClassName={styles.checkboxHeaderContainer}>
                            <TableCheckbox
                                checked={commonCheckboxChecked}
                                onChange={handleCommonCheckboxChange}
                            />
                        </Th>
                    )}
                    {headers.map((header, i) => (
                        <Th key={i}>{header}</Th>
                    ))}
                    <Th addClassName={styles.deleteHeaderContainer}>
                        {enableMassDeleting && (
                            <span>
                                <Button
                                    classNames={checkedDeleteButtonClasses}
                                    disabled={checkedItems.length === 0}
                                    loading={deletingRequest}
                                    onClick={startMassDelete}
                                >
                                    {t('533_ItemsList_deleteSelected')}
                                </Button>
                            </span>
                        )}
                        {additionMass}
                    </Th>
                </THead>
                <TBody>
                    {rows.map((row, i) => (
                        <Tr
                            onClick={
                                onRowClick
                                    ? () => onRowClick(row.id)
                                    : undefined
                            }
                            key={i}
                        >
                            {enableMassDeleting && (
                                <Td addClassName={styles.checkboxBodyContainer}>
                                    <TableCheckbox
                                        checked={
                                            checkedItems.indexOf(row.id) !== -1
                                        }
                                        onChange={(checked) =>
                                            changeChecked(row.id, checked)
                                        }
                                    />
                                </Td>
                            )}
                            {row.values.map((value, j) => (
                                <Td key={j}>{value}</Td>
                            ))}
                            {onEdit && onDelete && (
                                <Td align="right">
                                    <span className={styles.actions}>
                                        <button
                                            className={styles.action}
                                            onClick={() =>
                                                onEdit && onEdit(row.id)
                                            }
                                        >
                                            <EditIcon />
                                        </button>
                                        <button
                                            className={styles.action}
                                            onClick={() =>
                                                onDelete && onDelete(row.id)
                                            }
                                        >
                                            <DeleteIcon />
                                        </button>
                                    </span>
                                </Td>
                            )}
                        </Tr>
                    ))}
                </TBody>
            </Table>

            {loading && (
                <div className={styles.loader}>
                    <Spinner />
                </div>
            )}

            {deleting && (
                <Confirm
                    text={t('481_ItemsList_confirmMassDeleting')}
                    onConfirm={confirmMassDelete}
                    onCancel={cancelMassDelete}
                />
            )}
        </div>
    )
}

export default ItemsList

import React, {
    ChangeEvent,
    FC,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import moment, { Moment } from 'moment'

import { Order as OrderType, OrderPriceMethods } from '../../../types'
import {
    Orders as OrdersService,
    SortOrder as OrdersSortOrder,
    SortOrderBy as OrdersSortOrderBy,
} from '../../../services/Orders'
import { useAppDispatch, useAppSelector } from '../../hooks/store'
import { loadAutomats, selectAutomats } from '../../../store/slices/storage'

import { Layout } from '../../complexes/Layout'
import { Header } from '../../simples/tablePages/Header'
import { SalesChart, SalesChartType } from '../../complexes/SalesChart'
import { Search } from '../../simples/Search'
import { Button } from '../../simples/Button'
import { OrderTable } from '../../complexes/automat/OrdersTable'
import { OrdersExport } from '../../complexes/OrdersExport'
import {
    OptionSelectorType,
    SearchSelector,
} from '../../simples/SearchSelector'
import { TextField } from '../../simples/TextField'
import { CheckBox } from '../../simples/CheckBox'
import styles from './OrderSchedule.module.scss'
import { Selector } from '../../simples/Selector'
import { getTimezone } from '../../../utils/date'

const filterSearchSelectorClasses = {
    controller: styles.filterSearchSelectorController,
    input: styles.filterSearchSelectorInput,
}
const filterTextFieldClasses = {
    input: styles.filterTextFieldInput,
}
const filterSelectorClasses = {
    controller: styles.filterSelectorController,
}

const getStartByType = (type: SalesChartType): Date => {
    let startOfPeriod: Moment

    if (type === SalesChartType.PerYear) {
        startOfPeriod = moment().startOf('year')
    } else if (type === SalesChartType.PerMonth) {
        startOfPeriod = moment().startOf('month')
    } else if (type === SalesChartType.PerWeek) {
        startOfPeriod = moment().startOf('isoWeek')
    } else {
        startOfPeriod = moment().startOf('day')
    }

    return getDateUTCFromMoment(startOfPeriod).toDate()
}

const getDateUTCFromMoment = (moment: Moment): Moment => {
    const timezone = getTimezone()

    if (timezone > 0) {
        moment.subtract(timezone, 'hours')
    } else if (timezone < 0) {
        moment.add(Math.abs(timezone), 'hours')
    }

    return moment
}

const getDateCurrentTimezoneFromMoment = (moment: Moment): Moment => {
    const timezone = getTimezone()

    if (timezone > 0) {
        moment.add(timezone, 'hours')
    } else if (timezone < 0) {
        moment.subtract(Math.abs(timezone), 'hours')
    }

    return moment
}

const downloadButtonClassNames = {
    button: styles.downloadButton,
}

const OrderSchedule: FC = () => {
    const { t } = useTranslation()
    const dispatch = useAppDispatch()
    const automats = useAppSelector(selectAutomats)

    useEffect(() => {
        dispatch(loadAutomats())
    }, [])

    const [type, setType] = useState<SalesChartType>(SalesChartType.Today)

    const [orders, setOrders] = useState<Array<OrderType>>([])

    const [ordersLoading, setOrdersLoading] = useState(false)
    const [ordersOffset, setOrdersOffset] = useState(0)
    const [ordersHas, setOrdersHas] = useState(true)
    const [ordersOrderBy, setOrdersOrdersBy] =
        useState<OrdersSortOrderBy>('buyAt')
    const [ordersOrder, setOrdersOrder] = useState<OrdersSortOrder>('DESC')
    const [start, setStart] = useState(() => {
        return getStartByType(type)
    })
    const [end, setEnd] = useState(() => {
        return getDateUTCFromMoment(moment().startOf('day')).toDate()
    })
    const [search, setSearch] = useState('')
    const [automatId, setAutomatId] = useState(0)
    const [paymentMethod, setPaymentMethod] = useState<OrderPriceMethods>()
    const [withPromocode, setWithPromocode] = useState<boolean>()

    const loadOrders = useCallback(
        async (
            offset: number,
            start: Date,
            end: Date,
            orderBy: OrdersSortOrderBy,
            order: OrdersSortOrder,
            search: string,
            automatId: number,
            paymentMethod?: OrderPriceMethods,
            withPromocode?: boolean
        ) => {
            if (!ordersHas || ordersLoading) {
                return
            }

            setOrdersLoading(true)

            try {
                const result = await OrdersService.getList({
                    offset: offset,
                    start: start,
                    end: end,
                    orderBy: orderBy,
                    order: order,
                    search: search,
                    automatId: automatId,
                    priceMethod: paymentMethod,
                    withPromocode: withPromocode,
                })

                setOrdersLoading(false)

                if (!result || result.length === 0) {
                    setOrdersHas(false)
                }

                setOrders((orders) => [...orders, ...result])
            } catch (e) {
                setOrdersLoading(false)
            }
        },
        [ordersLoading, ordersHas]
    )

    const clear = () => {
        setOrdersOffset(0)
        setOrdersHas(true)
        setOrders([])
    }

    useEffect(() => {
        loadOrders(
            ordersOffset,
            start,
            end,
            ordersOrderBy,
            ordersOrder,
            search,
            automatId,
            paymentMethod,
            withPromocode
        ).then()
    }, [
        ordersOffset,
        start,
        end,
        ordersOrderBy,
        ordersOrder,
        search,
        automatId,
        paymentMethod,
        withPromocode,
    ])

    useEffect(() => {
        clear()
        setStart(getStartByType(type))
        setEnd(getDateUTCFromMoment(moment().startOf('day')).toDate())
    }, [type])

    const handleEndReached = () => {
        setOrdersOffset(orders.length)
    }

    const handleSortChange = (
        orderBy: OrdersSortOrderBy,
        order: OrdersSortOrder
    ) => {
        clear()
        setOrdersOrdersBy(orderBy)
        setOrdersOrder(order)
    }

    const handleSearch = (search: string) => {
        clear()
        setSearch(search)
    }

    const handleAutomatIdChange = (option: OptionSelectorType) => {
        clear()
        setAutomatId(option.id)
    }

    const handleStartChange = (e: ChangeEvent<HTMLInputElement>) => {
        clear()
        const value = e.target.value
        setStart(getDateUTCFromMoment(moment(value, 'YYYY-MM-DD')).toDate())
    }

    const handleEndChange = (e: ChangeEvent<HTMLInputElement>) => {
        clear()
        const value = e.target.value
        setEnd(getDateUTCFromMoment(moment(value, 'YYYY-MM-DD')).toDate())
    }

    const handlePaymentMethodChange = (value: OrderPriceMethods) => {
        clear()
        setPaymentMethod(value)
    }

    const handleWithPromocodeChange = (checked: boolean) => {
        clear()
        setWithPromocode(checked ? true : undefined)
    }

    const [showOrdersExport, setShowOrdersExport] = useState(false)

    const automatsOptions = useMemo(() => {
        return [
            { id: 0, value: t('584_OrderSchedule_noValue') },
            ...automats.map((automat) => ({
                id: automat.id,
                value: automat.name,
            })),
        ]
    }, [automats])

    const dateStart = useMemo(
        () =>
            getDateCurrentTimezoneFromMoment(moment(start)).format(
                'YYYY-MM-DD'
            ),
        [start]
    )
    const dateEnd = useMemo(
        () =>
            getDateCurrentTimezoneFromMoment(moment(end)).format('YYYY-MM-DD'),
        [end]
    )

    const paymentMethodOptions = [
        // {value: OrderPriceMethods.Nothing, label: t('572_OrderSchedule_Nothing')},
        {
            value: 0,
            label: t('584_OrderSchedule_noValue'),
        },
        {
            value: OrderPriceMethods.Cashless,
            label: t('573_OrderSchedule_Cashless'),
        },
        { value: OrderPriceMethods.Cash, label: t('577_OrderSchedule_Cash') },
        { value: OrderPriceMethods.RFID, label: t('574_OrderSchedule_RFID') },
        { value: OrderPriceMethods.QR, label: t('575_OrderSchedule_QR') },
    ]

    return (
        <>
            <Layout onEndReached={handleEndReached}>
                <Header text={t('484_OrderSchedule_title')} />

                <div className={styles.salesChartContainer}>
                    <SalesChart type={type} onChangeType={setType} />
                </div>

                <div className={styles.ordersTableTitle}>
                    {t('492_OrderSchedule_salesTable')}
                </div>

                <div className={styles.panel}>
                    <div className={styles.searchContainer}>
                        <Search onSearch={handleSearch} />
                    </div>
                    <div className={styles.downloadButtonContainer}>
                        <Button
                            classNames={downloadButtonClassNames}
                            onClick={() => setShowOrdersExport(true)}
                        >
                            {t('510_OrderSchedule_export')}
                        </Button>
                    </div>
                </div>

                <div className={styles.filterContainer}>
                    <div className={styles.filterItem}>
                        <SearchSelector
                            options={automatsOptions}
                            value={automatId}
                            onClick={handleAutomatIdChange}
                            placeholder={t('569_OrderSchedule_automate')}
                            classes={filterSearchSelectorClasses}
                        />
                    </div>

                    <div className={styles.filterItem}>
                        <TextField
                            type={'date'}
                            value={dateStart}
                            onChange={handleStartChange}
                            placeholder={t('570_OrderSchedule')}
                            classes={filterTextFieldClasses}
                        />
                    </div>

                    <div className={styles.filterItem}>
                        <TextField
                            type={'date'}
                            value={dateEnd}
                            onChange={handleEndChange}
                            placeholder={t('571_OrderSchedule')}
                            classes={filterTextFieldClasses}
                        />
                    </div>

                    <div className={styles.filterItem}>
                        <Selector
                            options={paymentMethodOptions}
                            label={t('576_OrderSchedule_paymentMethod')}
                            value={paymentMethod}
                            onChange={handlePaymentMethodChange}
                            classes={filterSelectorClasses}
                        />
                    </div>

                    <div className={styles.filterItem}>
                        <CheckBox
                            label={t('578_OrderSchedule_SaleByPromocode')}
                            checked={withPromocode}
                            onChange={handleWithPromocodeChange}
                        />
                    </div>
                </div>

                <div className={styles.ordersTableContainer}>
                    <OrderTable
                        orders={orders}
                        loading={ordersLoading}
                        onSortChange={handleSortChange}
                    />
                </div>
            </Layout>

            {showOrdersExport && (
                <OrdersExport
                    onClose={() => setShowOrdersExport(false)}
                    start={start}
                />
            )}
        </>
    )
}

export default OrderSchedule

import React, { FC, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
    selectBaseDosages,
    selectBrands,
    selectProducts,
    selectTastes,
} from '../../../store/slices/storage'
import { useAppSelector } from '../../hooks/store'
import {
    AutomatContainerTypes,
    AutomatContainerData,
    DosageItemData,
    AutomatType,
} from '../../../types'
import { Col } from '../../simples/modalEditor/Col'
import { Editor } from '../../simples/modalEditor/Editor'
import { Row } from '../../simples/modalEditor/Row'
import {
    SearchSelector,
    OptionSelectorType,
} from '../../simples/SearchSelector'
import { DosageItem } from './DosageItem'
import styles from './AutomatContainerEditor.module.scss'

const initDosageData: DosageItemData = {
    drinkVolume: 0,
    water: 0,
    product: 0,
    conversionFactor: 0,
    tasteId: 0,
    price: 0,
}

const initData: AutomatContainerData = {
    tasteId: 0,
    dosages: [{ ...initDosageData }],
}

type Props = {
    data?: AutomatContainerData
    containerType: AutomatContainerTypes
    onClose: () => void
    onSubmit: (data: AutomatContainerData) => void
    automat?: AutomatType
    onDelete?: () => void
}

const AutomatContainerEditor: FC<Props> = ({
    data: defaultData = initData,
    containerType,
    onSubmit,
    onClose,
    automat,
    onDelete,
}) => {
    const { t } = useTranslation()

    const brands = useAppSelector(selectBrands)
    const products = useAppSelector(selectProducts)
    const tastes = useAppSelector(selectTastes)
    const baseDosages = useAppSelector(selectBaseDosages)

    const [data, setData] = useState(defaultData)

    const [doseBasage, setBasage] = useState(
        baseDosages.map((i) => ({
            drinkVolume: i.drinkVolume,
            water: i.water,
            product: i.product,
            conversionFactor: i.conversionFactor,
            price: i.price,
        }))
    )
    const [doseBasageList, setBasageList] = useState(
        automat?.dosages.map((i) => ({
            drinkVolume: i.drinkVolume,
            water: i.water,
            product: i.product,
            conversionFactor: i.conversionFactor,
            price: i.price,
        }))
    )
    useEffect(() => {
        if (
            JSON.stringify(
                doseBasage.map((item) => {
                    const { price, ...rest } = item
                    return rest
                })
            ) ===
            JSON.stringify(
                doseBasageList?.map((item) => {
                    const { price, ...rest } = item
                    return rest
                })
            )
        ) {
            setData({
                ...data,
                dosages: tastes
                    .filter((item) => item.id === data.tasteId)[0]
                    .baseDosages.map((i) => ({
                        drinkVolume: i.drinkVolume,
                        water: i.water,
                        product: i.product,
                        conversionFactor: i.conversionFactor,
                        price: 0,
                        tasteId: data.tasteId || 0,
                    })),
            })
        }
    }, [data.tasteId])

    useEffect(() => {
        if (data.tasteId <= 0 && data.tasteId !== 0) {
            setData({
                ...data,
                dosages: [
                    ...tastes
                        .filter((item) => item.id === data.tasteId)[0]
                        .baseDosages.map((i) => ({
                            drinkVolume: i.drinkVolume,
                            water: i.water,
                            product: i.product,
                            conversionFactor: i.conversionFactor,
                            price: 0,
                            tasteId: 0,
                        })),
                ],
            })
        }
    }, [data.tasteId])

    const [brandId, setBrandId] = useState(0)
    const [productId, setProductId] = useState(0)

    useEffect(() => {
        if (!data.tasteId) {
            return
        }

        const taste = tastes.find((t) => t.id === data.tasteId)

        if (!taste) {
            return
        }

        const product = products.find((p) => p.id === taste.productId)

        if (!product) {
            return
        }

        const brand = brands.find((b) => b.id === product.brandId)

        if (brand) {
            setBrandId(brand.id)
        }
        setProductId(product.id)
    }, [])

    const brandsOptions = useMemo(() => {
        return brands.map((brand) => ({ value: brand.name, id: brand.id }))
    }, [brands])

    const productsOptions = useMemo(() => {
        if (!brandId) {
            return []
        }

        return products
            .filter((product) => product.brandId === brandId)
            .map((product) => ({ value: product.name, id: product.id }))
    }, [products, brandId])

    const tasteOptions = useMemo(() => {
        if (!productId) {
            return []
        }
        return tastes
            .filter(
                (taste) =>
                    taste.product &&
                    taste.product.id === productId &&
                    +taste.product.condition === +containerType
            )
            .map((t) => {
                return {
                    id: t.id,
                    value: t.name,
                }
            })
    }, [tastes, containerType, productId])
    // console.log(tastes)

    const handleTasteChange = (option: OptionSelectorType) => {
        console.log('AutomatContainerEditor@handleTasteChange', { option })
        /*let list = tastes
            .filter((item) => item.id === option.id)[0]
            .baseDosages.map((i) => ({
                drinkVolume: i.drinkVolume,
                water: i.water,
                product: i.product,
                conversionFactor: i.conversionFactor,
                price: 0,
            }))*/
        setData({
            ...data,
            tasteId: option.id,
            dosages: data.dosages.map((d) => ({
                ...d,
                tasteId: option.id,
            })),
        })
    }

    const handleDosageChange = (dosage: DosageItemData, index: number) => {
        setData({
            ...data,
            dosages: data.dosages.map((d, i) => {
                if (i === index) {
                    return dosage
                }

                return d
            }),
        })
    }

    const submit = () => {
        onSubmit(data)
    }

    const largeDosageIndex = useRef<number>(-1)
    const largeDosage = useMemo(() => {
        if (data.dosages.length === 0) {
            return undefined
        }

        if (largeDosageIndex.current !== -1) {
            if (data.dosages.length > largeDosageIndex.current) {
                return data.dosages[largeDosageIndex.current]
            }

            return undefined
        }

        const largeDosage = [...data.dosages].sort(
            (d1, d2) => d2.drinkVolume - d1.drinkVolume
        )[0]

        largeDosageIndex.current = data.dosages.findIndex(
            (d) => d.drinkVolume === largeDosage.drinkVolume
        )

        return largeDosage
    }, [data.dosages])

    const handleLargeDosageChange = (dosage: DosageItemData) => {
        setData({
            ...data,
            dosages: data.dosages.map((d, i) => {
                if (i === largeDosageIndex.current) {
                    return dosage
                }

                return d
            }),
        })
    }

    const smallDosageIndex = useRef<number>(-1)
    const smallDosage = useMemo(() => {
        if (data.dosages.length < 2) {
            return undefined
        }

        if (
            smallDosageIndex.current !== -1 &&
            data.dosages.length > smallDosageIndex.current
        ) {
            return data.dosages[smallDosageIndex.current]
        }

        const smallDosage = [...data.dosages].sort(
            (d1, d2) => d1.drinkVolume - d2.drinkVolume
        )[0]

        smallDosageIndex.current = data.dosages.findIndex(
            (d) => d.drinkVolume === smallDosage.drinkVolume
        )

        return smallDosage
    }, [data.dosages])

    const handleSmallDosageChange = (dosage: DosageItemData) => {
        setData({
            ...data,
            dosages: data.dosages.map((d, i) => {
                if (i === smallDosageIndex.current) {
                    return dosage
                }

                return d
            }),
        })
    }

    const handleDosage2CheckboxChange = (checked: boolean) => {
        if (!data) {
            throw new Error('Data is empty')
        }

        const { dosages, tasteId } = data

        const currentTaste = tastes.find((item) => item.id === tasteId)

        if (!currentTaste) {
            throw new Error('currentTaste not found')
        }

        if (checked) {
            // Добавляем самую маленькую дозировку из базовых

            if (dosages.length > 1) {
                throw new Error('Cannot add more than two doses')
            }

            const newDosages = [...dosages]

            if (currentTaste.baseDosages.length > 0) {
                const smallDosage = [...currentTaste.baseDosages].sort(
                    (d1, d2) => d1.drinkVolume - d2.drinkVolume
                )[0]
                newDosages.push({
                    ...smallDosage,
                    tasteId: tasteId,
                    id: undefined,
                })
            } else {
                newDosages.push({
                    drinkVolume: 0,
                    water: 0,
                    product: 0,
                    conversionFactor: 0,
                    price: 0,
                    tasteId: tasteId,
                    id: undefined,
                })
            }

            setData({
                ...data,
                dosages: newDosages,
            })
        } else if (dosages.length > 0) {
            // Оставляем только самую большую дозировку

            const largestDosage = [...dosages].sort(
                (d1, d2) => d2.drinkVolume - d1.drinkVolume
            )[0]

            setData({
                ...data,
                dosages: [largestDosage],
            })
        }
    }

    console.log('AutomatContainerEditor', {
        data,
        largeDosage,
        smallDosage,
        tasteOptions,
    })

    return (
        <Editor
            header={t('91_AutomatContainerEditor_products')}
            onCancel={onClose}
            onSubmit={submit}
            submitDisabled={!data.tasteId}
        >
            <div className={styles.deleteButtonContainer}>
                <button className={styles.deleteButton} onClick={onDelete}>
                    Удалить
                </button>
            </div>
            <Row>
                <Col>
                    <SearchSelector
                        value={brandId}
                        options={brandsOptions}
                        onClick={(option) => setBrandId(option.id)}
                        placeholder={t('92_AutomatContainerEditor_selectBrand')}
                    />
                </Col>
            </Row>
            <Row>
                <Col>
                    <SearchSelector
                        value={productId}
                        options={productsOptions}
                        onClick={(option) => setProductId(option.id)}
                        placeholder={t(
                            '93_AutomatContainerEditor_selectProduct'
                        )}
                        disabled={!brandId}
                    />
                </Col>
            </Row>
            <Row>
                <Col>
                    <SearchSelector
                        value={data.tasteId}
                        options={tasteOptions}
                        onClick={handleTasteChange}
                        placeholder={t('94_AutomatContainerEditor_selectTaste')}
                        disabled={!productId}
                    />
                </Col>
            </Row>

            <DosageItem
                dosage={largeDosage}
                number={1}
                onChange={handleLargeDosageChange}
                condition={containerType}
            />

            <DosageItem
                dosage={smallDosage}
                number={2}
                onChange={handleSmallDosageChange}
                condition={containerType}
                showEnableCheckbox
                enabledChecked={!!smallDosage}
                onEnableChange={handleDosage2CheckboxChange}
            />
        </Editor>
    )
}

export default AutomatContainerEditor

import React, { FC } from 'react'
import { OutletType } from '../../../../../types'
import { Row } from '../../../modalEditor/Row'
import styles from './Outlet.module.scss'

type Props = {
    outlets: Array<OutletType>
}

const Outlet: FC<Props> = ({ outlets }) => {
    return (
        <Row css={styles.outletWrapper}>
            {outlets.map((outlet) => (
                <div className={styles.borderOutlet}>
                    <div className={styles.wrapper}>
                        <div className={styles.outletName}>{outlet.name}</div>
                        {outlet.automats.map((automat) => (
                            <div className={styles.mashineName}>
                                {automat.name}
                            </div>
                        ))}
                    </div>
                </div>
            ))}
        </Row>
    )
}

export default Outlet

import React, { ChangeEvent, FC, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import { AutomatSnackCell, GoodsItem } from '../../../../types'
import { useAppSelector } from '../../../hooks/store'
import { selectBrands, selectGoods } from '../../../../store/slices/storage'

import { ReactComponent as DeleteIcon } from '../../../../assets/icons/delete.svg'
import {
    OptionSelectorType,
    SearchSelector,
} from '../../../simples/SearchSelector'

import styles from './SnackCells.module.scss'
import { TextField } from '../../../simples/TextField'

const priceFieldClasses = {
    input: styles.priceFieldInput,
}

type Props = {
    number: number
    data?: AutomatSnackCell
    onChange?: (number: number, data?: AutomatSnackCell) => void
    onDelete?: (number: number) => void
}

export const Cell: FC<Props> = ({ number, data, onChange, onDelete }) => {
    const { t } = useTranslation()

    const goods = useAppSelector(selectGoods)
    const brands = useAppSelector(selectBrands)
    const [currentBrand, setCurrentBrand] = useState<number>()

    useEffect(() => {
        if (data && data.good) {
            setCurrentBrand(data.good.brand.id)
        }
    }, [])

    const goodsOptions = useMemo(() => {
        return goods
            .filter((g) => g.brand.id === currentBrand)
            .map((goodItem) => ({
                value: goodItem.name,
                id: goodItem.id,
            }))
    }, [currentBrand, goods])

    const brandsOptions = useMemo(() => {
        return brands.map((goodItem) => ({
            value: goodItem.name,
            id: goodItem.id,
        }))
    }, [brands])

    const handleGoodsItemChange = (option: OptionSelectorType) => {
        if (!option.value.length) onChange && onChange(number, undefined)
        if (data) {
            onChange &&
                onChange(number, {
                    ...data,
                    goodsItemId: option.id,
                })
        } else {
            const goodItem = goods.find((gi) => gi.id === option.id)

            if (!goodItem) {
                return
            }

            onChange &&
                onChange(number, {
                    number,
                    goodsItemId: option.id,
                    price: 0,
                    remains: 0,
                    good: goodItem,
                })
        }
    }

    const handleChangeBrand = (option: OptionSelectorType) => {
        setCurrentBrand(option.id)
        onChange && onChange(number, undefined)
    }

    const handlePriceChange = (event: ChangeEvent<HTMLInputElement>) => {
        if (!data) {
            return
        }

        const value = Math.abs(+event.target.value)

        onChange &&
            onChange(number, {
                ...data,
                price: value,
            })
    }

    const handleDelete = () => {
        onDelete && onDelete(number)
        setCurrentBrand(undefined)
    }

    return (
        <tr className={styles.cell}>
            <td className={styles.cellNumber}>{number}.</td>
            <td className={styles.cellBrand}>
                <SearchSelector
                    value={currentBrand || 0}
                    options={brandsOptions}
                    onClick={handleChangeBrand}
                    placeholder={t('134_FillingEditor_selectBrand')}
                    upperCase
                />
            </td>
            <td className={styles.cellGoods}>
                <SearchSelector
                    value={data && data.good ? data.good.id : 0}
                    options={goodsOptions}
                    onClick={handleGoodsItemChange}
                    placeholder={t('639_SnackCells_Choose_goods_item')}
                    disabled={!currentBrand}
                    upperCase
                />
            </td>
            <td className={styles.cellPrice}>
                <TextField
                    type={'number'}
                    value={data && data.price && currentBrand ? data.price : ''}
                    onChange={handlePriceChange}
                    disabled={!data && !currentBrand}
                    classes={priceFieldClasses}
                />
            </td>
            <td className={styles.actions}>
                <div
                    onClick={handleDelete}
                    className={classNames(styles.icon, {
                        [styles.iconDisabled]: !data,
                    })}
                >
                    <DeleteIcon />
                </div>
            </td>
        </tr>
    )
}

import React, { FC, useState, useRef, useEffect, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { User as UserType, UserGroups } from '../../../types'
import { User as UserService } from '../../../services/User'
import { getUserGroupName } from '../../../utils/helpers'
import { useAppDispatch, useAppSelector } from '../../hooks/store'
import { loadOutlets, loadAutomats } from '../../../store/slices/storage'
import { routes } from '../../../config'
import { route } from '../../../utils/route'

import { ReactComponent as QRIcon } from '../../../assets/icons/user-qr.svg'
import { ReactComponent as AddQRIcon } from '../../../assets/icons/user-add-qr.svg'
import { Layout } from '../../complexes/Layout'
import { Header } from '../../simples/tablePages/Header'
import { TopPanel } from '../../simples/tablePages/TopPanel'
import { ItemsList } from '../../simples/tablePages/ItemsList'
import { UserInviter } from '../../complexes/UserInviter'
import { Confirm } from '../../simples/Confirm'
import { UserAccessKeyEditor } from '../../complexes/UserAccessKeyEditor'
import styles from './CompanyUsers.module.scss'
import { selectAuthUser } from '../../../store/slices/auth'
import { useUserGroup } from '../../hooks/userGroup'

const CompanyUsers: FC = () => {
    const { t } = useTranslation()
    const header = [
        t('628_CompanyUsers_key'),
        t('347_CompanyUsers_fullName'),
        t('348_CompanyUsers_group'),
        t('349_CompanyUsers_email'),
    ]

    const history = useHistory()

    const dispatch = useAppDispatch()
    useEffect(() => {
        dispatch(loadOutlets())
        dispatch(loadAutomats())
    }, [])

    const authUser = useAppSelector(selectAuthUser)
    const authUserGroup = useUserGroup(authUser)

    const [users, setUsers] = useState<Array<UserType>>([])
    const usersCurrent = useRef<Array<UserType>>(users)
    usersCurrent.current = users
    const [loading, setLoading] = useState(false)
    const offset = useRef(0)
    const search = useRef('')
    const has = useRef(true)

    const [showUserInviter, setShowUserInviter] = useState(false)

    const [deletedUserId, setDeletedUserId] = useState(0)

    const load = async () => {
        if (!has.current || loading) {
            return
        }

        setLoading(true)
        const result = await UserService.getList({
            offset: offset.current,
            search: search.current,
        })
        setLoading(false)

        if (!result.length) {
            has.current = false
            return
        }

        const newUsers = [...usersCurrent.current, ...result]
        offset.current = newUsers.length
        setUsers(newUsers)
    }

    const clear = () => {
        has.current = true
        offset.current = 0
        search.current = ''
        setUsers([])
    }

    useEffect(() => {
        load().then()
    }, [])

    const handleEndReached = async () => {
        await load()
    }

    const handleSearch = async (text: string) => {
        clear()
        search.current = text
        await load()
    }

    const startInviteUser = () => {
        setShowUserInviter(true)
    }

    const handleUserInvite = (user: UserType) => {
        setUsers([user, ...users])
        setShowUserInviter(false)
    }

    const startEditUser = (userId: number) => {
        history.push(route(routes.companyUserEdit, { id: userId }))
    }

    const [editedAccessKeyUser, setEditedAccessKeyUser] = useState<UserType>()

    const updateUserFromAccessKeyEditor = (user: UserType) => {
        setUsers(
            users.map((u) => {
                if (u.id === user.id) {
                    return user
                }

                return u
            })
        )

        setEditedAccessKeyUser(user)
    }

    const addAccessKeyToUser = async (user: UserType) => {
        if (user.accessKey) {
            throw new Error('The user already has an access key')
        }

        const accessKey = await UserService.generateAccessKey(user.id)

        const updatedUser = {
            ...user,
            accessKey,
        }

        updateUserFromAccessKeyEditor(updatedUser)

        setEditedAccessKeyUser({
            ...user,
            accessKey,
        })
    }

    const closeAccessKeyEditor = () => {
        setEditedAccessKeyUser(undefined)
    }

    const rows = useMemo(() => {
        return users.map((user) => {
            const email = user.contacts.find(
                (contact) => contact.type === 'email'
            )

            const qrButton = user.accessKey ? (
                <button
                    className={styles.qrButton}
                    onClick={() => setEditedAccessKeyUser(user)}
                    title={t('629_Open_access_key')}
                >
                    <QRIcon />
                </button>
            ) : authUserGroup === UserGroups.Owner ? (
                <button
                    className={styles.qrButton}
                    onClick={() => addAccessKeyToUser(user)}
                    title={t('630_Create_access_key')}
                >
                    <AddQRIcon />
                </button>
            ) : (
                <></>
            )

            return {
                id: user.id,
                values: [
                    qrButton,
                    user.fullName || (
                        <span className={styles.emptyFullName}>Не указано</span>
                    ),
                    getUserGroupName(user.permissions.group, t),
                    email ? email.contact : '',
                ],
            }
        })
    }, [users, t])

    const startDeleteUser = (userId: number) => {
        setDeletedUserId(userId)
    }

    const cancelUserDelete = () => {
        setDeletedUserId(0)
    }

    const confirmUserDelete = async () => {
        setDeletedUserId(0)
        await UserService.delete(deletedUserId)
        setUsers(users.filter((user) => user.id !== deletedUserId))
    }

    return (
        <Layout onEndReached={handleEndReached}>
            <Header text={t('350_CompanyUsers_users')} />
            <TopPanel
                createButtonName={t('351_CompanyUsers_inviteUser')}
                onCreateButtonClick={startInviteUser}
                onSearch={handleSearch}
            />
            <ItemsList
                headers={header}
                rows={rows}
                onEdit={startEditUser}
                onDelete={startDeleteUser}
            />

            {showUserInviter && (
                <UserInviter
                    onInvite={handleUserInvite}
                    onCancel={() => setShowUserInviter(false)}
                />
            )}

            {!!deletedUserId && (
                <Confirm
                    text={t('352_CompanyUsers_confirmDelete')}
                    onConfirm={confirmUserDelete}
                    onCancel={cancelUserDelete}
                />
            )}

            {editedAccessKeyUser && (
                <UserAccessKeyEditor
                    user={editedAccessKeyUser}
                    onUserChange={updateUserFromAccessKeyEditor}
                    onClose={closeAccessKeyEditor}
                />
            )}
        </Layout>
    )
}

export default CompanyUsers

import React, { FC, useEffect } from 'react'
import classNames from 'classnames'
import { ReactComponent as CloseIcon } from '../../../assets/icons/toast-close.svg'
import styles from './Toasts.module.scss'

type Props = {
    message: string
    status?: 'success' | 'error'
    onClose: () => void
}

export const Item: FC<Props> = ({ message, status = 'success', onClose }) => {
    useEffect(() => {
        const timer = setTimeout(() => {
            onClose()
        }, 5000)

        return () => {
            clearTimeout(timer)
        }
    }, [])

    return (
        <div
            className={classNames(styles.item, {
                [styles.error]: status === 'error',
            })}
        >
            <button className={styles.itemClose} onClick={onClose}>
                <CloseIcon />
            </button>

            <div className={styles.itemMessage}>{message}</div>
        </div>
    )
}

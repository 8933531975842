import React, { FC, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AdModule as AdModuleType, UserGroups } from '../../../types'
import { AdModules as AdModulesService } from '../../../services/AdModules'
import { useAppDispatch, useAppSelector } from '../../hooks/store'
import { loadAutomats, selectAutomats } from '../../../store/slices/storage'
import { selectAuthUser } from '../../../store/slices/auth'
import { useUserGroup } from '../../hooks/userGroup'
import { Layout } from '../../complexes/Layout'
import { Header } from '../../simples/tablePages/Header'
import { TopPanel } from '../../simples/tablePages/TopPanel'
import { ItemsList } from '../../simples/tablePages/ItemsList'
import { AdModuleEditor, SourceFormData } from '../../complexes/AdModuleEditor'
import { Confirm } from '../../simples/Confirm'
import styles from './AdModules.module.scss'

const AdModules: FC = () => {
    const { t } = useTranslation()

    const header = [
        '',
        t('308_AdModules_name'),
        t('309_AdModules_automates'),
        t('310_AdModules_showing'),
        t('311_AdModules_time'),
    ]

    const automats = useAppSelector(selectAutomats)
    const user = useAppSelector(selectAuthUser)
    const userGroup = useUserGroup(user)
    const dispatch = useAppDispatch()

    useEffect(() => {
        dispatch(loadAutomats())
    }, [])

    const [adModules, setAdModules] = useState<Array<AdModuleType>>([])
    const adModulesCurrent = useRef<Array<AdModuleType>>(adModules)
    adModulesCurrent.current = adModules
    const [loading, setLoading] = useState(false)
    const offset = useRef(0)
    const search = useRef('')
    const has = useRef(true)

    const [showAdModuleEditor, setShowAdModuleEditor] = useState(false)
    const [adModuleData, setAdModuleData] = useState<SourceFormData>()

    const [deletedAdModuleId, setDeletedAdModuleId] = useState(0)

    const load = async () => {
        if (!has.current || loading) {
            return
        }

        setLoading(true)
        const result = await AdModulesService.getList({
            offset: offset.current,
            search: search.current,
        })
        setLoading(false)

        if (!result.length) {
            has.current = false
            return
        }

        const newAdModules = [...adModulesCurrent.current, ...result]
        offset.current = newAdModules.length
        setAdModules(newAdModules)
    }

    const clear = () => {
        has.current = true
        offset.current = 0
        search.current = ''
        setAdModules([])
    }

    useEffect(() => {
        load().then()
    }, [])

    const handleEndReached = async () => {
        await load()
    }

    const handleSearch = async (text: string) => {
        clear()
        search.current = text
        await load()
    }

    const startCreateAdModule = () => {
        setShowAdModuleEditor(true)
    }

    const startUpdateAdModule = (adModuleId: number) => {
        const adModule = adModules.find((am) => am.id === adModuleId)

        if (!adModule) {
            throw new Error('Ad module not found')
        }

        setAdModuleData({
            id: adModule.id,
            adMaterial: adModule.adMaterial,
            name: adModule.name,
            automatId: adModule.automatId,
            automatsIds: adModule.automatsIds,
            numberOfShowing: adModule.numberOfShowing,
            timeOfShowing: adModule.timeOfShowing,
        })

        setShowAdModuleEditor(true)
    }

    const handleSubmit = (savedAdModule: AdModuleType) => {
        let updated = false
        let newAdModules = adModules.map((am) => {
            if (am.id === savedAdModule.id) {
                updated = true
                return savedAdModule
            }

            return am
        })

        if (!updated) {
            newAdModules = [savedAdModule, ...newAdModules]
        }

        setAdModules(newAdModules)

        closeAdModuleEditor()
    }

    const closeAdModuleEditor = () => {
        setShowAdModuleEditor(false)
        setAdModuleData(undefined)
    }

    const startDeleteAdModule = (adModuleId: number) => {
        setDeletedAdModuleId(adModuleId)
    }

    const cancelAdModuleDelete = () => {
        setDeletedAdModuleId(0)
    }

    const confirmAdModuleDelete = async () => {
        setDeletedAdModuleId(0)
        await AdModulesService.delete(deletedAdModuleId)
        setAdModules(adModules.filter((am) => am.id !== deletedAdModuleId))
    }

    const rows = useMemo(() => {
        return adModules.map((am) => {
            const numberAuto =
                am.automatsIds !== 'all' && am.automatsIds.map((n) => n)

            const automat =
                numberAuto !== false
                    ? automats.filter((item) => numberAuto.includes(item.id))
                    : []

            const nameAutomat = automat.map((f) => f.name)

            const timeOfShowing = am.timeOfShowing
                ? am.timeOfShowing
                      .split(',')
                      .map((hour) => {
                          const startHour = +hour
                          const endHour = startHour + 1
                          return `${startHour}-${endHour}`
                      })
                      .join(', ')
                : ''

            return {
                id: am.id,
                values: [
                    <div
                        className={styles.adModulePhoto}
                        style={{
                            backgroundImage: am.adMaterial.photoPath
                                ? `url("${am.adMaterial.photoPath}")`
                                : undefined,
                        }}
                    />,
                    am.name,
                    automat
                        ? am.automatsIds === 'all'
                            ? 'Все автоматы'
                            : nameAutomat.join()
                        : 'Все автоматы',
                    am.numberOfShowing + '',
                    timeOfShowing,
                ],
            }
        })
    }, [adModules, automats])

    return (
        <Layout onEndReached={handleEndReached}>
            <Header text={t('312_AdModules_advertisingModules')} />
            <TopPanel
                createButtonName={t('313_AdModules_createModule')}
                onCreateButtonClick={startCreateAdModule}
                hideCreateButton={
                    userGroup !== UserGroups.Root &&
                    userGroup !== UserGroups.Owner
                }
                onSearch={handleSearch}
            />

            <ItemsList
                headers={header}
                rows={rows}
                onEdit={startUpdateAdModule}
                onDelete={startDeleteAdModule}
            />

            {showAdModuleEditor && (
                <AdModuleEditor
                    data={adModuleData}
                    onSubmit={handleSubmit}
                    onCancel={closeAdModuleEditor}
                />
            )}

            {!!deletedAdModuleId && (
                <Confirm
                    text={t('314_AdModules_confirmDelete')}
                    onConfirm={confirmAdModuleDelete}
                    onCancel={cancelAdModuleDelete}
                />
            )}
        </Layout>
    )
}

export default AdModules

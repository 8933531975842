import React, { FC, useCallback, useMemo, useRef, useState } from 'react'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import styles from './AdModuleEditor.module.scss'
import { ReactComponent as ArrowIcon } from '../../../assets/icons/select-arrow.svg'

const options = [
    { value: 0, label: '00:00 - 01:00' },
    { value: 1, label: '01:00 - 02:00' },
    { value: 2, label: '02:00 - 03:00' },
    { value: 3, label: '03:00 - 04:00' },
    { value: 4, label: '04:00 - 05:00' },
    { value: 5, label: '05:00 - 06:00' },
    { value: 6, label: '06:00 - 07:00' },
    { value: 7, label: '07:00 - 08:00' },
    { value: 8, label: '08:00 - 09:00' },
    { value: 9, label: '09:00 - 10:00' },
    { value: 10, label: '10:00 - 11:00' },
    { value: 11, label: '11:00 - 12:00' },
    { value: 12, label: '12:00 - 13:00' },
    { value: 13, label: '13:00 - 14:00' },
    { value: 14, label: '14:00 - 15:00' },
    { value: 15, label: '15:00 - 16:00' },
    { value: 16, label: '16:00 - 17:00' },
    { value: 17, label: '17:00 - 18:00' },
    { value: 18, label: '18:00 - 19:00' },
    { value: 19, label: '19:00 - 20:00' },
    { value: 20, label: '20:00 - 21:00' },
    { value: 21, label: '21:00 - 22:00' },
    { value: 22, label: '22:00 - 23:00' },
    { value: 23, label: '23:00 - 00:00' },
]

type Props = {
    value: string
    onChange: (value: string) => void
}

export const TimeSelector: FC<Props> = ({ value, onChange }) => {
    const { t } = useTranslation()
    const hours = value ? value.split(',').map((v) => +v) : []

    const selectRef = useRef<HTMLDivElement>(null)
    const [isOpen, setIsOpen] = useState(false)

    const view = useMemo(() => {
        if (hours.length === 0) {
            return t('26_AdModuleEditor_showTime')
        }

        const view: Array<string> = []

        for (let i = 0; i < options.length; i++) {
            const option = options[i]

            if (hours.indexOf(option.value) !== -1) {
                view.push(option.label)
            }
        }

        return view.join(', ')
    }, [options, hours])

    const clickOption = (value: number) => {
        if (!onChange) {
            return
        }

        if (hours.indexOf(value) !== -1) {
            onChange(hours.filter((h) => h !== value).join(','))
        } else {
            onChange([...hours, value].sort().join(','))
        }
    }

    const toggleOpen = () => {
        if (isOpen) {
            close()
        } else {
            open()
        }
    }

    // Обработчик по клику по любой части документа
    // Если клик происходит внутри селектора, то ничего не делается
    const onHandleClickDocument = useCallback((event: MouseEvent) => {
        if (
            event &&
            event.target &&
            selectRef &&
            selectRef.current &&
            selectRef.current.contains(event.target as HTMLElement)
        ) {
            return
        }

        if (close) {
            close()
        }
    }, [])

    // Функция открытия
    const open = useCallback(() => {
        setIsOpen(true)
        // @ts-ignore
        document.addEventListener('click', onHandleClickDocument)
    }, [setIsOpen])

    // Функция закрытия
    const close = useCallback(() => {
        setIsOpen(false)
        // @ts-ignore
        document.removeEventListener('click', onHandleClickDocument)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setIsOpen])

    const all = useMemo(() => {
        for (let i = 0; i < options.length; i++) {
            if (hours.findIndex((val) => val === options[i].value) === -1) {
                return false
            }
        }
        return true
    }, [hours, options])

    const handleAllClick = (checked: boolean) => {
        // Если галочку ВСЕ убрали, снимается все галочки
        if (!checked) {
            onChange('')
            return
        }

        const val: Array<number> = []
        options.map((option) => {
            val.push(option.value)
        })
        onChange(val.sort().join(','))
    }

    return (
        <div
            className={classNames(styles.timeSelector, {
                [styles.open]: isOpen,
            })}
            ref={selectRef}
        >
            <div className={styles.timeSelectorController} onClick={toggleOpen}>
                <div
                    className={classNames(styles.timeSelectorLabel, {
                        [styles.timeSelectorPlaceholder]: hours.length === 0,
                    })}
                >
                    {view}
                </div>
                <ArrowIcon className={styles.timeSelectorArrow} />
            </div>

            <div className={styles.timeSelectorMenu}>
                <div
                    className={classNames(styles.timeSelectorOption, {
                        [styles.checked]: all,
                    })}
                    onClick={() => handleAllClick(!all)}
                >
                    <div className={styles.timeSelectorCheckbox} />
                    {t('27_AdModuleEditor_all')}
                </div>

                {options.map((option) => (
                    <div
                        className={classNames(styles.timeSelectorOption, {
                            [styles.checked]:
                                hours.indexOf(option.value) !== -1,
                        })}
                        onClick={() => clickOption(option.value)}
                    >
                        <div className={styles.timeSelectorCheckbox} />
                        {option.label}
                    </div>
                ))}
            </div>
        </div>
    )
}

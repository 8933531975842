import React, { FC } from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { route } from '../../../utils/route'
import { routes } from '../../../config'
import { TopsItem } from './TopsItem'
import styles from './WidgetStatisticsTops.module.scss'

type Props = {
    top3Products?: Array<{
        id: number
        name: string
    }>
    top3Automats?: Array<{
        id: number
        address: string
    }>
    top3PromoCodes?: Array<{
        id: number
        promoCode: string
    }>
}

const WidgetStatisticsTops: FC<Props> = ({
    top3Products,
    top3Automats,
    top3PromoCodes,
}) => {
    const { t } = useTranslation()

    return (
        <div className={styles.root}>
            {top3Products && (
                <div className={styles.topsItemWrap}>
                    <TopsItem
                        head={t('300_WidgetStatisticsTops_top3Drinks')}
                        items={top3Products.map((product) => product.name)}
                    />
                </div>
            )}

            <div className={styles.topsItemWrap}>
                {top3Automats ? (
                    <TopsItem
                        head={t('301_WidgetStatisticsTops_top3Automates')}
                        items={top3Automats.map((automat) => (
                            <Link
                                to={route(routes.automatMain, {
                                    id: automat.id,
                                })}
                                className={styles.link}
                            >
                                {automat.address}
                            </Link>
                        ))}
                    />
                ) : top3PromoCodes ? (
                    <TopsItem
                        head={t('302_WidgetStatisticsTops_top3PromoCodes')}
                        items={top3PromoCodes.map((promoCode) => (
                            <Link
                                to={route(routes.promoCodes, {
                                    id: promoCode.id,
                                })}
                                className={styles.link}
                            >
                                {promoCode.promoCode}
                            </Link>
                        ))}
                    />
                ) : null}
            </div>
        </div>
    )
}

export default WidgetStatisticsTops

import _ from 'lodash'
import moment from 'moment'
import {
    AutomatFilterType,
    IconOptions,
} from '../components/complexes/AutomatsFilter/AutomatsFilter'
import { endpoints } from '../constants'
import {
    AddressNoteType,
    AutomatAddressNote,
    AutomatKioskGoodsItem,
    AutomatLog,
    AutomatLogLevels,
    AutomatSnackCell,
    AutomatStatus,
    AutomatStatusNumberType,
    AutomatType,
    DosageItemData,
} from '../types'
import api from '../utils/api'
import { arrayToQuery } from '../utils/helpers'
import { route } from '../utils/route'
import { getTimezone } from '../utils/date'

type GetListParams = {
    search?: string
    outletId?: number
    companyId?: number
    offset?: number
    limit?: number
    status?: Array<'green' | 'yellow' | 'red'>
    filter?: AutomatFilterType | null
}
type GetListResponse = {
    data: Array<AutomatType>
}

type GetItemResponse = {
    data: AutomatType
}

type CreateParams = {
    name: string
    serialNumber: string
    automatModelId: number
    outletId?: number
    address?: string
    longitude?: number
    latitude?: number
    waterVolume?: number
    containers?: Array<{
        automatModelContainerId: number
        tasteId: number
        isActive: boolean
        hasSmallDrink: boolean
    }>
    dosages?: Array<{
        drinkVolume: number
        water: number
        product: number
        conversionFactor: number
        price: number
    }>
    cells?: Array<AutomatSnackCell>
    goods?: Array<AutomatKioskGoodsItem>
}

type CreateResponse = {
    data: AutomatType
}

type UpdateParams = {
    name?: string
    serialNumber?: string
    automatModelId?: number
    outletId?: number
    address?: string
    longitude?: number
    latitude?: number
    waterVolume?: number
    containers?: Array<{
        id?: number
        automatModelContainerId: number
        tasteId: number
        isActive: boolean
        hasSmallDrink: boolean
    }>
    dosages?: Array<DosageItemData>
    automatFillingId?: number
    cells?: Array<AutomatSnackCell>
    goods?: Array<AutomatKioskGoodsItem>
}

type UpdateResponse = {
    data: AutomatType
}

type GetLogsParams = {
    automatId?: number
    level?: AutomatLogLevels[]
    offset?: number
    limit?: number
    automatStatus?: AutomatStatusNumberType[]
}

type ResponseAutomatLog = AutomatLog & {
    timeAt: string
}

type GetLogsResponse = {
    data: Array<ResponseAutomatLog>
}

type SetRemainsParams = {
    cupsRemaining?: number
    waterRemaining?: number
    containers?: Array<{ id: number; remainsVolume: number }>
    cells?: Array<AutomatSnackCell>
    goods?: Array<AutomatKioskGoodsItem>
}

const timezone = getTimezone()

export class Automats {
    /**
     * Метод получения списка автоматов
     * @param params
     * @returns
     */
    static async getLists(
        params: GetListParams = {}
    ): Promise<Array<AutomatType>> {
        const {
            search = '',
            outletId,
            companyId,
            offset = 0,
            limit = 30,
            status = [],
            filter,
        } = params

        const queries = []
        if (search) {
            queries.push(`search=${search}`)
        }
        if (outletId) {
            queries.push(`outletId=${outletId}`)
        }
        if (companyId) {
            queries.push(`companyId=${companyId}`)
        }
        if (offset) {
            queries.push(`offset=${offset}`)
        }
        if (limit) {
            queries.push(`limit=${limit}`)
        }
        if (status.length > 0) {
            queries.push(`status=[${status.map((s) => `"${s}"`)}]`)
        }

        for (let key in filter) {
            const currentFilter: any = filter
            if (currentFilter[key] && key !== 'status') {
                queries.push(`${key}=${currentFilter[key]}`)
            }
            if (
                currentFilter[key] &&
                key === 'status' &&
                currentFilter[key].length > 0
            ) {
                queries.push(
                    `${key}=${JSON.stringify(
                        Array.from(
                            currentFilter[key],
                            (i: IconOptions) => i.value
                        )
                    )}`
                )
            }
        }

        let endpoint = endpoints.getAutomatsList
        if (queries.length > 0) {
            endpoint += `?` + queries.join('&')
        }

        const response = await api.get<GetListResponse>(endpoint)

        if (!response || !response.data || !_.isArray(response.data.data)) {
            return []
        }

        return response.data.data
    }

    static async getItem(id: number): Promise<AutomatType> {
        let endpoint = endpoints.getAutomatsList + '?automatId=' + id

        const response = await api.get<GetItemResponse>(endpoint)

        return response.data.data
    }

    /**
     * Метод создания автомата
     */

    static async create(data: CreateParams): Promise<AutomatType> {
        const response = await api.post<CreateResponse>(
            endpoints.createAutomat,
            {
                ...data,
                containers: data.containers
                    ? JSON.stringify(data.containers)
                    : undefined,
                dosages: data.dosages
                    ? JSON.stringify(data.dosages)
                    : undefined,
                cells: data.cells ? JSON.stringify(data.cells) : undefined,
                goods: data.goods ? JSON.stringify(data.goods) : undefined,
            }
        )

        return response.data.data
    }

    /**
     * Метод обновления автомата
     */
    static async update(id: number, data: UpdateParams): Promise<AutomatType> {
        const response = await api.post<UpdateResponse>(
            route(endpoints.updateAutomat, { id }),
            {
                ...data,
                containers: data.containers
                    ? JSON.stringify(data.containers)
                    : undefined,
                dosages: data.dosages
                    ? JSON.stringify(data.dosages)
                    : undefined,
                cells: data.cells ? JSON.stringify(data.cells) : undefined,
                goods: data.goods ? JSON.stringify(data.goods) : undefined,
            }
        )

        return response.data.data
    }

    /**
     * Метод удаления автомата
     */

    static async deleteAutomat(id: number) {
        const response = await api.delete(
            route(endpoints.deleteAutomat, { id })
        )
        return response.data.data
    }

    static async getLogs(
        params: GetLogsParams = {}
    ): Promise<Array<AutomatLog>> {
        const {
            offset = 0,
            limit = 30,
            automatId,
            automatStatus,
            level,
        } = params

        const queries = [`offset=${offset}`, `limit=${limit}`]

        if (automatStatus) {
            queries.push(`automatStatus=${arrayToQuery(automatStatus)}`)
        }
        if (automatId) {
            queries.push(`automatId=${automatId}`)
        }
        if (level) {
            // @ts-ignore
            queries.push(`level=${arrayToQuery(level)}`)
        }

        const endpoint = endpoints.getAutomatsLogs + '?' + queries.join('&')

        const response = await api.get<GetLogsResponse>(endpoint)

        if (!response || !response.data || !_.isArray(response.data.data)) {
            return []
        }

        return response.data.data.map(Automats._prepareAutomatLog)
    }

    static _prepareAutomatLog(log: ResponseAutomatLog): AutomatLog {
        const timeAt = moment(log.timeAt)

        if (timezone > 0) {
            timeAt.add(timezone, 'hours')
        } else if (timezone < 0) {
            timeAt.subtract(Math.abs(timezone), 'hours')
        }

        return {
            ...log,
            timeAt: timeAt.toDate(),
        }
    }

    static async sendCommand(id: number, command: string): Promise<void> {
        await api.post(route(endpoints.sendCommandToAutomat, { id }), {
            command,
        })
    }

    static async sendRefillApp(
        automatId: number,
        containers: 'all' | Array<number>
    ): Promise<void> {
        await api.post(endpoints.sendRefillAppToAutomat, {
            automatId,
            containers: _.isArray(containers)
                ? containers.join(',')
                : containers,
        })
    }

    /**
     * get notes
     */
    static async getNotes(search: string): Promise<AutomatAddressNote[]> {
        const response = await api.get(
            `${endpoints.getAddressNotes}?address=${search}`
        )
        return response.data.data
    }
    static async getAddressNotes(search: string): Promise<AddressNoteType[]> {
        const response = await api.get(
            `${endpoints.getAddressesNotes}?search=${search}`
        )
        return response.data.data
    }

    static async setRemains(
        automatId: number,
        params: SetRemainsParams
    ): Promise<void> {
        const { cupsRemaining, waterRemaining, containers, cells, goods } =
            params

        const data = new FormData()

        if (cupsRemaining !== undefined) {
            data.set('cupsRemaining', cupsRemaining + '')
        }
        if (waterRemaining !== undefined) {
            data.set('waterRemaining', waterRemaining + '')
        }
        if (containers) {
            data.set('containers', JSON.stringify(containers))
        }
        if (cells) {
            data.set('cells', JSON.stringify(cells))
        }
        if (goods) {
            data.set('goods', JSON.stringify(goods))
        }

        await api.post(
            route(endpoints.setRemainsOfAutomat, { id: automatId }),
            data
        )
    }
}

import React, { FC, useState } from 'react'
import { Layout } from '../../complexes/Layout'
import { SearchSelector } from '../../simples/SearchSelector'
import { OptionSelectorType } from '../../simples/SearchSelector/SearchSelector'
import { Editor } from '../../simples/modalEditor/Editor'
import { Row } from '../../simples/modalEditor/Row'
import { Col } from '../../simples/modalEditor/Col'
import { H } from '../../simples/modalEditor/H'
import { TextField } from '../../simples/TextField'

const demoOptions = [
    { id: 1, value: 'First' },
    { id: 2, value: 'Second' },
    { id: 3, value: 'Third' },
    { id: 4, value: 'Fourth' },
    { id: 5, value: 'Fith' },
    { id: 6, value: 'Six' },
    { id: 7, value: 'Seven' },
    { id: 8, value: 'Eight' },
    { id: 9, value: 'Nine' },
    { id: 10, value: 'Ten' },
    { id: 11, value: 'Eleven' },
    { id: 12, value: 'Twelve' },
]

const Test: FC = () => {
    const [value, setValue] = useState<OptionSelectorType>({ id: 0, value: '' })

    const [data, setData] = useState<Array<OptionSelectorType>>(demoOptions)

    /**
     *
     * @param s строка поиска
     * функция фильтра
     */
    const filterOptions = (s: string) => {
        setData(
            demoOptions.filter((i) =>
                i.value.toLowerCase().includes(s.toLowerCase())
            )
        )
    }
    /**
     *
     * @param e выбранный пункт
     */
    const onHandleClick = (e: OptionSelectorType) => {
        setValue(e)
        // очищаем поиск
        filterOptions('')
    }
    return (
        <Layout>
            {/* <SearchSelector
                onAdd={() => console.log('ADD')}
                onClick={onHandleClick}
                value={value}
                onSearch={filterOptions}
                options={data}
                placeholder={'Не выбрано'}
            /> */}
            <Editor header={'Заголовок модального окна'}>
                <Row>
                    <Col>
                        <TextField />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <TextField />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <TextField />
                    </Col>
                    <Col>
                        <TextField />
                    </Col>
                </Row>

                <H level={2}>Заголовок второго уровня</H>

                <Row>
                    <Col>
                        <TextField />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <TextField />
                    </Col>
                </Row>

                <H level={3}>Заголовок третьего уровня</H>

                <Row>
                    <Col>
                        <TextField />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <TextField />
                    </Col>
                </Row>
            </Editor>
        </Layout>
    )
}

export default Test

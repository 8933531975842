import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { routes } from '../../../config'
import { selectAuthUser } from '../../../store/slices/auth'
import {
    loadKnowledgeBase,
    selectKnowledgeBase,
} from '../../../store/slices/storage'
import { Layout } from '../../complexes/Layout'
import { useAppDispatch, useAppSelector } from '../../hooks/store'
import { Button } from '../../simples/Button'
import { Header } from '../../simples/tablePages/Header'
import parse from 'html-react-parser'
import styles from './KnowledgeBase.module.scss'

const KnowledgeBase: React.FC = () => {
    const dispatch = useAppDispatch()
    const { t } = useTranslation()
    const histori = useHistory()
    const user = useAppSelector(selectAuthUser)
    const { data: knowledgeBase } = useAppSelector(selectKnowledgeBase)

    useEffect(() => {
        dispatch(loadKnowledgeBase())
    }, [])

    const goEdit = () => histori.push(routes.knowledge_base_edit)

    return (
        <Layout>
            <div className={styles.headerWrapper}>
                <Header text={knowledgeBase ? knowledgeBase.title : ''} />

                {user && user.groups.root && (
                    <Button onClick={goEdit}>
                        {t('162_LocalizationEditor_edit')}
                    </Button>
                )}
            </div>

            {knowledgeBase && (
                <div className={styles.content}>
                    {parse(knowledgeBase.content)}
                </div>
            )}
        </Layout>
    )
}

export default KnowledgeBase

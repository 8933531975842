import React, { FC } from 'react'
import { Link } from 'react-router-dom'
import { routes } from '../../../config'
import { LanguageSelect } from '../../simples/LanguageSelect'
import styles from './Header.module.scss'
import { ReactComponent as NewNot } from '../../../assets/icons/newNotification.svg'
import { ReactComponent as Lk } from '../../../assets/icons/lk.svg'
import { useAppSelector } from '../../hooks/store'
import { selectAuthUser } from '../../../store/slices/auth'

type Props = {}

const Header: FC<Props> = () => {
    const user = useAppSelector(selectAuthUser)

    return (
        <header className={styles.header} id="main-header">
            <div className={styles.language}>
                <LanguageSelect />
            </div>
            <div className={styles.notification}>
                <NewNot />
            </div>
            <div className={styles.items}>
                <Link to={routes.profile}>
                    {user && !!user.fullName && <span>{user.fullName}</span>}
                    <Lk />
                </Link>
            </div>
        </header>
    )
}

export default Header

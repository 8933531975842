import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { AutomatModel, AutomatModelTypes, AutomatType } from '../../../../types'
import { SubHeader } from '../SubHeader'
import { General } from './General'
import { Containers } from './Containers'
import { Cells } from './Cells'
import { Goods } from './Goods'

type Props = {
    automat: AutomatType
    model: AutomatModel
    onSubmit: (automat: AutomatType) => void
}

const Settings: FC<Props> = ({ automat, model, onSubmit }) => {
    const { t } = useTranslation()

    return (
        <>
            <SubHeader>{t('51_GeneralSettings_title')}</SubHeader>

            <General automat={automat} onSubmit={onSubmit} model={model} />

            {model.type === AutomatModelTypes.Beverages ? (
                <Containers
                    automat={automat}
                    onSubmit={onSubmit}
                    model={model}
                />
            ) : model.type === AutomatModelTypes.Snack ? (
                <Cells automat={automat} onSubmit={onSubmit} model={model} />
            ) : model.type === AutomatModelTypes.Kiosk ? (
                <Goods automat={automat} onSubmit={onSubmit} model={model} />
            ) : null}
        </>
    )
}

export default Settings

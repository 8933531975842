import React, { ChangeEvent, FC } from 'react'
import { useTranslation } from 'react-i18next'
import { BaseDosagesType, ConditionType } from '../../../types'
import { WrapperBlockWithDelete } from '../../simples/WrapperBlockWithDelete'
import { Row } from '../../simples/modalEditor/Row'
import { Col } from '../../simples/modalEditor/Col'
import { TextField } from '../../simples/TextField'

type Props = {
    number: number
    dosage: BaseDosagesType
    onChange: (dosage: BaseDosagesType) => void
    onDelete: () => void
    showDeleteButton?: boolean
    condition: ConditionType
}

export const DosageItem: FC<Props> = ({
    number,
    dosage,
    onChange,
    onDelete,
    showDeleteButton,
    condition,
}) => {
    const { t } = useTranslation()

    const handleDrinkVolumeChange = (event: ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value.replace(/\D/, '')

        onChange({
            ...dosage,
            drinkVolume: +value,
        })
    }

    const handleWaterChange = (event: ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value //.replace(/\D/, '')

        onChange({
            ...dosage,
            water: +value,
        })
    }

    const handleProductChange = (event: ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value //.replace(/\D/, '')

        onChange({
            ...dosage,
            product: Math.abs(+value),
        })
    }

    const handleConversionFactorChange = (
        event: ChangeEvent<HTMLInputElement>
    ) => {
        const value = event.target.value //.replace(/\D/, '')

        onChange({
            ...dosage,
            conversionFactor: +value,
        })
    }

    return (
        <div>
            <WrapperBlockWithDelete
                showDeleteButton={showDeleteButton}
                deleteLabel={t('272_TasteEditor_deleteDosage')}
                onClick={onDelete}
                title={`${t('273_TasteEditor_dosage')} ${number}`}
            />
            <Row>
                <Col>
                    <TextField
                        label={t('274_TasteEditor_drinkVolume')}
                        placeholder={t('274_TasteEditor_drinkVolume')}
                        value={dosage.drinkVolume || ''}
                        onChange={handleDrinkVolumeChange}
                    />
                </Col>
                <Col>
                    <TextField
                        label={t('275_TasteEditor_water')}
                        type={'number'}
                        placeholder={t('275_TasteEditor_water')}
                        value={dosage.water || ''}
                        onChange={handleWaterChange}
                    />
                </Col>
            </Row>
            <Row>
                <Col>
                    <TextField
                        label={t('276_TasteEditor_product')}
                        type={'number'}
                        placeholder={t('276_TasteEditor_product')}
                        value={dosage.product || ''}
                        onChange={handleProductChange}
                    />
                </Col>

                <Col>
                    <TextField
                        label={
                            condition === ConditionType.SYRUP
                                ? t('627_TasteEditor_amountOfProduct_ml_sec')
                                : t('277_TasteEditor_amountOfProduct')
                        }
                        type={'number'}
                        placeholder={
                            condition === ConditionType.SYRUP
                                ? t('627_TasteEditor_amountOfProduct_ml_sec')
                                : t('277_TasteEditor_amountOfProduct')
                        }
                        value={dosage.conversionFactor || ''}
                        onChange={handleConversionFactorChange}
                    />
                </Col>
            </Row>
        </div>
    )
}

import api from '../utils/api'
import { endpoints } from '../constants'
import { route } from '../utils/route'
import { Brand as BrandType } from '../types'
import _ from 'lodash'

type GetListParams = {
    search?: string
    offset?: number
    limit?: number
}

type GetListResponse = {
    data: Array<BrandType>
}

type CreateBrandParams = {
    mediaKey: string
    name: string
}

type CreateBrandResponse = {
    data: BrandType
}

type UpdateBrandParams = {
    mediaKey?: string
    name?: string
}

type UpdateBrandResponse = {
    data: BrandType
}

const prepareBrand = (brand: BrandType): BrandType => {
    return {
        ...brand,
        name: brand.name,
    }
}

export class Brands {
    static async getList(
        params: GetListParams = {}
    ): Promise<Array<BrandType>> {
        const { search = '', offset = 0, limit = 30 } = params

        const queries = []
        if (search) {
            queries.push(`search=${search}`)
        }
        if (offset) {
            queries.push(`offset=${offset}`)
        }
        if (limit) {
            queries.push(`limit=${limit}`)
        }

        let endpoint = endpoints.getBrandList
        if (queries.length > 0) {
            endpoint += `?` + queries.join('&')
        }

        const response = await api.get<GetListResponse>(endpoint)

        if (
            !response.data ||
            !response.data.data ||
            !_.isArray(response.data.data)
        ) {
            return []
        }

        return response.data.data.map(prepareBrand)
    }

    static async create(params: CreateBrandParams): Promise<BrandType> {
        const { mediaKey, name } = params

        const data = {
            mediaKey,
            name,
        }

        const response = await api.post<CreateBrandResponse>(
            endpoints.createBrand,
            data
        )

        return response.data.data
    }

    static async update(
        id: number,
        params: UpdateBrandParams
    ): Promise<BrandType> {
        const { mediaKey, name } = params

        const data = {
            mediaKey,
            name,
        }

        const response = await api.post<UpdateBrandResponse>(
            route(endpoints.updateBrand, { id }),
            data
        )
        return response.data.data
    }

    static async delete(id: number): Promise<void> {
        await api.delete(route(endpoints.deleteBrand, { id }))
    }
}

import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import en from './langs/en.json'
import ru from './langs/ru.json'
import it from './langs/it.json'

// Собираем переводы
const resources = {
    en: {
        translation: en,
    },
    ru: {
        translation: ru,
    },
    it: {
        translation: it,
    },
}

// Определяем язык браузера
let lang = 'en'
if (navigator.language) {
    if (navigator.language === 'ru-RU') {
        lang = 'ru'
    } else if (
        navigator.language === 'it' ||
        navigator.language === 'ita' ||
        navigator.language === 'it-CH' ||
        navigator.language === 'it-IT'
    ) {
        lang = 'it'
    }
}

i18n.use(initReactI18next).init({
    resources,
    lng: lang,

    interpolation: {
        escapeValue: false,
    },
})

export default i18n

import React, { FC, useMemo } from 'react'
import { Link, NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { routes } from '../../../config'
import { selectAuthUser, signOut } from '../../../store/slices/auth'
import { useAppDispatch, useAppSelector } from '../../hooks/store'
import styles from './LeftSidebar.module.scss'
import { ReactComponent as Logo } from '../../../assets/icons/menu-logo.svg'
import { ReactComponent as RootLogo } from '../../../assets/icons/menu-logo-root.svg'
import { ReactComponent as LogoutIcon } from '../../../assets/icons/logout.svg'
import MenuItemsGroup from './MenuItemsGroup'
import { UserGroups } from '../../../types'
import { useUserGroup } from '../../hooks/userGroup'

type MenuGroupLink = {
    type: 'link'
    url: string
}

type MenuGroupItems = {
    type: 'items'
    items: Array<MenuItem>
}

type MenuGroup = (MenuGroupLink | MenuGroupItems) & {
    title: string
}

type MenuItem = {
    title: string
    url: string
}

const LeftSidebar: FC = () => {
    const { t } = useTranslation()
    const dispatch = useAppDispatch()

    const logout = () => dispatch(signOut())

    const user = useAppSelector(selectAuthUser)

    const userGroup = useUserGroup(user)

    const menuConfig: { [group in UserGroups]: Array<MenuGroup> } = {
        [UserGroups.Root]: [
            {
                type: 'link',
                title: t('483_LeftSidebar_orders'),
                url: routes.orderSchedule,
            },
            {
                type: 'link',
                title: t('531_LeftSidebar_automates'),
                url: routes.automatsPage,
            },
            {
                type: 'link',
                title: t('158_LeftSidebar_outlets'),
                url: routes.outlets,
            },
            {
                type: 'items',
                title: t('143_LeftSidebar_marketing'),
                items: [
                    {
                        title: t('146_LeftSidebar_promotionalMaterials'),
                        url: routes.adMaterials,
                    },
                    { title: t('147_LeftSidebar_news'), url: routes.news },
                    {
                        title: t('148_LeftSidebar_promoCodes'),
                        url: routes.promoCodes,
                    },
                    {
                        title: t('149_LeftSidebar_promotions'),
                        url: routes.promotions,
                    },
                ],
            },
            {
                type: 'items',
                title: t('144_LeftSidebar_baseCreation'),
                items: [
                    { title: t('150_LeftSidebar_brands'), url: routes.brands },
                    {
                        title: t('151_LeftSidebar_products'),
                        url: routes.products,
                    },
                    {
                        title: t('152_LeftSidebar_automateModels'),
                        url: routes.model,
                    },
                    {
                        title: t('153_LeftSidebar_automateFilling'),
                        url: routes.fillings,
                    },
                    {
                        title: t('154_LeftSidebar_localization'),
                        url: routes.languages,
                    },
                ],
            },
            {
                type: 'items',
                title: t('145_LeftSidebar_control'),
                items: [
                    {
                        title: t('155_LeftSidebar_organizations'),
                        url: routes.companies,
                    },
                    {
                        title: t('156_LeftSidebar_mobileAppUsers'),
                        url: routes.mobileUsers,
                    },
                ],
            },
            {
                type: 'link',
                title: t('681_LeftSidebar_knowledge_base'),
                url: routes.knowledge_base,
            },
        ],
        [UserGroups.Owner]: [
            {
                type: 'link',
                title: t('483_LeftSidebar_orders'),
                url: routes.orderSchedule,
            },
            {
                type: 'link',
                title: t('531_LeftSidebar_automates'),
                url: routes.automatsPage,
            },
            {
                type: 'link',
                title: t('158_LeftSidebar_outlets'),
                url: routes.outlets,
            },
            {
                type: 'items',
                title: t('143_LeftSidebar_marketing'),
                items: [
                    {
                        title: t('157_LeftSidebar_advertisingModules'),
                        url: routes.adModules,
                    },
                    {
                        title: t('148_LeftSidebar_promoCodes'),
                        url: routes.promoCodes,
                    },
                ],
            },
            {
                type: 'items',
                title: t('144_LeftSidebar_baseCreation'),
                items: [
                    {
                        title: t('153_LeftSidebar_automateFilling'),
                        url: routes.fillings,
                    },
                    {
                        title: t('626_Goods'),
                        url: routes.goods,
                    },
                ],
            },
            {
                type: 'items',
                title: t('145_LeftSidebar_control'),
                items: [
                    {
                        title: t('159_LeftSidebar_users'),
                        url: routes.companyUsers,
                    },
                    {
                        title: t('160_LeftSidebar_organizationSettings'),
                        url: routes.myCompany,
                    },
                    {
                        title: t('665_LeftSidebar_Webhooks'),
                        url: routes.webHooksSettings,
                    },
                ],
            },
            {
                type: 'link',
                title: t('681_LeftSidebar_knowledge_base'),
                url: routes.knowledge_base,
            },
        ],
        [UserGroups.Club]: [
            {
                type: 'link',
                title: t('483_LeftSidebar_orders'),
                url: routes.orderSchedule,
            },
            {
                type: 'link',
                title: t('531_LeftSidebar_automates'),
                url: routes.automatsPage,
            },
            {
                type: 'link',
                title: t('158_LeftSidebar_outlets'),
                url: routes.outlets,
            },
            {
                type: 'items',
                title: t('143_LeftSidebar_marketing'),
                items: [
                    {
                        title: t('157_LeftSidebar_advertisingModules'),
                        url: routes.adModules,
                    },
                    {
                        title: t('148_LeftSidebar_promoCodes'),
                        url: routes.promoCodes,
                    },
                ],
            },
            {
                type: 'items',
                title: t('145_LeftSidebar_control'),
                items: [
                    {
                        title: t('159_LeftSidebar_users'),
                        url: routes.companyUsers,
                    },
                    {
                        title: t('160_LeftSidebar_organizationSettings'),
                        url: routes.myCompany,
                    },
                ],
            },
            {
                type: 'link',
                title: t('681_LeftSidebar_knowledge_base'),
                url: routes.knowledge_base,
            },
        ],
        [UserGroups.Employer]: [
            {
                type: 'link',
                title: t('681_LeftSidebar_knowledge_base'),
                url: routes.knowledge_base,
            },
        ],
        [UserGroups.Manager]: [
            {
                type: 'link',
                title: t('483_LeftSidebar_orders'),
                url: routes.orderSchedule,
            },
            {
                type: 'link',
                title: t('531_LeftSidebar_automates'),
                url: routes.automatsPage,
            },
            {
                type: 'link',
                title: t('158_LeftSidebar_outlets'),
                url: routes.outlets,
            },
            {
                type: 'items',
                title: t('143_LeftSidebar_marketing'),
                items: [
                    {
                        title: t('157_LeftSidebar_advertisingModules'),
                        url: routes.adModules,
                    },
                    {
                        title: t('148_LeftSidebar_promoCodes'),
                        url: routes.promoCodes,
                    },
                ],
            },
            {
                type: 'items',
                title: t('144_LeftSidebar_baseCreation'),
                items: [
                    {
                        title: t('153_LeftSidebar_automateFilling'),
                        url: routes.fillings,
                    },
                    {
                        title: t('626_Goods'),
                        url: routes.goods,
                    },
                ],
            },
            {
                type: 'items',
                title: t('145_LeftSidebar_control'),
                items: [
                    {
                        title: t('159_LeftSidebar_users'),
                        url: routes.companyUsers,
                    },
                    {
                        title: t('160_LeftSidebar_organizationSettings'),
                        url: routes.myCompany,
                    },
                ],
            },
            {
                type: 'link',
                title: t('681_LeftSidebar_knowledge_base'),
                url: routes.knowledge_base,
            },
        ],
        [UserGroups.Service]: [
            {
                type: 'link',
                title: t('531_LeftSidebar_automates'),
                url: routes.automatsPage,
            },
            {
                type: 'link',
                title: t('158_LeftSidebar_outlets'),
                url: routes.outlets,
            },
            {
                type: 'items',
                title: t('143_LeftSidebar_marketing'),
                items: [
                    {
                        title: t('148_LeftSidebar_promoCodes'),
                        url: routes.promoCodes,
                    },
                ],
            },
            {
                type: 'items',
                title: t('144_LeftSidebar_baseCreation'),
                items: [
                    {
                        title: t('153_LeftSidebar_automateFilling'),
                        url: routes.fillings,
                    },
                    {
                        title: t('626_Goods'),
                        url: routes.goods,
                    },
                ],
            },
            {
                type: 'items',
                title: t('145_LeftSidebar_control'),
                items: [
                    {
                        title: t('160_LeftSidebar_organizationSettings'),
                        url: routes.myCompany,
                    },
                ],
            },
            {
                type: 'link',
                title: t('681_LeftSidebar_knowledge_base'),
                url: routes.knowledge_base,
            },
        ],
        [UserGroups.User]: [],
    }

    return (
        <div className={styles.root}>
            <div className={styles.top}>
                <div className={styles.logoWrap}>
                    <Link to={routes.home}>
                        {userGroup === UserGroups.Root ? (
                            <RootLogo />
                        ) : (
                            <Logo />
                        )}
                    </Link>
                </div>
                <div className={styles.menu}>
                    {userGroup &&
                        menuConfig[userGroup].map((menuGroup, groupIndex) => {
                            if (menuGroup.type === 'link') {
                                return (
                                    <NavLink
                                        to={menuGroup.url}
                                        className={styles.menuLinkGroup}
                                        activeClassName={
                                            styles.menuLinkGroupActive
                                        }
                                        exact
                                        key={`link-${groupIndex}`}
                                    >
                                        {menuGroup.title}
                                    </NavLink>
                                )
                            } else if (menuGroup.type === 'items') {
                                return (
                                    <MenuItemsGroup
                                        title={menuGroup.title}
                                        defaultOpen
                                        key={`items-${groupIndex}`}
                                    >
                                        {menuGroup.items.map(
                                            (menuItem, itemIndex) => (
                                                <div
                                                    className={styles.menuItem}
                                                    key={itemIndex}
                                                >
                                                    <NavLink
                                                        to={menuItem.url}
                                                        className={
                                                            styles.menuItemLink
                                                        }
                                                        activeClassName={
                                                            styles.menuItemActiveLink
                                                        }
                                                        exact
                                                    >
                                                        {menuItem.title}
                                                    </NavLink>
                                                </div>
                                            )
                                        )}
                                    </MenuItemsGroup>
                                )
                            }

                            return null
                        })}
                </div>
            </div>

            <div className={styles.bottom}>
                <button className={styles.logout} onClick={logout}>
                    <LogoutIcon className={styles.logoutIcon} />
                    <span>{t('142_LeftSidebar_exit')}</span>
                </button>
            </div>
        </div>
    )
}

export default LeftSidebar

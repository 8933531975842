import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
    AutomatKioskGoodsItem,
    AutomatModel,
    AutomatModelTypes,
    AutomatType,
} from '../../../../types'

import styles from './KioskRefill.module.scss'
import { useAppSelector } from '../../../hooks/store'
import { selectGoods } from '../../../../store/slices/storage'
import { Automats as AutomatsService } from '../../../../services/Automats'

type Props = {
    automat: AutomatType
    model: AutomatModel
}

const KioskRefill: FC<Props> = ({ automat, model }) => {
    const { t } = useTranslation()
    const goodsData = useAppSelector(selectGoods)

    const [goods, setGoods] = useState<Array<AutomatKioskGoodsItem>>(
        automat.goods || []
    )

    if (model.type !== AutomatModelTypes.Kiosk) {
        throw new Error('Automat model must be Kiosk')
    }

    const handleCellChange = (goodsItemId: number, value: string) => {
        const newRemains = Math.round(Math.abs(+value))
        const newGoods = goods.map((gi) => {
            if (gi.goodsItemId === goodsItemId) {
                return {
                    ...gi,
                    remains: newRemains,
                }
            }

            return gi
        })

        setGoods(newGoods)
    }

    useEffect(() => {
        const saveValue = () => {
            AutomatsService.setRemains(automat.id, {
                goods: goods,
            })
        }

        const timer = setTimeout(saveValue, 500)

        return () => {
            clearTimeout(timer)
        }
    }, [goods])

    return (
        <div className={styles.root}>
            {goods.length > 0 ? (
                <table>
                    <thead>
                        <tr>
                            <th>{t('660_KioskRefill_Goods_item')}</th>
                            <th className={styles.remainValues}>
                                {t('661_KioskRefill_Remains')}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {goods.map((goodsItem, index) => {
                            const goodsItemData = goodsData.find(
                                (gid) => gid.id === goodsItem.goodsItemId
                            )

                            return (
                                <tr key={goodsItem.goodsItemId}>
                                    <td>
                                        {goodsItemData
                                            ? goodsItemData.name
                                            : '--'}
                                    </td>
                                    <td className={styles.remainValues}>
                                        <input
                                            className={styles.input}
                                            type={'number'}
                                            value={goodsItem.remains || ''}
                                            onChange={(event) =>
                                                handleCellChange(
                                                    goodsItem.goodsItemId,
                                                    event.target.value
                                                )
                                            }
                                        />
                                        <span className={styles.unit}>
                                            {t('662_KioskRefill_items')}
                                        </span>
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            ) : (
                <div>{t('663_KioskRefill_No_goods')}</div>
            )}
        </div>
    )
}

export default KioskRefill

import React, { FC, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { routes } from '../../../config'
import { User as UserService } from '../../../services/User'
import { AuthLayout } from '../../complexes/AuthLayout'
import { Spinner } from '../../simples/Spinner'
import { Button } from '../../simples/Button'
import styles from './ConfirmEmail.module.scss'

type RouteParams = {
    key: string
}

const ConfirmEmail: FC = () => {
    const { t } = useTranslation()
    const { key } = useParams<RouteParams>()

    const [checking, setChecking] = useState(true)
    const [success, setSuccess] = useState(false)

    useEffect(() => {
        UserService.confirmEmail(key)
            .then(() => {
                setChecking(false)
                setSuccess(true)
            })
            .catch(() => {
                setChecking(false)
            })
    }, [key])

    const history = useHistory()

    const goToLogin = () => {
        history.push(routes.login)
    }

    const goToRegistration = () => {
        history.push(routes.registration)
    }

    return (
        <AuthLayout>
            <div className={styles.root}>
                {checking ? (
                    <div className={styles.loader}>
                        <Spinner rootClassName={styles.spinner} />
                    </div>
                ) : success ? (
                    <>
                        <p className={styles.message}>
                            {t('353_ConfirmEmail_success')}
                        </p>

                        <Button
                            classNames={{ button: styles.button }}
                            onClick={goToLogin}
                        >
                            {t('354_ConfirmEmail_goToAuthorization')}
                        </Button>
                    </>
                ) : (
                    <>
                        <p className={styles.message}>
                            {t('355_ConfirmEmail_error')}
                        </p>

                        <Button
                            classNames={{ button: styles.button }}
                            onClick={goToRegistration}
                        >
                            {t('356_ConfirmEmail_goToBack')}
                        </Button>
                    </>
                )}
            </div>
        </AuthLayout>
    )
}

export default ConfirmEmail

import api from '../utils/api'
import { endpoints } from '../constants'

type WebHooksData = {
    enableUsePromoCode: boolean
    usePromoCodeUrl: string
}

type WebHooksResponse = {
    data: WebHooksData
}

export class WebHooks {
    static async save(params: WebHooksData): Promise<WebHooksData> {
        const { enableUsePromoCode, usePromoCodeUrl } = params

        const data = new FormData()
        data.set('enableUsePromoCode', enableUsePromoCode ? 'true' : 'false')
        data.set('usePromoCodeUrl', usePromoCodeUrl)

        const response = await api.post<WebHooksResponse>(
            endpoints.saveWebhooks,
            data
        )
        return response.data.data
    }

    static async get(): Promise<WebHooksData> {
        const response = await api.get<WebHooksResponse>(endpoints.getWebhooks)
        return response.data.data
    }
}

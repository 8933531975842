import React, { FC, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useAppDispatch, useAppSelector } from '../../hooks/store'
import { loadModels, selectModels } from '../../../store/slices/storage'
import { AutomatType, AutomatModelTypes } from '../../../types'
import { Automats as AutomatsService } from '../../../services/Automats'
import { Layout } from '../../complexes/Layout'
import { AutomatTabs } from '../../complexes/automat/AutomatTabs'
import { IncomeInfo } from '../../complexes/automat/IncomeInfo'
import { Logs } from '../../complexes/automat/Logs'
import { Remains } from '../../complexes/automat/Remains'
import { SnackRemains } from '../../complexes/automat/SnackRemains'
import { KioskRemains } from '../../complexes/automat/KioskRemains'
import { MonthErrorTime } from '../../complexes/automat/MonthErrorTime'
import { Spinner } from '../../simples/Spinner'
import styles from './AutomatMain.module.scss'

type RouterParams = {
    id: string
}

const AutomatMain: FC = () => {
    const { t } = useTranslation()
    const { id } = useParams<RouterParams>()

    const [loading, setLoading] = useState(false)
    const models = useAppSelector(selectModels)

    const [automat, setAutomat] = useState<AutomatType>()
    useEffect(() => {
        setLoading(true)
        AutomatsService.getItem(+id).then((automat) => {
            setAutomat(automat)
            setLoading(false)
        })
    }, [])

    const dispatch = useAppDispatch()
    useEffect(() => {
        dispatch(loadModels())
    }, [])

    const currentModel = useMemo(() => {
        if (!automat) {
            return undefined
        }

        return models.find((m) => m.id === automat.automatModelId)
    }, [automat, models])

    return (
        <Layout>
            <h1 className={styles.header}>
                {t('323_AutomatMain_automateControl')} "
                {automat ? automat.name : ''}"
            </h1>

            <AutomatTabs automatId={+id} />

            {loading ? (
                <div className={styles.loading}>
                    <Spinner />
                </div>
            ) : (
                automat &&
                currentModel && (
                    <div className={styles.row}>
                        <div className={styles.left}>
                            <div className={styles.incomeInfo}>
                                <IncomeInfo
                                    incomeToday={automat.statistics.incomeToday}
                                    incomeWeek={automat.statistics.incomeWeek}
                                    incomeMonth={automat.statistics.incomeMonth}
                                />
                            </div>

                            <div className={styles.logs}>
                                <MonthErrorTime
                                    hours={automat.errorHoursMonth}
                                />
                            </div>

                            <div className={styles.logs}>
                                <Logs automatId={+id} />
                            </div>
                        </div>

                        <div className={styles.col}>
                            {currentModel.type ===
                            AutomatModelTypes.Beverages ? (
                                <Remains
                                    automat={automat}
                                    model={currentModel}
                                />
                            ) : currentModel.type ===
                              AutomatModelTypes.Snack ? (
                                <SnackRemains
                                    automat={automat}
                                    model={currentModel}
                                />
                            ) : currentModel.type ===
                              AutomatModelTypes.Kiosk ? (
                                <KioskRemains
                                    automat={automat}
                                    model={currentModel}
                                />
                            ) : null}
                        </div>
                    </div>
                )
            )}
        </Layout>
    )
}

export default AutomatMain

import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import styles from './Confirm.module.scss'
import { Modal } from '../Modal'
import { Button, ButtonKind } from '../Button'

type Props = {
    text: string
    onConfirm: () => void
    onCancel: () => void
}

const Confirm: FC<Props> = ({ text, onConfirm, onCancel }) => {
    const { t } = useTranslation()

    return (
        <Modal>
            <div className={styles.root}>
                <div className={styles.text}>{text}</div>

                <div className={styles.buttons}>
                    <Button onClick={onCancel} kind={ButtonKind.Link}>
                        {t('456_Confirm_cancel')}
                    </Button>
                    <Button onClick={onConfirm} kind={ButtonKind.Primary}>
                        {t('457_Confirm_yes')}
                    </Button>
                </div>
            </div>
        </Modal>
    )
}

export default Confirm

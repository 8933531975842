import React, { FC } from 'react'

import styles from './WidgetStatisticsNumbers.module.scss'

type Props = {
    label: string
    value: number
    additionally?: string | JSX.Element
}

export const NumbersItem: FC<Props> = ({ label, value, additionally }) => {
    return (
        <div className={styles.numbersItem}>
            <div className={styles.numbersItemLabel}>{label}</div>

            <div className={styles.numbersItemValue}>{value}</div>

            {!!additionally && (
                <div className={styles.numbersItemAdditionally}>
                    {additionally}
                </div>
            )}
        </div>
    )
}

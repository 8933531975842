import { OptionType } from '../components/simples/Selector'
import { AutomatLogLevels, OrderPriceMethods, UserGroups } from '../types'
import { TFunction } from 'i18next'

export const prepareOptionsFromConst = (i: any): OptionType[] => {
    const options: OptionType[] = []
    Object.keys(i).map((o) => {
        options.push({
            value: +o,
            label: i[o],
        })
    })
    return options
}

/**
 * Получение названия уровня лога, понятный для человека.
 *
 * @param level     Уровень лога
 * @return string   Название уровня лога
 */
export const getLogLevelName = (level: AutomatLogLevels, t: TFunction) => {
    let name = ''

    switch (level) {
        case AutomatLogLevels.Error:
            name = t('561_helpers_error')
            break

        case AutomatLogLevels.Warn:
            name = t('562_helpers_warning')
            break

        case AutomatLogLevels.Info:
            name = t('563_helpers_information')
            break
    }

    return name
}

/**
 * Получение названия метода оплаты
 *
 * @param method    Метод оплаты
 * @return string   Название метода оплаты
 */
export const getPriceMethodName = (method: OrderPriceMethods, t: TFunction) => {
    let name = ''

    switch (method) {
        case OrderPriceMethods.Nothing:
            name = t('556_helpers_withoutPayment')
            break

        case OrderPriceMethods.Cashless:
            name = t('557_helpers_card')
            break

        case OrderPriceMethods.Cash:
            name = t('558_helpers_cash')
            break

        case OrderPriceMethods.RFID:
            name = t('559_helpers_rfid')
            break

        case OrderPriceMethods.QR:
            name = t('560_helpers_qr')
            break
    }

    return name
}

/**
 * Получение названия группы пользователей.
 *
 * @param group     Группа пользователей
 * @return string   Название группы пользователей
 */
export const getUserGroupName = (group: UserGroups, t: TFunction) => {
    let name = ''

    switch (group) {
        case UserGroups.Root:
            name = t('550_helpers_administrator')
            break

        case UserGroups.Owner:
            name = t('551_helpers_organizationOwner')
            break

        case UserGroups.Club:
            name = t('552_helpers_club')
            break

        case UserGroups.Employer:
            name = t('553_helpers_employer')
            break

        case UserGroups.Service:
            name = t('554_helpers_serviceEmployee')
            break

        case UserGroups.Manager:
            name = t('555_helpers_manager')
            break
    }

    return name
}

export const arrayToQuery = (data: Array<number | string>) => {
    let query = ''
    if (data.length > 0) {
        query = '%5B' + data.join('%2C') + '%5D'
    }
    return query
}

export function timeLocalParser(time: string) {
    const date = new Date(time.split(' ').join('T')).getTime()

    const dateTime = new Date(
        date + new Date().getTimezoneOffset() * -60000
    ).toLocaleTimeString()
    const dateDate = new Date(date + new Date().getTimezoneOffset() * -60000)
        .toLocaleDateString()
        .split('/')
        .join('.')
    return `${dateDate} ${dateTime}`
}

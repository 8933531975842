import React, { FC, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AdMaterial as AdMaterialType } from '../../../types'
import { AdMaterials as AdMaterialsService } from '../../../services/AdMaterials'
import { Layout } from '../../complexes/Layout'
import { Header } from '../../simples/tablePages/Header'
import { TopPanel } from '../../simples/tablePages/TopPanel'
import { ItemsGrid } from '../../simples/ItemsGrid'
import {
    AdMaterialEditor,
    SourceFormData,
} from '../../complexes/AdMaterialEditor'
import { Confirm } from '../../simples/Confirm'

const AdMaterials: FC = () => {
    const { t } = useTranslation()

    const [adMaterials, setAdMaterials] = useState<Array<AdMaterialType>>([])
    const adMaterialsCurrent = useRef<Array<AdMaterialType>>(adMaterials)
    adMaterialsCurrent.current = adMaterials
    const [loading, setLoading] = useState(false)
    const offset = useRef(0)
    const search = useRef('')
    const has = useRef(true)

    const [showAdMaterialEditor, setShowAdMaterialEditor] = useState(false)
    const [adMaterialData, setAdMaterialData] = useState<SourceFormData>()

    const [deletedAdMaterialId, setDeletedAdMaterialId] = useState(0)

    const load = async () => {
        if (!has.current || loading) {
            return
        }

        setLoading(true)
        const result = await AdMaterialsService.getList({
            offset: offset.current,
            search: search.current,
        })
        setLoading(false)

        if (!result.length) {
            has.current = false
            return
        }

        const newAdMaterials = [...adMaterialsCurrent.current, ...result]
        offset.current = newAdMaterials.length
        setAdMaterials(newAdMaterials)
    }

    const clear = () => {
        has.current = true
        offset.current = 0
        search.current = ''
        setAdMaterials([])
    }

    useEffect(() => {
        load().then()
    }, [])

    const handleEndReached = async () => {
        await load()
    }

    const handleSearch = async (text: string) => {
        clear()
        search.current = text
        await load()
    }

    const startCreateAdMaterial = () => {
        setShowAdMaterialEditor(true)
    }

    const startUpdateAdMaterial = (adMaterialId: number) => {
        const adMaterial = adMaterials.find((am) => am.id === adMaterialId)

        if (!adMaterial) {
            throw new Error('Ad material not found')
        }

        setAdMaterialData({
            id: adMaterial.id,
            name: adMaterial.name,
            photo: adMaterial.photoPath,
        })
        setShowAdMaterialEditor(true)
    }

    const handleSubmit = (savedAdMaterial: AdMaterialType) => {
        let updated = false
        let newAdMaterials = adMaterials.map((am) => {
            if (am.id === savedAdMaterial.id) {
                updated = true
                return savedAdMaterial
            }

            return am
        })

        if (!updated) {
            newAdMaterials = [savedAdMaterial, ...newAdMaterials]
        }

        setAdMaterials(newAdMaterials)

        closeAdMaterialEditor()
    }

    const closeAdMaterialEditor = () => {
        setShowAdMaterialEditor(false)
        setAdMaterialData(undefined)
    }

    const startDeleteAdMaterial = (adMaterialId: number) => {
        setDeletedAdMaterialId(adMaterialId)
    }

    const cancelAdMaterialDelete = () => {
        setDeletedAdMaterialId(0)
    }

    const confirmAdMaterialDelete = async () => {
        setDeletedAdMaterialId(0)
        await AdMaterialsService.delete(deletedAdMaterialId)
        setAdMaterials(
            adMaterials.filter((am) => am.id !== deletedAdMaterialId)
        )
    }

    return (
        <Layout onEndReached={handleEndReached}>
            <Header text={t('305_AdMaterials_promotionalMaterials')} />
            <TopPanel
                createButtonName={t('306_AdMaterials_createMaterial')}
                onCreateButtonClick={startCreateAdMaterial}
                onSearch={handleSearch}
            />

            <ItemsGrid
                data={adMaterials.map((adMaterial) => ({
                    id: adMaterial.id,
                    title: adMaterial.name,
                    photo: adMaterial.photoPath,
                }))}
                loading={loading}
                onEdit={startUpdateAdMaterial}
                onDelete={startDeleteAdMaterial}
            />

            {showAdMaterialEditor && (
                <AdMaterialEditor
                    data={adMaterialData}
                    onSubmit={handleSubmit}
                    onCancel={closeAdMaterialEditor}
                />
            )}

            {!!deletedAdMaterialId && (
                <Confirm
                    text={t('307_AdMaterials_confirmDelete')}
                    onConfirm={confirmAdMaterialDelete}
                    onCancel={cancelAdMaterialDelete}
                />
            )}
        </Layout>
    )
}

export default AdMaterials

import { useState, useCallback, ChangeEvent } from 'react'
import moment from 'moment'

const checkValid = (value: string, format = 'DD.MM.YYYY') => {
    if (!value) {
        return '303_date_specifyDate'
    }

    if (!moment(value, format).isValid()) {
        return '304_date_incorrectDate'
    }

    return ''
}

export const useDate = (defaultValue: string, format?: string) => {
    const [value, setValue] = useState(defaultValue)
    const [error, setError] = useState(() => checkValid(defaultValue, format))
    const [touched, setTouched] = useState(false)

    const handleChange = useCallback((newValue: string) => {
        setValue(newValue)
        setError(checkValid(newValue, format))
    }, [])

    return {
        value,
        error,
        touched,
        setValue: handleChange,
        setTouched,
    }
}

import React, {
    FC,
    useState,
    useEffect,
    useRef,
    useMemo,
    MouseEvent,
    ChangeEvent,
} from 'react'
import classNames from 'classnames'
import styles from './VerticalSlider.module.scss'

const isError = (value: number, maxValue: number) => {
    return isNaN(value) || value < 0 || value > maxValue
}

type Props = {
    defaultValue: number
    maxValue: number
    onChange: (value: number, isError?: boolean) => void
    number: number
    unit?: string
    round?: boolean
    classes?: {
        sliderIndicator?: string
    }
    disabled?: boolean
}

const VerticalSlider: FC<Props> = ({
    defaultValue,
    maxValue,
    onChange,
    number,
    unit,
    round,
    classes = {},
    disabled = false,
}) => {
    const handlerRef = useRef<HTMLDivElement>(null)
    const indicatorRef = useRef<HTMLDivElement>(null)
    const sliderRef = useRef<HTMLDivElement>(null)

    const [value, setValue] = useState(defaultValue)

    const startDragCounter = useRef(0)
    const [dragging, setDragging] = useState(false)
    const draggingRef = useRef(dragging)
    draggingRef.current = dragging

    const offsetX = useRef(0)

    useEffect(() => {
        const calcValue = (clientY: number) => {
            if (!sliderRef.current) {
                return
            }

            const { bottom, height } = sliderRef.current.getBoundingClientRect()

            let x = bottom - clientY - offsetX.current
            if (x < 0) {
                x = 0
            } else if (x > height) {
                x = height
            }

            let value = (x / height) * maxValue
            if (round) {
                value = Math.round(value)
            }

            return value
        }

        const calcAndSetValue = (clientY: number) => {
            const value = calcValue(clientY)

            if (value !== undefined) {
                setValue(value)
            }
        }

        const handleMouseDown = (event: MouseEvent<HTMLDivElement>) => {
            const { clientY } = event

            if (
                !event ||
                !event.target ||
                !handlerRef ||
                !handlerRef.current ||
                !handlerRef.current.contains(event.target as HTMLElement)
            ) {
                return
            }

            startDragCounter.current = 1

            if (indicatorRef.current) {
                const { top } = indicatorRef.current.getBoundingClientRect()

                offsetX.current = clientY - top
            }
        }

        const handleMouseUp = (event: MouseEvent<HTMLDivElement>) => {
            const { clientY } = event

            if (startDragCounter.current < 1) {
                return
            }

            if (startDragCounter.current > 5) {
                calcAndSetValue(clientY)
                setDragging(false)
            }

            startDragCounter.current = 0
        }

        const handleMouseMove = (event: MouseEvent<HTMLDivElement>) => {
            const { clientY } = event

            if (startDragCounter.current > 5) {
                if (!draggingRef.current) {
                    setDragging(true)
                }

                calcAndSetValue(clientY)
            } else if (startDragCounter.current > 0) {
                startDragCounter.current++
            }
        }

        // @ts-ignore
        document.addEventListener('mousedown', handleMouseDown)
        // @ts-ignore
        document.addEventListener('mouseup', handleMouseUp)
        // @ts-ignore
        document.addEventListener('mousemove', handleMouseMove)

        return () => {
            // @ts-ignore
            document.removeEventListener('mousedown', handleMouseDown)
            // @ts-ignore
            document.removeEventListener('mouseup', handleMouseUp)
            // @ts-ignore
            document.removeEventListener('mousemove', handleMouseMove)
        }
    }, [maxValue, round])

    const heightIndicator = useMemo(() => {
        let height = maxValue > 0 ? (value / maxValue) * 100 : 0

        if (height < 0) {
            height = 0
        } else if (height > 100) {
            height = 100
        }

        return height
    }, [value, maxValue])

    const error = useMemo(() => {
        return isError(value, maxValue)
    }, [value, maxValue])

    const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        const value = +event.target.value
        setValue(value)
    }

    useEffect(() => {
        const saveValue = () => {
            if (!isError(value, maxValue)) {
                onChange(value)
            }
        }

        const timer = setTimeout(saveValue, 500)

        return () => {
            clearTimeout(timer)
        }
    }, [value])

    return (
        <div
            className={classNames(styles.root, {
                [styles.error]: error,
                [styles.disabled]: disabled,
                [styles.ends]: heightIndicator < 10,
            })}
        >
            <div className={styles.number}>{number}</div>
            <div className={styles.slider} ref={sliderRef}>
                <div className={styles.sliderIndicatorContainer}>
                    <div
                        className={classNames(
                            styles.sliderIndicator,
                            classes.sliderIndicator
                        )}
                        style={{
                            height: `${heightIndicator}%`,
                        }}
                        ref={indicatorRef}
                    >
                        <div
                            className={styles.sliderHandler}
                            ref={handlerRef}
                        />
                    </div>
                </div>
            </div>

            <input
                type={'number'}
                value={value + ''}
                onChange={handleInputChange}
                className={styles.input}
            />

            {unit && <div className={styles.unit}>{unit}</div>}

            {dragging && <div className={styles.cursor} />}
        </div>
    )
}

export default VerticalSlider

import React, { FC, useMemo } from 'react'
import { AutomatModelTypes, AutomatType } from '../../../../types'
import { useAppSelector } from '../../../hooks/store'
import { selectModels } from '../../../../store/slices/storage'
import styles from './KioskRemainsForTable.module.scss'

type Row = {
    goodsItemName: string
    remains: number
}

type Props = {
    mashin: AutomatType
}

const KioskRemainsForTable: FC<Props> = ({ mashin }) => {
    const models = useAppSelector(selectModels)

    const rows = useMemo((): Array<Row> => {
        const model = models.find((m) => m.id === mashin.automatModelId)

        if (!model) {
            return []
        }

        if (model.type !== AutomatModelTypes.Kiosk) {
            throw new Error('Automat model must be Kiosk')
        }

        if (!mashin.goods) {
            return []
        }

        const mashinGoods = [...mashin.goods]
            .sort((g1, g2) => g1.remains - g2.remains)
            .slice(0, 3)

        return mashinGoods.map((mg) => {
            return {
                goodsItemName: mg.good ? mg.good.name : '',
                remains: mg.remains,
            }
        })
    }, [mashin, models])

    return (
        <span className={styles.root}>
            {rows.map((row) => (
                <span className={styles.row}>
                    <span className={styles.goodsItemName}>
                        {row.goodsItemName}
                    </span>
                    <span className={styles.remains}>{row.remains}</span>
                </span>
            ))}
        </span>
    )
}

export default KioskRemainsForTable

import React, { ChangeEvent, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { routes } from '../../../config'
import { KnowledgeBaseService } from '../../../services/KnowledgeBase'
import {
    loadKnowledgeBase,
    selectKnowledgeBase,
} from '../../../store/slices/storage'
import { Layout } from '../../complexes/Layout'
import { useAppDispatch, useAppSelector } from '../../hooks/store'
import { Button } from '../../simples/Button'
import { Header } from '../../simples/tablePages/Header'
import JoditEditor from 'jodit-react'
import styles from './KnowledgeBaseEdit.module.scss'
import { TextField } from '../../simples/TextField'

const KnowledgeBaseEdit: React.FC = () => {
    const dispatch = useAppDispatch()
    const { t } = useTranslation()
    const histori = useHistory()
    const { createKnowledgeBase } = KnowledgeBaseService
    const { data: knowledgeBase } = useAppSelector(selectKnowledgeBase)
    const [currentKnowledgeBase, setCurrentKnowledgeBase] =
        useState(knowledgeBase)

    useEffect(() => {
        dispatch(loadKnowledgeBase())
    }, [])

    const config = {
        height: 600,
    }

    const handleChange = (content: any) => {
        setCurrentKnowledgeBase((prev) => ({ ...prev, content: content }))
    }

    const handleChangeTitle = ({ target }: ChangeEvent<HTMLInputElement>) => {
        setCurrentKnowledgeBase((prev) => ({ ...prev, title: target.value }))
    }

    const isValid =
        currentKnowledgeBase &&
        Object.values(currentKnowledgeBase).every(
            (value) => String(value).length > 0
        )

    const onSumbit = async () => {
        if (!isValid) return

        const result = await createKnowledgeBase({ data: currentKnowledgeBase })

        if (result.success) {
            histori.push(routes.knowledge_base)
        }
    }

    return (
        <Layout>
            <Header text={t('682_LeftSidebar_knowledge_base_editor')} />
            <TextField
                classes={{ input: styles.input }}
                value={currentKnowledgeBase ? currentKnowledgeBase.title : ''}
                onChange={handleChangeTitle}
            />

            <div className={styles.editorWrapper}>
                <JoditEditor
                    value={knowledgeBase ? knowledgeBase.content : ''}
                    config={config}
                    onBlur={handleChange}
                    css={styles.editor}
                />
            </div>

            <Button onClick={onSumbit} disabled={!isValid}>
                {t('670_WebHooksSettings_Save')}
            </Button>
        </Layout>
    )
}

export default KnowledgeBaseEdit

import React, { FC } from 'react'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import { SubHeader } from '../SubHeader'
import { CommandItem } from './CommandItem'
import styles from './Command.module.scss'

type Props = {
    automatId: number
}

const Commands: FC<Props> = ({ automatId }) => {
    const { t } = useTranslation()

    return (
        <div className={styles.root}>
            <SubHeader>{t('41_Commands_operationsWithMachine')}</SubHeader>

            <div className={styles.container}>
                <div className={styles.header}>
                    <div className={classNames(styles.th, styles.commandName)}>
                        {t('42_Commands_operation')}
                    </div>
                    <div
                        className={classNames(styles.th, styles.commandStatus)}
                    >
                        {t('43_Commands_status')}
                    </div>
                    <div className={classNames(styles.th, styles.commandRun)}>
                        {t('44_Commands_action')}
                    </div>
                </div>

                <div className={styles.body}>
                    {/*<CommandItem
                        automatId={automatId}
                        label={t('45_Commands_stop')}
                        command={'stop'}
                    />*/}
                    <CommandItem
                        automatId={automatId}
                        label={t('46_Commands_restart')}
                        command={'restart'}
                    />
                </div>
            </div>
        </div>
    )
}

export default Commands

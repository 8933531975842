import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { WebHooks as WebHooksService } from '../../../services/WebHooks'
import { useDispatch } from 'react-redux'
import { showToast } from '../../../store/slices/site'
import { useValidator } from '../../hooks/validator'
import { Layout } from '../../complexes/Layout'
import { Spinner } from '../../simples/Spinner'
import { SubHeader } from '../../complexes/automat/SubHeader'
import { Row } from '../../simples/modalEditor/Row'
import { Col } from '../../simples/modalEditor/Col'
import { CheckBox } from '../../simples/CheckBox'
import { TextField, TextFieldStatus } from '../../simples/TextField'
import { Button } from '../../simples/Button'
import styles from './WebHooksSettings.module.scss'

const usePromoCodeUrlValidateOptions = {
    max: 255,
}

const WebHooksSettings: FC = () => {
    const { t } = useTranslation()
    const dispatch = useDispatch()

    const [loading, setLoading] = useState(true)
    const [enableUsePromoCode, setEnableUsePromoCode] = useState(false)
    const {
        value: usePromoCodeUrl,
        setValue: setUsePromoCodeUrl,
        error: usePromoCodeUrlError,
        setError: setUsePromoCodeUrlError,
        touched: usePromoCodeUrlTouched,
        setTouched: setUsePromoCodeUrlTouched,
    } = useValidator('', usePromoCodeUrlValidateOptions, t)

    useEffect(() => {
        WebHooksService.get().then((result) => {
            setEnableUsePromoCode(result.enableUsePromoCode)
            setUsePromoCodeUrl(result.usePromoCodeUrl)
            setLoading(false)
        })
    }, [])

    const [saving, setSaving] = useState(false)
    const submit = async () => {
        setSaving(true)
        await WebHooksService.save({ enableUsePromoCode, usePromoCodeUrl })
        setSaving(false)

        dispatch(showToast(t('671_WebHooksSettings_Save_success')))
    }

    return (
        <Layout>
            <h1 className={styles.header}>
                {t('666_WebHooksSettings_Header')}
            </h1>

            {loading ? (
                <div className={styles.loading}>
                    <Spinner />
                </div>
            ) : (
                <div className={styles.main}>
                    <SubHeader>
                        {t('667_WebHooksSettings_Use_promocode_header')}
                    </SubHeader>
                    <div className={styles.block}>
                        <Row>
                            <Col>
                                <CheckBox
                                    label={t(
                                        '668_WebHooksSettings_Use_promocode_enable_checkbox_label'
                                    )}
                                    checked={enableUsePromoCode}
                                    onChange={setEnableUsePromoCode}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <div className={styles.blockHeader}>
                                    {t(
                                        '669_WebHooksSettings_Use_promocode_url_label'
                                    )}
                                </div>
                                <TextField
                                    value={usePromoCodeUrl}
                                    onChange={(event) =>
                                        setUsePromoCodeUrl(event.target.value)
                                    }
                                    onBlur={() =>
                                        setUsePromoCodeUrlTouched(true)
                                    }
                                    status={
                                        usePromoCodeUrlError &&
                                        usePromoCodeUrlTouched
                                            ? TextFieldStatus.Error
                                            : undefined
                                    }
                                    note={
                                        usePromoCodeUrlTouched &&
                                        usePromoCodeUrlError
                                            ? usePromoCodeUrlError
                                            : ''
                                    }
                                    disabled={!enableUsePromoCode}
                                    placeholder={
                                        'https://100.100.100.100/?promoCode=[[PROMO_CODE]]'
                                    }
                                />
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <div className={styles.blockNote}>
                                    <p>
                                        {t(
                                            '672_WebHooksSettings_Use_promocode_note_1'
                                        )}
                                    </p>
                                    <p>
                                        {t(
                                            '673_WebHooksSettings_Use_promocode_note_2'
                                        )}
                                    </p>
                                    <ul>
                                        <li>
                                            {t(
                                                '674_WebHooksSettings_Use_promocode_note_li_1'
                                            )}
                                        </li>
                                        <li>
                                            {t(
                                                '675_WebHooksSettings_Use_promocode_note_li_2'
                                            )}
                                        </li>
                                        <li>
                                            {t(
                                                '676_WebHooksSettings_Use_promocode_note_li_3'
                                            )}
                                        </li>
                                        <li>
                                            {t(
                                                '677_WebHooksSettings_Use_promocode_note_li_4'
                                            )}
                                        </li>
                                        <li>
                                            {t(
                                                '678_WebHooksSettings_Use_promocode_note_li_5'
                                            )}
                                        </li>
                                        <li>
                                            {t(
                                                '678_WebHooksSettings_Use_promocode_note_li_6'
                                            )}
                                        </li>
                                        <li>
                                            {t(
                                                '679_WebHooksSettings_Use_promocode_note_li_7'
                                            )}
                                        </li>
                                        <li>
                                            {t(
                                                '680_WebHooksSettings_Use_promocode_note_li_8'
                                            )}
                                        </li>
                                    </ul>
                                    <p>
                                        {t(
                                            '680_WebHooksSettings_Use_promocode_note_3'
                                        )}
                                        ,{' '}
                                        <em>
                                            https://100.100.100.100/?promoCode=[[PROMO_CODE]]&serialNumber=[[SERIAL_NUMBER]]&paymentMethod=[[PAYMENT_METHOD]]&item_name=[[ITEM_NAME]]&buyAt=[[BUY_AT]]&priceSum=[[PRICE_SUM]]&discount=[[DISCOUNT]]
                                        </em>
                                    </p>
                                </div>
                            </Col>
                        </Row>
                    </div>

                    <div className={styles.action}>
                        <Button onClick={submit} loading={saving}>
                            {t('670_WebHooksSettings_Save')}
                        </Button>
                    </div>
                </div>
            )}
        </Layout>
    )
}

export default WebHooksSettings

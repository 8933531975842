import React, { FC, ChangeEvent } from 'react'
import { useTranslation } from 'react-i18next'
import { ComponentType } from '../../../types'
import styles from './TasteEditor.module.scss'
import { Col } from '../../simples/modalEditor/Col'
import { TextField } from '../../simples/TextField'
import { Row } from '../../simples/modalEditor/Row'
import { Selector } from '../../simples/Selector'
import { ReactComponent as DeleteIcon } from '../../../assets/icons/delete.svg'

const units = ['g', 'mg', 'ml', 'kCal', 'msg', 'kj']

type Props = {
    component: ComponentType
    onChange: (component: ComponentType) => void
    onDelete: () => void
}

export const ComponentItem: FC<Props> = ({ component, onChange, onDelete }) => {
    const { t } = useTranslation()

    const handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
        onChange({
            ...component,
            name: event.target.value,
        })
    }

    const handleAmountChange = (event: ChangeEvent<HTMLInputElement>) => {
        // const value = event.target.value.replace(/\D/, '')
        const value = event.target.value //.replace(/\D./, '')

        onChange({
            ...component,
            amount: +value,
        })
    }

    const handleUnitChange = (index: number) => {
        onChange({
            ...component,
            measurementUnit: units[index - 1],
        })
    }

    return (
        <>
            <Row>
                <Col>
                    <TextField
                        placeholder={t('270_TasteEditor_componentItemName')}
                        value={component.name}
                        onChange={handleNameChange}
                    />
                </Col>
                <Col>
                    <Row>
                        <Col>
                            <TextField
                                placeholder={t(
                                    '271_TasteEditor_componentItemAmount'
                                )}
                                type={'number'}
                                value={component.amount}
                                onChange={handleAmountChange}
                            />
                        </Col>
                        <Col css={styles.col}>
                            <Selector
                                options={units.map((unit, i) => ({
                                    value: i + 1,
                                    label: unit,
                                }))}
                                label={''}
                                value={
                                    units.findIndex(
                                        (unit) =>
                                            unit === component.measurementUnit
                                    ) + 1
                                }
                                onChange={handleUnitChange}
                            />

                            <DeleteIcon
                                className={styles.delete}
                                onClick={onDelete}
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>
        </>
    )
}

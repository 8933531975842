import React, { FC, useEffect, useRef, useState, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Company as CompanyService } from '../../../services/Company'
import { Company as CompanyType } from '../../../types'
import { Layout } from '../../complexes/Layout'
import { Header } from '../../simples/tablePages/Header'
import { TopPanel } from '../../simples/tablePages/TopPanel'

import { Confirm } from '../../simples/Confirm'
import { CompanyItem } from '../../simples/tablePages/CompanyItem'
import { useHistory } from 'react-router-dom'
import { routes } from '../../../config'

const Companies: FC = () => {
    const { t } = useTranslation()

    const [companies, setCompanies] = useState<Array<CompanyType>>([])
    const companyCurrent = useRef<Array<CompanyType>>(companies)
    companyCurrent.current = companies
    const [loading, setLoading] = useState(false)
    const offset = useRef(0)
    const search = useRef('')
    const has = useRef(true)

    const history = useHistory()

    const [deletedCompanyId, setDeletedCompanyId] = useState(0)

    const load = async () => {
        if (!has.current || loading) {
            return
        }

        setLoading(true)

        const result = await CompanyService.getList({
            offset: offset.current,
            search: search.current,
        })

        setLoading(false)

        if (!result.length) {
            has.current = false
            return
        }

        offset.current = offset.current + result.length
        setCompanies([...companyCurrent.current, ...result])
    }

    const clear = () => {
        has.current = true
        offset.current = 0
        search.current = ''
        setCompanies([])
    }

    useEffect(() => {
        load().then()
    }, [])

    const handleEndReached = async () => {
        await load()
    }

    const handleSearch = async (text: string) => {
        clear()
        search.current = text
        await load()
    }

    const startCreateCompany = () => {
        history.replace(routes.createCompany)
    }

    const startDeleteCompany = (CompanyId: number) => {
        setDeletedCompanyId(CompanyId)
    }

    const cancelCompanyDelete = () => {
        setDeletedCompanyId(0)
    }

    const confirmCompanyDelete = async () => {
        setDeletedCompanyId(0)
        await CompanyService.delete(deletedCompanyId)
        const t = companies.filter((company) => company.id !== deletedCompanyId)
        setCompanies(
            companies.filter((company) => company.id !== deletedCompanyId)
        )
    }

    return (
        <Layout onEndReached={handleEndReached}>
            <Header text={t('344_Companies_organizations')} />
            <TopPanel
                createButtonName={t('345_Companies_createOrganization')}
                onCreateButtonClick={startCreateCompany}
                onSearch={handleSearch}
            />
            {companies.map((company) => {
                return (
                    <CompanyItem
                        key={company.id}
                        company={company}
                        loading={loading}
                        onDelete={startDeleteCompany}
                    />
                )
            })}

            {!!deletedCompanyId && (
                <Confirm
                    text={t('346_Companies_confirmDelete')}
                    onConfirm={confirmCompanyDelete}
                    onCancel={cancelCompanyDelete}
                />
            )}
        </Layout>
    )
}

export default Companies

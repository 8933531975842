import React, { FC, useMemo } from 'react'
import Chart from 'react-apexcharts'
import ru from 'apexcharts/dist/locales/ru.json'
import en from 'apexcharts/dist/locales/en.json'
import { useTranslation } from 'react-i18next'
import styles from './TimelineChart.module.scss'
import { round } from '../../../utils/math'

const defaultColors = ['#42880F', '#66B32E', '#17171B']

type Props = {
    data: Array<{
        legend: string
        data: Array<{
            label: string
            value: number
        }>
    }>
    activeSeries?: Array<number>
}

const TimelineChart: FC<Props> = ({ data, activeSeries }) => {
    const { i18n } = useTranslation()

    const currentLocale = i18n.language === 'ru' ? 'ru' : 'en'

    const { series, categories, colors } = useMemo(() => {
        const series = data
            .filter(
                (seriesItem, index) =>
                    !activeSeries || activeSeries.indexOf(index) !== -1
            )
            .map((seriesItem) => {
                return {
                    name: seriesItem.legend,
                    data: seriesItem.data.map((dataItem) =>
                        round(dataItem.value, 2)
                    ),
                }
            })

        const categories =
            data.length > 0
                ? data[0].data.map((dataItem) => dataItem.label)
                : []

        const colors = defaultColors.filter(
            (c, index) => !activeSeries || activeSeries.indexOf(index) !== -1
        )

        return {
            series,
            categories,
            colors,
        }
    }, [data, activeSeries])

    // console.log('TimelineChart', { series, colors })

    const options = {
        colors: colors,
        xaxis: {
            categories: categories,
            labels: {
                style: {
                    colors: data.map((d) => '#868686'),
                    fontFamily: 'Roboto',
                    fontSize: '16px',
                },
            },
            axisBorder: {
                color: '#868686',
                offsetY: 5,
            },
            // axisTicks: {
            //     show: false,
            // },
        },
        yaxis: {
            labels: {
                style: {
                    colors: ['#868686'],
                    fontFamily: 'Roboto',
                    fontSize: '16px',
                },
            },
            axisBorder: {
                show: true,
                color: '#868686',
                offsetY: 4,
            },
        },
        grid: {
            show: false,
        },

        plotOptions: {
            bar: {
                borderRadius: 6,
            },
        },
        // tooltip: {
        //     enabled: false,
        // },
        dataLabels: {
            enabled: false,
        },
        chart: {
            parentHeightOffset: 0,
            toolbar: {
                show: false,
            },
            height: '100%',
            animations: {
                enabled: false,
            },
            zoom: {
                enabled: false,
            },
            locales: [en, ru],
            defaultLocale: currentLocale,
        },
        legend: {
            show: false,
        },
        states: {
            hover: {
                filter: {
                    type: 'none',
                },
            },
            active: {
                filter: {
                    type: 'none',
                },
            },
        },
        stroke: {
            curve: 'smooth' as
                | 'smooth'
                | 'straight'
                | 'stepline'
                | ('smooth' | 'straight' | 'stepline')[],
            width: 3,
        },
    }

    return (
        <div className={styles.root}>
            <Chart
                options={options}
                series={series}
                type={'line'}
                height={'100%'}
            />
        </div>
    )
}

export default TimelineChart

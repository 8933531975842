import moment from 'moment'
import api from '../utils/api'
import _ from 'lodash'
import { endpoints } from '../constants'
import { AutomatLog, DashboardData, NewsType } from '../types'
import {
    SalesChartType,
    Data as SalesChartData,
} from '../components/complexes/SalesChart'

type GetSalesScheduleParams = {
    automatId?: number
    type?: SalesChartType
    timezone?: number
}

type GetSalesScheduleResponse = {
    data: Array<{ day: string; income: number }>
    income: {
        today: number
        sevenDays: number
        thirtyDays: number
    }
}

type GetSalesScheduleResult = GetSalesScheduleResponse

type GetDataResponse = {
    baseUrl: string
    data: DashboardData
} | null

export class Dashboard {
    static async getSalesSchedule(
        params: GetSalesScheduleParams = {}
    ): Promise<SalesChartData> {
        const { automatId, type, timezone } = params

        const queries: Array<string> = []

        if (automatId) {
            queries.push(`automatId=${automatId}`)
        }
        if (type) {
            queries.push(`type=${type}`)
        }
        if (timezone) {
            queries.push(`timezone=${timezone}`)
        }

        let endpoint = endpoints.getSalesSchedule
        if (queries.length > 0) {
            endpoint += '?' + queries.join('&')
        }

        const result = await api.get<SalesChartData>(endpoint)

        return result.data
    }

    static async getData(): Promise<DashboardData> {
        const response = await api.get<GetDataResponse>(
            endpoints.getDashboardData
        )

        if (!response.data || !response.data.data) {
            return {}
        }

        const data = response.data.data
        return {
            ...data,
            news: _.isArray(data.news)
                ? data.news.map((newsItem) =>
                      Dashboard._prepareNews(
                          newsItem,
                          response.data ? response.data.baseUrl : ''
                      )
                  )
                : undefined,
        }
    }

    static _prepareNews(newsItem: NewsType, baseUrl: string): NewsType {
        return {
            ...newsItem,
            photoPath: newsItem.photoPath ? baseUrl + newsItem.photoPath : null,
        }
    }
}

import React, { FC, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FillingsService } from '../../../services/Fillings'
import { AutomatFillinsType } from '../../../types'
import { useAppDispatch, useAppSelector } from '../../hooks/store'
import {
    loadBrands,
    loadProducts,
    loadTastes,
    loadModels,
    selectModels,
} from '../../../store/slices/storage'
import { FillingEditor } from '../../complexes/FillingEditor'
import { Layout } from '../../complexes/Layout'
import { Header } from '../../simples/tablePages/Header'
import { ItemsList } from '../../simples/tablePages/ItemsList'
import { TopPanel } from '../../simples/tablePages/TopPanel'
import { Confirm } from '../../simples/Confirm'

const FillingPage: FC = () => {
    const { t } = useTranslation()

    const header = [
        t('371_FillingPage_fillingName'),
        t('373_FillingPage_automateModel'),
    ]

    const dispatch = useAppDispatch()

    useEffect(() => {
        dispatch(loadBrands())
        dispatch(loadProducts())
        dispatch(loadTastes())
        dispatch(loadModels())
    }, [])

    const models = useAppSelector(selectModels)

    const [fillings, setFillings] = useState<AutomatFillinsType[]>([])
    const [loading, setLoading] = useState<boolean>(false)

    const fillingsCurrent = useRef<Array<AutomatFillinsType>>(fillings)
    fillingsCurrent.current = fillings

    const [editFilling, setEditFilling] = useState<
        AutomatFillinsType | undefined
    >()

    const [modal, setModal] = useState<boolean>(false)

    const [deletedFillingId, setDeletedFillingId] = useState(0)

    const offset = useRef(0)
    const search = useRef('')
    const has = useRef(true)

    const rows: any = useMemo(() => {
        return fillings.map((filling) => ({
            id: filling.id,
            values: [
                filling.name,
                models.find((i) => i.id === filling.automatModelId)?.name,
            ],
        }))
    }, [fillings])

    const load = async () => {
        if (!has.current || loading) {
            return
        }

        setLoading(true)
        try {
            const result = await FillingsService.getFillings({
                offset: offset.current,
                search: search.current,
            })
            setLoading(false)
            if (!result.length) {
                has.current = false
                return
            }

            offset.current = offset.current + result.length
            setFillings([...fillingsCurrent.current, ...result])
        } catch (error) {
            console.log(error)
            setLoading(false)
        }
    }

    const clear = () => {
        has.current = true
        offset.current = 0
        search.current = ''
        setFillings([])
    }

    useEffect(() => {
        load().then()
    }, [])

    const handleSearch = async (text: string) => {
        clear()
        search.current = text
        await load()
    }

    const startCreateFilling = () => {
        setEditFilling(undefined)
        setModal(true)
    }

    const startUpdateFilling = (id: number) => {
        const fill = fillings.find((i) => i.id === id)

        if (!fill) {
            return
        }

        setEditFilling(fill)
        setModal(true)
    }

    const handleSubmit = (savedFilling: AutomatFillinsType) => {
        let updated = false
        let newFillings = fillings.map((f) => {
            if (f.id === savedFilling.id) {
                updated = true
                return savedFilling
            }

            return f
        })

        if (!updated) {
            newFillings = [savedFilling, ...fillings]
        }

        setFillings(newFillings)

        closeEditor()
    }

    const closeEditor = () => {
        setEditFilling(undefined)
        setModal(false)
    }

    const startFillingDelete = (id: number) => {
        setDeletedFillingId(id)
    }
    const cancelFillingDelete = () => {
        setDeletedFillingId(0)
    }
    const confirmFillingDelete = async () => {
        setDeletedFillingId(0)
        await FillingsService.deleteFilling(deletedFillingId)
        setFillings(fillings.filter((f) => f.id !== deletedFillingId))
    }
    return (
        <Layout>
            <Header text={t('374_FillingPage_baseCreation')} />
            <TopPanel
                createButtonName={t('375_FillingPage_addFilling')}
                onCreateButtonClick={startCreateFilling}
                onSearch={handleSearch}
            />
            <ItemsList
                headers={header}
                rows={rows}
                loading={loading}
                onEdit={startUpdateFilling}
                onDelete={startFillingDelete}
            />

            {modal && (
                <FillingEditor
                    filling={editFilling}
                    onSubmit={handleSubmit}
                    onClose={closeEditor}
                />
            )}

            {!!deletedFillingId && (
                <Confirm
                    text={t('376_FillingPage_confirmDelete')}
                    onConfirm={confirmFillingDelete}
                    onCancel={cancelFillingDelete}
                />
            )}
        </Layout>
    )
}

export default FillingPage

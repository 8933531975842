import React, { ChangeEvent, FC } from 'react'
import { useTranslation } from 'react-i18next'
import { AutomatContainerTypes, DosageItemData } from '../../../types'
import { WrapperBlockWithDelete } from '../../simples/WrapperBlockWithDelete'
import { Row } from '../../simples/modalEditor/Row'
import { Col } from '../../simples/modalEditor/Col'
import { TextField } from '../../simples/TextField'
import { CheckBox } from '../../simples/CheckBox'
import styles from './AutomatContainerEditor.module.scss'

type FieldNames =
    | 'drinkVolume'
    | 'water'
    | 'product'
    | 'conversionFactor'
    | 'price'

const initDosage: DosageItemData = {
    drinkVolume: 0,
    water: 0,
    product: 0,
    conversionFactor: 0,
    tasteId: 0,
    price: 0,
}

type Props = {
    dosage?: DosageItemData
    number: number
    onChange: (dosage: DosageItemData) => void
    condition: AutomatContainerTypes
    showEnableCheckbox?: boolean
    enabledChecked?: boolean
    onEnableChange?: (checked: boolean) => void
    disabled?: boolean
}

export const DosageItem: FC<Props> = ({
    dosage = initDosage,
    number,
    onChange,
    condition,
    showEnableCheckbox = false,
    enabledChecked,
    onEnableChange,
    disabled = false,
}) => {
    const { t } = useTranslation()

    const handleChange = (
        event: ChangeEvent<HTMLInputElement>,
        field: FieldNames
    ) => {
        const value = event.target.value //.replace(/\D/, '')

        onChange({
            ...dosage,
            [field]: Math.abs(+value),
        })
    }

    return (
        <div>
            <WrapperBlockWithDelete
                title={`${t('96_AutomatContainerEditor_dosage')} ${number}`}
            />

            {showEnableCheckbox && (
                <div className={styles.enableWrapper}>
                    <CheckBox
                        label={'Включить'}
                        checked={enabledChecked}
                        onChange={onEnableChange}
                    />
                </div>
            )}

            {(!showEnableCheckbox || enabledChecked) && (
                <>
                    <Row>
                        <Col>
                            <TextField
                                label={t(
                                    '97_AutomatContainerEditor_drinkVolume'
                                )}
                                placeholder={t(
                                    '97_AutomatContainerEditor_drinkVolume'
                                )}
                                value={dosage.drinkVolume || ''}
                                onChange={(event) =>
                                    handleChange(event, 'drinkVolume')
                                }
                            />
                        </Col>
                        <Col>
                            <TextField
                                label={t('98_AutomatContainerEditor_water')}
                                type={'number'}
                                placeholder={t(
                                    '98_AutomatContainerEditor_water'
                                )}
                                value={dosage.water || ''}
                                onChange={(event) =>
                                    handleChange(event, 'water')
                                }
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <TextField
                                label={t('99_AutomatContainerEditor_product')}
                                type={'number'}
                                placeholder={t(
                                    '99_AutomatContainerEditor_product'
                                )}
                                value={dosage.product || ''}
                                onChange={(event) =>
                                    handleChange(event, 'product')
                                }
                            />
                        </Col>

                        <Col>
                            <TextField
                                label={
                                    condition === AutomatContainerTypes.Syrup
                                        ? t(
                                              '627_TasteEditor_amountOfProduct_ml_sec'
                                          )
                                        : t(
                                              '100_AutomatContainerEditor_amountOfProduct'
                                          )
                                }
                                type={'number'}
                                placeholder={
                                    condition === AutomatContainerTypes.Syrup
                                        ? t(
                                              '627_TasteEditor_amountOfProduct_ml_sec'
                                          )
                                        : t(
                                              '100_AutomatContainerEditor_amountOfProduct'
                                          )
                                }
                                value={dosage.conversionFactor || ''}
                                onChange={(event) =>
                                    handleChange(event, 'conversionFactor')
                                }
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <TextField
                                label={t('101_AutomatContainerEditor_price')}
                                placeholder={t(
                                    '101_AutomatContainerEditor_price'
                                )}
                                type={'number'}
                                value={dosage.price || ''}
                                onChange={(event) =>
                                    handleChange(event, 'price')
                                }
                            />
                        </Col>

                        <Col />
                    </Row>
                </>
            )}
        </div>
    )
}

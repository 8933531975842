import React, { ChangeEvent, FC, useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { User as UserService } from '../../../services/User'
import { routes } from '../../../config'
import { useValidator } from '../../hooks/validator'
import { validatePassword } from '../../../utils/validate'
import { AuthLayout } from '../../complexes/AuthLayout'
import { PassField } from '../../simples/PassField'
import { Button } from '../../simples/Button'
import styles from './SetPassword.module.scss'

type RouteParams = {
    key: string
}

const passwordValidateOptions = {
    required: true,
    max: 100,
    validateFunc: validatePassword,
}

const SetPassword: FC = () => {
    const { t } = useTranslation()
    const { key } = useParams<RouteParams>()
    const history = useHistory()

    const {
        value: password,
        setValue: setPassword,
        error: passwordError,
        touched: passwordTouched,
        setTouched: setPasswordTouched,
    } = useValidator('', passwordValidateOptions, t)
    const {
        value: repeatPassword,
        setValue: setRepeatPassword,
        error: repeatPasswordError,
        setError: setRepeatPasswordError,
        touched: repeatPasswordTouched,
        setTouched: setRepeatPasswordTouched,
    } = useValidator('', passwordValidateOptions, t)

    const isValid = !passwordError && !repeatPasswordError

    const handleRepeatPasswordChange = (
        event: ChangeEvent<HTMLInputElement>
    ) => {
        const value = event.target.value
        setRepeatPassword(value)
        if (value !== password) {
            setRepeatPasswordError(t('475_SetPassword_passwordMismatch'))
        }
    }

    const [loading, setLoading] = useState(false)

    const submit = async () => {
        if (!isValid) {
            throw new Error('Invalid form')
        }

        setLoading(true)
        await UserService.setPassword(key, password)
        setLoading(false)
        history.push(routes.setPasswordSuccess)
    }

    return (
        <AuthLayout>
            <div className={styles.root}>
                <h3 className={styles.header}>
                    {t('450_SetPassword_newPassword')}
                </h3>

                <div className={styles.formGroup}>
                    <PassField
                        placeholder={t('451_SetPassword_password')}
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        onBlur={() => setPasswordTouched(true)}
                        status={
                            passwordError && passwordTouched ? 2 : undefined
                        }
                        note={
                            passwordError && passwordTouched
                                ? passwordError
                                : ''
                        }
                    />
                </div>

                <div className={styles.formGroup}>
                    <PassField
                        placeholder={t('452_SetPassword_repeatPassword')}
                        value={repeatPassword}
                        onChange={handleRepeatPasswordChange}
                        onBlur={() => setRepeatPasswordTouched(true)}
                        status={
                            repeatPasswordError && repeatPasswordTouched
                                ? 2
                                : undefined
                        }
                        note={
                            repeatPasswordError && repeatPasswordTouched
                                ? repeatPasswordError
                                : ''
                        }
                    />
                </div>

                <Button
                    classNames={{ button: styles.button }}
                    disabled={!isValid}
                    loading={loading}
                    onClick={submit}
                >
                    {t('453_SetPassword_updatePassword')}
                </Button>
            </div>
        </AuthLayout>
    )
}

export default SetPassword

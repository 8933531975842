import api from '../utils/api'
import { endpoints } from '../constants'
import { AddressNoteType } from '../types'

type SearchParams = {
    query?: string
    language?: 'ru' | 'en'
}

type SearchResponse = {
    data: Array<AddressNoteType>
}

export class Address {
    static async search(
        params: SearchParams = {}
    ): Promise<Array<AddressNoteType>> {
        const { query, language } = params

        const queries: Array<string> = []
        if (query) {
            queries.push(`query=${query}`)
        }
        if (language) {
            queries.push(`language=${language}`)
        }

        let url = endpoints.searchAddress
        if (queries.length > 0) {
            url += `?${queries.join('&')}`
        }

        const response = await api.get<SearchResponse>(url)
        return response.data.data
    }
}

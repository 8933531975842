import React, { FC } from 'react'
import Chart from 'react-apexcharts'
import { useTranslation } from 'react-i18next'
import styles from './LineChart.module.scss'
import './InnerStyles.scss'
import { round } from '../../../utils/math'

type Props = {
    data: Array<{
        label: string
        value: number
        orders?: number
    }>
}

const LineChart: FC<Props> = ({ data }) => {
    const { t } = useTranslation()
    const options = {
        colors: ['#66b32e'],
        xaxis: {
            categories: data.map((d) => d.label),
            labels: {
                style: {
                    colors: data.map((d) => '#868686'),
                    fontFamily: 'Roboto',
                    fontSize: '16px',
                },
            },
            axisBorder: {
                color: '#868686',
                offsetY: 5,
            },
            // axisTicks: {
            //     show: false,
            // },
        },
        yaxis: {
            labels: {
                style: {
                    colors: ['#868686'],
                    fontFamily: 'Roboto',
                    fontSize: '16px',
                },
            },
            axisBorder: {
                show: true,
                color: '#868686',
                offsetY: 4,
            },
        },
        grid: {
            show: false,
        },

        plotOptions: {
            bar: {
                borderRadius: 6,
            },
        },

        dataLabels: {
            enabled: false,
        },
        chart: {
            parentHeightOffset: 0,
            toolbar: {
                show: false,
            },
            height: '100%',
            animations: {
                enabled: false,
            },
            zoom: {
                enabled: false,
            },
        },
        states: {
            hover: {
                filter: {
                    type: 'none',
                },
            },
            active: {
                filter: {
                    type: 'none',
                },
            },
        },
        stroke: {
            curve: 'smooth' as
                | 'smooth'
                | 'straight'
                | 'stepline'
                | ('smooth' | 'straight' | 'stepline')[],
            width: 3,
        },
    }

    const series = [
        {
            name: t('513_Automats_sales'),
            data: data.map((d) => round(d.value, 2)),
        },
    ]

    return (
        <div className={styles.root}>
            <Chart
                options={{
                    ...options,
                    tooltip: {
                        enabled: true,
                        custom: function ({
                            series,
                            seriesIndex,
                            dataPointIndex,
                            w,
                        }: any) {
                            // CUSTOM tooltip

                            return (
                                '<div class="arrow_box">' +
                                '<div class="arrow_box_header">' +
                                w.globals.categoryLabels[dataPointIndex] +
                                '</div>' +
                                '<div class="labelItem">' +
                                t('546_Chart_series_summ') +
                                ': <span>' +
                                series[seriesIndex][dataPointIndex] +
                                ' </span>' +
                                '</div>' +
                                '<div class="labelItem">' +
                                t('546_Chart_series_sales_count') +
                                ': <span>' +
                                data[dataPointIndex].orders +
                                '</span>' +
                                '</div>' +
                                '<div class="labelItem">' +
                                t('546_Chart_series_about_check') +
                                ': <span>' +
                                Math.round(
                                    series[seriesIndex][dataPointIndex] /
                                        (data[dataPointIndex].orders || 1)
                                ) +
                                '</span>' +
                                '</div>' +
                                '</div>'
                            )
                        },
                    },
                }}
                series={series}
                type={'line'}
                height={'100%'}
            />
        </div>
    )
}

export default LineChart

import React, { FC } from 'react'

import styles from './WidgetStatisticsTops.module.scss'

type Props = {
    head: string
    items: Array<string | JSX.Element>
}

export const TopsItem: FC<Props> = ({ head, items }) => {
    return (
        <div className={styles.topsItem}>
            <div className={styles.topsItemHead}>{head}</div>

            <ol className={styles.topsItemList}>
                {items.map((item) => (
                    <li className={styles.topsItemListItem}>{item}</li>
                ))}
            </ol>
        </div>
    )
}

import React, { FC, useMemo } from 'react'
import Select, { MultiValue } from 'react-select'
import { useTranslation } from 'react-i18next'
import './MultiSelector.scss'

export type Option = {
    value: number
    label: string
}

export type Props = {
    options: Array<Option>
    value: Array<number>
    onChange: (value: Array<number>) => void
    isDisabled?: boolean
    placeholder?: string
}

const MultiSelector: FC<Props> = ({
    options,
    value: _value,
    onChange,
    isDisabled = false,
    placeholder,
}) => {
    const { t } = useTranslation()

    const value = useMemo(() => {
        return _value.map((v) => {
            const option = options.find((o) => o.value === v)

            if (!option) {
                return {
                    value: 0,
                    label: '---',
                }
            }

            return option
        })
    }, [_value, options])

    const handleChange = (values: MultiValue<Option>) => {
        onChange(values.map((v) => v.value))
    }

    return (
        <Select
            classNamePrefix="multi-selector"
            options={options}
            isMulti
            value={value}
            onChange={handleChange}
            isDisabled={isDisabled}
            noOptionsMessage={() => t('477_MultiSelector_noOptions')}
            placeholder={placeholder || t('478_MultiSelector_placeholder')}
        />
    )
}

export default MultiSelector

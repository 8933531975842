import React, { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { AutomatModelTypes, AutomatType } from '../../../../types'
import { useAppSelector } from '../../../hooks/store'
import {
    selectBrands,
    selectProducts,
    selectTastes,
    selectModels,
} from '../../../../store/slices/storage'
import { route } from '../../../../utils/route'
import { routes } from '../../../../config'
import { Button } from '../../../simples/Button'
import styles from './ContainersTable.module.scss'

const buttonClasses = {
    button: styles.button,
}

type Props = {
    automat: AutomatType
}

const ContainersTable: FC<Props> = ({ automat }) => {
    const { t } = useTranslation()
    const history = useHistory()

    const brands = useAppSelector(selectBrands)
    const products = useAppSelector(selectProducts)
    const tastes = useAppSelector(selectTastes)
    const models = useAppSelector(selectModels)

    const { rows } = useMemo<{ rows: Array<Array<string>> }>(() => {
        const rows: Array<Array<string>> = []

        const model = models.find((m) => m.id === automat.automatModelId)

        if (!model) {
            return { rows }
        }

        if (model.type !== AutomatModelTypes.Beverages) {
            throw new Error('Automat must be Beverages type')
        }

        const containers = [...model.containers].sort(
            (containerA, containerB) => {
                return containerA.number - containerB.number
            }
        )

        for (let i = 0; i < containers.length; i++) {
            const container = containers[i]

            let tasteName = ''
            let productName = ''
            let brandName = ''

            const automatContainer = automat.containers.find(
                (c) => c.automatModelContainerId === container.id
            )

            if (automatContainer) {
                const taste = tastes.find(
                    (t) => t.id === automatContainer.tasteId
                )

                if (taste) {
                    tasteName = taste.name

                    const product = products.find(
                        (p) => p.id === taste.productId
                    )

                    if (product) {
                        productName = product.name

                        const brand = brands.find(
                            (b) => b.id === product.brandId
                        )

                        if (brand) {
                            brandName = brand.name
                        }
                    }
                }
            }

            rows.push([
                container.number + '',
                brandName,
                productName,
                tasteName,
            ])
        }

        return { rows }
    }, [automat, brands, products, tastes, models])

    const goToSettings = () => {
        history.push(route(routes.automatSettings, { id: automat.id }))
    }

    const noStr = t('540_ContainersTable_no')

    return (
        <div className={styles.root}>
            <table>
                <tr>
                    <th>{t('541_ContainersTable_nn')}</th>
                    <th>{t('542_ContainersTable_brand')}</th>
                    <th>{t('543_ContainersTable_product')}</th>
                    <th>{t('544_ContainersTable_taste')}</th>
                </tr>

                {rows.map((row) => (
                    <tr>
                        {row.map((coll) => (
                            <td>{coll || noStr}</td>
                        ))}
                    </tr>
                ))}
            </table>

            <div className={styles.buttons}>
                <Button classNames={buttonClasses} onClick={goToSettings}>
                    Изменить
                </Button>
            </div>
        </div>
    )
}

export default ContainersTable

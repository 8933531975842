import React, { FC, useEffect, useMemo, useRef, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useAppSelector, useAppDispatch } from '../../hooks/store'
import { selectModels } from '../../../store/slices/storage'
import { useQuery } from '../../hooks/query'
import { route } from '../../../utils/route'
import { routes } from '../../../config'
import { Automats as AutomatsService } from '../../../services/Automats'

import {
    loadFillings,
    loadModels,
    loadTastes,
    loadBrands,
    loadProducts,
    fetchBaseDosages,
    loadOutlets,
} from '../../../store/slices/storage'
import { AutomatType, UserGroups } from '../../../types'
import { Layout } from '../../complexes/Layout'
import { AutomatEditor } from '../../complexes/AutomatEditor'
import { Confirm } from '../../simples/Confirm'
import { Header } from '../../simples/tablePages/Header'
import { ItemsList } from '../../simples/tablePages/ItemsList'
import { TopPanel } from '../../simples/tablePages/TopPanel'
import { selectAuthUser } from '../../../store/slices/auth'
import { useUserGroup } from '../../hooks/userGroup'

const Automats: FC = () => {
    const { t } = useTranslation()
    const header = [
        t('332_Automats_automateName'),
        t('333_Automats_automateModel'),
        t('334_Automats_serialNumber'),
    ]

    const query = useQuery()
    const outletId = query.get('outletId')

    const history = useHistory()

    const models = useAppSelector(selectModels)

    const user = useAppSelector(selectAuthUser)
    const userGroup = useUserGroup(user)
    const dispatch = useAppDispatch()

    const offset = useRef(0)
    const search = useRef('')
    const has = useRef(true)

    const [mashins, setMashins] = useState<Array<AutomatType>>([])
    const mashinsCurrent = useRef<Array<AutomatType>>(mashins)
    mashinsCurrent.current = mashins
    const [loading, setLoading] = useState<boolean>(false)

    const [showAutomatEditor, setShowAutomatEditor] = useState(false)
    const [deletedBrandId, setDeletedBrandId] = useState(0)

    const load = async () => {
        if (!has.current || loading) {
            return
        }
        setLoading(true)
        try {
            const result = await AutomatsService.getLists({
                offset: offset.current,
                search: search.current,
                outletId: outletId ? +outletId : undefined,
            })
            setLoading(false)
            if (!result.length) {
                has.current = false
                return
            }

            offset.current = offset.current + result.length
            setMashins([...mashinsCurrent.current, ...result])
        } catch (error) {
            console.log(error)
            setLoading(false)
        }
    }

    const clear = () => {
        has.current = true
        offset.current = 0
        search.current = ''
        setMashins([])
    }

    useEffect(() => {
        load().then()
    }, [])

    const rows: any = useMemo(() => {
        return mashins.map((mashin) => {
            const model = models.find((m) => m.id === mashin.automatModelId)

            return {
                id: mashin.id,
                values: [
                    <Link to={route(routes.automatMain, { id: mashin.id })}>
                        {mashin.name}
                    </Link>,
                    model ? model.name : '',
                    mashin.serialNumber,
                ],
            }
        })
    }, [mashins])

    const handleSearch = async (text: string) => {
        clear()
        search.current = text
        await load()
    }

    const startCreateAutomat = () => {
        setShowAutomatEditor(true)
    }

    const startUpdateAutomat = (automatId: number) => {
        history.push(route(routes.automatSettings, { id: automatId }))
    }

    const closeAutomatEditor = () => {
        setShowAutomatEditor(false)
    }

    const handleAutomatSubmit = (automat: AutomatType) => {
        setMashins([automat, ...mashins])
        closeAutomatEditor()
    }

    useEffect(() => {
        dispatch(loadTastes())
        dispatch(loadFillings())
        dispatch(loadModels())
        dispatch(loadProducts())
        dispatch(loadBrands())
        dispatch(fetchBaseDosages())
        dispatch(loadOutlets())
    }, [])

    const cancelBrandDelete = () => {
        setDeletedBrandId(0)
    }

    const confirmBrandDelete = async () => {
        setDeletedBrandId(0)
        await AutomatsService.deleteAutomat(deletedBrandId)
        setMashins(mashins.filter((mashin) => mashin.id !== deletedBrandId))
    }

    return (
        <Layout>
            <Header text={t('335_Automats_baseCreation')} />
            <TopPanel
                createButtonName={t('336_Automats_addAutomate')}
                onCreateButtonClick={startCreateAutomat}
                hideCreateButton={
                    userGroup !== UserGroups.Root &&
                    userGroup !== UserGroups.Owner
                }
                onSearch={handleSearch}
            />

            <ItemsList
                headers={header}
                rows={rows}
                loading={loading}
                onEdit={startUpdateAutomat}
                onDelete={setDeletedBrandId}
            />

            {showAutomatEditor && (
                <AutomatEditor
                    outletId={outletId ? +outletId : 0}
                    onSubmit={handleAutomatSubmit}
                    onClose={closeAutomatEditor}
                />
            )}

            {!!deletedBrandId && (
                <Confirm
                    text={t('337_Automates_confirmDelete')}
                    onConfirm={confirmBrandDelete}
                    onCancel={cancelBrandDelete}
                />
            )}
        </Layout>
    )
}

export default Automats

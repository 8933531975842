import React, { FC, useState, useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { User as UserType } from '../../../types'
import { User as UserService } from '../../../services/User'
import { routes } from '../../../config'
import { useAppDispatch } from '../../hooks/store'
import { loadAutomats, loadOutlets } from '../../../store/slices/storage'
import { Layout } from '../../complexes/Layout'
import { Header } from '../../simples/tablePages/Header'
import { UserEditForm, ResultDataForm } from '../../complexes/UserEditForm'

type RouteParams = {
    id: string
}

const EditMobileUser: FC = () => {
    const { t } = useTranslation()
    const params = useParams<RouteParams>()
    const id = +params.id

    const history = useHistory()

    const [user, setUser] = useState<UserType>()
    const [saving, setSaving] = useState(false)

    const dispatch = useAppDispatch()
    useEffect(() => {
        dispatch(loadAutomats())
        dispatch(loadOutlets())
    }, [])

    useEffect(() => {
        UserService.getItem(id).then((user) => {
            setUser(user)
        })
    }, [id])

    const handleSave = async (
        data: ResultDataForm,
        deletePhoto: boolean = false
    ) => {
        setSaving(true)
        await UserService.updateById(id, {
            fullName: data.name,
            contacts: data.contacts,
            permissions: data.permissions,
        })

        if (data.photo) {
            await UserService.uploadPhotoByUserId(id, data.photo)
        } else if (deletePhoto) {
            await UserService.deletePhotoByUserId(id)
        }
        setSaving(false)

        history.push(routes.mobileUsers)
    }

    const handleCancel = () => {
        history.push(routes.mobileUsers)
    }

    return (
        <Layout>
            <Header text={t('360_EditMobileUser_editUser')} />

            {user && (
                <UserEditForm
                    data={{
                        name: user.fullName,
                        photo: user.photoPath || '',
                        contacts: user.contacts,
                    }}
                    loading={saving}
                    onSave={handleSave}
                    onCancel={handleCancel}
                />
            )}
        </Layout>
    )
}

export default EditMobileUser

import React, { FC } from 'react'
import classNames from 'classnames'
import styles from './CheckBox.module.scss'

type Props = {
    label?: string
    checked?: boolean
    onChange?: (checked: boolean) => void
    disabled?: boolean
    classes?: {
        check?: string
        checkbox?: string
        mark?: string
        label?: string
    }
}

const CheckBox: FC<Props> = ({
    label,
    checked = false,
    onChange,
    disabled = false,
    classes = {},
}) => {
    const handleClick = () => {
        onChange && onChange(!checked)
    }

    return (
        <div
            className={classNames(
                styles.check,
                { [styles.disabled]: disabled },
                classes.check
            )}
            onClick={handleClick}
        >
            <div className={classNames(styles.checkbox, classes.checkbox)}>
                <div
                    className={classNames(styles.mark, classes.mark, {
                        [styles.checked]: checked,
                    })}
                />
            </div>

            {label && (
                <div className={classNames(styles.label, classes.label)}>
                    {label}
                </div>
            )}
        </div>
    )
}

export default CheckBox

import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Promotion as PromotionType } from '../../../types'
import { Promotion as PromotionService } from '../../../services/Promotion'
import { TextField } from '../../simples/TextField'
import { Editor } from '../../simples/modalEditor/Editor'
import { Row } from '../../simples/modalEditor/Row'
import { Col } from '../../simples/modalEditor/Col'
import { SearchSelector } from '../../simples/SearchSelector'
import { selectAutomats, selectProducts } from '../../../store/slices/storage'
import { useDate } from '../../hooks/date'

import styles from './PromotionEditor.module.scss'
import { useAppSelector } from '../../hooks/store'
import { MaskField } from '../../simples/MaskField'
import moment from 'moment'

export type PromotionParamsType = {
    id: number
    productId: number
    automatId: number
    price: number
    startAt: string
    endAt: string
}

const initPromotion: PromotionParamsType = {
    id: 0,
    productId: 0,
    automatId: 0,
    price: 0,
    startAt: moment(new Date()).format('DD.MM.YYYY'),
    endAt: moment(new Date()).format('DD.MM.YYYY'),
}

const editorClasses = {
    modalModal: styles.modal,
    buttons: styles.buttons,
}

type Props = {
    promotion?: PromotionParamsType
    onSubmit: (promotion: PromotionType) => void
    onClose: () => void
}

const PromotionEditor: FC<Props> = ({
    promotion = initPromotion,
    onSubmit,
    onClose,
}) => {
    const { t } = useTranslation()

    const [productId, setProductId] = useState<number>(promotion.productId)
    const [automatId, setAutomatId] = useState<number>(promotion.automatId)
    const automats = useAppSelector(selectAutomats)
    const products = useAppSelector(selectProducts)

    const [price, setPrice] = useState<number>(promotion.price)
    const {
        value: startAt,
        setValue: setStartAt,
        error: startAtError,
        touched: startAtTouched,
        setTouched: setStartAtTouched,
    } = useDate(promotion.startAt)
    const {
        value: endAt,
        setValue: setEndAt,
        error: endAtError,
        touched: endAtTouched,
        setTouched: setEndAtTouched,
    } = useDate(promotion.endAt)
    const [loading, setLoading] = useState(false)
    const [errorMess, setErrorMess] = useState(false)

    useEffect(() => {
        if (!startAt && !endAt) return
        const start = new Date(startAt)
        const end = new Date(endAt)

        if (start > end) {
            setErrorMess(true)
        } else {
            setErrorMess(false)
        }
    }, [startAt, endAt])

    const submit = async () => {
        if (
            !productId ||
            !automatId ||
            !price ||
            !startAt ||
            !!startAtError ||
            !endAt ||
            !!endAtError
        ) {
            throw new Error('There are empty data')
        }

        let savedPromotion: PromotionType

        setLoading(true)

        if (promotion.id > 0) {
            savedPromotion = await PromotionService.update(promotion.id, {
                productId,
                automatId,
                price,
                startAt: moment(startAt, 'DD.MM.YYYY').format('YYYY-MM-DD'),
                endAt: moment(endAt, 'DD.MM.YYYY').format('YYYY-MM-DD'),
            })
        } else {
            savedPromotion = await PromotionService.create({
                productId,
                automatId,
                price,
                startAt: moment(startAt, 'DD.MM.YYYY').format('YYYY-MM-DD'),
                endAt: moment(endAt, 'DD.MM.YYYY').format('YYYY-MM-DD'),
            })
        }

        setLoading(false)
        onSubmit(savedPromotion)
    }

    return (
        <Editor
            header={
                promotion.id > 0
                    ? t('239_PromotionEditor_editPromotion')
                    : t('240_PromotionEditor_createPromotion')
            }
            submitButtonName={
                promotion.id > 0
                    ? t('241_PromotionEditor_save')
                    : t('242_PromotionEditor_create')
            }
            submitDisabled={
                !productId ||
                !automatId ||
                !price ||
                !startAt ||
                !!startAtError ||
                !endAt ||
                !!endAtError
            }
            submitLoading={loading}
            onSubmit={submit}
            onCancel={onClose}
            classes={editorClasses}
        >
            <Row>
                <Col>
                    <SearchSelector
                        value={productId}
                        options={products.map((a) => ({
                            id: a.id,
                            value: a.name,
                        }))}
                        onClick={(option) => setProductId(option.id)}
                        placeholder={t('243_PromotionEditor_selectProduct')}
                    />
                </Col>
                <Col>
                    <TextField
                        placeholder={t('244_PromotionEditor_price')}
                        value={price === 0 ? '' : price}
                        onChange={(event) => setPrice(+event.target.value)}
                    />
                </Col>
            </Row>
            <Row>
                <Col css={styles.dates}>
                    <div className={styles.dateStart}>
                        <MaskField
                            mask={'99.99.9999'}
                            placeholder={t('245_PromotionEditor_start')}
                            value={startAt}
                            onChange={(event) => setStartAt(event.target.value)}
                            onBlur={() => setStartAtTouched(true)}
                            status={
                                startAtError && startAtTouched ? 2 : undefined
                            }
                            note={
                                startAtError && startAtTouched
                                    ? startAtError
                                    : ''
                            }
                        />
                    </div>
                    <div className={styles.dateEnd}>
                        <MaskField
                            mask={'99.99.9999'}
                            placeholder={t('246_PromotionEditor_end')}
                            value={endAt}
                            onChange={(event) => setEndAt(event.target.value)}
                            onBlur={() => setEndAtTouched(true)}
                            status={endAtError && endAtTouched ? 2 : undefined}
                            note={endAtError && endAtTouched ? endAtError : ''}
                        />
                    </div>
                </Col>
                <Col>
                    <SearchSelector
                        value={automatId}
                        options={automats.map((a) => ({
                            id: a.id,
                            value: a.name,
                        }))}
                        onClick={(option) => setAutomatId(option.id)}
                        placeholder={t('247_PromotionEditor_selectAutomate')}
                    />
                </Col>
            </Row>
            {errorMess && (
                <Row>
                    <Col>
                        <div className={styles.errorMess}>
                            {t('248_PromotionEditor_periodError_1')}
                        </div>
                    </Col>
                </Row>
            )}
        </Editor>
    )
}

export default PromotionEditor

import React, { FC, useCallback, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory, Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { checkUser } from '../../../store/slices/auth'
import { TextField, TextFieldStatus } from '../../simples/TextField'
import { Button } from '../../simples/Button'
import { Auth as AuthServices } from '../../../services/Auth'

import styles from './AuthForm.module.scss'
import { PassField } from '../../simples/PassField'
import { useValidator } from '../../hooks/validator'
import { validatePassword, validateLogin } from '../../../utils/validate'

import { routes } from '../../../config'

const buttonClasses = {
    button: styles.button,
}

const loginValidateOptions = {
    required: true,
    max: 100,
    validateFunc: validateLogin,
}

const passwordValidateOptions = {
    required: true,
    validateFunc: validatePassword,
    max: 100,
}

const AuthForm: FC = () => {
    const { t } = useTranslation()

    const {
        value: login,
        setValue: setLogin,
        error: loginError,
        touched: loginTouched,
        setTouched: setLoginTouched,
    } = useValidator('', loginValidateOptions, t)
    const {
        value: password,
        setValue: setPassword,
        error: passwordError,
        touched: passwordTouched,
        setTouched: setPasswordTouched,
    } = useValidator('', passwordValidateOptions, t)
    const [isErLog, setErLog] = useState<boolean>(false)

    const navigation = useHistory()

    const dispatch = useDispatch()

    const handleSubmit = useCallback(
        async (e: React.FormEvent<HTMLFormElement>) => {
            e.preventDefault()

            if (loginError || passwordError) {
                throw new Error('Invalid form')
            }

            try {
                await AuthServices.login(login, password)
                dispatch(checkUser())
                navigation.push(routes.home)
            } catch (e) {
                setErLog(true)
            }
        },
        [dispatch, login, password]
    )

    return (
        <div className={styles.content}>
            <h2 className={styles.auth_h2}>{t('28_AuthForm_authorization')}</h2>

            <form action="/" onSubmit={(e) => handleSubmit(e)}>
                <div className={styles.formGroup}>
                    <TextField
                        value={login}
                        onChange={(e) => setLogin(e.target.value)}
                        onBlur={() => setLoginTouched(true)}
                        placeholder={t('29_AuthForm_loginPlaceholder')}
                        status={
                            isErLog || (loginError && loginTouched)
                                ? TextFieldStatus.Error
                                : undefined
                        }
                        required
                        note={
                            isErLog
                                ? t('30_AuthForm_loginOrPasswordIsIncorrect')
                                : loginError && loginTouched
                                ? loginError
                                : ''
                        }
                    />
                </div>

                <div className={styles.keep_pass_box}>
                    <Link
                        to={routes.recoveryPassword}
                        className={styles.keep_pass}
                        tabIndex={-1}
                    >
                        {t('31_AuthForm_recoveryPassword')}
                    </Link>
                </div>

                <div className={styles.formGroup}>
                    <PassField
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        onBlur={() => setPasswordTouched(true)}
                        placeholder={t('32_AuthForm_passwordPlaceholder')}
                        required
                        status={
                            isErLog || (passwordError && passwordTouched)
                                ? TextFieldStatus.Error
                                : undefined
                        }
                        note={
                            passwordError && passwordTouched
                                ? passwordError
                                : ''
                        }
                    />
                </div>

                <div className={styles.marg_btn}>
                    <Button
                        type={'submit'}
                        classNames={buttonClasses}
                        disabled={!!loginError || !!passwordError}
                    >
                        {t('33_AuthForm_signIn')}
                    </Button>
                    <label>{t('34_AuthForm_or')}</label>
                    <Link to={routes.registration}>
                        {t('35_AuthForm_registerNow')}
                    </Link>
                </div>
            </form>
        </div>
    )
}

export default AuthForm

import { useMemo } from 'react'
import { User as UserType, UserGroups } from '../../types'

export const useUserGroup = (user: UserType | null): UserGroups | undefined => {
    const userGroup = useMemo(() => {
        if (!user) {
            return undefined
        }

        if (user.groups.root) {
            return UserGroups.Root
        } else if (user.groups.owner) {
            return UserGroups.Owner
        } else if (user.groups.club) {
            return UserGroups.Club
        } else if (user.groups.employer) {
            return UserGroups.Employer
        } else if (user.groups.service) {
            return UserGroups.Service
        } else if (user.groups.manager) {
            return UserGroups.Manager
        }

        return undefined
    }, [user])

    return userGroup
}

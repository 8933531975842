import React, { FC } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { routes } from '../../../config'
import { AuthLayout } from '../../complexes/AuthLayout'
import { Button } from '../../simples/Button'
import styles from './RegistrationByInvitationSuccess.module.scss'

const RegistrationByInvitationSuccess: FC = () => {
    const { t } = useTranslation()
    const history = useHistory()

    const goToLogin = () => {
        history.push(routes.login)
    }

    return (
        <AuthLayout>
            <div className={styles.root}>
                <h3 className={styles.header}>
                    {t('446_RegistrationByInvitationSuccess_message')}
                </h3>
                <Button
                    classNames={{ button: styles.button }}
                    onClick={goToLogin}
                >
                    {t('447_RegistrationByInvitationSuccess_goToAuthorization')}
                </Button>
            </div>
        </AuthLayout>
    )
}

export default RegistrationByInvitationSuccess

import React, { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import {
    AutomatContainerTypes,
    AutomatModel,
    AutomatModelTypes,
    AutomatType,
} from '../../../../types'
import { SubHeader } from '../SubHeader'
import { RemainsIndicator } from '../../../simples/RemainsIndicator'
import styles from './Remains.module.scss'
import { MAX_INT_32 } from '../../../../constants'

const waterRemainsIndicatorClasses = {
    scaleIndicator: styles.waterRemainsScaleIndicator,
}

const cupsRemainsIndicatorClasses = {
    scaleIndicator: styles.cupsRemainsScaleIndicator,
}

type Props = {
    automat: AutomatType
    model: AutomatModel
}

const Remains: FC<Props> = ({ automat, model }) => {
    const { t } = useTranslation()

    const { cupsPercent, powderIndicators, syrupIndicators, waterRemaining } =
        useMemo(() => {
            let cupsPercent = 0
            let powderIndicators: Array<{ percent: number }> = []
            let syrupIndicators: Array<{ percent: number }> = []
            let waterRemaining = 0

            if (model.type !== AutomatModelTypes.Beverages) {
                throw new Error('Automat must be Beverages')
            }

            // Вычисление остатков воды

            if (automat.waterVolume === 0) {
                waterRemaining = 0
            } else if (automat.waterVolume === MAX_INT_32) {
                waterRemaining = 100
            } else {
                waterRemaining =
                    automat.waterRemaining > 0
                        ? (automat.waterRemaining / automat.waterVolume) * 100
                        : 0
            }

            // Вычисляем заполненность бункера стаканов
            const maxCups = model.cups || 0
            const cups = automat.cupsRemaining || 0
            cupsPercent = maxCups > 0 ? (cups / maxCups) * 100 : 0

            // Вычисляем заполненность бункеров
            const sortedContainers = model.containers
                .slice()
                .sort((c1, c2) => c1.number - c2.number)

            for (let i = 0; i < sortedContainers.length; i++) {
                const container = sortedContainers[i]

                const volume = container.volume

                const automatContainer = automat.containers.find(
                    (ac) => ac.automatModelContainerId === container.id
                )
                const remainsVolume = automatContainer
                    ? automatContainer.remainsVolume
                    : 0

                if (container.type === AutomatContainerTypes.Powder) {
                    powderIndicators.push({
                        percent: (remainsVolume / volume) * 100,
                    })
                } else if (container.type === AutomatContainerTypes.Syrup) {
                    syrupIndicators.push({
                        percent: (remainsVolume / volume) * 100,
                    })
                }
            }

            return {
                cupsPercent,
                powderIndicators,
                syrupIndicators,
                waterRemaining,
            }
        }, [automat, model])

    return (
        <div className={styles.root}>
            <SubHeader>{t('85_Remains_stockStatus')}</SubHeader>

            <div className={styles.body}>
                <div className={styles.indicatorWrap}>
                    <RemainsIndicator
                        title={t('86_Remains_liquid')}
                        indicators={[
                            {
                                percent: waterRemaining,
                            },
                        ]}
                        classes={waterRemainsIndicatorClasses}
                    />
                </div>

                {powderIndicators.length > 0 && (
                    <div className={styles.indicatorWrap}>
                        <RemainsIndicator
                            title={t('88_Remains_powder')}
                            indicators={powderIndicators}
                        />
                    </div>
                )}

                {syrupIndicators.length > 0 && (
                    <div className={styles.indicatorWrap}>
                        <RemainsIndicator
                            title={t('530_Remains_syrups')}
                            indicators={syrupIndicators}
                        />
                    </div>
                )}

                <div className={styles.indicatorWrap}>
                    <RemainsIndicator
                        title={t('89_Remains_cup')}
                        indicators={[
                            {
                                percent: cupsPercent,
                            },
                        ]}
                        classes={cupsRemainsIndicatorClasses}
                    />
                </div>
            </div>
        </div>
    )
}

export default Remains

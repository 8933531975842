import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Tabs } from '../../../simples/Tabs'
import styles from './AutomatTabs.module.scss'
import { route } from '../../../../utils/route'
import { routes } from '../../../../config'

type Props = {
    automatId: number
}

const AutomatTabs: FC<Props> = ({ automatId }) => {
    const { t } = useTranslation()

    const tabs = [
        {
            label: t('36_AutomatTabs_main'),
            url: route(routes.automatMain, { id: automatId }),
        },
        {
            label: t('37_AutomatTabs_replenishment'),
            url: route(routes.automatRefill, { id: automatId }),
        },
        {
            label: t('38_AutomatTabs_sales'),
            url: route(routes.automatOrders, { id: automatId }),
        },
        {
            label: t('39_AutomatTabs_fiscalization'),
            url: route(routes.automatFiscalization, { id: automatId }),
        },
        {
            label: t('40_AutomatTabs_settings'),
            url: route(routes.automatSettings, { id: automatId }),
        },
    ]

    return (
        <div className={styles.automatTabs}>
            <Tabs items={tabs} />
        </div>
    )
}

export default AutomatTabs

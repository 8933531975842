import React, { FC, useEffect, useMemo, useRef, useState } from 'react'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { PromoCode as PromoCodeService } from '../../../services/PromoCode'
import { NOT_LIMIT_PERIOD } from '../../../constants'
import { Layout } from '../../complexes/Layout'
import { Header } from '../../simples/tablePages/Header'
import { TopPanel } from '../../simples/tablePages/TopPanel'
import { ItemsList } from '../../simples/tablePages/ItemsList'
import { PromoCodeEditor } from '../../complexes/PromoCodeEditor'
import { Confirm } from '../../simples/Confirm'
import { useAppDispatch, useAppSelector } from '../../hooks/store'
import {
    loadAutomats,
    loadTastes,
    selectAutomats,
    selectTastes,
} from '../../../store/slices/storage'
import { Button } from '../../simples/Button'
import { QrCodesModal } from '../../complexes/QrCodesModal'
import styles from './PromoCode.module.scss'
import { selectAuthUser } from '../../../store/slices/auth'
import { useUserGroup } from '../../hooks/userGroup'
import { UserGroups } from '../../../types'

const qrButtonClasses = {
    button: styles.qrButton,
}

export type SourceFormData = {
    id: number
    promoCode: string
    startAt: Date
    endAt: Date
    usageAmount: number
    discount: number
    automatsIds: Array<number> | 'all'
    tastes: string
}

const PromoCode: FC = () => {
    const { t } = useTranslation()
    const header = [
        t('414_PromoCode_promoCode'),
        t('415_PromoCode_startDate'),
        t('416_PromoCode_endDate'),
        t('417_PromoCode_usageAmount'),
        t('418_PromoCode_discount'),
        t('419_PromoCode_automates'),
        t('420_PromoCode_tastes'),
    ]

    const user = useAppSelector(selectAuthUser)
    const userGroup = useUserGroup(user)

    const [promoCodes, setPromoCodes] = useState<Array<SourceFormData>>([])
    const promoCodesCurrent = useRef<Array<SourceFormData>>(promoCodes)
    promoCodesCurrent.current = promoCodes
    const [loading, setLoading] = useState(false)
    const offset = useRef(0)
    const search = useRef('')
    const has = useRef(true)
    const dispatch = useAppDispatch()
    const automats = useAppSelector(selectAutomats)

    const tastes = useAppSelector(selectTastes)

    const [showPromoCodeEditor, setShowPromoCodeEditor] = useState(false)
    const [editedPromoCode, setEditedPromoCode] = useState<SourceFormData>()

    const [deletedPromoCodeId, setDeletedPromoCodeId] = useState(0)

    const load = async () => {
        if (!has.current || loading) {
            return
        }

        setLoading(true)
        const result = await PromoCodeService.getPromoCodes({
            offset: offset.current,
            search: search.current,
        })
        setTimeout(() => {
            setLoading(false)
        }, 2000)

        if (!result.length) {
            has.current = false
            return
        }

        offset.current = offset.current + result.length
        setPromoCodes([...promoCodesCurrent.current, ...result])
    }

    const clear = () => {
        has.current = true
        offset.current = 0
        search.current = ''
        setPromoCodes([])
    }

    useEffect(() => {
        load().then()
        dispatch(loadTastes())
        dispatch(loadAutomats())
    }, [dispatch])

    const handleEndReached = async () => {
        await load()
    }

    const handleSearch = async (text: string) => {
        clear()
        search.current = text
        await load()
    }

    const startCreatePromoCode = () => {
        setShowPromoCodeEditor(true)
    }

    const startUpdatePromoCode = (promoCodeId: number) => {
        const promoCode = promoCodes.find((b) => b.id === promoCodeId)

        if (!promoCode) {
            return
        }

        setShowPromoCodeEditor(true)
        // console.log('promoCode',promoCode);
        // const newArr:any = promoCode.automatsIds
        // promoCode.automatsIds = JSON.parse(newArr)
        setEditedPromoCode(promoCode)
    }

    const handleSubmit = async (
        savedPromoCode: SourceFormData | Array<SourceFormData>
    ) => {
        let newPromoCodes: Array<SourceFormData> = [...promoCodes]

        const addPromoToArray = (promoCode: SourceFormData) => {
            let updated = false

            newPromoCodes = newPromoCodes.map((b) => {
                if (b.id === promoCode.id) {
                    updated = true
                    return promoCode
                }

                return b
            })

            if (!updated) {
                newPromoCodes = [promoCode, ...newPromoCodes]
            }
        }

        if (Array.isArray(savedPromoCode)) {
            savedPromoCode.forEach(addPromoToArray)
        } else {
            addPromoToArray(savedPromoCode)
        }

        setPromoCodes(newPromoCodes)

        closePromoCodeEditor()
    }

    const closePromoCodeEditor = () => {
        setShowPromoCodeEditor(false)
        setEditedPromoCode(undefined)
    }

    const startDeletePromoCode = (promoCodeId: number) => {
        setDeletedPromoCodeId(promoCodeId)
    }

    const cancelPromoCodeDelete = () => {
        setDeletedPromoCodeId(0)
    }

    const confirmPromoCodeDelete = async () => {
        setDeletedPromoCodeId(0)
        await PromoCodeService.delete(deletedPromoCodeId)
        setPromoCodes(
            promoCodes.filter(
                (promoCode) => promoCode.id !== deletedPromoCodeId
            )
        )
    }

    const rows = useMemo(() => {
        if (tastes.length === 0) {
            return []
        }

        return promoCodes.map((promoCode) => {
            let automatsString: string = ''
            if (promoCode.automatsIds === 'all') {
                automatsString = t('421_PromoCode_allAutomates')
            } else {
                promoCode.automatsIds.map((i) => {
                    if (automatsString === '') {
                        automatsString =
                            automatsString +
                            automats.find((ii) => ii.id == i)?.name
                    } else {
                        automatsString =
                            automatsString +
                            ', ' +
                            automats.find((ii) => ii.id == i)?.name
                    }
                })
            }

            let tastesString: string = ''
            if (promoCode.tastes === 'all') {
                tastesString = t('422_PromoCode_allTastes')
            } else {
                promoCode.tastes.split(',').map((i) => {
                    if (tastesString === '') {
                        tastesString =
                            // (tastesString || '') +
                            tastes.find((ii) => ii.id == +i)?.name || ''
                    } else {
                        tastesString =
                            (tastesString || '') +
                                ', ' +
                                tastes.find((ii) => ii.id == +i)?.name || ''
                    }
                })
            }

            return {
                id: promoCode.id,
                values: [
                    // `${}`,
                    `${promoCode.promoCode}`,
                    <span className={styles.dateData}>
                        {moment(promoCode.endAt).isSame(
                            NOT_LIMIT_PERIOD,
                            'date'
                        )
                            ? '-'
                            : moment(promoCode.startAt).format('DD.MM.YYYY')}
                    </span>,
                    <span className={styles.dateData}>
                        {moment(promoCode.endAt).isSame(
                            NOT_LIMIT_PERIOD,
                            'date'
                        )
                            ? '-'
                            : moment(promoCode.endAt).format('DD.MM.YYYY')}
                    </span>,
                    promoCode.usageAmount === -1
                        ? t('423_PromoCode_unlimited')
                        : `${promoCode.usageAmount}`,
                    `${promoCode.discount}`,
                    automatsString, //JSON.stringify(promoCode.automatsIds), //automatsList,
                    tastesString, //promoCode.tastes,
                    // automats.find((i) => i.id === promoCode.automatsIds),
                ],
            }
        })
    }, [promoCodes, t, automats, tastes])

    const handleMassDelete = async (automatsIds: Array<number>) => {
        for (let i = 0; i < automatsIds.length; i++) {
            const automatId = automatsIds[i]
            await PromoCodeService.delete(automatId)
        }

        setPromoCodes(
            promoCodes.filter(
                (promoCode) => automatsIds.indexOf(promoCode.id) === -1
            )
        )
    }

    // QR codes
    const [checkedItems, setCheckedItems] = useState<Array<number>>([])
    const [showQRs, setShowQRs] = useState(false)

    const checkedPromoCodes = useMemo(() => {
        if (checkedItems.length === 0) {
            return []
        }

        const _checkedPromoCodes: Array<SourceFormData> = []

        checkedItems.forEach((id) => {
            const findPromoCode = promoCodes.find(
                (promoCode) => promoCode.id === id
            )

            if (findPromoCode) {
                _checkedPromoCodes.push(findPromoCode)
            }
        })

        return _checkedPromoCodes
    }, [promoCodes, checkedItems])

    const canCreate =
        userGroup === UserGroups.Owner ||
        userGroup === UserGroups.Manager ||
        userGroup === UserGroups.Club ||
        userGroup === UserGroups.Service

    return (
        <Layout onEndReached={handleEndReached}>
            <Header text={t('424_PromoCode_baseCreation')} />
            <TopPanel
                createButtonName={t('425_PromoCode_createPromoCode')}
                onCreateButtonClick={startCreatePromoCode}
                hideCreateButton={!canCreate}
                onSearch={handleSearch}
                rightAddition={
                    <Button
                        classNames={qrButtonClasses}
                        disabled={checkedItems.length === 0}
                        onClick={() => setShowQRs(true)}
                    >
                        QR
                    </Button>
                }
            />
            <ItemsList
                headers={header}
                rows={rows}
                loading={loading}
                onEdit={startUpdatePromoCode}
                onDelete={startDeletePromoCode}
                enableMassDeleting
                onMassDelete={handleMassDelete}
                onCheckedItemsChange={setCheckedItems}
            />

            {showPromoCodeEditor && (
                <PromoCodeEditor
                    promoCode={editedPromoCode}
                    onSubmit={handleSubmit}
                    onClose={closePromoCodeEditor}
                />
            )}

            {!!deletedPromoCodeId && (
                <Confirm
                    text={t('416_PromoCode_confirmDelete')}
                    onConfirm={confirmPromoCodeDelete}
                    onCancel={cancelPromoCodeDelete}
                />
            )}

            {showQRs && (
                <QrCodesModal
                    onClose={() => setShowQRs(false)}
                    promoCodes={checkedPromoCodes}
                />
            )}
        </Layout>
    )
}

export default PromoCode

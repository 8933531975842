import { useState, useCallback } from 'react'
import { TFunction } from 'i18next'
import * as EmailValidator from 'email-validator'

type ValidateOptions = {
    required?: boolean
    min?: number
    max?: number
    email?: boolean
    regExp?: RegExp
    validateFunc?: (value: string, t: TFunction) => string
}

const emailRegExp =
    /^((([0-9A-Za-z]{1}[-0-9A-z\.]{1,}[0-9A-Za-z]{1})|([0-9А-Яа-я]{1}[-0-9А-я\.]{1,}[0-9А-Яа-я]{1}))@([-A-Za-z]{1,}\.){1,2}[-A-Za-z]{2,})$/

const validate = (
    value: string,
    options: ValidateOptions,
    t: TFunction
): string => {
    const {
        required = false,
        min,
        max,
        email = false,
        regExp,
        validateFunc,
    } = options

    if (required && !value) {
        return t('469_validator_pleaseEnterValue')
    }

    if (min !== undefined && value.length < min) {
        return t('470_validator_minimumStringLength', { min })
    }
    if (max !== undefined && value.length > max) {
        return t('471_validator_maximumStringLength', { max })
    }

    if (email && !EmailValidator.validate(value)) {
        return t('472_validator_incorrectEmail')
    }

    if (regExp && !regExp.test(value)) {
        return t('473_validator_incorrectValue')
    }

    if (validateFunc) {
        return validateFunc(value, t)
    }

    return ''
}

export const useValidator = (
    defaultValue: string,
    options: ValidateOptions,
    t: TFunction
) => {
    const [value, setValue] = useState(defaultValue)
    const [error, setError] = useState(() => validate(defaultValue, options, t))
    const [touched, setTouched] = useState(false)

    const handleChange = useCallback((newValue: string) => {
        setValue(newValue)
        setError(validate(newValue, options, t))
    }, [])

    return {
        value,
        error,
        touched,
        setValue: handleChange,
        setError,
        setTouched,
    }
}

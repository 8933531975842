import React, {
    ChangeEvent,
    FC,
    FormEvent,
    MouseEvent,
    useEffect,
    useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { QRCodeCanvas } from 'qrcode.react'
import { UserGroups, UserPermissions } from '../../../types'
import { useValidator } from '../../hooks/validator'
import { validatePassword } from '../../../utils/validate'
import { useAppSelector } from '../../hooks/store'
import { selectAuthUser } from '../../../store/slices/auth'
import { useUserGroup } from '../../hooks/userGroup'
import { ReactComponent as UserAddQRIcon } from '../../../assets/icons/user-add-qr.svg'
import { Row } from '../../simples/modalEditor/Row'
import { Col } from '../../simples/modalEditor/Col'
import { TextField } from '../../simples/TextField'
import { Button, ButtonKind } from '../../simples/Button'
import { ContactsFields, FormContact } from '../ContactsFields'
import { PermissionsForm } from '../PermissionsForm'

import styles from './UserEditForm.module.scss'
import { PassField } from '../../simples/PassField'

export type SourceDataForm = {
    name: string
    photo: string
    // ownerId?: number
    contacts: Array<FormContact>
    permissions?: UserPermissions
    accessKey?: string | null
}

const initDataForm: SourceDataForm = {
    name: '',
    photo: '',
    contacts: [],
}

export type ResultDataForm = {
    name: string
    photo?: File
    ownerId?: number
    contacts: Array<FormContact>
    permissions?: UserPermissions
    password?: string
    accessKey?: string | null
}

const passwordValidateOptions = {
    required: false,
    max: 100,
    validateFunc: validatePassword,
}

type Props = {
    data?: SourceDataForm
    loading?: boolean
    onSave: (company: ResultDataForm, deletePhoto?: boolean) => void
    onCancel: () => void
    showPermissionsFields?: boolean
    showPasswordFields?: boolean
    onOpenAccessKey?: () => void
    onCreateAccessKey?: () => void
}

const UserEditForm: FC<Props> = ({
    data = initDataForm,
    loading,
    onSave,
    onCancel,
    showPermissionsFields = false,
    showPasswordFields = false,
    onOpenAccessKey,
    onCreateAccessKey,
}) => {
    const { t } = useTranslation()

    const user = useAppSelector(selectAuthUser)
    const userGroup = useUserGroup(user)

    const [userCurrent, setUserCurrent] = useState<SourceDataForm>(data)
    const [photo, setPhoto] = useState<File>()
    const [deletePhoto, setDeletePhoto] = useState(false)

    useEffect(() => {
        setUserCurrent(data)
    }, [data])

    const {
        value: password,
        setValue: setPassword,
        error: passwordError,
        touched: passwordTouched,
        setTouched: setPasswordTouched,
    } = useValidator('', passwordValidateOptions, t)
    const {
        value: repeatPassword,
        setValue: setRepeatPassword,
        error: repeatPasswordError,
        setError: setRepeatPasswordError,
        touched: repeatPasswordTouched,
        setTouched: setRepeatPasswordTouched,
    } = useValidator('', passwordValidateOptions, t)

    const isValid = !passwordError && !repeatPasswordError

    const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault()

        if (!isValid) {
            throw new Error('Form is invalid')
        }

        onSave(
            {
                ...userCurrent,
                photo: photo,
                password,
            },
            !photo && deletePhoto
        )
    }

    const handlePermissionsChange = (permissions?: UserPermissions) => {
        setUserCurrent({
            ...userCurrent,
            permissions,
        })
    }

    const handlePhotoDelete = () => {
        setPhoto(undefined)
        setDeletePhoto(true)
    }

    const handleRepeatPasswordChange = (
        event: ChangeEvent<HTMLInputElement>
    ) => {
        const value = event.target.value
        setRepeatPassword(value)
        if (value !== password) {
            setRepeatPasswordError(t('475_SetPassword_passwordMismatch'))
        }
    }

    const handleOpenAccessKeyQRClick = (
        event: MouseEvent<HTMLButtonElement>
    ) => {
        event.preventDefault()
        onOpenAccessKey && onOpenAccessKey()
    }

    const handleCreateAccessKeyClick = (
        event: MouseEvent<HTMLButtonElement>
    ) => {
        event.preventDefault()
        onCreateAccessKey && onCreateAccessKey()
    }

    return (
        <div className={styles.wrapper}>
            <form onSubmit={handleSubmit}>
                {userCurrent.accessKey ? (
                    <div className={styles.info}>
                        <div className={styles.h}>
                            {t('631_Access_key_label')}
                        </div>
                        <div className={styles.accessKeyQR}>
                            <button
                                className={styles.accessKeyQRButton}
                                onClick={handleOpenAccessKeyQRClick}
                                title={t('629_Open_access_key')}
                            >
                                <QRCodeCanvas
                                    value={userCurrent.accessKey}
                                    size={128}
                                />
                            </button>
                        </div>
                    </div>
                ) : userGroup === UserGroups.Owner ? (
                    <div className={styles.info}>
                        <div className={styles.h}>
                            {t('631_Access_key_label')}
                        </div>
                        <div className={styles.accessKeyQR}>
                            <button
                                className={styles.accessKeyQRButton}
                                onClick={handleCreateAccessKeyClick}
                                title={t('630_Create_access_key')}
                            >
                                <UserAddQRIcon />
                            </button>
                        </div>
                    </div>
                ) : null}

                <div className={styles.info}>
                    <div className={styles.h}>
                        {t('278_UserEditForm_contactInformation')}
                    </div>
                    <Row>
                        <Col>
                            <TextField
                                value={userCurrent.name || ''}
                                onChange={(e) => {
                                    setUserCurrent({
                                        ...userCurrent,
                                        name: e.target.value,
                                    })
                                }}
                                placeholder={t('279_UserEditForm_fullName')}
                            />
                        </Col>
                    </Row>

                    <ContactsFields
                        contacts={userCurrent.contacts}
                        onChange={(contacts) => {
                            setUserCurrent({
                                ...userCurrent,
                                contacts: contacts,
                            })
                        }}
                    />
                </div>

                {showPermissionsFields && (
                    <div className={styles.info}>
                        <PermissionsForm
                            permissions={userCurrent.permissions}
                            onChange={handlePermissionsChange}
                        />
                    </div>
                )}

                {showPasswordFields && (
                    <div className={styles.info}>
                        <div className={styles.h}>
                            {t('537_UserEditForm_changePassword')}
                        </div>

                        <Row>
                            <Col>
                                <PassField
                                    placeholder={t(
                                        '538_UserEditForm_newPassword'
                                    )}
                                    value={password}
                                    onChange={(e) =>
                                        setPassword(e.target.value)
                                    }
                                    onBlur={() => setPasswordTouched(true)}
                                    status={
                                        passwordError && passwordTouched
                                            ? 2
                                            : undefined
                                    }
                                    note={
                                        passwordError && passwordTouched
                                            ? passwordError
                                            : ''
                                    }
                                />
                            </Col>
                            <Col>
                                <PassField
                                    placeholder={t(
                                        '539_UserEditForm_repeatPassword'
                                    )}
                                    value={repeatPassword}
                                    onChange={handleRepeatPasswordChange}
                                    onBlur={() =>
                                        setRepeatPasswordTouched(true)
                                    }
                                    status={
                                        repeatPasswordError &&
                                        repeatPasswordTouched
                                            ? 2
                                            : undefined
                                    }
                                    note={
                                        repeatPasswordError &&
                                        repeatPasswordTouched
                                            ? repeatPasswordError
                                            : ''
                                    }
                                />
                            </Col>
                        </Row>
                    </div>
                )}

                <div className={styles.action}>
                    <Button
                        classNames={{
                            contain: styles.link,
                        }}
                        kind={ButtonKind.Link}
                        onClick={onCancel}
                        disabled
                    >
                        {t('280_UserEditForm_cancel')}
                    </Button>
                    <Button
                        type={'submit'}
                        loading={loading}
                        disabled={!isValid}
                    >
                        {t('281_UserEditForm_save')}
                    </Button>
                </div>
            </form>
        </div>
    )
}

export default UserEditForm

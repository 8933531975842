import classNames from 'classnames'
import React, { FC } from 'react'
import styles from './TextFieldNote.module.scss'

export type NoteType = {
    value: number
    label: string
}

type Props = {
    value: string
    notes: Array<string>
    onClick: (note: string) => void
    label?: string
    onChange: (val: string) => void
    placeholder?: string
}

/**
 *
 * @param value
 * @param notes
 * @param onClick
 * @param label
 * @param onChange
 * @param rest
 * @returns
 */

const TextFieldNote: FC<Props> = ({
    value,
    notes,
    onClick,
    label,
    onChange,
    placeholder,
    ...rest
}) => {
    return (
        <div className={classNames(styles.root)}>
            {label && <div className={styles.label}>{label}</div>}
            <input
                value={value}
                onChange={(e) => onChange(e.target.value)}
                className={classNames(styles.input)}
                placeholder={placeholder}
                {...rest}
            />
            {notes.length > 0 && (
                <div className={styles.notes}>
                    {notes.map((note, index) => {
                        return (
                            <div
                                onClick={() => onClick(note)}
                                className={classNames(styles.note)}
                                key={index}
                            >
                                {note}
                            </div>
                        )
                    })}
                </div>
            )}
        </div>
    )
}

export default TextFieldNote

import React, { ChangeEvent, FC, useMemo, useState } from 'react'
import { TFunction } from 'i18next'
import { GoodsItem as GoodsItemType } from '../../../types'
import { useTranslation } from 'react-i18next'
import { useValidator } from '../../hooks/validator'
import { useAppSelector } from '../../hooks/store'
import { useAppDispatch } from '../../hooks/store'
import { showToast } from '../../../store/slices/site'
import { selectBrands } from '../../../store/slices/storage'
import { Goods as GoodsService } from '../../../services/Goods'
import { Modal } from '../../simples/Modal'
import { TextField, TextFieldStatus } from '../../simples/TextField'
import { SearchSelector } from '../../simples/SearchSelector'
import { Button, ButtonKind } from '../../simples/Button'

import styles from './GoodsItemEditor.module.scss'

const modalClasses = {
    modal: styles.modal,
}

const nameValidationOptions = {
    required: true,
    max: 255,
}

const validateBarcode = (barcode: string, t: TFunction) => {
    if (!/^[0-9]+$/.test(barcode)) {
        return t('609_Barcode_must_contain_only_numbers')
    }

    if (barcode.length !== 13) {
        return t('610_Barcode_length_must_be_13_characters')
    }

    return ''
}

const barcodeValidationOptions = {
    required: true,
    validateFunc: validateBarcode,
}

type Props = {
    goodsItem?: GoodsItemType
    onSubmit?: (newGoodsItem: GoodsItemType) => void
    onClose?: () => void
}

const GoodsItemEditor: FC<Props> = ({ goodsItem, onSubmit, onClose }) => {
    const { t } = useTranslation()
    const brands = useAppSelector(selectBrands)
    const dispatch = useAppDispatch()

    const {
        value: name,
        setValue: setName,
        touched: nameTouched,
        setTouched: setNameTouched,
        error: nameError,
        // setError: setNameError
    } = useValidator(goodsItem ? goodsItem.name : '', nameValidationOptions, t)
    const {
        value: barcode,
        setValue: setBarcode,
        touched: barcodeTouched,
        setTouched: setBarcodeTouched,
        error: barcodeError,
        setError: setBarcodeError,
    } = useValidator(
        goodsItem ? goodsItem.barcode : '',
        barcodeValidationOptions,
        t
    )
    const [brandId, setBrandId] = useState(() =>
        goodsItem ? goodsItem.brand.id : 0
    )
    const brandOptions = useMemo(() => {
        return brands.map((brand) => ({
            id: brand.id,
            value: brand.name,
        }))
    }, [brands])

    const [photoUrl, setPhotoUrl] = useState(() =>
        goodsItem ? goodsItem.photoPath : ''
    )
    const [photoUrlError, setPhotoUrlError] = useState('')
    const [photoFile, setPhotoFile] = useState<File>()

    const handlePhotoChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { files } = event.target

        if (!files || files.length === 0) {
            return
        }

        const file = files[0]

        const photoUrl = URL.createObjectURL(file)
        setPhotoUrl(photoUrl)
        setPhotoFile(file)

        if (file.size > 1024 * 1024 * 10) {
            setPhotoUrlError(t('611_The_maximum_file_size_is_10Mb'))
        } else {
            setPhotoUrlError('')
        }
    }

    const isValid = !barcodeError && !nameError && !!brandId && !photoUrlError

    const [submiting, setSubmiting] = useState(false)
    const submit = async () => {
        if (!name || !barcode || !brandId) {
            throw new Error('Form is invalid')
        }

        setSubmiting(true)

        try {
            if (goodsItem) {
                const updatedGoodsItem = await GoodsService.update(
                    goodsItem.id,
                    {
                        name,
                        barcode,
                        brandId,
                        photo: photoFile,
                    }
                )

                onSubmit && onSubmit(updatedGoodsItem)

                dispatch(showToast(t('612_Product_updated_successfully')))
            } else {
                const newGoodsItem = await GoodsService.create({
                    name,
                    barcode,
                    brandId,
                    photo: photoFile,
                })

                onSubmit && onSubmit(newGoodsItem)

                dispatch(showToast(t('613_Product_created_successfully')))
            }
        } catch (error) {
            if (
                error.response &&
                error.response.data &&
                error.response.data.errors &&
                Array.isArray(error.response.data.errors)
            ) {
                const { errors } = error.response.data

                // Комбинация \"1234567890123\"-\"73\" параметров Barcode и Company Id уже существует.
                if (
                    errors[0].indexOf('Комбинация') !== -1 &&
                    errors[0].indexOf(
                        'параметров Barcode и Company Id уже существует'
                    )
                ) {
                    setBarcodeError(
                        t('614_A_product_with_this_barcode_already_exists')
                    )
                } else {
                    dispatch(
                        showToast({
                            message: `${t(
                                '615_An_error_has_occurred'
                            )}: ${errors.join(', ')}`,
                            status: 'error',
                        })
                    )
                }
            } else {
                dispatch(
                    showToast({
                        message: `${t('615_An_error_has_occurred')}!`,
                        status: 'error',
                    })
                )
            }
        }

        setSubmiting(false)
    }

    return (
        <Modal classes={modalClasses} showClose onClose={onClose}>
            <h3 className={styles.header}>
                {goodsItem
                    ? t('616_Goods_item_editing')
                    : t('617_Goods_item_creation')}
            </h3>

            <div className={styles.body}>
                <div className={styles.left}>
                    <div className={styles.row}>
                        <TextField
                            placeholder={t('618_Barcode')}
                            value={barcode}
                            onChange={(event) => setBarcode(event.target.value)}
                            onBlur={() => setBarcodeTouched(true)}
                            status={
                                barcodeError && barcodeTouched
                                    ? TextFieldStatus.Error
                                    : TextFieldStatus.Normal
                            }
                            note={
                                barcodeError && barcodeTouched
                                    ? barcodeError
                                    : ''
                            }
                        />
                    </div>
                    <div className={styles.row}>
                        <TextField
                            placeholder={t('619_Goods_item_name')}
                            value={name}
                            onChange={(event) => setName(event.target.value)}
                            onBlur={() => setNameTouched(true)}
                            status={
                                nameError && nameTouched
                                    ? TextFieldStatus.Error
                                    : TextFieldStatus.Normal
                            }
                            note={nameError && nameTouched ? nameError : ''}
                        />
                    </div>

                    <div className={styles.row}>
                        <SearchSelector
                            value={brandId}
                            options={brandOptions}
                            onClick={(option) => setBrandId(option.id)}
                            placeholder={t('620_Brand')}
                            upperCase
                        />
                    </div>
                </div>

                <div className={styles.right}>
                    <label className={styles.photoUploader}>
                        <input
                            type="file"
                            accept="image/png, image/jpeg"
                            onChange={handlePhotoChange}
                        />

                        {photoUrl ? (
                            <span
                                className={styles.photoUploaderValue}
                                style={{
                                    backgroundImage: `url("${photoUrl}")`,
                                }}
                            />
                        ) : (
                            <span className={styles.photoUploaderEmpty}>
                                <span>+</span>
                                <span>
                                    {t('621_Goods_item_photo_1')}
                                    <br />
                                    {t('622_Goods_item_photo_2')}
                                </span>
                                <span>PNG, JPEG (10Mb max)</span>
                            </span>
                        )}

                        {photoUrlError && (
                            <span className={styles.photoUploaderError}>
                                {photoUrlError}
                            </span>
                        )}
                    </label>
                </div>
            </div>

            <div className={styles.buttons}>
                <Button
                    disabled={!isValid}
                    onClick={submit}
                    loading={submiting}
                >
                    {goodsItem ? t('623_Save') : t('624_Create')}
                </Button>

                <Button kind={ButtonKind.Link} onClick={onClose}>
                    {t('625_Cancel')}
                </Button>
            </div>
        </Modal>
    )
}

export default GoodsItemEditor

import React, { FC, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AdMaterial as AdMaterialType, AdMaterial } from '../../../types'
import { AdMaterials as AdMaterialsService } from '../../../services/AdMaterials'
import { useAppSelector } from '../../hooks/store'
import { selectAuthUser } from '../../../store/slices/auth'
import { Editor } from '../../simples/modalEditor/Editor'
import { Button } from '../../simples/Button'
import { Search } from '../../simples/Search'
import { Spinner } from '../../simples/Spinner'
import { AdMaterial as AdMaterialItem } from './AdMaterial'
import { AdMaterialEditor } from '../AdMaterialEditor'
import styles from './AdMaterialsFinder.module.scss'

const searchClasses = {
    root: styles.searchRoot,
}

type Props = {
    onAdd?: (adMaterial: AdMaterial) => void
    onCancel?: () => void
}

const AdMaterialsFinder: FC<Props> = ({ onAdd, onCancel }) => {
    const { t } = useTranslation()
    const user = useAppSelector(selectAuthUser)

    const [selectedAdMaterialId, setSelectedAdMaterialId] = useState(0)

    const [adMaterials, setAdMaterials] = useState<Array<AdMaterialType>>([])
    const adMaterialsCurrent = useRef<Array<AdMaterialType>>(adMaterials)
    adMaterialsCurrent.current = adMaterials
    const [loading, setLoading] = useState(false)
    const offset = useRef(0)
    const search = useRef('')
    const has = useRef(true)

    const [showEditor, setShowEditor] = useState(false)

    const load = async () => {
        if (!has.current || loading) {
            return
        }

        setLoading(true)
        const result = await AdMaterialsService.getList({
            offset: offset.current,
            search: search.current,
        })
        setLoading(false)

        if (!result.length) {
            has.current = false
            return
        }

        const newAdMaterials = [...adMaterialsCurrent.current, ...result]
        offset.current = newAdMaterials.length
        setAdMaterials(newAdMaterials)
    }

    const clear = () => {
        has.current = true
        offset.current = 0
        search.current = ''
        setAdMaterials([])
    }

    useEffect(() => {
        load().then()
    }, [])

    const handleSearch = async (text: string) => {
        clear()
        search.current = text
        await load()
    }

    const listRef = useRef<HTMLDivElement>(null)
    const containerRef = useRef<HTMLDivElement>(null)
    const reached = useRef(false)

    const handleScroll = async () => {
        if (!listRef.current || !containerRef.current) {
            return
        }

        const mainHeight = listRef.current.offsetHeight
        const contentHeight = containerRef.current.offsetHeight
        const scrollTop = listRef.current.scrollTop

        if (contentHeight <= mainHeight) {
            return
        }

        const afterEndReach =
            contentHeight - (scrollTop + mainHeight) < mainHeight / 2

        if (afterEndReach && !reached.current) {
            reached.current = true

            await load()
        } else if (!afterEndReach && reached.current) {
            reached.current = false
        }
    }

    const handleAdMaterialChecked = (
        checked: boolean,
        adMaterialId: number
    ) => {
        if (!checked) {
            setSelectedAdMaterialId(0)
        } else {
            setSelectedAdMaterialId(adMaterialId)
        }
    }

    const startCreateAdMaterial = () => {
        setShowEditor(true)
    }

    const closeEditor = () => {
        setShowEditor(false)
    }

    const handleEditorSubmit = (adMaterial: AdMaterialType) => {
        setAdMaterials([adMaterial, ...adMaterials])

        setSelectedAdMaterialId(adMaterial.id)
    }

    const submit = () => {
        const adMaterial = adMaterials.find(
            (am) => am.id === selectedAdMaterialId
        )

        if (!adMaterial) {
            throw new Error('Ad material not found')
        }

        onAdd && onAdd(adMaterial)
    }

    return (
        <Editor
            header={t('11_AdMaterialFinder_promotionalMaterials')}
            onCancel={onCancel}
            submitButtonName={t('12_AdMaterialFinder_add')}
            submitDisabled={!selectedAdMaterialId}
            onSubmit={submit}
        >
            <div className={styles.top}>
                {user && user.groups.root && (
                    <div className={styles.createButton}>
                        <Button onClick={startCreateAdMaterial}>
                            {t('13_AdMaterialFinder_createMaterial')}
                        </Button>
                    </div>
                )}

                <div className={styles.search}>
                    <Search classes={searchClasses} onSearch={handleSearch} />
                </div>
            </div>

            <div className={styles.adMaterials}>
                <div className={styles.adMaterialsHeader}>
                    {t('14_AdMaterialFinder_uploaded')}
                </div>

                <div className={styles.adMaterialsBody}>
                    {adMaterials.length > 0 && (
                        <div
                            className={styles.adMaterialList}
                            ref={listRef}
                            onScroll={handleScroll}
                        >
                            <div
                                className={styles.adMaterialsContainer}
                                ref={containerRef}
                            >
                                {adMaterials.map((adMaterial) => (
                                    <div
                                        className={styles.adMaterialItemWrap}
                                        key={adMaterial.id}
                                    >
                                        <AdMaterialItem
                                            id={adMaterial.id}
                                            photo={adMaterial.photoPath}
                                            name={adMaterial.name}
                                            checked={
                                                selectedAdMaterialId ===
                                                adMaterial.id
                                            }
                                            onChecked={handleAdMaterialChecked}
                                        />
                                    </div>
                                ))}

                                <div className={styles.adMaterialItemEmpty} />
                                <div className={styles.adMaterialItemEmpty} />
                                <div className={styles.adMaterialItemEmpty} />
                            </div>
                        </div>
                    )}

                    {loading && (
                        <div className={styles.adMaterialsLoader}>
                            <Spinner />
                        </div>
                    )}
                </div>
            </div>

            {showEditor && (
                <AdMaterialEditor
                    onSubmit={handleEditorSubmit}
                    onCancel={closeEditor}
                />
            )}
        </Editor>
    )
}

export default AdMaterialsFinder

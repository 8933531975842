import React, { FC, useMemo } from 'react'
import { selectModels } from '../../../store/slices/storage'
import {
    AutomatContainerTypes,
    AutomatModel as ModelType,
    AutomatModelTypes,
    AutomatType,
} from '../../../types'
import { useAppSelector } from '../../hooks/store'
import { SmallRemainsIndicator } from '../../simples/SmallRemainsIndicator'
import styles from './RemainsContainerBlock.module.scss'
import { MAX_INT_32 } from '../../../constants'

type Props = {
    mashin: AutomatType
    // containers: Array<AutomatContainerType>
    // automatModelId: number
}

const RemainsContainerBlock: FC<Props> = ({ mashin }) => {
    const models: Array<ModelType> = useAppSelector(selectModels)
    const find = models.find((i) => i.id === mashin.automatModelId)

    const { powderIndicators, syrupIndicators, waterRemaining } =
        useMemo(() => {
            let powderIndicators: Array<{
                remainsVolume: number
                totalVolume: number
            }> = []
            let syrupIndicators: Array<{
                remainsVolume: number
                totalVolume: number
            }> = []
            let waterRemaining = 0

            if (!mashin) {
                return {
                    powderIndicators,
                    syrupIndicators,
                    waterRemaining,
                }
            }

            const currentModel = models.find(
                (m) => m.id === mashin.automatModelId
            )
            if (!currentModel) {
                return {
                    powderIndicators,
                    syrupIndicators,
                    waterRemaining,
                }
            }

            if (currentModel.type !== AutomatModelTypes.Beverages) {
                throw new Error('Model must be Beverages')
            }

            // Вычисляем заполненность бункеров
            const sortedContainers = currentModel.containers
                .slice()
                .sort((c1, c2) => c1.number - c2.number)

            for (let i = 0; i < sortedContainers.length; i++) {
                const container = sortedContainers[i]

                const volume = container.volume

                const automatContainer = mashin.containers.find(
                    (ac) => ac.automatModelContainerId === container.id
                )
                const remainsVolume = automatContainer
                    ? automatContainer.remainsVolume
                    : 0

                if (currentModel) {
                    if (container.type === AutomatContainerTypes.Powder) {
                        powderIndicators.push({
                            remainsVolume: remainsVolume,
                            totalVolume: volume,
                        })
                    } else if (container.type === AutomatContainerTypes.Syrup) {
                        syrupIndicators.push({
                            remainsVolume: remainsVolume,
                            totalVolume: volume,
                        })
                    }
                }
            }

            if (mashin.waterVolume === 0) {
                waterRemaining = 0
            } else if (mashin.waterVolume === MAX_INT_32) {
                waterRemaining = 100
            } else {
                waterRemaining =
                    mashin.waterRemaining > 0
                        ? (mashin.waterRemaining / mashin.waterVolume) * 100
                        : 0
            }

            return { powderIndicators, syrupIndicators, waterRemaining }
        }, [mashin, models])

    return (
        <div className={styles.root}>
            <div className={styles.wrapper}>
                <SmallRemainsIndicator
                    color={'#009cf0'}
                    percents={waterRemaining}
                    volume={0}
                    totalVolume={0}
                />
            </div>
            {[powderIndicators, syrupIndicators].map((block) => {
                return (
                    <div className={styles.wrapper}>
                        {block.map((container) => (
                            <SmallRemainsIndicator
                                color={'#66b32e'}
                                volume={container.remainsVolume}
                                totalVolume={container.totalVolume}
                            />
                        ))}
                    </div>
                )
            })}
            <div className={styles.wrapper}>
                <SmallRemainsIndicator
                    color={'#17171B'}
                    volume={mashin.cupsRemaining}
                    totalVolume={
                        find &&
                        find.type === AutomatModelTypes.Beverages &&
                        find.cups
                            ? find.cups
                            : 0
                    }
                />
            </div>
        </div>
    )
}

export default React.memo(RemainsContainerBlock)

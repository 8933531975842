import React, { FC, useCallback, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'
import { Auth as AuthService } from '../../../services/Auth'
import { routes } from '../../../config'
import { useValidator } from '../../hooks/validator'
import { validateLogin, validatePassword } from '../../../utils/validate'
import { AuthLayout } from '../../complexes/AuthLayout'
import { TextField, TextFieldStatus } from '../../simples/TextField'
import { EmailField } from '../../simples/EmailField'
import { PassField } from '../../simples/PassField'
import { Button } from '../../simples/Button'
import styles from './RegistrationByInvitation.module.scss'

const buttonClasses = {
    button: styles.button,
}

type RouteParams = {
    key: string
}

const loginValidateOptions = {
    required: true,
    validateFunc: validateLogin,
    max: 100,
}
const organizationValidateOptions = {
    required: true,
    max: 100,
}
const passwordValidateOptions = {
    required: true,
    max: 100,
    validateFunc: validatePassword,
}
const emailValidateOptions = {
    required: true,
    max: 100,
    email: true,
}

const RegistrationByInvitation: FC = () => {
    const { t } = useTranslation()
    const { key } = useParams<RouteParams>()

    const {
        value: login,
        setValue: setLogin,
        error: loginError,
        setError: setLoginError,
        touched: loginTouched,
        setTouched: setLoginTouched,
    } = useValidator('', loginValidateOptions, t)
    const {
        value: fullName,
        setValue: setFullName,
        error: fullNameError,
        setError: setFullNameError,
        touched: fullNameTouched,
        setTouched: setFullNameTouched,
    } = useValidator('', organizationValidateOptions, t)
    const {
        value: password,
        setValue: setPassword,
        error: passwordError,
        setError: setPasswordError,
        touched: passwordTouched,
        setTouched: setPasswordTouched,
    } = useValidator('', passwordValidateOptions, t)
    const {
        value: email,
        setValue: setEmail,
        error: emailError,
        setError: setEmailError,
        touched: emailTouched,
        setTouched: setEmailTouched,
    } = useValidator('', emailValidateOptions, t)

    const [errors, setErrors] = useState<Array<string>>([])

    const isValid =
        !fullNameError && !loginError && !passwordError && !emailError

    const [requesting, setRequesting] = useState(false)

    const history = useHistory()

    const handleSubmit = useCallback(
        async (e: React.FormEvent<HTMLFormElement>) => {
            e.preventDefault()

            if (!isValid) {
                throw new Error('Invalid form')
            }

            setFullNameTouched(true)
            setLoginTouched(true)
            setPasswordTouched(true)
            setEmailTouched(true)
            setErrors([])

            setRequesting(true)

            try {
                await AuthService.registrationByInvitation({
                    key,
                    fullName,
                    login,
                    password,
                    email,
                })

                history.push(routes.registrationByInvitationSuccess)
            } catch (error) {
                if (error.response && error.response.errors) {
                    const errors = error.response.errors

                    if (_.isArray(errors)) {
                        setErrors(errors)
                    } else {
                        if (errors.fullName && _.isArray(errors.fullName)) {
                            setFullNameError(errors.fullName.join(', '))
                        } else if (errors.login && _.isArray(errors.login)) {
                            setLoginError(errors.login.join(', '))
                        } else if (errors.email && _.isArray(errors.email)) {
                            setEmailError(errors.email.join(', '))
                        } else if (
                            errors.password &&
                            _.isArray(errors.password)
                        ) {
                            setPasswordError(errors.password.join(', '))
                        }
                    }
                }
            }

            setRequesting(false)
        },
        [login, password, fullName, email, key]
    )

    return (
        <AuthLayout>
            <div className={styles.content}>
                <h2 className={styles.auth_h2}>
                    {t('440_RegistrationByInvitation_registration')}
                </h2>

                {errors.length > 0 && (
                    <div className={styles.errors}>
                        {errors.map((error) => (
                            <div className={styles.errorsItem}>{error}</div>
                        ))}
                    </div>
                )}

                <form action="/" onSubmit={(e) => handleSubmit(e)}>
                    <div className={styles.formGroup}>
                        <TextField
                            value={fullName}
                            onChange={(e) => setFullName(e.target.value)}
                            onBlur={() => setFullNameTouched(true)}
                            placeholder={t(
                                '441_RegistrationByInvitation_fullName'
                            )}
                            required
                            status={
                                fullNameError && fullNameTouched
                                    ? TextFieldStatus.Error
                                    : undefined
                            }
                            note={
                                fullNameError && fullNameTouched
                                    ? fullNameError
                                    : ''
                            }
                        />
                    </div>

                    <div className={styles.formGroup}>
                        <TextField
                            value={login}
                            onChange={(e) => setLogin(e.target.value)}
                            onBlur={() => setLoginTouched(true)}
                            placeholder={t(
                                '442_RegistrationByInvitation_username'
                            )}
                            status={
                                loginError && loginTouched
                                    ? TextFieldStatus.Error
                                    : undefined
                            }
                            required
                            note={loginError && loginTouched ? loginError : ''}
                        />
                    </div>

                    <div className={styles.formGroup}>
                        <EmailField
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            onBlur={() => setEmailTouched(true)}
                            placeholder={t(
                                '443_RegistrationByInvitation_email'
                            )}
                            required
                            type={'text'}
                            status={
                                emailError && emailTouched
                                    ? TextFieldStatus.Error
                                    : undefined
                            }
                            note={emailError && emailTouched ? emailError : ''}
                        />
                    </div>

                    <div className={styles.formGroup}>
                        <PassField
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            onBlur={() => setPasswordTouched(true)}
                            placeholder={t(
                                '444_RegistrationByInvitation_password'
                            )}
                            required
                            status={
                                passwordError && passwordTouched
                                    ? TextFieldStatus.Error
                                    : undefined
                            }
                            note={
                                passwordError && passwordTouched
                                    ? passwordError
                                    : ''
                            }
                        />
                    </div>

                    <div className={styles.marg_btn}>
                        <Button
                            type={'submit'}
                            classNames={buttonClasses}
                            disabled={!isValid}
                            loading={requesting}
                        >
                            {t('445_RegistrationByInvitation_signUp')}
                        </Button>
                    </div>
                </form>
            </div>
        </AuthLayout>
    )
}

export default RegistrationByInvitation

import React, { FC, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FillingsService } from '../../../services/Fillings'
import { selectModels } from '../../../store/slices/storage'
import { AutomatFillinsType, AutomatModelTypes } from '../../../types'
import { useAppSelector } from '../../hooks/store'
import { Col } from '../../simples/modalEditor/Col'
import { Editor } from '../../simples/modalEditor/Editor'
import { Row } from '../../simples/modalEditor/Row'
import { H } from '../../simples/modalEditor/H'
import { OptionType, Selector } from '../../simples/Selector'
import { TextField } from '../../simples/TextField'
import { ContainerItem } from './ContainerItem'
import { selectAuthUser } from '../../../store/slices/auth'

const initFilling: AutomatFillinsType = {
    id: 0,
    name: '',
    automatModelId: 0,
    containers: [],
}

type Props = {
    filling?: AutomatFillinsType
    onSubmit: (filling: AutomatFillinsType) => void
    onClose: () => void
}

/**
 *
 * @param filling - наполнитель который редактируем
 * @param onSubmit - нажатие на сохранить
 * @param onClose - нажатие на закрыть или отмена
 * @returns - модальное окно редактирования или создания наполнителя
 */

const FillingEditor: FC<Props> = ({
    filling = initFilling,
    onClose,
    onSubmit,
}) => {
    const { t } = useTranslation()
    const user = useAppSelector(selectAuthUser)

    const models = useAppSelector(selectModels)
    const [editFill, setEditFill] = useState<AutomatFillinsType>(filling)
    const [saving, setSaving] = useState(false)

    const bunkers = useMemo(() => {
        if (!editFill.automatModelId) {
            return []
        }

        const model = models.find((m) => m.id === editFill.automatModelId)

        if (!model) {
            return []
        }

        if (model.type !== AutomatModelTypes.Beverages) {
            throw new Error('Automat must be Beverages')
        }

        return model.containers
    }, [editFill.automatModelId, models])

    const modelOptions = useMemo(() => {
        const options: OptionType[] = models
            .filter((m) => m.type === AutomatModelTypes.Beverages)
            .map((o) => {
                return {
                    value: o.id,
                    label: o.name,
                }
            })

        return options
    }, [models])

    const onHandleSubmit = async () => {
        try {
            if (editFill.id) {
                setSaving(true)
                const updatedFilling = await FillingsService.updateFilling(
                    editFill.id,
                    {
                        ...editFill,
                        companyId: user?.company.id,
                    }
                )
                setSaving(false)
                onSubmit(updatedFilling)
            } else {
                setSaving(true)
                const createdFilling = await FillingsService.createFiling({
                    ...editFill,
                    companyId: user?.company.id,
                })
                setSaving(false)
                onSubmit(createdFilling)
            }
        } catch (error) {
            console.log('ОШИБКА СОХРАНЕНИЯ', error)
            setSaving(false)
        }
    }

    const onChooseBuncerTaste = (id: number, tasteId: number) => {
        let existB = editFill.containers.find(
            (i) => i.automatContainerId === id
        )
        if (existB) {
            let index =
                editFill.containers?.findIndex(
                    (i) => i.automatContainerId === id
                ) || 0

            const arrBefore =
                editFill.containers && index < editFill.containers.length
                    ? editFill.containers?.slice(0, index)
                    : []

            const arrAfter =
                editFill.containers && index < editFill.containers.length
                    ? editFill.containers?.slice(index + 1)
                    : []

            existB = { ...existB, tasteId: tasteId }

            setEditFill({
                ...editFill,
                containers: [...arrBefore, existB, ...arrAfter],
            })
        } else {
            setEditFill({
                ...editFill,
                containers: [
                    ...editFill.containers,
                    {
                        automatContainerId: id,
                        tasteId,
                    },
                ],
            })
        }
    }

    return (
        <Editor
            onCancel={onClose}
            onSubmit={onHandleSubmit}
            submitLoading={saving}
            submitDisabled={!editFill.name || !editFill.automatModelId}
            header={t('130_FillingEditor_automateFilling')}
        >
            <Row>
                <Col>
                    <TextField
                        placeholder={t('131_FillingEditor_fillingName')}
                        value={editFill.name}
                        onChange={(event) =>
                            setEditFill({
                                ...editFill,
                                name: event.target.value,
                            })
                        }
                    />
                </Col>
            </Row>
            <Row>
                <Col>
                    <Selector
                        options={modelOptions}
                        label={t('132_FillingEditor_automateModel')}
                        value={editFill.automatModelId}
                        onChange={(event) =>
                            setEditFill({ ...editFill, automatModelId: event })
                        }
                    />
                </Col>
            </Row>

            <H level={3}>{t('133_FillingEditor_bunkerDistribution')}</H>

            {[...bunkers]
                .sort((b1, b2) => b1.number - b2.number)
                .map((bunker) => {
                    const fillingContainer = editFill.containers.find(
                        (c) => c.automatContainerId === bunker.id
                    )

                    return (
                        <Row key={bunker.id}>
                            <Col>
                                <ContainerItem
                                    number={bunker.number}
                                    containerType={bunker.type}
                                    tasteId={
                                        fillingContainer
                                            ? fillingContainer.tasteId
                                            : 0
                                    }
                                    onTasteChange={(tasteId) =>
                                        onChooseBuncerTaste(bunker.id, tasteId)
                                    }
                                />
                            </Col>
                        </Row>
                    )
                })}
        </Editor>
    )
}
export default FillingEditor

import React, { FC } from 'react'
import { AuthLayout } from '../../complexes/AuthLayout'

import { AuthForm } from '../../complexes/AuthForm'

const Auth: FC = () => {
    return (
        <AuthLayout>
            <AuthForm />
        </AuthLayout>
    )
}

export default Auth

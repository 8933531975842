import React, {
    ChangeEvent,
    FC,
    useCallback,
    useEffect,
    useRef,
    useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { AddressNoteType, Outlet as OutletType } from '../../../types'
import { Outlets as OutletsService } from '../../../services/Outlets'
import { Editor } from '../../simples/modalEditor/Editor'
import { Row } from '../../simples/modalEditor/Row'
import { Col } from '../../simples/modalEditor/Col'
import { TextField } from '../../simples/TextField'
import AutomatsSelectorModule from '../AutomatsSelector/AutomatsSelector.module'
import { Address as AddressService } from '../../../services/Address'
import { TextFieldNote } from '../../simples/TextFieldNotes'
import styles from './OutletEditor.module.scss'

enum coordsTypes {
    longitude = 'longitude',
    latitude = 'latitude',
}

type OutletEditorData = {
    id: number
    name: string
    address: string
    longitude: number
    latitude: number
}

const initOutlet: OutletEditorData = {
    id: 0,
    name: '',
    address: '',
    longitude: 0,
    latitude: 0,
}

type Props = {
    data?: OutletType
    companyId?: number
    onSubmit: (outlet: OutletType) => void
    onClose: () => void
}

const OutletEditor: FC<Props> = ({
    data = initOutlet,
    companyId,
    onSubmit,
    onClose,
}) => {
    const { t, i18n } = useTranslation()

    const {
        id,
        name: defaultName,
        address: defaultAddress,
        longitude,
        latitude,
    } = data

    const [loading, setLoading] = useState(false)
    const [coords, setCoords] = useState({ longitude, latitude })
    const [name, setName] = useState(defaultName)
    const [address, setAddress] = useState(defaultAddress)

    const [automatIds, setAutomatIds] = useState<Array<number>>([])

    const submit = async () => {
        setLoading(true)
        const sendAutomatIds = JSON.stringify(automatIds)
        const savedOutlet =
            id > 0
                ? await OutletsService.update(id, {
                      name,
                      address,
                      automatIds: sendAutomatIds,
                      longitude: coords ? coords.longitude : 0,
                      latitude: coords ? coords.latitude : 0,
                  })
                : await OutletsService.create({
                      name,
                      address,
                      companyId,
                      automatIds: sendAutomatIds,
                      longitude: coords ? coords.longitude : 0,
                      latitude: coords ? coords.latitude : 0,
                  })

        setLoading(false)

        onSubmit(savedOutlet)
    }

    const [notes, setNotes] = useState<Array<AddressNoteType>>([])
    const addressWasChanged = useRef(false)

    const getAddressNotes = async (search: string) => {
        const response = await AddressService.search({
            query: search,
            language: i18n.language as 'ru' | 'en',
        })
        setNotes(response)
    }

    useEffect(() => {
        if (!addressWasChanged.current) {
            return
        }

        const fetchAddressNotes = async () => {
            await getAddressNotes(address)
        }

        const timer = setTimeout(fetchAddressNotes, 500)

        return () => {
            clearTimeout(timer)
        }
    }, [address])

    const onSetSearch = (s: string) => {
        addressWasChanged.current = true
        setAddress(s)
    }

    const changeCoords = (
        { target }: ChangeEvent<HTMLInputElement>,
        name: string
    ) => {
        if (name === coordsTypes.latitude) {
            setCoords((prev) => ({ ...prev, latitude: +target.value }))
        } else {
            setCoords((prev) => ({ ...prev, longitude: +target.value }))
        }
    }

    const onClickOnNote = useCallback(
        (e: string) => {
            const find = notes.find((i) => i.address === e)
            if (find) {
                setAddress(find.address)
            }
            setNotes([])
            addressWasChanged.current = false
        },
        [notes]
    )

    return (
        <Editor
            header={
                id > 0
                    ? t('190_OutletEditor_editOutlet')
                    : t('191_OutletEditor_createOutlet')
            }
            submitButtonName={
                id > 0
                    ? t('192_OutletEditor_save')
                    : t('193_OutletEditor_create')
            }
            submitDisabled={!name || !address}
            submitLoading={loading}
            onSubmit={submit}
            onCancel={onClose}
        >
            <Row>
                <Col>
                    <TextField
                        placeholder={t('194_OutletEditor_name')}
                        value={name}
                        onChange={(event) => setName(event.target.value)}
                    />
                </Col>
            </Row>

            <Row>
                <Col>
                    <div className={styles.wrapper}>
                        <TextFieldNote
                            value={address || ''}
                            onChange={onSetSearch}
                            notes={Array.from(notes, (i) => i.address)}
                            onClick={onClickOnNote}
                            placeholder={t('195_OutletEditor_address')}
                        />
                        {/*<div className={styles.coordsWraper}>
                            <TextField
                                value={coords.latitude}
                                onChange={(e) =>
                                    changeCoords(e, coordsTypes.latitude)
                                }
                                type="number"
                                classes={{ root: styles.shadow }}
                            />
                            <TextField
                                value={coords.longitude}
                                onChange={(e) =>
                                    changeCoords(e, coordsTypes.longitude)
                                }
                                type="number"
                                classes={{ root: styles.shadow }}
                            />
                        </div>*/}
                    </div>
                </Col>
            </Row>
            <Row>
                <Col>
                    <AutomatsSelectorModule
                        value={automatIds}
                        onChange={setAutomatIds}
                    />
                </Col>
            </Row>
        </Editor>
    )
}

export default OutletEditor

import React, { FC } from 'react'
import { useAppSelector, useAppDispatch } from '../../hooks/store'
import { selectToasts, hideToast } from '../../../store/slices/site'
import { Item } from './Item'
import styles from './Toasts.module.scss'

const Toasts: FC = () => {
    const toasts = useAppSelector(selectToasts)
    const dispatch = useAppDispatch()

    const handleToastClose = (time: number) => {
        dispatch(hideToast(time))
    }

    if (toasts.length === 0) {
        return null
    }

    return (
        <div className={styles.root}>
            {toasts.map((toast) => (
                <div className={styles.itemWrap} key={toast.time}>
                    <Item
                        message={toast.message}
                        status={toast.status}
                        onClose={() => handleToastClose(toast.time)}
                    />
                </div>
            ))}
        </div>
    )
}

export default Toasts

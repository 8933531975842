import React, { FC } from 'react'
import classNames from 'classnames'
import styles from './Table.module.scss'

type Props = {
    addClassName?: string
}

const Th: FC<Props> = ({ children, addClassName }) => {
    return <th className={classNames(styles.th, addClassName)}>{children}</th>
}

export default Th

import React, { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { getPriceMethodName, timeLocalParser } from '../../../../utils/helpers'
import { ConditionType, Order as OrderType } from '../../../../types'
import {
    SortOrder as OrdersSortOrder,
    SortOrderBy as OrdersSortOrderBy,
} from '../../../../services/Orders'
import { Spinner } from '../../../simples/Spinner'
import { Th } from './Th'
import styles from './OrdersTable.module.scss'

type Props = {
    orders: Array<OrderType>
    loading?: boolean
    onSortChange?: (orderBy: OrdersSortOrderBy, order: OrdersSortOrder) => void
}

const OrdersTable: FC<Props> = ({ orders, loading = false, onSortChange }) => {
    const { t } = useTranslation()
    const [orderBy, setOrderBy] = useState<OrdersSortOrderBy>('buyAt')
    const [order, setOrder] = useState<OrdersSortOrder>('DESC')

    const handleSort = (orderBy: OrdersSortOrderBy, order: OrdersSortOrder) => {
        setOrderBy(orderBy)
        setOrder(order)

        onSortChange && onSortChange(orderBy, order)
    }

    return (
        <div className={styles.root}>
            <table className={styles.table}>
                <thead>
                    <tr>
                        <Th
                            field={'productName'}
                            orderBy={orderBy}
                            order={order}
                            onSort={handleSort}
                        >
                            {t('494_OrdersTable_productName')}
                        </Th>
                        <Th
                            field={'outletName'}
                            orderBy={orderBy}
                            order={order}
                            onSort={handleSort}
                        >
                            {t('495_OrdersTable_outletName')}
                        </Th>
                        <Th
                            field={'automatName'}
                            orderBy={orderBy}
                            order={order}
                            onSort={handleSort}
                        >
                            {t('76_OrdersTable_automateName')}
                        </Th>
                        <Th
                            field={'totalPrice'}
                            orderBy={orderBy}
                            order={order}
                            onSort={handleSort}
                        >
                            {t('77_OrdersTable_totalPrice')}
                        </Th>
                        <Th
                            field={'priceMethod'}
                            orderBy={orderBy}
                            order={order}
                            onSort={handleSort}
                        >
                            {t('78_OrdersTable_priceMethod')}
                        </Th>
                        <Th
                            field={'promo'}
                            orderBy={orderBy}
                            order={order}
                            onSort={handleSort}
                        >
                            {t('80_OrdersTable_promo')}
                        </Th>
                        <Th
                            field={'buyAt'}
                            orderBy={orderBy}
                            order={order}
                            onSort={handleSort}
                        >
                            {t('585_OrdersTable_buyAt')}
                        </Th>
                    </tr>
                </thead>

                <tbody>
                    {orders.map((order) => {
                        let name = ''

                        if (order.goodName) {
                            let brandName = ''

                            if (
                                order.items &&
                                order.items.length > 0 &&
                                order.items[0].goodsItem
                            ) {
                                brandName = order.items[0].goodsItem.brand.name
                            }

                            name = `${brandName ? `${brandName}, ` : ''}${
                                order.goodName
                            }`
                        } else {
                            name = `${order.brandName}, ${order.productName}
                                    ${
                                        order.items &&
                                        order.items.length > 0 &&
                                        order.items[0].taste
                                            ? `, ${
                                                  order.items[0].taste.name
                                              }, ${
                                                  order.items[0].drinkVolume
                                              } ${t('579_OrdersTable_ml')}`
                                            : ''
                                    }`
                        }

                        return (
                            <tr>
                                <td className={styles.tdName}>{name}</td>
                                <td>{order.outletName}</td>
                                <td>{order.automat?.name}</td>
                                <td>{order.totalPrice}</td>
                                <td>
                                    {order.prices && order.prices.length > 0
                                        ? getPriceMethodName(
                                              order.prices[0].method,
                                              t
                                          )
                                        : ''}
                                </td>
                                <td>{order.promo}</td>
                                <td>
                                    {order.buyAt
                                        ? moment(order.buyAt).format(
                                              'YYYY-MM-DD HH:mm:ss'
                                          )
                                        : ''}
                                </td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>

            {loading && (
                <div className={styles.loader}>
                    <Spinner />
                </div>
            )}
        </div>
    )
}

export default OrdersTable

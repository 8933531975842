import React, { ChangeEvent, FC, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AutomatModel, AutomatModelTypes } from '../../../types'
import { Models as ModelsService } from '../../../services/Models'
import { TextField } from '../../simples/TextField'
import { Editor } from '../../simples/modalEditor/Editor'
import { Row } from '../../simples/modalEditor/Row'
import { Col } from '../../simples/modalEditor/Col'
import { Selector } from '../../simples/Selector'
import { BunkerTypeEditor } from './BunkerTypeEditor'

const DEFAULT_CELLS_COUNT = 60

const initModel: AutomatModel = {
    id: 0,
    type: AutomatModelTypes.Beverages,
    name: '',
    cups: 0,
    containers: [],
}

type Props = {
    model?: AutomatModel
    onSubmit: (model: AutomatModel) => void
    onClose: () => void
}

const ModelEditor: FC<Props> = ({ model = initModel, onSubmit, onClose }) => {
    const { t } = useTranslation()

    const automatModelTypeOptions = [
        { value: AutomatModelTypes.Beverages, label: t('390_Model_beverages') },
        { value: AutomatModelTypes.Snack, label: t('391_Model_snacks') },
        { value: AutomatModelTypes.Coffee, label: t('392_Model_coffee') },
        { value: AutomatModelTypes.Kiosk, label: t('634_Model_kiosk') },
    ]

    const [modelCurrent, setModelCurrent] = useState<AutomatModel>(model)
    const [loading, setLoading] = useState(false)

    const prevType = useRef(modelCurrent.type)
    useEffect(() => {
        if (prevType.current !== modelCurrent.type) {
            if (
                modelCurrent.type === AutomatModelTypes.Snack &&
                !modelCurrent.cellsCount
            ) {
                setModelCurrent({
                    ...modelCurrent,
                    cellsCount: DEFAULT_CELLS_COUNT,
                })
            }

            prevType.current = modelCurrent.type
        }
    }, [modelCurrent])

    const handleCupsChange = (event: ChangeEvent<HTMLInputElement>) => {
        if (modelCurrent.type !== AutomatModelTypes.Beverages) {
            throw new Error('Model must be Beverages')
        }

        const value = event.target.value.replace(/\D/, '')

        setModelCurrent({
            ...modelCurrent,
            cups: +value,
        })
    }

    const handleCellsCountChange = (event: ChangeEvent<HTMLInputElement>) => {
        if (modelCurrent.type !== AutomatModelTypes.Snack) {
            throw new Error('Model must be Snack')
        }

        const value = Math.round(Math.abs(+event.target.value))

        setModelCurrent({
            ...modelCurrent,
            cellsCount: value,
        })
    }

    const submit = async () => {
        let savedModel: AutomatModel

        setLoading(true)

        if (modelCurrent.id && modelCurrent.id > 0) {
            savedModel = await ModelsService.update(modelCurrent.id, {
                type: modelCurrent.type,
                name: modelCurrent.name,
                cups:
                    modelCurrent.type === AutomatModelTypes.Beverages
                        ? modelCurrent.cups
                        : undefined,
                containers:
                    modelCurrent.type === AutomatModelTypes.Beverages
                        ? modelCurrent.containers.map((container) => {
                              if (container.id > 0) {
                                  return container
                              }

                              return {
                                  type: container.type,
                                  number: container.number,
                                  volume: container.volume,
                              }
                          })
                        : undefined,
                cellsCount:
                    modelCurrent.type === AutomatModelTypes.Snack
                        ? modelCurrent.cellsCount
                        : undefined,
            })
        } else {
            savedModel = await ModelsService.create({
                type: modelCurrent.type,
                name: modelCurrent.name,
                cups:
                    modelCurrent.type === AutomatModelTypes.Beverages
                        ? modelCurrent.cups
                        : undefined,
                containers:
                    modelCurrent.type === AutomatModelTypes.Beverages
                        ? modelCurrent.containers.map((container) => ({
                              type: container.type,
                              number: container.number,
                              volume: container.volume,
                          }))
                        : undefined,
                cellsCount:
                    modelCurrent.type === AutomatModelTypes.Snack
                        ? modelCurrent.cellsCount
                        : undefined,
            })
        }

        setLoading(false)
        onSubmit(savedModel)
    }

    return (
        <Editor
            header={
                modelCurrent.id && modelCurrent.id > 0
                    ? t('169_ModelEditor_editModel')
                    : t('170_ModelEditor_createModel')
            }
            submitButtonName={
                modelCurrent.id && modelCurrent.id > 0
                    ? t('171_ModelEditor_save')
                    : t('172_ModelEditor_create')
            }
            submitDisabled={!modelCurrent.type || !modelCurrent.name}
            submitLoading={loading}
            onSubmit={submit}
            onCancel={onClose}
        >
            <Row>
                <Col>
                    <TextField
                        placeholder={t('173_ModelEditor_name')}
                        value={modelCurrent.name}
                        onChange={(event) =>
                            setModelCurrent({
                                ...modelCurrent,
                                name: event.target.value,
                            })
                        }
                    />
                </Col>
            </Row>
            <Row>
                <Col>
                    <Selector
                        options={automatModelTypeOptions}
                        label={t('174_ModelEditor_automateType')}
                        value={modelCurrent.type}
                        onChange={(e) =>
                            setModelCurrent({
                                ...modelCurrent,
                                type: e,
                            })
                        }
                    />
                </Col>
            </Row>

            {modelCurrent.type === AutomatModelTypes.Beverages ? (
                <>
                    <Row>
                        <Col>
                            <TextField
                                placeholder={t('175_ModelEditor_cups')}
                                value={modelCurrent.cups || ''}
                                onChange={handleCupsChange}
                            />
                        </Col>
                    </Row>

                    <BunkerTypeEditor
                        model={modelCurrent}
                        onChange={setModelCurrent}
                    />
                </>
            ) : modelCurrent.type === AutomatModelTypes.Snack ? (
                <Row>
                    <Col>
                        <TextField
                            type={'number'}
                            placeholder={t('635_ModelEditor_cells_count')}
                            value={modelCurrent.cellsCount || ''}
                            onChange={handleCellsCountChange}
                        />
                    </Col>
                </Row>
            ) : null}
        </Editor>
    )
}

export default ModelEditor

import classNames from 'classnames'
import React, { FC, useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Select, {
    components,
    MultiValue,
    MultiValueGenericProps,
} from 'react-select'
import { AutomatStatus } from '../../../types'
import { Button, ButtonKind } from '../../simples/Button'
import { Modal } from '../../simples/Modal'
import { TextField } from '../../simples/TextField'
import styles from './AutomatsFilter.module.scss'

import { Automats as AutomatService } from '../../../services/Automats'

import { ReactComponent as RedIcon } from '../../../assets/icons/red.svg'
import { ReactComponent as GreenIcon } from '../../../assets/icons/green.svg'
import { ReactComponent as YellowIcon } from '../../../assets/icons/yellow.svg'
import { useAppDispatch, useAppSelector } from '../../hooks/store'
import {
    resetFilter,
    selectAutoFilter,
    setFilter,
} from '../../../store/slices/filters'
import { TextFieldNote } from '../../simples/TextFieldNotes'

const MultiValueLabel = (props: MultiValueGenericProps<IconOptions>) => {
    return (
        <components.MultiValueLabel {...props}>
            <div
                style={{
                    display: 'flex',
                }}
            >
                {props.data.value === AutomatStatus.RED ? (
                    <RedIcon />
                ) : props.data.value === AutomatStatus.YELLOW ? (
                    <YellowIcon />
                ) : (
                    <GreenIcon />
                )}
            </div>
        </components.MultiValueLabel>
    )
}

export type AutomatFilterType = {
    status?: IconOptions[]
    address?: string
    minDay?: string
    maxDay?: string
    minWeek?: string
    maxWeek?: string
    minMonth?: string
    maxMonth?: string
}

type Props = {
    onClose: () => void
}

export type IconOptions = {
    value: AutomatStatus
    label: AutomatStatus
    icon: JSX.Element
}

// AutomatStatus

const AutomatsFilter: FC<Props> = ({ onClose }) => {
    const { t } = useTranslation()
    const dispatch = useAppDispatch()
    const filter = useAppSelector(selectAutoFilter)

    const [currentFilter, setCurrentFilter] = useState<AutomatFilterType>(
        filter || ({} as AutomatFilterType)
    )
    const isTyping = useRef<any>()
    const onClickNote = useRef<boolean>(false)

    const [notes, setNotes] = useState<Array<string>>([])

    const modalClasses = {
        root: classNames(styles.modalRoot),
        underline: classNames(styles.modalUnderlay),
        modal: classNames(styles.modalModal),
        container: classNames(styles.modalContainer),
        close: classNames(styles.modalClose),
    }

    const options: readonly IconOptions[] = [
        {
            value: AutomatStatus.RED,
            icon: <RedIcon />,
            label: t(`519_Automats_status_${AutomatStatus.RED}`),
        },
        {
            value: AutomatStatus.YELLOW,
            icon: <YellowIcon />,
            label: t(`520_Automats_status_${AutomatStatus.YELLOW}`),
        },
        {
            value: AutomatStatus.GREEN,
            icon: <GreenIcon />,
            label: t(`521_Automats_status_${AutomatStatus.GREEN}`),
        },
    ]

    const getAddressNotes = async (search: string) => {
        const response = await AutomatService.getNotes(search)
        setNotes(Array.from(response, (i) => i.address))
    }

    const countdownTimer = useCallback(async () => {
        if (isTyping.current && currentFilter.address && !onClickNote.current) {
            getAddressNotes(currentFilter.address || '').then()
        }
        clearInterval(isTyping.current)
    }, [currentFilter.address])

    const onSetSearch = (s: string) => {
        onClickNote.current = false
        clearInterval(isTyping.current)
        setCurrentFilter({ ...currentFilter, address: s })
    }

    useEffect(() => {
        isTyping.current = setInterval(countdownTimer, 500)
    }, [countdownTimer, currentFilter.address])

    const onHandleSave = () => {
        dispatch(setFilter(currentFilter))
        onClose()
    }

    const onHandleClearFilter = () => {
        setCurrentFilter({} as AutomatFilterType)
        dispatch(resetFilter())
        onClose()
    }

    const handleChange = (values: MultiValue<IconOptions>) => {
        setCurrentFilter({ ...currentFilter, status: values.map((i) => i) })
    }

    const onChangeValue = (value: string, key: keyof AutomatFilterType) => {
        const valueNumber = +value || 0
        setCurrentFilter({ ...currentFilter, [key]: valueNumber })
    }

    const onClickOnNote = useCallback(
        (e: string) => {
            setCurrentFilter({ ...currentFilter, address: e })
            setNotes([])
            onClickNote.current = true
        },
        [currentFilter]
    )

    return (
        <Modal classes={modalClasses} onClose={onClose} showClose>
            <div className={styles.mainTitle}>
                {t('522_Automats_filter_parametrs')}
            </div>
            <div className={styles.holder}>
                <div className={styles.subTitle}>
                    {t('523_Automats_status')}
                </div>
                <Select
                    classNamePrefix="multi-selector"
                    options={options}
                    isMulti
                    value={currentFilter.status}
                    onChange={handleChange}
                    noOptionsMessage={() => t('477_MultiSelector_noOptions')}
                    placeholder={t('478_MultiSelector_placeholder')}
                    components={{ MultiValueLabel }}
                />
            </div>
            <div className={styles.holder}>
                <div className={styles.subTitle}>
                    {t('524_Automats_address')}
                </div>
                <TextFieldNote
                    value={currentFilter.address || ''}
                    onChange={onSetSearch}
                    notes={notes}
                    onClick={onClickOnNote}
                />
            </div>
            <div className={styles.holder}>
                <div className={styles.subTitle}>{t('513_Automats_sales')}</div>
                <div className={styles.inputHolder}>
                    <div className={styles.lable}>
                        {t('525_Automats_sales_min_day')}
                    </div>
                    <TextField
                        value={currentFilter.minDay || ''}
                        onChange={(e) =>
                            onChangeValue(e.target.value, 'minDay')
                        }
                    />
                </div>
                <div className={styles.inputHolder}>
                    <div className={styles.lable}>
                        {t('525_Automats_sales_max_day')}
                    </div>
                    <TextField
                        value={currentFilter.maxDay || ''}
                        onChange={(e) =>
                            onChangeValue(e.target.value, 'maxDay')
                        }
                    />
                </div>
                <div className={styles.inputHolder}>
                    <div className={styles.lable}>
                        {t('525_Automats_sales_min_week')}
                    </div>
                    <TextField
                        value={currentFilter.minWeek || ''}
                        onChange={(e) =>
                            onChangeValue(e.target.value, 'minWeek')
                        }
                    />
                </div>
                <div className={styles.inputHolder}>
                    <div className={styles.lable}>
                        {t('525_Automats_sales_max_week')}
                    </div>
                    <TextField
                        value={currentFilter.maxWeek || ''}
                        onChange={(e) =>
                            onChangeValue(e.target.value, 'maxWeek')
                        }
                    />
                </div>
                <div className={styles.inputHolder}>
                    <div className={styles.lable}>
                        {t('525_Automats_sales_min_month')}
                    </div>
                    <TextField
                        value={currentFilter.minMonth || ''}
                        onChange={(e) =>
                            onChangeValue(e.target.value, 'minMonth')
                        }
                    />
                </div>
                <div className={styles.inputHolder}>
                    <div className={styles.lable}>
                        {t('525_Automats_sales_max_month')}
                    </div>
                    <TextField
                        value={currentFilter.maxMonth || ''}
                        onChange={(e) =>
                            onChangeValue(e.target.value, 'maxMonth')
                        }
                    />
                </div>
            </div>
            <div className={styles.btnBlock}>
                <Button onClick={onHandleSave}>{t('459_Search_search')}</Button>
                <span className={styles.cancel}>
                    <Button
                        kind={ButtonKind.Link}
                        onClick={onHandleClearFilter}
                    >
                        {t('518_Automats_clear_filter')}
                    </Button>
                </span>
            </div>
        </Modal>
    )
}

export default AutomatsFilter

import React, { ChangeEvent, FC, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AutomatKioskGoodsItem, GoodsItem } from '../../../../types'
import { useAppSelector } from '../../../hooks/store'
import { selectBrands, selectGoods } from '../../../../store/slices/storage'

import { ReactComponent as DeleteIcon } from '../../../../assets/icons/delete.svg'

import {
    OptionSelectorType,
    SearchSelector,
} from '../../../simples/SearchSelector'
import { TextField } from '../../../simples/TextField'

import styles from './Goods.module.scss'

const priceFieldClasses = {
    input: styles.priceFieldInput,
}

type Props = {
    data?: AutomatKioskGoodsItem
    automatGoods?: Array<AutomatKioskGoodsItem>
    onChange?: (data: AutomatKioskGoodsItem) => void
    onDelete?: (data?: AutomatKioskGoodsItem) => void
}

export const Row: FC<Props> = ({
    data,
    automatGoods = [],
    onChange,
    onDelete,
}) => {
    const { t } = useTranslation()

    const goods = useAppSelector(selectGoods)
    const brands = useAppSelector(selectBrands)
    const [currentBrand, setCurrentBrand] = useState<number>()

    useEffect(() => {
        if (data && data.good) {
            setCurrentBrand(data.good.brand.id)
        }
    }, [])

    const goodsOptions = useMemo(() => {
        return goods
            .filter((good) => good.brand.id === currentBrand)
            .filter(
                (goodsItem) =>
                    (data && data.goodsItemId === goodsItem.id) ||
                    automatGoods.findIndex(
                        (agi) => agi.goodsItemId === goodsItem.id
                    ) === -1
            )

            .map((goodItem) => ({ value: goodItem.name, id: goodItem.id }))
    }, [goods, currentBrand, data, automatGoods])

    const brandsOptions = useMemo(() => {
        return brands.map((goodItem) => ({
            value: goodItem.name,
            id: goodItem.id,
        }))
    }, [brands])

    const handleGoodsItemChange = (option: OptionSelectorType) => {
        if (option.value.length) {
            if (data) {
                onChange &&
                    onChange({
                        ...data,
                        goodsItemId: option.id,
                    })
            } else {
                const goodsItem = goods.find((gi) => gi.id === option.id)

                if (!goodsItem) {
                    return
                }

                onChange &&
                    onChange({
                        goodsItemId: option.id,
                        price: 0,
                        remains: 0,
                        good: goodsItem,
                    })
            }
        }
    }

    const handleChangeBrand = (option: OptionSelectorType) => {
        setCurrentBrand(option.id)
    }

    const handlePriceChange = (event: ChangeEvent<HTMLInputElement>) => {
        if (!data) {
            throw new Error('Data is empty')
        }

        const value = Math.abs(+event.target.value)

        onChange &&
            onChange({
                ...data,
                price: value,
            })
    }

    const handleDelete = () => {
        onDelete && onDelete(data)
    }

    return (
        <tr className={styles.row}>
            <td className={styles.rowBrand}>
                <SearchSelector
                    value={currentBrand || 0}
                    options={brandsOptions}
                    onClick={handleChangeBrand}
                    placeholder={t('134_FillingEditor_selectBrand')}
                    upperCase
                />
            </td>
            <td className={styles.rowGoods}>
                <SearchSelector
                    value={data && data.good ? data.good.id : 0}
                    options={goodsOptions}
                    onClick={handleGoodsItemChange}
                    placeholder={t('643_Goods_Choose_goods_item')}
                    disabled={!currentBrand}
                    upperCase
                />
            </td>
            <td className={styles.rowPrice}>
                <TextField
                    type={'number'}
                    value={data && data.price && currentBrand ? data.price : ''}
                    onChange={handlePriceChange}
                    disabled={!data && !currentBrand}
                    classes={priceFieldClasses}
                />
            </td>
            <td className={styles.actions}>
                <div onClick={handleDelete} className={styles.icon}>
                    <DeleteIcon />
                </div>
            </td>
        </tr>
    )
}

import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
    AutomatModel,
    AutomatModelTypes,
    AutomatSnackCell,
    AutomatType,
} from '../../../../types'
import { useAppSelector } from '../../../hooks/store'
import { selectGoods } from '../../../../store/slices/storage'
import { Automats as AutomatsService } from '../../../../services/Automats'

import styles from './SnackRefill.module.scss'

type Props = {
    automat: AutomatType
    model: AutomatModel
}

const SnackRefill: FC<Props> = ({ automat, model }) => {
    const { t } = useTranslation()
    const goods = useAppSelector(selectGoods)

    const [cells, setCells] = useState<Array<AutomatSnackCell>>(
        automat.cells || []
    )

    if (model.type !== AutomatModelTypes.Snack) {
        throw new Error('Automat model must be Snack')
    }

    const handleCellChange = (number: number, value: string) => {
        const newRemains = Math.round(Math.abs(+value))
        const newCells = cells.map((c) => {
            if (c.number === number) {
                return {
                    ...c,
                    remains: newRemains,
                }
            }

            return c
        })

        setCells(newCells)
    }

    useEffect(() => {
        const saveValue = () => {
            AutomatsService.setRemains(automat.id, {
                cells: cells,
            })
        }

        const timer = setTimeout(saveValue, 500)

        return () => {
            clearTimeout(timer)
        }
    }, [cells])

    return (
        <div className={styles.root}>
            {model.cellsCount > 0 ? (
                <table>
                    <thead>
                        <tr>
                            <th className={styles.number}>
                                {t('654_SnackRefill_Cell')}
                            </th>
                            <th>{t('656_SnackRefill_Goods_item')}</th>
                            <th className={styles.remainValues}>
                                {t('657_SnackRefill_Remains')}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {Array(model.cellsCount)
                            .fill(1)
                            .map((_, index) => {
                                const number = index + 1

                                const cell = cells.find(
                                    (c) => c.number === number
                                )
                                const goodsItem = cell
                                    ? goods.find(
                                          (gi) => gi.id === cell.goodsItemId
                                      )
                                    : undefined

                                return (
                                    <tr key={number}>
                                        <td className={styles.number}>
                                            {number}.
                                        </td>
                                        <td>
                                            {goodsItem ? goodsItem.name : '--'}
                                        </td>
                                        <td className={styles.remainValues}>
                                            <input
                                                className={styles.input}
                                                type={'number'}
                                                value={
                                                    cell && cell.remains
                                                        ? cell.remains
                                                        : ''
                                                }
                                                onChange={(event) =>
                                                    handleCellChange(
                                                        number,
                                                        event.target.value
                                                    )
                                                }
                                                disabled={!cell}
                                            />
                                            <span className={styles.unit}>
                                                {t('658_SnackRefill_items')}
                                            </span>
                                        </td>
                                    </tr>
                                )
                            })}
                    </tbody>
                </table>
            ) : (
                <div>{t('659_SnackRefill_No_cells')}</div>
            )}
        </div>
    )
}

export default SnackRefill

import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
    AutomatKioskGoodsItem,
    AutomatModel,
    AutomatModelTypes,
    AutomatType,
} from '../../../../types'
import { Automats as AutomatsService } from '../../../../services/Automats'
import { useAppDispatch } from '../../../hooks/store'
import { SubHeader } from '../SubHeader'
import { Goods as KioskGoods } from '../Goods'
import { Button } from '../../../simples/Button'

import styles from './Settings.module.scss'
import { showToast } from '../../../../store/slices/site'

type Props = {
    automat: AutomatType
    onSubmit: (automat: AutomatType) => void
    model: AutomatModel
}

export const Goods: FC<Props> = ({
    automat: automatDefault,
    onSubmit,
    model,
}) => {
    const { t } = useTranslation()
    const dispatch = useAppDispatch()

    const [automat, setAutomat] = useState(automatDefault)
    const [submiting, setSubmiting] = useState(false)
    const [changed, setChanged] = useState(false)

    useEffect(() => {
        if (automatDefault !== automat) {
            setAutomat(automatDefault)
        }
    }, [automatDefault])

    if (model.type !== AutomatModelTypes.Kiosk) {
        throw new Error('Automat model must be Kiosk')
    }

    const handleGoodsChange = (goods: Array<AutomatKioskGoodsItem>) => {
        setAutomat({
            ...automat,
            goods,
        })
        setChanged(true)
    }

    const save = async () => {
        setSubmiting(true)
        const updatedAutomat = await AutomatsService.update(automat.id, {
            goods: automat.goods,
        })

        setSubmiting(false)
        setChanged(false)

        dispatch(showToast(t('50_GeneralSettings_saveSuccessMessage')))

        onSubmit(updatedAutomat)
    }

    return (
        <div className={styles.goodsSection}>
            <SubHeader>{t('646_Settings_goods')}</SubHeader>

            <div className={styles.goodsBlock}>
                <KioskGoods
                    goods={automat.goods || []}
                    onChange={handleGoodsChange}
                />

                <div className={styles.buttons}>
                    <Button
                        onClick={save}
                        loading={submiting}
                        disabled={!changed}
                    >
                        {t('60_GeneralSettings_update')}
                    </Button>
                </div>
            </div>
        </div>
    )
}

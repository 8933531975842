import React, { FC } from 'react'
import moment from 'moment'
import { useTranslation } from 'react-i18next'

import { ReactComponent as RedIcon } from '../../../../assets/icons/red.svg'
import { ReactComponent as YellowIcon } from '../../../../assets/icons/yellow.svg'
import { ReactComponent as GreenIcon } from '../../../../assets/icons/green.svg'

import { AutomatLog, AutomatLogLevels } from '../../../../types'
import styles from './Logs.module.scss'
import { timeLocalParser } from '../../../../utils/helpers'

type Props = {
    log: AutomatLog
}

export const LogItem: FC<Props> = ({ log }) => {
    const { t } = useTranslation()

    return (
        <div className={styles.logItem}>
            <div className={styles.logItemLeft} />

            <div className={styles.logItemRight}>
                {log.level === AutomatLogLevels.Error ? (
                    <RedIcon className={styles.logItemIcon} />
                ) : log.level === AutomatLogLevels.Warn ? (
                    <YellowIcon className={styles.logItemIcon} />
                ) : (
                    <GreenIcon className={styles.logItemIcon} />
                )}

                <div className={styles.logItemTime}>
                    {moment(log.timeAt).format('YYYY-MM-DD HH:mm:ss')}
                </div>
                <div className={styles.logItemMessage}>{log.message}</div>
            </div>
        </div>
    )
}

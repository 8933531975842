import React, { FC, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import _ from 'lodash'
import { Dashboard as DashboardService } from '../../../services/Dashboard'

import { ColumnChart } from '../../simples/LineChart'
import { TimelineChart } from '../../simples/TimelineChart'
import { Spinner } from '../../simples/Spinner'
import styles from './SalesChart.module.scss'
import { getTimezone } from '../../../utils/date'

export enum SalesChartType {
    Today = 'today',
    PerWeek = 'perWeek',
    PerMonth = 'perMonth',
    PerYear = 'perYear',
}

type ColumnTypeData = {
    type: 'column'
    data: Array<
        Array<{
            label: string
            value: number
        }>
    >
}

type TimelineTypeData = {
    type: 'timeline'
    series: Array<{
        legend: string
        data: Array<{
            label: string
            value: number
        }>
    }>
}

export type Data = {
    data: ColumnTypeData | TimelineTypeData
    income: {
        today: number
        sevenDays: number
        thirtyDays: number
    }
}

type Props = {
    type: SalesChartType
    onChangeType: (type: SalesChartType) => void
    automatId?: number
}

const SalesChart: FC<Props> = ({ type, onChangeType, automatId }) => {
    const { t } = useTranslation()

    const [data, setData] = useState<Data>()
    const [activeSeries, setActiveSeries] = useState<Array<number>>()
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if (!data) {
            return
        }

        if (data.data.type !== 'timeline' || activeSeries) {
            return
        }

        setActiveSeries(data.data.series.map((s, index) => index))
    }, [data])

    const handleActiveSeriesChange = (index: number, checked: boolean) => {
        if (checked) {
            setActiveSeries(activeSeries ? [...activeSeries, index] : [])
        } else {
            setActiveSeries(
                activeSeries ? activeSeries.filter((as) => as !== index) : []
            )
        }
    }

    useEffect(() => {
        setLoading(true)
        DashboardService.getSalesSchedule({
            type,
            automatId,
            timezone: getTimezone(),
        }).then((data) => {
            setLoading(false)
            setData(data)
        })
    }, [type, automatId])

    return (
        <div className={styles.root}>
            <div className={styles.chart}>
                <div className={styles.chartTop}>
                    <div className={styles.tabs}>
                        <button
                            className={classNames(styles.tab, {
                                [styles.active]: type === SalesChartType.Today,
                            })}
                            onClick={() => onChangeType(SalesChartType.Today)}
                        >
                            {t('485_SalesChart_forToday')}
                        </button>
                        <button
                            className={classNames(styles.tab, {
                                [styles.active]:
                                    type === SalesChartType.PerWeek,
                            })}
                            onClick={() => onChangeType(SalesChartType.PerWeek)}
                        >
                            {t('486_SalesChart_perWeek')}
                        </button>
                        <button
                            className={classNames(styles.tab, {
                                [styles.active]:
                                    type === SalesChartType.PerMonth,
                            })}
                            onClick={() =>
                                onChangeType(SalesChartType.PerMonth)
                            }
                        >
                            {t('487_SalesChart_perMonth')}
                        </button>
                        <button
                            className={classNames(styles.tab, {
                                [styles.active]:
                                    type === SalesChartType.PerYear,
                            })}
                            onClick={() => onChangeType(SalesChartType.PerYear)}
                        >
                            {t('488_SalesChart_perYear')}
                        </button>
                    </div>

                    {data && data.data.type === 'timeline' && (
                        <div className={styles.legends}>
                            {data.data.series.map((seriesItem, index) => {
                                const isChecked =
                                    activeSeries?.indexOf(index) !== -1
                                return (
                                    <div
                                        className={styles.legend}
                                        key={index}
                                        onClick={() =>
                                            handleActiveSeriesChange(
                                                index,
                                                !isChecked
                                            )
                                        }
                                    >
                                        <div
                                            className={classNames(
                                                styles.legendCheckbox,
                                                { [styles.active]: isChecked }
                                            )}
                                        />
                                        <div className={styles.legendLabel}>
                                            {seriesItem.legend}
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    )}
                </div>

                <div
                    className={classNames(styles.chartContainer, {
                        [styles.loading]: loading,
                    })}
                >
                    {!data ? (
                        <ColumnChart data={[]} />
                    ) : data.data.type === 'column' ? (
                        <ColumnChart
                            data={
                                data.data.data.length > 0
                                    ? data.data.data[0].map((dataItem) => {
                                          if (type === SalesChartType.PerWeek) {
                                              return {
                                                  ...dataItem,
                                                  label: t(
                                                      `491_SalesChart_dayOfWeek_${dataItem.label}`
                                                  ),
                                              }
                                          }

                                          return dataItem
                                      })
                                    : []
                            }
                        />
                    ) : data.data.type === 'timeline' ? (
                        <TimelineChart
                            data={data.data.series}
                            activeSeries={activeSeries}
                        />
                    ) : null}

                    <div className={styles.chartLoader}>
                        <Spinner />
                    </div>
                </div>
            </div>

            {data && (
                <div className={styles.income}>
                    <table>
                        <thead>
                            <tr>
                                <th>{t('489_SalesChart_timeSpan')}</th>
                                <th>{t('490_SalesChart_amountOfProceeds')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className={styles.periodTitle}>
                                    {t('485_SalesChart_forToday')}
                                </td>
                                <td className={styles.periodValue}>
                                    {_.round(data.income.today, 2)}
                                </td>
                            </tr>
                            <tr>
                                <td className={styles.periodTitle}>
                                    {t('486_SalesChart_perWeek')}
                                </td>
                                <td className={styles.periodValue}>
                                    {_.round(data.income.sevenDays, 2)}
                                </td>
                            </tr>
                            <tr>
                                <td className={styles.periodTitle}>
                                    {t('487_SalesChart_perMonth')}
                                </td>
                                <td className={styles.periodValue}>
                                    {_.round(data.income.thirtyDays, 2)}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            )}
        </div>
    )
}

export default SalesChart

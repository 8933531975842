import React, { FC } from 'react'
import { NewsType } from '../../../types'
import styles from './WidgetNews.module.scss'

type Props = {
    newsItem: NewsType
}

export const NewsItem: FC<Props> = ({ newsItem }) => {
    return (
        <div className={styles.newsItem}>
            <div
                className={styles.newsItemPhoto}
                style={{
                    backgroundImage: newsItem.photoPath
                        ? `url("${newsItem.photoPath}")`
                        : undefined,
                }}
            />
            <div className={styles.newsItemDescription}>{newsItem.title}</div>
        </div>
    )
}

import React, {
    FC,
    useState,
    useEffect,
    useRef,
    useMemo,
    MouseEvent,
    ChangeEvent,
} from 'react'
import classNames from 'classnames'
import styles from './HorizontalSlider.module.scss'

const isError = (value: number, maxValue: number) => {
    return value < 0 || value > maxValue
}

type Props = {
    defaultValue: number
    maxValue: number
    onChange: (value: number, isError?: boolean) => void
    unit?: string
    round?: boolean
    numberOfDecimals?: number
    classes?: {
        sliderIndicator?: string
    }
    disabled?: boolean
    emptyInput?: boolean
}

const HorizontalSlider: FC<Props> = ({
    defaultValue,
    maxValue,
    onChange,
    unit,
    round,
    numberOfDecimals,
    classes = {},
    disabled = false,
    emptyInput = false,
}) => {
    const handlerRef = useRef<HTMLDivElement>(null)
    const indicatorRef = useRef<HTMLDivElement>(null)
    const sliderRef = useRef<HTMLDivElement>(null)

    const [value, setValue] = useState(defaultValue)

    const startDragCounter = useRef(0)
    const [dragging, setDragging] = useState(false)
    const draggingRef = useRef(dragging)
    draggingRef.current = dragging

    const offsetX = useRef(0)

    useEffect(() => {
        const calcValue = (clientX: number) => {
            if (!sliderRef.current) {
                return
            }

            const { left, width } = sliderRef.current.getBoundingClientRect()

            let x = clientX - left - offsetX.current
            if (x < 0) {
                x = 0
            } else if (x > width) {
                x = width
            }

            let value = (x / width) * maxValue
            if (round) {
                value = Math.round(value)
            } else if (numberOfDecimals && numberOfDecimals > 0) {
                const multiplier = Math.pow(10, numberOfDecimals)
                value = Math.round(value * multiplier) / multiplier
            }

            return value
        }

        const calcAndSetValue = (clientX: number) => {
            const value = calcValue(clientX)

            if (value !== undefined) {
                setValue(value)
            }
        }

        const handleMouseDown = (event: MouseEvent<HTMLDivElement>) => {
            const { clientX } = event

            if (
                !event ||
                !event.target ||
                !handlerRef ||
                !handlerRef.current ||
                !handlerRef.current.contains(event.target as HTMLElement)
            ) {
                return
            }

            startDragCounter.current = 1

            if (indicatorRef.current) {
                const { right } = indicatorRef.current.getBoundingClientRect()

                offsetX.current = clientX - right
            }
        }

        const handleMouseUp = (event: MouseEvent<HTMLDivElement>) => {
            const { clientX } = event

            if (startDragCounter.current < 1) {
                return
            }

            if (startDragCounter.current > 5) {
                calcAndSetValue(clientX)
                setDragging(false)
            }

            startDragCounter.current = 0
        }

        const handleMouseMove = (event: MouseEvent<HTMLDivElement>) => {
            const { clientX } = event

            if (startDragCounter.current > 5) {
                if (!draggingRef.current) {
                    setDragging(true)
                }

                calcAndSetValue(clientX)
            } else if (startDragCounter.current > 0) {
                startDragCounter.current++
            }
        }

        // @ts-ignore
        document.addEventListener('mousedown', handleMouseDown)
        // @ts-ignore
        document.addEventListener('mouseup', handleMouseUp)
        // @ts-ignore
        document.addEventListener('mousemove', handleMouseMove)

        return () => {
            // @ts-ignore
            document.removeEventListener('mousedown', handleMouseDown)
            // @ts-ignore
            document.removeEventListener('mouseup', handleMouseUp)
            // @ts-ignore
            document.removeEventListener('mousemove', handleMouseMove)
        }
    }, [maxValue, round, numberOfDecimals])

    const widthIndicator = useMemo(() => {
        let width = maxValue > 0 ? (value / maxValue) * 100 : 0

        if (width < 0) {
            width = 0
        } else if (width > 100) {
            width = 100
        }

        return width
    }, [value, maxValue])

    const error = useMemo(() => {
        return isError(value, maxValue)
    }, [value, maxValue])

    const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        const value = +event.target.value
        setValue(value)
    }

    useEffect(() => {
        const saveValue = () => {
            if (!isError(value, maxValue)) {
                onChange(value)
            }
        }

        const timer = setTimeout(saveValue, 500)

        return () => {
            clearTimeout(timer)
        }
    }, [value])

    return (
        <div
            className={classNames(styles.root, {
                [styles.error]: error,
                [styles.disabled]: disabled,
                [styles.ends]: widthIndicator < 10,
            })}
        >
            <div className={styles.slider} ref={sliderRef}>
                <div className={styles.sliderIndicatorContainer}>
                    <div
                        className={classNames(
                            styles.sliderIndicator,
                            classes.sliderIndicator
                        )}
                        style={{
                            width: `${widthIndicator}%`,
                        }}
                        ref={indicatorRef}
                    >
                        <div
                            className={styles.sliderHandler}
                            ref={handlerRef}
                        />
                    </div>
                </div>
            </div>

            <input
                type={'number'}
                value={emptyInput ? '' : value + ''}
                onChange={handleInputChange}
                className={styles.input}
            />

            {unit && <div className={styles.unit}>{unit}</div>}

            {dragging && <div className={styles.cursor} />}
        </div>
    )
}

export default HorizontalSlider

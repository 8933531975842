import React, { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import {
    Orders as OrdersService,
    ExportFields,
    ExportType,
} from '../../../services/Orders'
import { useDate } from '../../hooks/date'
import { Modal } from '../../simples/Modal'
import { CheckBox } from '../../simples/CheckBox'
import { Button, ButtonKind } from '../../simples/Button'
import { TextField } from '../../simples/TextField'
import { Selector } from '../../simples/Selector'
import styles from './OrdersExport.module.scss'

const modalClasses = {
    modal: styles.modal,
}

const checkboxClasses = {
    label: styles.checkboxLabel,
}

const initFields: Array<ExportFields> = [
    'productType',
    'productName',
    'tasteName',
    'drinkVolume',
    'positionPrice',
    'purchaseCost',
    'purchaseDate',
    'payType',
    'automatName',
    'automatSerialNumber',
    'automatAddr',
    'automatStatus',
    'automatWaterRemaining',
    'automatConsumption',
    'promo',
]

const formats: { [format in ExportType]: number } = {
    XLSX: 1,
    CSV: 2,
}

const formatOptions = [
    { value: 1, label: 'XLSX' },
    { value: 2, label: 'CSV' },
]

type Props = {
    onClose?: () => void
    start?: Date
    automatId?: number
}

const OrdersExport: FC<Props> = ({ onClose, start, automatId }) => {
    const { t } = useTranslation()

    const {
        value: dateStart,
        setValue: setDateStart,
        error: dateStartError,
        touched: dateStartTouched,
        setTouched: setDateStartTouched,
    } = useDate(moment(start).format('YYYY-MM-DD'), 'YYYY-MM-DD')
    const {
        value: dateEnd,
        setValue: setDateEnd,
        error: dateEndError,
        touched: dateEndTouched,
        setTouched: setDateEndTouched,
    } = useDate(moment().format('YYYY-MM-DD'), 'YYYY-MM-DD')

    const [fields, setField] = useState<Array<ExportFields>>(initFields)
    const [format, setFormat] = useState<ExportType>('XLSX')
    const [downloading, setDownloading] = useState(false)

    const options: Array<{ value: ExportFields; label: string }> = [
        { value: 'productType', label: t('496_OrdersExport_productType') },
        { value: 'productName', label: t('497_OrdersExport_productName') },
        { value: 'tasteName', label: t('581_OrdersExport_tasteName') },
        { value: 'drinkVolume', label: t('582_OrdersExport_drinkVolume') },
        { value: 'positionPrice', label: t('498_OrdersExport_positionPrice') },
        { value: 'purchaseCost', label: t('583_OrdersExport_purchaseCost') },
        { value: 'promo', label: t('586_OrdersExport_promotion') },
        { value: 'purchaseDate', label: t('499_OrdersExport_purchaseDate') },
        { value: 'payType', label: t('500_OrdersExport_payType') },
        { value: 'automatName', label: t('501_OrdersExport_automatName') },
        {
            value: 'automatSerialNumber',
            label: t('502_OrdersExport_automatSerialNumber'),
        },
        { value: 'automatAddr', label: t('503_OrdersExport_automatAddr') },
        { value: 'automatStatus', label: t('504_OrdersExport_automatStatus') },
        {
            value: 'automatWaterRemaining',
            label: t('505_OrdersExport_automatWaterRemaining'),
        },
        {
            value: 'automatConsumption',
            label: t('506_OrdersExport_automatConsumption'),
        },
    ]

    const handleCheckboxChange = (value: ExportFields, checked: boolean) => {
        if (checked) {
            setField([...fields, value])
        } else {
            setField(fields.filter((f) => f != value))
        }
    }

    const handleFormatChange = (value: number) => {
        const formatsKeys = Object.keys(formats) as Array<ExportType>
        const newFormat = formatsKeys.find((m) => formats[m] === value)

        if (!newFormat) {
            throw new Error('Format not found')
        }

        setFormat(newFormat)
    }

    const isValid = !dateStartError && !dateEndError && fields.length > 0

    const download = async () => {
        if (!isValid) {
            throw new Error('Form is invalid')
        }

        setDownloading(true)
        await OrdersService.download(format as ExportType, fields, {
            start: moment(dateStart).toDate(),
            end: moment(dateEnd).add(1, 'day').toDate(),
            automatId,
        })
        setDownloading(false)
        onClose && onClose()
    }

    return (
        <Modal showClose onClose={onClose} classes={modalClasses}>
            <div className={styles.title}>
                {t('507_OrdersExport_downloadOptions')}
            </div>

            <div className={styles.periodSection}>
                <div className={styles.periodTitle}>
                    {t('519_OrdersExport_period')}
                </div>

                <div className={styles.periodField}>
                    <TextField
                        type={'date'}
                        value={dateStart}
                        onChange={(e) => setDateStart(e.target.value)}
                        onBlur={() => setDateStartTouched(true)}
                        status={
                            dateStartError && dateStartTouched ? 2 : undefined
                        }
                        note={
                            dateStartError && dateStartTouched
                                ? t(dateStartError)
                                : ''
                        }
                        placeholder={t('520_OrdersExport_dateStart')}
                    />
                </div>
                <div className={styles.periodField}>
                    <TextField
                        type={'date'}
                        value={dateEnd}
                        onChange={(e) => setDateEnd(e.target.value)}
                        onBlur={() => setDateEndTouched(true)}
                        status={dateEndError && dateEndTouched ? 2 : undefined}
                        note={
                            dateEndError && dateEndTouched
                                ? t(dateEndError)
                                : ''
                        }
                        placeholder={t('521_OrdersExport_dateEnd')}
                    />
                </div>
            </div>

            <div className={styles.checkboxesList}>
                {options.map((option) => (
                    <div className={styles.checkboxesItem}>
                        <CheckBox
                            checked={fields.indexOf(option.value) !== -1}
                            onChange={(checked) =>
                                handleCheckboxChange(option.value, checked)
                            }
                            label={option.label}
                            classes={checkboxClasses}
                        />
                    </div>
                ))}
            </div>

            <div className={styles.formatSection}>
                <div className={styles.formatTitle}>
                    {t('532_OrdersExport_format')}
                </div>

                <Selector
                    options={formatOptions}
                    label={t('532_OrdersExport_format')}
                    value={formats[format]}
                    onChange={handleFormatChange}
                />
            </div>

            <div className={styles.buttons}>
                <Button kind={ButtonKind.Link} onClick={onClose}>
                    {t('508_OrdersExport_cancel')}
                </Button>
                <Button
                    onClick={download}
                    loading={downloading}
                    disabled={!isValid}
                >
                    {t('509_OrdersExport_export')}
                </Button>
            </div>
        </Modal>
    )
}

export default OrdersExport

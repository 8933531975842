import api from '../utils/api'
import { endpoints } from '../constants'
import { BaseDosagesType, BaseDosageType, TasteType } from '../types'
import { route } from '../utils/route'
import _ from 'lodash'

type TasteParamType = {
    search?: string
    productId?: number
    offset?: number
    limit?: number
    disableTranslation?: boolean
    disableUppercase?: boolean
}

type GetTasteResponse = {
    data: Array<TasteType>
}

type CreateTasteParams = {
    name: string
    productId: number
    color: string
    componentOnAmount: number
    mediaKey: string
    components: Array<{
        name: string
        amount: number
        measurementUnit: string
    }>
    baseDosages: Array<{
        drinkVolume: number
        water: number
        product: number
        conversionFactor: number
    }>
}
type CreateTasteResponse = {
    data: TasteType
}

type UpdateTasteParams = {
    name: string
    productId: number
    color: string
    componentOnAmount: number
    mediaKey: string
    components: Array<{
        id?: number
        name: string
        amount: number
        measurementUnit: string
    }>
    baseDosages: Array<{
        id?: number
        drinkVolume: number
        water: number
        product: number
        conversionFactor: number
    }>
}

type UpdateTasteResponse = {
    data: TasteType
}

const prepareTaste = (
    taste: TasteType,
    disableUppercase: boolean = false
): TasteType => {
    return {
        ...taste,
        name: disableUppercase ? taste.name : taste.name.toUpperCase(),
    }
}

export class TasteService {
    static async getTastes(params: TasteParamType): Promise<Array<TasteType>> {
        const {
            search = '',
            offset = 0,
            limit = 30,
            productId,
            disableTranslation,
            disableUppercase,
        } = params

        const queries = []
        if (search) {
            queries.push(`search=${search}`)
        }
        if (offset) {
            queries.push(`offset=${offset}`)
        }
        if (limit) {
            queries.push(`limit=${limit}`)
        }
        if (productId) {
            queries.push(`productId=${productId}`)
        }
        if (disableTranslation) {
            queries.push(`disableTranslation=1`)
        }

        let endpoint = endpoints.getTastes
        if (queries.length > 0) {
            endpoint += `?` + queries.join('&')
        }

        const response = await api.get<GetTasteResponse>(endpoint)

        if (
            !response.data ||
            !response.data.data ||
            !_.isArray(response.data.data)
        ) {
            return []
        }

        return response.data.data.map((taste) =>
            prepareTaste(taste, disableUppercase)
        )
    }

    static async creteTaste(data: CreateTasteParams): Promise<TasteType> {
        const response = await api.post<CreateTasteResponse>(
            endpoints.createTaste,
            {
                ...data,
                baseDosages: JSON.stringify(data.baseDosages),
                components: JSON.stringify(data.components),
            }
        )
        return response.data.data
    }

    static async updateTaste(
        id: number,
        data: UpdateTasteParams
    ): Promise<TasteType> {
        const response = await api.post<UpdateTasteResponse>(
            route(endpoints.updateTaste, { id }),
            {
                ...data,
                baseDosages: JSON.stringify(data.baseDosages),
                components: JSON.stringify(data.components),
            }
        )
        return response.data.data
    }

    static async deleteTaste(id: number) {
        await api.delete<CreateTasteResponse>(
            route(endpoints.deleteTaste, { id })
        )
    }

    static async fetchBaseDosages(): Promise<BaseDosagesType[]> {
        const response = await api.get(endpoints.fetchBaseDosages)
        return response.data.data
    }
}

import React, { FC, useEffect, useMemo, useState } from 'react'
import styles from './Settings.module.scss'
import { SubHeader } from '../SubHeader'
import { OptionType, Selector } from '../../../simples/Selector'
import { Button } from '../../../simples/Button'
import {
    AutomatContainerData,
    AutomatContainerTypes,
    AutomatModel,
    AutomatModelTypes,
    AutomatType,
    FillingContainerType,
} from '../../../../types'
import { ContainerItem } from './ContainerItem'
import { AutomatContainerEditor } from '../../AutomatContainerEditor'
import { ContainerFormType, DosagesFormType, FormType } from './Types/Types'
import { useTranslation } from 'react-i18next'
import { useAppDispatch, useAppSelector } from '../../../hooks/store'
import { selectAuthUser } from '../../../../store/slices/auth'
import { selectFillings, selectTastes } from '../../../../store/slices/storage'
import { Automats as AutomatsService } from '../../../../services/Automats'
import { showToast } from '../../../../store/slices/site'
import { FillingsService } from '../../../../services/Fillings'

const setSelectClasses = {
    controller: styles.setSelectController,
}
const setButtonClasses = {
    button: styles.setSelectButton,
}

type Props = {
    automat: AutomatType
    onSubmit: (automat: AutomatType) => void
    model: AutomatModel
}

export const Containers: FC<Props> = ({
    automat: automatDefault,
    onSubmit,
    model,
}) => {
    const { t } = useTranslation()
    const user = useAppSelector(selectAuthUser)
    const fillings = useAppSelector(selectFillings)
    const tastes = useAppSelector(selectTastes)
    const dispatch = useAppDispatch()

    const [automat, setAutomat] = useState<FormType>(() => ({
        ...automatDefault,
        longitude: automatDefault.longitude + '',
        latitude: automatDefault.latitude + '',
    }))

    useEffect(() => {
        setAutomat({
            ...automatDefault,
            longitude: automatDefault.longitude + '',
            latitude: automatDefault.latitude + '',
        })
    }, [automatDefault])

    const [editedContainerNumber, setEditedContainerNumber] = useState(0)
    const [editedContainerType, setEditedContainerType] =
        useState<AutomatContainerTypes>()
    const [editedContainerData, setEditedContainerData] =
        useState<AutomatContainerData>()

    const [copedFillingId, setCopedFillingId] = useState<number>(
        automatDefault.automatFillingId || 0
    )
    const [loadedSet, setLoadedSet] = useState<number>()

    const [containersSaving, setContainersSaving] = useState(false)

    if (model.type !== AutomatModelTypes.Beverages) {
        throw new Error('Automat model must be Beverages')
    }

    const fillingsOptions: OptionType[] = useMemo(() => {
        return fillings
            .filter((f) => f.automatModelId === automat.automatModelId)
            .map((t) => {
                return {
                    label: t.name,
                    value: t.id,
                }
            })
    }, [fillings, automat.automatModelId])

    const handleCopyFromSet = () => {
        if (!copedFillingId) {
            return
        }

        const filling = fillings.find((f) => f.id === copedFillingId)

        if (!filling) {
            throw new Error('Filling not found')
        }

        if (filling.automatModelId !== automat.automatModelId) {
            return
        }

        setLoadedSet(copedFillingId)

        const newDosages: Array<DosagesFormType> = []
        const hasSmallDrinkOfContainers: Array<boolean> = []

        for (let i = 0; i < filling.containers.length; i++) {
            const container = filling.containers[i]
            let taste = tastes.find((t) => t.id === container.tasteId)

            if (!taste) {
                continue
            }

            hasSmallDrinkOfContainers[container.automatContainerId] =
                taste.baseDosages.length > 1

            for (let j = 0; j < taste.baseDosages.length; j++) {
                let dosage = taste.baseDosages[j]

                newDosages.push({
                    ...dosage,
                    id: null,
                    tasteId: taste.id,
                    price: 0,
                })
            }
        }

        const newContainers = filling.containers.map((c) => ({
            automatModelContainerId: c.automatContainerId,
            tasteId: c.tasteId,
            isActive: true,
            hasSmallDrink: hasSmallDrinkOfContainers[c.automatContainerId],
        }))

        setAutomat({
            ...automat,
            containers: newContainers,
            dosages: newDosages,
        })
    }

    const startUpdateContainer = (
        number: number,
        container?: AutomatContainerData
    ) => {
        setEditedContainerNumber(number)

        const modelContainer = model.containers.find((c) => c.number === number)

        if (!modelContainer) {
            throw new Error('Model container not found')
        }

        setEditedContainerType(modelContainer.type)

        if (container) {
            setEditedContainerData({
                tasteId: container.tasteId,
                dosages: automat.dosages.filter(
                    (d) => d.tasteId === container.tasteId
                ),
            })
        }
    }

    const closeContainerEditor = () => {
        setEditedContainerNumber(0)
        setEditedContainerData(undefined)
    }

    const saveContainer = (
        containerNumber: number,
        containerData?: AutomatContainerData,
        forcedToChangeDosages: boolean = false
    ) => {
        const automatModelContainer = model.containers.find(
            (c) => c.number === containerNumber
        )

        if (!automatModelContainer) {
            throw new Error('Automat model container not found')
        }

        const containerIndex = automat.containers.findIndex(
            (c) => c.automatModelContainerId === automatModelContainer.id
        )
        const container =
            containerIndex !== -1 ? automat.containers[containerIndex] : null

        // Полезные параметры для установки дозировок
        const prevTasteId = container ? container.tasteId : null
        const newTasteId = containerData ? containerData.tasteId : null

        const isThereOtherContainersWithPrevTasteId = prevTasteId
            ? !!automat.containers.find(
                  (c) =>
                      c.automatModelContainerId !== automatModelContainer.id &&
                      c.tasteId === prevTasteId
              )
            : false
        const isThereOtherContainersWithNewTasteId = newTasteId
            ? !!automat.containers.find(
                  (c) =>
                      c.automatModelContainerId !== automatModelContainer.id &&
                      c.tasteId === newTasteId
              )
            : false

        // Установка дозировок

        let minId =
            automat.dosages.length > 0
                ? Math.min(...automat.dosages.map((d) => d.id || 0))
                : 0

        if (minId > 0) {
            minId = 0
        }

        const changeDosagesByTasteId = () => {
            if (!containerData) {
                return automat.dosages
            }

            const obj: any = {}
            const objNotRegistr: any = {}
            const uniq = automat.dosages.map((i: any) => {
                if (i.id) {
                    obj[i.id] = { ...i }
                } else {
                    objNotRegistr[-1] = { ...i }
                }
            })

            const fDosage = [
                ...Object.values(obj)
                    .filter(
                        (d: any) =>
                            d.tasteId !== containerData.tasteId ||
                            (d.id !== undefined &&
                                containerData.dosages.findIndex(
                                    (cdd) => cdd.id === d.id
                                ) !== -1)
                    )
                    .map((d: any) => {
                        const updatedDosage = containerData.dosages.find(
                            (cdd) => cdd.id === d.id
                        )
                        return updatedDosage || d
                    }),
                ...containerData.dosages
                    .filter((d) => d.id === undefined)
                    .map((d) => ({
                        ...d,
                        id: --minId,
                    })),
            ]

            return fDosage
        }

        let newDosages = automat.dosages

        if (!newTasteId) {
            if (prevTasteId && !isThereOtherContainersWithPrevTasteId) {
                newDosages = automat.dosages.filter(
                    (d) => d.tasteId !== prevTasteId
                )
            }
        } else if (prevTasteId === newTasteId || forcedToChangeDosages) {
            newDosages = changeDosagesByTasteId()
        } else if (
            isThereOtherContainersWithPrevTasteId &&
            isThereOtherContainersWithNewTasteId
        ) {
            // Nothing
        } else if (isThereOtherContainersWithNewTasteId) {
            newDosages = automat.dosages.filter(
                (d) => d.tasteId !== prevTasteId
            )
        } else if (isThereOtherContainersWithPrevTasteId) {
            if (containerData) {
                const obj: { [id: string]: DosagesFormType } = {}
                ;[...automat.dosages, ...containerData.dosages].forEach(
                    (dosage) => {
                        if (dosage.id) {
                            obj[dosage.id] = { ...dosage }
                        } else {
                            const id = --minId
                            obj[id] = { ...dosage, id }
                        }
                    }
                )

                newDosages = Object.values(obj)
            }
        } else {
            if (containerData) {
                const n = automat.dosages.filter(
                    (d) => d.tasteId !== prevTasteId
                )
                newDosages = [...n, ...containerData.dosages]
                // newDosages = changeDosagesByTasteId()
            }
        }

        let newContainers: Array<ContainerFormType> = []

        if (containerData) {
            if (container) {
                newContainers = automat.containers.map((c, index) => {
                    if (index === containerIndex) {
                        return {
                            ...c,
                            tasteId: containerData.tasteId,
                            isActive: true,
                            hasSmallDrink: containerData.dosages.length > 1,
                        }
                    }

                    return c
                })
            } else {
                newContainers = [
                    ...automat.containers,
                    {
                        automatModelContainerId: automatModelContainer.id,
                        tasteId: containerData.tasteId,
                        isActive: true,
                        hasSmallDrink: containerData.dosages.length > 1,
                    },
                ]
            }
        } else {
            newContainers = automat.containers.filter(
                (c) => c.automatModelContainerId !== automatModelContainer.id
            )
        }

        setAutomat({
            ...automat,
            containers: newContainers,
            dosages: [...newDosages],
        })
    }

    // console.log('automat 1', automat)
    const containerEditorSubmit = (containerData: AutomatContainerData) => {
        saveContainer(editedContainerNumber, containerData)
        closeContainerEditor()
    }

    const saveContainers = async () => {
        setContainersSaving(true)

        try {
            const updatedAutomat = await AutomatsService.update(
                automatDefault.id,
                {
                    containers: automat.containers,
                    dosages: automat.dosages.map((d) => {
                        if (d && d.id && d.id < 0) {
                            const { id, ...rest } = d
                            return rest
                        }

                        return d
                    }),
                    automatFillingId: loadedSet,
                }
            )

            setContainersSaving(false)

            dispatch(showToast(t('50_GeneralSettings_saveSuccessMessage')))

            onSubmit(updatedAutomat)
        } catch (error) {
            setContainersSaving(false)
        }

        try {
            if (copedFillingId) {
                const filling = fillings.find((f) => f.id === copedFillingId)
                if (filling) {
                    const updatedFilling = await FillingsService.updateFilling(
                        copedFillingId,
                        {
                            ...filling,
                            containers: automat.containers.map((c) => ({
                                automatContainerId: c.automatModelContainerId,
                                tasteId: c.tasteId,
                            })) as FillingContainerType[],
                            companyId: user?.company.id,
                        }
                    )
                }
            }
        } catch (error) {
            // @ts-ignore
            console.log(error.response.data)
        }
    }

    const handleDeleteContainer = () => {
        if (!editedContainerData || !editedContainerNumber) {
            throw new Error('Edited container data are empty')
        }

        const deletedTestId = editedContainerData.tasteId
        const modelContainer = model.containers.find(
            (mc) => mc.number === editedContainerNumber
        )

        if (!modelContainer) {
            throw new Error('Model container is empty')
        }

        let newAutomat = {
            ...automat,
            containers: automat.containers.filter(
                (c) => c.automatModelContainerId !== modelContainer.id
            ),
        }

        if (
            automat.containers.findIndex(
                (c) =>
                    c.tasteId === deletedTestId &&
                    c.automatModelContainerId !== modelContainer.id
            ) === -1
        ) {
            newAutomat.dosages = automat.dosages.filter(
                (d) => d.tasteId !== deletedTestId
            )
        }

        closeContainerEditor()
        setAutomat(newAutomat)
    }

    return (
        <>
            <div className={styles.fillingSection}>
                <SubHeader>{t('522_Settings_automateFilling')}</SubHeader>

                <div className={styles.setPanel}>
                    <div className={styles.setSelectContainer}>
                        <Selector
                            label={t('523_Settings_selectAutomateFilling')}
                            options={fillingsOptions}
                            value={copedFillingId}
                            onChange={setCopedFillingId}
                            classes={setSelectClasses}
                        />
                    </div>
                    <Button
                        classNames={setButtonClasses}
                        onClick={handleCopyFromSet}
                    >
                        {t('524_Settings_accept')}
                    </Button>
                </div>

                <div key={loadedSet} className={styles.containersBlock}>
                    <div className={styles.containersList}>
                        <table>
                            <tr>
                                <th />
                                <th>{t('525_Settings_brand')}</th>
                                <th>{t('526_Settings_product')}</th>
                                <th>{t('527_Settings_taste')}</th>
                                <th>{t('528_Settings_priceLarge')}</th>
                                <th />
                                <th>{t('529_Settings_priceSmall')}</th>
                                <th />
                            </tr>

                            {[...model.containers]
                                .sort((c1, c2) => c1.number - c2.number)
                                .map((modelContainer, index) => {
                                    const container = automat.containers.find(
                                        (c) =>
                                            c.automatModelContainerId ===
                                            modelContainer.id
                                    )

                                    const tasteId = container
                                        ? container.tasteId
                                        : 0

                                    const dosages = tasteId
                                        ? automat.dosages.filter(
                                              (d) => d.tasteId === tasteId
                                          )
                                        : []

                                    return (
                                        <ContainerItem
                                            key={index}
                                            number={modelContainer.number}
                                            automat={automat}
                                            container={{
                                                tasteId,
                                                dosages,
                                            }}
                                            containerType={modelContainer.type}
                                            onChange={saveContainer}
                                            onEdit={startUpdateContainer}
                                        />
                                    )
                                })}
                        </table>
                    </div>

                    <div className={styles.buttons}>
                        <Button
                            onClick={saveContainers}
                            loading={containersSaving}
                        >
                            {t('60_GeneralSettings_update')}
                        </Button>
                    </div>
                </div>
            </div>

            {!!editedContainerNumber && !!editedContainerType && (
                <AutomatContainerEditor
                    automat={automatDefault}
                    data={editedContainerData}
                    containerType={editedContainerType}
                    onClose={closeContainerEditor}
                    onSubmit={containerEditorSubmit}
                    onDelete={handleDeleteContainer}
                />
            )}
        </>
    )
}

import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Layout } from '../../complexes/Layout'
import { Header } from '../../simples/tablePages/Header'
import styles from './EditCompanyPage.module.scss'
import { Company as CompanyType } from '../../../types'
import { useHistory, useParams } from 'react-router'
import { routes } from '../../../config'
import { Company as CompanyService } from '../../../services/Company'
import {
    CompanyEditForm,
    ResultDataForm,
} from '../../complexes/CompanyEditForm'

const EditCompanyPage = () => {
    const { t } = useTranslation()
    const navigation = useHistory()
    const { id = 0 } = useParams<{ id?: string }>()

    const [loading, setLoading] = useState(false)
    const [saveLoading, setSaveLoading] = useState(false)
    const [company, setCompany] = useState<CompanyType>()

    useEffect(() => {
        if (!id) {
            return
        }

        setLoading(true)

        CompanyService.getCompanyItem(+id).then((companyItem) => {
            setLoading(false)
            setCompany(companyItem)
        })
    }, [id])

    // const [modal, setModal] = useState<boolean>(false)
    // const [editTaste, setEditTaste] = useState<TasteType>()
    // const newLastTasteId = useRef(-1)
    // const tastesForDelete = useRef<Array<number>>([])

    const onHandleSave = async (data: ResultDataForm) => {
        setSaveLoading(true)

        const savedCompany = id
            ? await CompanyService.updateCompany(+id, {
                  name: data.name,
                  contacts: data.contacts.map((contact) => ({
                      ...contact,
                      confirmed: contact.confirmed ? true : false,
                  })),
              })
            : await CompanyService.create({
                  name: data.name,
                  contacts: data.contacts.map((contact) => ({
                      ...contact,
                      confirmed: contact.confirmed ? true : false,
                  })),
              })

        if (data.photo) {
            await CompanyService.updatePhoto(+id, data.photo)
        }

        setSaveLoading(false)
        navigation.push(routes.companies)
    }

    const onHandleCancel = () => {
        navigation.push(routes.companies)
    }

    return (
        <Layout>
            <Header
                text={
                    !id
                        ? t('357_EditCompanyPage_createCompany')
                        : t('358_EditCompanyPage_editCompany')
                }
            />

            {loading ? (
                <></>
            ) : company || id === 0 ? (
                <div className={styles.root}>
                    <CompanyEditForm
                        data={
                            company
                                ? {
                                      name: company.name,
                                      contacts: company.contacts || [],
                                      photo: company.photoPath || '',
                                  }
                                : undefined
                        }
                        onSave={onHandleSave}
                        onCancel={onHandleCancel}
                        loading={saveLoading}
                    />
                </div>
            ) : null}
        </Layout>
    )
}

export default EditCompanyPage

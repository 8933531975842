import classNames from 'classnames'
import React, { FC } from 'react'
import styles from './Table.module.scss'
type Props = {
    onClick?: () => void
}
const Tr: FC<Props> = ({ onClick = undefined, children }) => {
    return (
        <tr
            onClick={onClick}
            className={classNames(styles.tr, {
                [styles.trCursor]: !!onClick,
            })}
        >
            {children}
        </tr>
    )
}

export default Tr

import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import styles from './MonthErrorTime.module.scss'

type Props = {
    hours?: number
}

const MonthErrorTime: FC<Props> = ({ hours }) => {
    const { t } = useTranslation()
    return (
        <div>
            <h2 className={styles.header}>{t('587_Logs_hours_month')}</h2>

            <div className={styles.block}>
                <span>{hours} </span>
                <span> часов</span>
            </div>
        </div>
    )
}
export default MonthErrorTime

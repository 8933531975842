import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Editor } from '../../simples/modalEditor/Editor'
import styles from './ImportMessage.module.scss'

const editorClasses = {
    modalModal: styles.modal,
    buttons: styles.buttons,
}

type Props = {
    onSubmit: () => void
    onClose: () => void
}

const ImportMessage: FC<Props> = ({ onClose, onSubmit }) => {
    const { t } = useTranslation()

    return (
        <Editor
            header={t('140_ImportMessage_importCompletedSuccessfully')}
            submitButtonName={t('141_ImportMessage_ok')}
            submitDisabled={false}
            // submitLoading={loading}
            onSubmit={onSubmit}
            onCancel={onClose}
            classes={editorClasses}
        />
    )
}

export default ImportMessage

import classNames from 'classnames'
import React, { FC, useMemo } from 'react'
import styles from './SmallRemainsIndicator.module.scss'

type Props = {
    volume: number
    totalVolume: number
    color: string
    percents?: number
}

const SmallRemainsIndicator: FC<Props> = ({
    volume,
    color,
    totalVolume,
    percents,
}) => {
    const percent = useMemo(() => {
        // const onePercent = totalVolume/100
        const fillPercent = (volume * 100) / totalVolume
        return fillPercent
    }, [volume, totalVolume])
    return (
        <div className={styles.root}>
            <div
                style={{
                    background: color,
                    height: `${percents || percent}%`,
                }}
                className={classNames(styles.indicator)}
            ></div>
        </div>
    )
}

export default React.memo(SmallRemainsIndicator)
